import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import QRCode from 'react-qr-code';

import styles from '../../../styles/components/wallet/deposit.module.scss';
import { copyText } from '../../../utils/copyText';

import { Asset } from 'metaverse-js/lib/proto/model/asset';
import { useAppSelector } from '../../../stores';
import NoData from '../../NoData';

const cx = classNames.bind(styles);

interface PropsType {
  tokenSupport?: Asset;
  networkActive?: string;
  networks: (string | undefined)[];
}

const DepositAddress = ({ tokenSupport, networkActive, networks }: PropsType) => {
  // const address = tokenSupport?.address || '';
  const [address, setAddress] = useState<string>();

  const metaverseClient = useAppSelector((state) => state.app.metaverseClient);

  const fetchAddress = async (network: string) => {
    try {
      const res = await metaverseClient.getMyAddressDeposit(network);
      setAddress(res);
    } catch (error) {
      setAddress('');
      console.log(error);
    }
  };

  useEffect(() => {
    if (networkActive) {
      fetchAddress(networkActive);
    } else setAddress(undefined);
  }, [networkActive]);

  return (
    <>
      <div className={cx('deposit-address')}>
        {address ? (
          <>
            {' '}
            <div className={cx('content')}>
              <div>
                Deposit Address{' '}
                {networks.length === 1 && (
                  <span>
                    ( Note: Only <span className={cx('text-yellow')}>{networkActive}</span> )
                  </span>
                )}
              </div>
              {address && (
                <div className={cx('address')}>
                  <span className={cx('text-yellow')}>{address.substring(0, 4)}</span>
                  <span>{address.substring(4, address.length - 4)}</span>
                  <span className={cx('text-yellow')}>
                    {address.substring(address.length - 4, address.length)}
                  </span>
                </div>
              )}

              <button
                type="button"
                onClick={() => address && copyText(address)}
                className={cx('button-copy')}>
                Copy
              </button>
            </div>
            <div className={cx('address-qr')}>
              <div className={cx('qr')}>
                <QRCode size={110} value={address || ''} />
              </div>
            </div>
          </>
        ) : (
          <NoData />
        )}
      </div>

      <div className={cx('notice')}>
        <span className={cx('text-notice')}>NOTICE:</span>{' '}
        <span>
          {address
            ? `Send only ${tokenSupport?.symbol} to this address. Coins will be deposited after 2 network
          confirmations.`
            : 'Not support'}
        </span>
      </div>
    </>
  );
};

export default DepositAddress;
