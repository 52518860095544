import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import AccountReducer from './AccountSlice';
import LoadingReducer from './LoadingSlice';
import CrashGameReducer from './CrashGameSlice';
import ModalSlice from './ModalSlice';
import { myBalancesReducer } from './BalanceSlice';
import NotificationReducer from './NotificationSlice';
import { userInfoReducer } from './UserInformation';
import dropdownReducer from './DropdownSlice';
import { appReducer } from './AppSlice';
import { assetsInfoReducer } from './AssetsSlice';
import { priceInfoReducer } from './PriceSlice';
export const store = configureStore({
  reducer: {
    account: AccountReducer,
    loading: LoadingReducer,
    crashGame: CrashGameReducer,
    modal: ModalSlice,
    myBalances: myBalancesReducer,
    notification: NotificationReducer,
    userInfo: userInfoReducer,
    assets: assetsInfoReducer,
    dropdown: dropdownReducer,
    price: priceInfoReducer,
    app: appReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
