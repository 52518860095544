import classNames from 'classnames/bind';
import { totalmem } from 'os';
import styles from '../../../styles/components/affiliate/affiliate-management/affiliate-rewards.module.scss';
import ContactColorIcon from '../../Icons/ContactColorIcon';
import MushroomNodata from '../../Icons/MushroomNoData';
import Pagination from '../../UI/Pagination';

const cx = classNames.bind(styles);

export interface Friend {
  id: string;
  nickname: string;
  vipLevel: number;
  earned: string;
}
interface FriendsProps {
  friends: Friend[];
  page: number;
  pageSize: number;
  total?: number;
  handlePageChange: (page: number) => void;
}
const Friends = ({ friends, page, pageSize, total, handlePageChange }: FriendsProps) => {
  return (
    <div className={cx('friends')}>
      <div className={cx('all-friends')}>
        <ContactColorIcon /> All Friends <span className={cx('friend-number')}>{total}</span>
      </div>
      {friends.length > 0 ? (
        <div className={cx('friend-list')}>
          <div className={cx('header')}>
            <div className={cx('name', 'title')}>Nickname</div>
            <div className={cx('level', 'title')}>VIP level</div>
            <div className={cx('earned', 'title')}>Earned Me</div>
          </div>
          <div className={cx('list')}>
            {friends.map((friend) => (
              <div className={cx('record')} key={friend.id}>
                <div className={cx('name')}>{friend.nickname}</div>
                <div className={cx('level')}>V{friend.vipLevel}</div>
                <div className={cx('earned')}>${friend.earned}</div>
              </div>
            ))}
          </div>
          <Pagination
            pagination={{
              page: page,
              pageSize: pageSize,
              total: total
            }}
            changePage={handlePageChange}
          />
        </div>
      ) : (
        <div className={cx('no-data')}>
          <MushroomNodata />
          <div>
            No info yet <br />
            Invite friends to join you now!{' '}
          </div>
        </div>
      )}
    </div>
  );
};

export default Friends;
