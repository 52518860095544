import BigNumber from 'bignumber.js';
import { CurrencyConfig, Game, Player } from 'bybet-game-js/lib/schema/BaseGame';
import {
  CrashGameResult,
  CrashGameState,
  CrashGameTurnType
} from 'bybet-game-js/lib/schema/CrashGame';
import { TransactionStateType } from 'bybet-game-js/lib/schema/Transaction';
import 'chart.js/auto';
import 'chartjs-adapter-moment';
import classnames from 'classnames/bind';
import { find } from 'lodash';
import { AssetBalance } from 'metaverse-js/lib/proto/model/asset';
import { useEffect, useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { BREAKPOINT } from '../../../constant';
import { CRASH_CONFIG } from '../../../constant/game';
import useCountDown from '../../../hooks/useCountDown';
import { GameConfigType, VOLUME } from '../../../interfaces';
import { shortenAddress } from '../../../lib/shortenAddress';
import { storageService } from '../../../services/storage';
import { RootState, useAppSelector } from '../../../stores';
import { decreaseAssetBalance } from '../../../stores/BalanceSlice';
import { setCurrentTurn, setPlayers } from '../../../stores/CrashGameSlice';
import styles from '../../../styles/components/games/crash/game-play.module.scss';
import MuteVolumeIcon from '../../Icons/MuteVolumeIcon';
import VolumeIcon from '../../Icons/VolumeIcon';
import ClassicManual from './ClassicManual';
import Falldown from './Falldown';
import RewardPopup from './RewardPopup';
import TrenballManual from './TrenballManual';

const cx = classnames.bind(styles);

interface TypeGameTab {
  key: 'classic' | 'trenball';
  title: string;
}

const typeGameTabs = [
  { key: 'classic', title: 'Classic' },
  { key: 'trenball', title: 'Trendball' }
] as TypeGameTab[];

const initialCharData = { labels: [1], datasets: [0] };

const initalChartOptions = {
  events: [],
  responsive: true,
  scales: {
    yAxes: {
      type: 'linear',
      min: 1,
      max: 2,
      afterTickToLabelConversion: function (scaleInstance: any) {
        scaleInstance.ticks[0].label = '';

        scaleInstance.ticks[scaleInstance.ticks.length - 1].label = '';
      },
      ticks: {
        color: 'rgba(255, 255, 255,1)',
        maxTicksLimit: 6,
        callback: function (value: any, index: any, values: any) {
          return +parseFloat(value).toFixed(1) + 'x';
        }
      },
      grid: {
        tickColor: '#C9C9C9E8',
        tickWidth: 1,
        tickLength: 13,
        borderWidth: 2,
        borderDash: [5, 5],
        color: [
          '#4A4A4A',
          '#00000000',
          '#00000000',
          '#00000000',
          '#00000000',
          '#00000000',
          '#00000000',
          '#00000000',
          '#00000000',
          '#00000000',
          '#00000000',
          '#00000000'
        ]
      }
    },
    xAxes: {
      type: 'linear',
      min: 0,
      max: 8,
      ticks: {
        color: 'rgba(255, 255, 255,1)',
        maxTicksLimit: 13,
        callback: function (value: any, index: any, values: any) {
          if (value % 2 == 0) return value;
        }
      },
      grid: {
        display: false
      }
    }
  },
  interaction: {
    mode: 'index',
    intersect: false
  },
  plugins: {
    legend: { display: false }
  }
};

const countDownTimeInit = 6000;
const gameAudios = {
  playing: './audios/playing_crash.wav',
  lose: './audios/lose.wav',
  win: './audios/winning_crash.mp3'
};
export default function GamePlay() {
  const { time, startCountDown, endCountDown } = useCountDown(countDownTimeInit);
  const chartRef = useRef();
  const audioRef = useRef<any>();
  const audioPlayingRef = useRef<any>();

  const spaceshipRef = useRef<HTMLCanvasElement>(null);
  const position = useRef([0, 0]);
  const [stateCrash, setStateCrash] = useState<CrashGameState>();
  const [isStart, setIsStart] = useState<boolean>(false);
  const [chartData, setChartData] = useState<any>(initialCharData);
  const [chartOptions, setChartOptions] = useState<any>(initalChartOptions);
  const [liveMultiplier, setLiveMultiplier] = useState(1.0);
  const [typeGame, setTypeGame] = useState<'classic' | 'trenball'>('classic');
  const [timeBang, setTimeBang] = useState(0);
  const [resultInfo, setResultInfo] = useState<CrashGameResult | undefined>();
  const [canStartGame, setCanStartGame] = useState(false);
  const [currencyConfig, setCurrencyConfig] = useState<CurrencyConfig>();
  const [gameHistory, setGameHistory] = useState<Game[]>([]);
  const crashGameIns = useAppSelector((state: RootState) => state.crashGame.crashGameIns);
  const currentTurn = useAppSelector((state: RootState) => state.crashGame.currentTurn);
  const currency = useSelector((state: RootState) => state.account.currency);
  const dispatch = useDispatch();
  const [volume, setVolume] = useState(VOLUME.NORMAL);

  function getGradient(ctx: any, chartArea: any) {
    let gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);

    gradient.addColorStop(0, '#FE83F8');
    gradient.addColorStop(1, '#01FFFF');

    return gradient;
  }

  const getLiveMultiplier = (time_elapsed: number) => {
    return 1.0024 * Math.pow(1.0718, time_elapsed);
  };

  const getGameProfile = async () => {
    try {
      let res = await crashGameIns?.getGameInformation();
      if (res) {
        // setBankRoll(res.bankRollAccount);

        let currencyConfig = find(res.currencyConfig, { symbol: currency.symbol?.toUpperCase() });
        setCurrencyConfig(currencyConfig);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getGames = async () => {
    try {
      const queryParams = {
        limit: 5,
        offset: 0
      };
      let res = await crashGameIns?.getGames(queryParams);
      if (res) {
        setGameHistory(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPayoutStatus = (payout: number) => {
    if (payout < 2) {
      return 'red';
    }
    if (payout > 10) {
      return 'moon';
    }
    return 'green';
  };

  useEffect(() => {
    if (!crashGameIns) return;
    crashGameIns.onGameResult((result) => {
      setResultInfo(result);
      if (result.isWin) {
        audioRef.current?.play();
      }
    });
    crashGameIns.onTransactionState((txnState) => {
      console.log('Crash game transaction state', txnState);
      if (txnState.state === TransactionStateType.CREATED) {
        const assetBalance = {
          assetSymbol: txnState.assetSymbol,
          availableAssetAmount: txnState.assetAmount,
          totalAssetAmount: txnState.assetAmount
        } as AssetBalance;
        dispatch(decreaseAssetBalance(assetBalance));
      }
    });

    crashGameIns.onBang((game) => {
      audioPlayingRef.current?.pause();
      setTimeBang(game?.resultNumber || 0);
    });

    crashGameIns.onLeave((code) => {
      dispatch(setCurrentTurn(CrashGameTurnType.IDLE));
      setIsStart(false);
      setChartData(initialCharData);
      setChartOptions(initalChartOptions);
    });

    crashGameIns.onStateChange((state) => {
      dispatch(setCurrentTurn(state?.currentTurn));
      setStateCrash(state);

      let players = [] as Player[];
      state.players.forEach((player) => {
        players.push(player);
      });
      dispatch(setPlayers(players));
    });

    getGames();
  }, [crashGameIns]);
  useEffect(() => {
    const crashConfig = storageService.getGameConfig(CRASH_CONFIG);
    if (crashConfig[GameConfigType.SOUND_DISABLE]) {
      audioRef.current.volume = 0;
      audioPlayingRef.current.volume = 0;
      setVolume(VOLUME.MUTE);
    }
  }, []);

  const handleVolume = () => {
    const newVolume = volume === VOLUME.MUTE ? VOLUME.NORMAL : VOLUME.MUTE;
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
    audioPlayingRef.current.volume = newVolume;
    storageService.saveGameConfig(CRASH_CONFIG, GameConfigType.SOUND_DISABLE, !Boolean(newVolume));
  };
  useEffect(() => {
    if (crashGameIns && currency.symbol.length > 0) {
      getGameProfile();
    }
  }, [crashGameIns, currency.symbol]);

  useEffect(() => {
    if (currentTurn) {
      if (currentTurn === CrashGameTurnType.WAITING_FOR_BET) {
        getGames();
      }

      if (currentTurn === CrashGameTurnType.WAITING_FOR_RESULT && canStartGame) {
        endCountDown();
        setIsStart(true);
        audioPlayingRef.current?.play();
      } else {
        setIsStart(false);
      }

      if (currentTurn === CrashGameTurnType.WAITING_FOR_BET) {
        setTimeBang(0);
        let timeCountDownStart = stateCrash?.lastTurnTimestamp
          ? countDownTimeInit - (Date.now() - stateCrash?.lastTurnTimestamp)
          : 0;
        startCountDown(timeCountDownStart >= 0 ? timeCountDownStart : 0);
      }

      if (!canStartGame && currentTurn !== CrashGameTurnType.WAITING_FOR_RESULT) {
        setCanStartGame(true);
      }
    }
  }, [currentTurn]);

  useEffect(() => {
    let gameCounter: any;
    if (isStart && stateCrash) {
      let multiplierCount: number[] = [];
      let timeCount_xaxis: number[] = [];
      let realCounter_yaxis = 5;
      let liveMultiplier = 1.0;
      let timeStart = Date.now();
      gameCounter = setInterval(() => {
        let time_elapsed = (Date.now() - timeStart) / 1000.0;
        liveMultiplier = getLiveMultiplier(time_elapsed);
        setLiveMultiplier(liveMultiplier);
        if (multiplierCount.length < 1) {
          multiplierCount = multiplierCount.concat([1]);
          timeCount_xaxis = timeCount_xaxis.concat([0]);
        } else if (realCounter_yaxis % 2 == 0) {
          multiplierCount = multiplierCount.concat([liveMultiplier]);
          timeCount_xaxis = timeCount_xaxis.concat([time_elapsed]);
        }
        realCounter_yaxis += 1;

        setChartData({
          labels: timeCount_xaxis,

          datasets: [
            {
              data: multiplierCount,
              backgroundColor: 'rgb(255, 99, 132)',
              borderColor: function (context: any) {
                const chart = context.chart;
                const { ctx, chartArea } = chart;
                if (!chartArea) {
                  // This case happens on initial chart load
                  return;
                }
                return getGradient(ctx, chartArea);
              },
              color: 'rgba(0,0,0,1)',

              pointRadius: 0,
              borderDash: [],
              lineTension: 0.4
            }
          ]
        });

        setChartOptions({
          events: [],
          responsive: true,
          scales: {
            yAxes: {
              type: 'linear',
              min: 1,
              max: liveMultiplier > 2 ? liveMultiplier : 2,
              afterTickToLabelConversion: function (scaleInstance: any) {
                scaleInstance.ticks[0].label = '';

                scaleInstance.ticks[scaleInstance.ticks.length - 1].label = '';
              },
              ticks: {
                color: 'rgba(255, 255, 255,1)',
                maxTicksLimit: 6,
                callback: function (value: any, index: any, values: any) {
                  // if (value >= 2 && value % 2 == 0)
                  return new BigNumber(+parseFloat(value)).toFixed(1) + 'x';
                  // if (value < 2 && value >= 1.2) {
                  //   return +parseFloat(value).toFixed(1) + "x";
                  // }
                }
              },
              grid: {
                // display: false,
                tickColor: '#C9C9C9E8',
                tickWidth: 1,
                tickLength: 13,
                borderWidth: 2,
                borderDash: [5, 5],
                color: [
                  '#4A4A4A',
                  '#00000000',
                  '#00000000',
                  '#00000000',
                  '#00000000',
                  '#00000000',
                  '#00000000',
                  '#00000000',
                  '#00000000',
                  '#00000000',
                  '#00000000',
                  '#00000000'
                ]
              }
            },
            xAxes: {
              type: 'linear',
              min: 0,
              max: time_elapsed > 8 ? time_elapsed : 8,
              ticks: {
                color: 'rgba(255, 255, 255,1)',
                maxTicksLimit: 13,
                callback: function (value: any, index: any, values: any) {
                  if (value % 10 === 0 && time_elapsed > 22) {
                    return value;
                  } else if (value % 2 === 0 && time_elapsed <= 22) {
                    return value;
                  }
                }
              },
              grid: {
                display: false
              }
            }
          },
          interaction: {
            mode: 'index',
            intersect: false
          },
          plugins: {
            legend: { display: false }
          },
          animation: {
            x: {
              type: 'number',
              easing: 'linear',
              duration: 0,
              from: 5,
              delay: 0
            },
            y: {
              type: 'number',
              easing: 'linear',
              duration: 0,
              from: 5,
              delay: 0
            },
            loop: true
          }
        });
      }, 1);
    } else {
      clearInterval(gameCounter);
      setChartData(initialCharData);
      setChartOptions(initalChartOptions);
    }
    return () => {
      clearInterval(gameCounter);
    };
  }, [isStart]);

  useEffect(() => {
    if (resultInfo) {
      const timeCountResult = setTimeout(() => {
        setResultInfo(undefined);
      }, 1500);

      return () => {
        clearTimeout(timeCountResult);
      };
    }
  }, [resultInfo]);

  useEffect(() => {
    let timeCounter: any;

    if (spaceshipRef.current) {
      const canvas = spaceshipRef.current;
      const ctx = canvas?.getContext('2d');

      if (currentTurn === CrashGameTurnType.WAITING_FOR_RESULT && ctx && canStartGame) {
        timeCounter = setInterval(() => {
          const isMobile = window.innerWidth < BREAKPOINT.mobile;
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          let base_image = new Image();
          base_image.src = '/images/games/spaceship.png';
          base_image.width = isMobile ? 60 : 113;
          ctx.drawImage(
            base_image,
            isMobile ? position.current[0] - 20 : position.current[0] - 40,
            isMobile ? position.current[1] - 5 : position.current[1] + 5,
            isMobile ? 65 : 113,
            isMobile ? 32 : 66
          );
        }, 1);
      } else if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        clearInterval(timeCounter);
      }
    }

    return () => {
      clearInterval(timeCounter);
    };
  }, [spaceshipRef.current, currentTurn, canStartGame]);

  return (
    <div className={cx('root')}>
      <div className={cx('game-info')}>
        <div className={cx('history-container')}>
          {gameHistory.map((history) => (
            <div key={history.gameId} className={cx('history-item')}>
              <div className={cx('ball', `${getPayoutStatus(history.resultNumber || 0)}-ball`)} />
              <div className={cx('info')}>
                <p className={cx('game-id')}>{shortenAddress(history.gameId, 2)}</p>
                <p className={cx('payout', `${getPayoutStatus(history.resultNumber || 0)}`)}>
                  {history.resultNumber}x
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className={cx('volume')} onClick={() => handleVolume()}>
          {volume === VOLUME.NORMAL ? <VolumeIcon /> : <MuteVolumeIcon />}
        </div>
        {/* <div>
          <p>Bankroll</p>
          <p>{new BigNumber(bankRoll || 0).dp(8, 1).toString()}</p>
        </div> */}
      </div>
      <div className={cx('monitor-container')}>
        <div className={cx('basically-the-graph')}>
          <Chart
            type="line"
            data={chartData}
            options={chartOptions}
            ref={chartRef}
            plugins={[
              {
                id: 'custom',
                afterDatasetDraw: (chart: any, args: any, options: any) => {
                  const data = args?.meta?.data as any[];
                  position.current = [data[data.length - 1]?.x, data[data.length - 1]?.y];
                }
              }
            ]}
          />
        </div>
        <div className={cx('custom-spaceship')}>
          <canvas id={'spaceship-canvas'} ref={spaceshipRef} width="2000" height="1000" />
        </div>
        <div className={cx('animation-fall-down')}>
          <Falldown xCoordinate={position.current[0]} yCoordinate={position.current[1]} />
        </div>
        <div className={cx('start-in')}>
          {!canStartGame ? (
            <p className={cx('wait-next-round')}>Waiting for the next round</p>
          ) : !isStart ? (
            timeBang > 0 ? (
              <p className={cx('bang')}>Bang @{new BigNumber(timeBang).toFixed(2)}x</p>
            ) : (
              <p>
                Start in: <span>{new BigNumber(time).div(1000).toFormat(1)}s</span>
              </p>
            )
          ) : (
            <p>{liveMultiplier.toFixed(2)}x</p>
          )}
        </div>

        {resultInfo && <RewardPopup resultInfo={resultInfo} />}
      </div>

      <div className={cx('tab-container')}>
        {/* {typeGameTabs.map((tab) => (
          <div
            key={tab.key}
            className={cx('tab-item', tab.key === typeGame && 'tab-item-active')}
            onClick={() => {
              setTypeGame(tab.key);
            }}>
            <p>{tab.title}</p>
          </div>
        ))} */}
      </div>

      <div className={cx('game-controller-container')}>
        <div className={cx('body')}>
          {/* <div className={cx('tab-type-container')}>
            <div className={cx('tab-type-item')}>
              <p>Manual</p>
            </div>
            <div className={cx('tab-type-item', 'tab-type-item-active')}>
              <p>Auto</p>
            </div>
          </div> */}

          <div className={cx('form-controller')}>
            {typeGame === 'trenball' ? (
              <TrenballManual />
            ) : (
              <ClassicManual currencyConfig={currencyConfig} resultInfo={resultInfo} />
            )}
          </div>
        </div>

        <div className={cx('footer')}>
          {/* <div className={cx('footer-left-container')}>
            <div className={cx('footer-item-left')}>
              <StarIcon />
              <p>567</p>
            </div>
            <div className={cx('footer-item-left')}>
              <HeartIcon />
              <p>567</p>
            </div>
            <div className={cx('footer-item-left')}>
              <TelegramIcon width={20} height={20} />
            </div>
          </div>
          <div className={cx('footer-right-container')}>
            <KeyboardIcon />
            <StatsIcon />
            <QuestionCircleIcon />
          </div> */}
        </div>
      </div>
      <audio
        ref={audioPlayingRef}
        src={gameAudios.playing}
        onEnded={() => audioPlayingRef.current.play()}
      />
      <audio ref={audioRef} src={gameAudios.win} />
    </div>
  );
}
