import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface NotificationState {
  unSeenCount: number;
}

const initialState: NotificationState = {
  unSeenCount: 0
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setUnseenCount: (state, action: PayloadAction<number>) => {
      state.unSeenCount = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setUnseenCount } = notificationSlice.actions;

export default notificationSlice.reducer;
