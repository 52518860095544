import React, { useEffect, useState } from 'react';
import Container from '../layouts/Container';
import TabWallet from './TabWallet';
import styles from '../../styles/components/wallet/layout-wallet.module.scss';
import classNames from 'classnames/bind';
import DatePicker from 'react-datepicker';
import DateIcon from '../Icons/DateIcon';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../constant/routes';
import moment from 'moment';
import _ from 'lodash';
import useQuery from '../../hooks/useQuery';

interface PropsType {
  children: React.ReactNode;
}

const cx = classNames.bind(styles);

const LayoutWallet = ({ children }: PropsType) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const queryParams = useQuery();
  const location = useLocation();
  const navigate = useNavigate();

  const onChangeDate = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    const query = _.pickBy(
      {
        ...queryParams,
        fromDate: start && moment(start).startOf('day').unix(),
        toDate: end && moment(end).endOf('day').unix()
      },
      _.identity
    );

    navigate({
      search: createSearchParams(query).toString()
    });
  };

  return (
    <>
      <div className={cx('header')}>
        <TabWallet />
        {location.pathname === ROUTES.WALLET.TRANSACTIONS && (
          <div className={cx('date-picker', 'date-picker-custom-ui')}>
            <DatePicker
              placeholderText="Select date"
              selectsRange
              selected={startDate}
              endDate={endDate}
              startDate={startDate}
              onChange={onChangeDate}
              isClearable
              dateFormat="dd/MM/yyyy"
            />
            <div className={cx('date-icon')}>
              <DateIcon />
            </div>
          </div>
        )}
      </div>
      <div className={cx('content')}>{children}</div>
    </>
  );
};

export default LayoutWallet;
