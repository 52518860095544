import { GameInformation } from '../interfaces/Game';

export const GAME_INTRODUCTION: { [key: string]: GameInformation } = {
  RING_OF_FORTUNE: {
    rtp: 99,
    provider: 'BYBET',
    type: 'Provably fairs',
    shortDescription: `Do you have what it takes to win amazing prizes? We're talking about the exciting roulette-style Ring of Fortune at BYBET, where you only need to select among a variety of colors: green, red, blue, or gray, and win if the roulette lands on your color.`,
    description: `<p><span style="font-weight: 400;">Do you have what it takes to win these amazing prizes? In this case we are talking about Ring of Fortune in a fun roulette style at Bybet, where you simply choose from different colors, and win if the roulette wheel lands on you chosen ones.</span></p>
     <p><span style="font-weight: 400;">Ring of Fortune does not try too hard to be anything else. Either way, it offers a hypnotic form of roulette-style entertainment, allowing players to win bets as the color wheel spins around to land on the color of their choice.</span></p>
     <p><span style="font-weight: 400;">When Coco takes a break from his job at the factory, you'll find him ecstatic as he watches the vibrating wheel of the fortune wheel spin, wishing it would eventually land in a green area. Plus, there's an opportunity waiting for players! The green area is in demand, mainly because it contains the highest 99x multiplier, providing players with incredible payouts to take advantage of!</span></p>
     <p><span style="font-weight: 400;">To play Ring of Fortune, all you have to do is bet on at least one or more colors. After doing this, click on the 'Bet' button. Here you will see that the wheel begins to spin. All you have to do then is wait for the wheel to stop at the color you placed your bet on &ndash; if it does, you win.</span></p>
     <p><b>Ring of Fortune: Slot Verdict</b></p>
     <p><span style="font-weight: 400;">Ring of Fortune is a progressive jackpot, unlike other slot games. Other players who have used video slots may find it unappealing. However, the appeal of the game Ring of Fortune is its simple yet engaging way to allow players to enjoy an enjoyable gaming experience without all the flashy graphics or icons. Each spin makes you want to bet and gamble more.</span></p>`
  },
  CLASSIC_DICE: {
    rtp: 99,
    provider: 'BYBET',
    type: 'Provably fairs',
    shortDescription: `Bybet's Classic Dice is a perfect example of why this game has led the industry for many years. It's a game of probability established by computing and algorithmicizing the hash dice of the blockchain. Hold tight while the dice roll! The closer your predicted number is to the randomly generated number, the higher your probability of winning!`,
    description: `<p><span style="font-weight: 400;">Bybet&rsquo;s Classic Dice is one of the best crypto games that has made Bybet a leader in the crypto betting platform industry. Like other gaming-house games, Classic Dice is a game of probability. You will be playing with chance, so you need to make the smartest predictions possible.</span></p>
    <p><span style="font-weight: 400;">This game is very easy to follow compared to other dice games. This is a 100-sided virtual dice roll where you can customize the settings to suit your gaming preferences. The outcome of the game offers many possibilities, but every gaming-house game at Bybet is guaranteed to be fair to provide the best online betting experience for every player. Classic dice only get 1% of the house edge, which means 99% of winnings are returned to the player.</span></p>
    <p><span style="font-weight: 400;">If you are unsure of the outcome of a dice game, you can verify the outcome using Bybet&rsquo;s Fair feature. This mechanism proves that the results are randomly generated without any bots. You can authenticate the game by entering the client and server seeds and hashing the seed. This information is sent to the player after you place your bet and the round is over.</span></p>
    <p><span style="font-weight: 400;">Due to Bybet&rsquo;s proven fair algorithm, you are sure that there are no background manipulations happening during the round.</span></p>
    <p><b>Classic Dice at Bybet: Verdict</b></p>
    <p><span style="font-weight: 400;">Because it is a regular game in most betting platforms, whether traditional or crypto, it constantly attracts players. Its unpredictable results are calculated using hash values and blockchain algorithms, which means 100% transparency is guaranteed for every round. You will be able to play fairly and get almost all your winnings as the game has only 1% House Edge.</span></p>
    <p><span style="font-weight: 400;">When you play the game you will also have a chance to get a crypto bonus. All details about the crypto amount are displayed in the interface, so you can control the coefficient whenever you want. Major cryptocurrencies such as Ethereum, Bitcoin, Litecoin, Dogecoin and others are accepted by Bybet, so if you own a cryptocurrency you can start playing the classic Dice game. Although deposit transactions are completed through a series of cycles, it will only take 5-10 minutes to be accepted by the blockchain network.</span></p>`
  },
  CRASH: {
    rtp: 99,
    maxWin: 100000,
    provider: 'BYBET',
    type: 'Provably fairs',
    shortDescription:
      'The Crash is a multiplayer game where you can join a pool and bet with multiplayers. In this mode, you are allowed to withdraw money after betting. The amount you can win will depend on how many players bet on the round.',
    description: `<p><span style="font-weight: 400;">This crypto-based game is one of the simplest among all the blockchain gaming-house games. It involves betting on the outcome of a game or event.</span></p>
    <p><span style="font-weight: 400;">If you are starting to play crypto betting, this game can be a good one for you because of its simplicity. Crash at Bybet is one of the most transparent and fair ways to earn rewards online. The game is developed with an easy-to-navigate user interface and interesting features that attract players to bet.</span></p>
    <p><span style="font-weight: 400;">This is an easy game to make and players do not need to follow certain instructions to play the game. Crash does not need to be downloaded but can be played directly from the browser on a smartphone or computer.</span></p>
    <p><span style="font-weight: 400;">The Crash is one of the games slated for release when cryptocurrencies are introduced into online betting platforms. If you are new to this, betting is actually very easy. You can even master the game just by playing a few innings. Before playing, first carefully study the interface of the game because everything that happens in the game is real.</span></p>
    <p><b>Crash at Bybet: Features</b></p>
    <p><span style="font-weight: 400;">To start playing, you will have time to choose a betting currency. The game is not only for Bitcoin holders. It is also available for Ethereum, Litecoin, and other digital currencies. If you are new, you can bet on the entry-level amount to avoid huge losses. You need to have a strategy to predict where the line will crash. Remember to pick a spot before the line crashes. It can crash anytime, even if the game is just starting, so you need to choose your spot wisely.</span></p>
    <p><span style="font-weight: 400;">You win the game when the line reaches the selected spot or higher. Once the line hangs before reaching your chosen spot, you lose. That is how easy this crypto game is. However, it is important that you keep your instincts working. If you think it will crash before reaching your chosen spot, you can quit the game anytime.</span></p>
    <p><span style="font-weight: 400;">The Crash is a multiplayer game where you can choose between Classic and Classic and Trenball modes. In classic mode, you are allowed to withdraw money after betting. The amount you can win will depend on how many players bet on the spin. For Trenball mode, you will bet on the line color that can be a factor in your win.</span></p>
    <p><span style="font-weight: 400;">Understanding any betting game can increase your chances of winning as there is no correct strategy for playing crypto betting games. There are times when you will lose and win. If you don't mind risking your crypto, you can bet for a higher score in Crash, which means when you win you can go big. In all gaming-house games, the player is responsible for the growth or loss of their money.</span></p>
    <p><span style="font-weight: 400;">For better gameplay, it is recommended to use all the game features. You can end the round early using the withdrawal option before the line is blocked. You don't have to wait for the line to reach the multiplier you bet on. Once you find the right time to end the game before the line is locked, your bet can be multiplied.</span></p>
    <p><span style="font-weight: 400;">Remember not to consider crypto betting games the only way to make your money. Only play if you want to have fun. Its algorithm is random, so you will never know when you will win.</span></p>
    <p><span style="font-weight: 400;">You are allowed to place any amount above the minimum and maximum amount to bet. For starters, you can try out the game by placing a bet with the minimum amount and see how the game plays out. By betting gradually, you will understand more about the game and avoid losing money in the future.</span></p>
    <p><span style="font-weight: 400;">Some players bet the maximum amount and wait for the right moment to withdraw their bets before the line crashes.</span></p>
    <p><b>Crash in Bybet: Final Verdict</b></p>
    <p><span style="font-weight: 400;">If you are looking for an alternative crypto game, Crash is a great choice as it is fast and rounds can end quickly if you want to play a new game after you lose, which increases Add excitement to the game. You are a beginner; you can first try to watch a few rounds or bet on a minimum amount.</span></p>
    <p><span style="font-weight: 400;">Bybet has a user interface that brings joy to every player, making it a top choice for crypto betting players. It also provides fair gaming and player privacy. This online betting site also offers limited promotions and bonuses that can grow your cryptocurrency asset.</span></p>
    `
  }
};

export const BET_DECIMAL_CONFIG = 8;
export const DEFAULT_VALUE = '0.00000000';
export const CLASSIC_DICE_CONFIG = 'bybet_classic_dice';
export const RING_CONFIG = 'bybet_ring_of_fortune';
export const CRASH_CONFIG = 'bybet_crash';
