import classNames from 'classnames/bind';
import styles from '../../styles/components/modal/modal-auth.module.scss';
import InputPopup from '../UI/InputPopup';
import { useForm } from 'react-hook-form';
import { REGEX } from '../../constant';
import { PASSWORD } from '../../constant/password';
import { toast } from 'react-toastify';
import { useState } from 'react';
import EyePasswordCloseIcon from '../Icons/EyePasswordCloseIcon';
import EyePasswordIcon from '../Icons/EyePasswordIcon';
import { useAppSelector } from '../../stores';
const cx = classNames.bind(styles);
interface PropsType {
  paramReset: string | null;
  toggle: () => void;
}
type RegisterForm = {
  password: string;
  confirmPassword: string;
};
const ConfirmResetPassword = ({ paramReset, toggle }: PropsType) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm<RegisterForm>();
  const identity = useAppSelector((state) => state.app.userIdentityClient);

  const [showCurrentPsw, setShowCurrentPsw] = useState(false);
  const [showPsw, setShowPsw] = useState(false);
  const [showConfirmPsw, setShowConfirmPsw] = useState(false);
  const [loading, setLoading] = useState(false);
  const onSubmit = async () => {
    setLoading(true);
    try {
      const res = await identity.resetPassword({
        token: String(paramReset),
        newPassword: getValues('password')
      });
      if (res.data) {
        toast.success('Update new password successfully');
        setLoading(false);
        toggle();
      }
    } catch (error: any) {
      toast.error(error?.response.data.message || error);
      toggle();
      setLoading(false);
    }
  };
  const handleShowPassword = (name: string) => {
    if (name === 'password') {
      setShowPsw(!showPsw);
    }
    if (name === 'confirmPassword') {
      setShowConfirmPsw(!showConfirmPsw);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={cx('verification')}>
        <img
          className={cx('send-verification')}
          src="/images/Protection.png"
          alt="send-verification"
        />
        <div className={cx('verifycation-complete')}>Reset Password</div>
      </div>
      <div className={cx('form-item')}>
        <InputPopup
          {...register('password', {
            required: PASSWORD.REQUIRED_FIELD,
            pattern: {
              value: new RegExp(REGEX.PASSWORD),
              message: PASSWORD.REQUIRED_PASSWORD
            }
          })}
          placeholder="New password"
          type={showPsw ? 'text' : 'password'}
        />
        <div className={cx('eye-icon')} onClick={() => handleShowPassword('password')}>
          {showPsw ? <EyePasswordCloseIcon /> : <EyePasswordIcon />}
        </div>
        <div className={cx('error')}>{errors.password && errors.password?.message}</div>
      </div>
      <div className={cx('form-item')}>
        <InputPopup
          {...register('confirmPassword', {
            required: PASSWORD.REQUIRED_FIELD,
            validate: {
              matchPassword: (value) =>
                value === getValues('password') || PASSWORD.REQUIRED_CONFIRM_PWD
            }
          })}
          placeholder="Confirm password"
          type={showConfirmPsw ? 'text' : 'password'}
        />
        <div className={cx('eye-icon')} onClick={() => handleShowPassword('confirmPassword')}>
          {showConfirmPsw ? <EyePasswordCloseIcon /> : <EyePasswordIcon />}
        </div>
        <div className={cx('error')}>
          {errors.confirmPassword && <p role="alert">{errors.confirmPassword?.message}</p>}
        </div>
      </div>
      <div className={cx('verification')}>
        <button className={cx('confirm', 'button-active')} type="submit" disabled={loading}>
          Submit
        </button>
      </div>
    </form>
  );
};

export default ConfirmResetPassword;
