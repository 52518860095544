import Affiliate from '../components/Affiliate';
import AffiliateMangement from '../components/Affiliate/AffiliateManagement';
import { RootState, useAppSelector } from '../stores';

const AffiliatePage = () => {
  const isLoggedIn = useAppSelector((state: RootState) => state.account.isLoggedIn);
  return <>{isLoggedIn ? <AffiliateMangement /> : <Affiliate />}</>;
};

export default AffiliatePage;
