export default function Star() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M8 0L10.1607 5.83927L16 8L10.1607 10.1607L8 16L5.83927 10.1607L0 8L5.83927 5.83927L8 0Z"
        fill="url(#paint0_linear_2159_41193)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2159_41193"
          x1="-3.70241"
          y1="8.00042"
          x2="24.7408"
          y2="8.00042"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#ECA740" />
          <stop offset="0.3198" stopColor="#FFE58A" />
          <stop offset="0.6802" stopColor="#DD7617" />
          <stop offset="1" stopColor="#FFEE95" />
        </linearGradient>
      </defs>
    </svg>
  );
}
