import React from 'react';

const ZoomAndAdjustIcon = () => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_783_24143)">
        <path
          d="M17.9312 1.32787e-08H2.06887C1.7972 -3.0767e-05 1.52818 0.0534507 1.27718 0.157391C1.02618 0.26133 0.798113 0.413693 0.605999 0.605778C0.413885 0.797863 0.261488 1.02591 0.15751 1.27689C0.0535324 1.52788 1.02583e-05 1.79688 0 2.06855L0 17.9314C1.53887e-05 18.2031 0.0535407 18.4721 0.15752 18.7231C0.261499 18.9741 0.413896 19.2021 0.606008 19.3942C0.798121 19.5863 1.02619 19.7386 1.27718 19.8426C1.52818 19.9465 1.7972 20 2.06887 20H17.9312C18.2029 20 18.4719 19.9465 18.7229 19.8426C18.9739 19.7386 19.2019 19.5863 19.394 19.3942C19.5861 19.2021 19.7385 18.974 19.8425 18.7231C19.9465 18.4721 20 18.2031 20 17.9314V2.06855C20 1.79689 19.9465 1.52789 19.8425 1.2769C19.7385 1.02592 19.5861 0.797879 19.394 0.605794C19.2019 0.413709 18.9739 0.261345 18.7229 0.157403C18.4719 0.0534599 18.2029 -2.56368e-05 17.9312 1.32787e-08V1.32787e-08ZM17.2417 15.1727H11.0345V13.4482H17.2417V15.1727ZM1.37891 18.6211V2.06855C1.3792 1.88568 1.45199 1.71039 1.58133 1.58111C1.71067 1.45183 1.886 1.37911 2.06887 1.37891H18.6211L1.37891 18.6211ZM9.31016 7.24184H7.24113V9.31047H5.51742V7.24184H3.44805V5.51742H5.51742V3.44824H7.24113V5.51742H9.31016V7.24184Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_783_24143">
          <rect width={20} height={20} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ZoomAndAdjustIcon;
