import React from 'react';

const TokenIcon = () => {
  return (
    <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5 3.44063C15.1534 2.03145 13.4127 1.13112 11.5 1V3.20621C12.8975 3.33009 14.1709 3.98532 15.1744 5L16.5 3.44063Z"
        fill="url(#paint0_linear_783_26367)"
      />
      <path
        d="M17.2938 9H19.5C19.3689 7.08729 18.4686 5.34664 17.0594 4L15.5 5.32558C16.5147 6.32908 17.1699 7.60249 17.2938 9Z"
        fill="url(#paint1_linear_783_26367)"
      />
      <path
        d="M3.70621 11H1.5C1.63112 12.9127 2.53145 14.6534 3.94063 16L5.5 14.6744C4.48532 13.6709 3.83009 12.3975 3.70621 11Z"
        fill="url(#paint2_linear_783_26367)"
      />
      <path
        d="M5.5 5.32558L3.94063 4C2.53145 5.34664 1.63112 7.08729 1.5 9H3.70621C3.83009 7.60249 4.48532 6.32908 5.5 5.32558Z"
        fill="url(#paint3_linear_783_26367)"
      />
      <path
        d="M9.5 3.20621V1C7.58729 1.13112 5.84664 2.03145 4.5 3.44063L5.82558 5C6.82908 3.98532 8.10249 3.33009 9.5 3.20621Z"
        fill="url(#paint4_linear_783_26367)"
      />
      <path
        d="M4.5 16.5594C5.84664 17.9686 7.58729 18.8689 9.5 19V16.7938C8.10249 16.6699 6.82908 16.0147 5.82558 15L4.5 16.5594Z"
        fill="url(#paint5_linear_783_26367)"
      />
      <path
        d="M11.5 16.7938V19C13.4127 18.8689 15.1534 17.9686 16.5 16.5594L15.1744 15C14.1709 16.0147 12.8975 16.6699 11.5 16.7938Z"
        fill="url(#paint6_linear_783_26367)"
      />
      <path
        d="M15.5 14.6744L17.0594 16C18.4686 14.6534 19.3689 12.9127 19.5 11H17.2938C17.1699 12.3975 16.5147 13.6709 15.5 14.6744Z"
        fill="url(#paint7_linear_783_26367)"
      />
      <path
        d="M16.5 10C16.5 6.69166 13.8083 4 10.5 4C7.19166 4 4.5 6.69166 4.5 10C4.5 13.3083 7.19166 16 10.5 16C13.8083 16 16.5 13.3083 16.5 10ZM11.2053 13.0701H11.1917H11.0381V13.7604C11.0381 14.0578 10.797 14.2985 10.5 14.2985C10.203 14.2985 9.96188 14.0578 9.96188 13.7604V13.0701L9.71579 13.0594C9.09552 13.0547 8.51256 12.7788 8.11543 12.3024C7.92529 12.0743 7.95614 11.7349 8.18466 11.5448C8.40856 11.3579 8.74685 11.3809 8.94233 11.6136C9.13605 11.8461 9.4209 11.9917 9.72368 11.9939H11.1996C11.6055 11.9946 11.9328 11.6712 11.9328 11.266C11.9328 10.8646 11.6064 10.5381 11.2049 10.5381C10.684 10.5381 10.3452 10.5381 9.79507 10.5381C8.80027 10.5381 7.99094 9.72879 7.99094 8.73399C7.99094 7.73883 8.79672 6.92387 9.80834 6.92987H9.96188V6.23964C9.96188 5.94224 10.203 5.70153 10.5 5.70153C10.797 5.70153 11.0381 5.94224 11.0381 6.23964V6.92987H11.1612C11.9155 6.93517 12.5814 7.35509 12.9244 7.92108C13.0844 8.17148 13.0112 8.50404 12.7604 8.66404C12.5257 8.81378 12.1919 8.77048 12.0103 8.48897C11.8227 8.19552 11.502 8.01865 11.1533 8.01579H9.80045C9.40231 8.01141 9.06717 8.3242 9.06717 8.73399C9.06717 9.13543 9.39363 9.46188 9.79507 9.46188H11.2049C12.1997 9.46188 13.0091 10.2712 13.0091 11.266C13.0091 12.2623 12.2037 13.0701 11.2053 13.0701Z"
        fill="url(#paint8_linear_783_26367)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_783_26367"
          x1="11.5"
          y1={3}
          x2="16.5"
          y2={3}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_783_26367"
          x1="15.5"
          y1="6.5"
          x2="19.5"
          y2="6.5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_783_26367"
          x1="1.5"
          y1="13.5"
          x2="5.5"
          y2="13.5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_783_26367"
          x1="1.5"
          y1="6.5"
          x2="5.5"
          y2="6.5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_783_26367"
          x1="4.5"
          y1={3}
          x2="9.5"
          y2={3}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_783_26367"
          x1="4.5"
          y1={17}
          x2="9.5"
          y2={17}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_783_26367"
          x1="11.5"
          y1={17}
          x2="16.5"
          y2={17}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_783_26367"
          x1="15.5"
          y1="13.5"
          x2="19.5"
          y2="13.5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_783_26367"
          x1="4.5"
          y1={10}
          x2="16.5"
          y2={10}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TokenIcon;
