import classnames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import QRCode from 'react-qr-code';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { errors2FA, lengthVerificationCode, success2FA } from '../../../constant/2fa';
import { RootState, useAppSelector } from '../../../stores';
import { setUserInfo } from '../../../stores/UserInformation';
import styles from '../../../styles/components/global-setting/two-factor-body.module.scss';
import CopyIcon from '../../Icons/CopyIcon';
import EyePasswordCloseIcon from '../../Icons/EyePasswordCloseIcon';
import EyePasswordIcon from '../../Icons/EyePasswordIcon';
import ModalWarning from '../../Modal/ModalWarning';
import InputCodeV2 from '../../UI/InputCodeV2';

const cx = classnames.bind(styles);

const GOOGLE_AUTHEN_LINK = 'https://support.google.com/accounts/answer/1066447?hl=en&rd=1';

interface PropsType {
  hide?: () => void;
}

export default function Enable2FA({ hide }: PropsType) {
  const [showPsw, setShowPsw] = useState(false);
  const account = useAppSelector((state: RootState) => state.account.accountInfo);
  const userInfo = useAppSelector((state: RootState) => state.userInfo.userInfo);
  const myInfo = useAppSelector((state) => state.userInfo.userInfo);
  const userIdentityClient = useAppSelector((state) => state.app.userIdentityClient);
  const [secretKey, setSecretKey] = useState('');
  const [isShowWarning, setIsShowWarning] = useState(false);
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: { verificationCode: '', password: '' }
  });
  const onSubmit = (data: any) => {};

  const createSecretKey = async () => {
    try {
      const res = await userIdentityClient.createQrCodeAndSecretKey();

      if (res) {
        setSecretKey(res.secret);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const renderQRCode = (text: string) => {
    const infoQRCode = `bybet (${myInfo?.nickName})`;
    return `otpauth://totp/${encodeURI(infoQRCode)}?secret=${text}`;
  };

  const handleShowPassword = () => {
    setShowPsw(!showPsw);
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success('Copied!');
  };

  const handleEnable2FA = async () => {
    const password = getValues('password');
    const twoFactorCode = getValues('verificationCode');
    const dataReq = {
      password,
      twoFactorCode
    };
    try {
      if (password.length === 0 && twoFactorCode.length < lengthVerificationCode) {
        throw new Error(errors2FA.verificationCodeAndPsw);
      }
      if (password.length === 0) {
        throw new Error(errors2FA.verificationPsw);
      }
      if (twoFactorCode.length < lengthVerificationCode) {
        throw new Error(errors2FA.verificationCode);
      }
      await userIdentityClient.turnOn2fa(dataReq);
      toast.success(success2FA.enable);
      dispatch(setUserInfo({ ...userInfo, isEnableTwoFactor: true }));
      if (hide) {
        hide();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || error);
    }
  };

  const handleChangeInputCode = (code: string) => {
    setValue('verificationCode', code);
  };

  const toggle = () => {
    setIsShowWarning(!isShowWarning);
  };

  useEffect(() => {
    if (!myInfo.isCanUseTwoFactor) {
      setIsShowWarning(true);
    }
  }, [myInfo.isCanUseTwoFactor]);

  useEffect(() => {
    if (account.id) {
      createSecretKey();
    }
  }, [account.id]);

  return (
    <>
      {myInfo && (
        <div className={cx('enable-view-container')}>
          {myInfo.isCanUseTwoFactor && (
            <>
              <div className={cx('note-container')}>
                <p>
                  Download and install{' '}
                  <a href={GOOGLE_AUTHEN_LINK} target={'_blank'} rel="noreferrer">
                    Google Authenticator
                  </a>
                  . Enable Two-factor Authentication to protect your account from unauthorized
                  access.
                </p>
              </div>

              <div className={cx('qr-code-scan-container')}>
                <p>
                  Scan the QR code with your Google Authenticator App or enter the secret key
                  manually.
                </p>
                <div className={cx('qr-code-container')}>
                  <div className={cx('qr-code')}>
                    {secretKey.length > 0 ? (
                      <QRCode size={130} value={renderQRCode(secretKey)} />
                    ) : (
                      <div className={cx('empty')} />
                    )}
                  </div>
                </div>
              </div>

              <div className={cx('secret-key-container')}>
                <p className={cx('label')}>Your secret key</p>
                <div className={cx('key')}>
                  <p>{secretKey}</p>
                  <div
                    className={cx('copy')}
                    onClick={() => {
                      handleCopy(secretKey);
                    }}>
                    <CopyIcon />
                  </div>
                </div>
                <p>
                  Write down this code, never reveal it to others. You can use it to regain access
                  to your account if there is no access to the authenticator.
                </p>
              </div>

              <div className={cx('divider')} />
            </>
          )}

          <form className={cx('verification-container')} onSubmit={handleSubmit(onSubmit)}>
            <div className={cx('code-container')}>
              <p>Verification code</p>
              <div className={cx('input-code')}>
                <InputCodeV2 length={lengthVerificationCode} onChange={handleChangeInputCode} />
              </div>
            </div>

            <div className={cx('input-form')}>
              <input
                type={showPsw ? 'text' : 'password'}
                placeholder="Login Password"
                autoComplete="off"
                {...register('password')}
                disabled={!myInfo.isCanUseTwoFactor}
              />
              <div className={cx('eye-icon')} onClick={handleShowPassword}>
                {showPsw ? <EyePasswordCloseIcon /> : <EyePasswordIcon />}
              </div>
            </div>

            <button
              className={cx('button-enable')}
              onClick={handleEnable2FA}
              disabled={!myInfo.isCanUseTwoFactor}>
              Enable
            </button>
          </form>
        </div>
      )}
      {!myInfo.isCanUseTwoFactor && <ModalWarning isShowing={isShowWarning} toggle={toggle} />}
    </>
  );
}
