import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Account, AUTH } from '../interfaces/Account';
import { firebaseService } from '../services/firebase';
import { storageService } from '../services/storage';
import { PersonalProfile } from 'game-profile-js/lib/client/entities/all.entity';
import { CLASSIC_DICE_CONFIG, CRASH_CONFIG, RING_CONFIG } from '../constant/game';
import { GameProfileClient } from 'game-profile-js';
export interface AccountState {
  accountInfo: Account;
  isLoggedIn: boolean;
  currency: {
    symbol: string;
    balance: string;
  };
  userLevel: UserLevel;
  displayCashAnimation: boolean;
  reward: string;
  authModalType: AUTH;
}

export interface UserLevel {
  vipLevel: number;
  exp: number;
  nextLevel: number;
  expToNextLevel: number;
  nextLevelExp: number;
  currentLevelExp: number;
  progressPercent: number;
}
const initialState: AccountState = {
  accountInfo: {
    accessToken: '',
    displayName: '',
    email: '',
    photoURL: '',
    id: '',
    refreshToken: ''
  },
  userLevel: {
    vipLevel: 0,
    exp: 0,
    nextLevel: 0,
    expToNextLevel: 0,
    currentLevelExp: 0,
    nextLevelExp: 0,
    progressPercent: 0
  },
  isLoggedIn: false,
  currency: {
    symbol: '',
    balance: ''
  },
  displayCashAnimation: false,
  reward: '',
  authModalType: AUTH.SIGN_IN
};

export const getUserLevel = createAsyncThunk(
  'GET_USER_LEVEL',
  async (gameProfileClient: GameProfileClient) => {
    const res: PersonalProfile = await gameProfileClient.getRefProfile();
    if (res) {
      const userLevel: UserLevel = {
        vipLevel: res.profile.vipLevel,
        exp: parseInt(res.profile.exp),
        nextLevel: res.nextLevel.nextLevel,
        expToNextLevel: res.nextLevel.rest,
        nextLevelExp: res.nextLevel.nextLevelExp,
        currentLevelExp: res.nextLevel.currentLevelExp,
        progressPercent: res.nextLevel.progressPercent
      };
      return userLevel;
    }
    return initialState.userLevel;
  }
);

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccountInfo: (state, action: PayloadAction<Account>) => {
      state.accountInfo = { ...state.accountInfo, ...action.payload };
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setCurrency: (
      state,
      action: PayloadAction<{
        symbol: string;
        balance: string;
      }>
    ) => {
      state.currency = action.payload;
    },
    setAuthModalType: (state, action: PayloadAction<AUTH>) => {
      state.authModalType = action.payload;
    },
    setUserLevel: (state, action: PayloadAction<UserLevel>) => {
      state.userLevel = action.payload;
    },
    logout: (state) => {
      state.accountInfo = {
        accessToken: '',
        displayName: '',
        email: '',
        photoURL: '',
        id: '',
        refreshToken: ''
      };
      state.isLoggedIn = false;
      state.currency = {
        symbol: '',
        balance: ''
      };
      state.userLevel = initialState.userLevel;

      firebaseService.logout();
      storageService.saveExpiredTimeAccessToken('', -1);
      storageService.removeAccount();
      storageService.removeGameConfig(CLASSIC_DICE_CONFIG);
      storageService.removeGameConfig(RING_CONFIG);
      storageService.removeGameConfig(CRASH_CONFIG);
    },
    setDisplayCashAnimation: (state, action: PayloadAction<boolean>) => {
      state.displayCashAnimation = action.payload;
    },
    setReward: (state, action: PayloadAction<string>) => {
      state.reward = action.payload;
    }
  },
  extraReducers: (build) => {
    build.addCase(getUserLevel.fulfilled, (state, action) => {
      state.userLevel = action.payload || initialState.userLevel;
    });
  }
});

// Action creators are generated for each case reducer function
export const {
  setCurrency,
  setAccountInfo,
  setLoggedIn,
  logout,
  setDisplayCashAnimation,
  setReward,
  setAuthModalType,
  setUserLevel
} = accountSlice.actions;

export default accountSlice.reducer;
