export default function AffiliateIcon() {
  return (
    <svg width={27} height={26} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_512_149)">
        <path
          d="M16.0638 6.29986C16.0638 3.00377 13.076 0.331787 9.39028 0.331787C5.70461 0.331787 2.7168 3.00377 2.7168 6.29986V7.71074C2.7168 11.0068 5.70466 13.6788 9.39033 13.6788C13.076 13.6788 16.0639 11.0068 16.0639 7.71074C16.0638 7.7061 16.0638 6.30445 16.0638 6.29986Z"
          fill="url(#paint0_linear_512_149)"
        />
        <path
          d="M14.8866 2.91461C13.6828 1.35445 11.6702 0.331787 9.39034 0.331787C7.11045 0.331787 5.09789 1.35445 3.89404 2.91461V11.0959C5.09784 12.6561 7.11035 13.6788 9.39029 13.6788C11.6702 13.6788 13.6827 12.656 14.8865 11.0959L14.8866 2.91461Z"
          fill="url(#paint1_linear_512_149)"
        />
        <path
          d="M13.9097 12.1016C14.7133 11.4398 15.3346 10.6039 15.6991 9.66064V8.24976L13.9097 10.6907V12.1016Z"
          fill="url(#paint2_linear_512_149)"
        />
        <path
          d="M5.47607 12.5444C6.57567 13.2578 7.92793 13.6788 9.39035 13.6788C10.8528 13.6788 12.205 13.2578 13.3046 12.5444V1.46615C12.205 0.752732 10.8527 0.331787 9.39035 0.331787C7.92798 0.331787 6.57572 0.752783 5.47607 1.46615V12.5444Z"
          fill="url(#paint3_linear_512_149)"
        />
        <path
          d="M7.87402 13.524C8.36114 13.6252 8.86865 13.6788 9.39023 13.6788C9.91176 13.6788 10.4193 13.6252 10.9064 13.524V0.486564C10.4193 0.385367 9.91181 0.331787 9.39023 0.331787C8.8687 0.331787 8.36114 0.385367 7.87402 0.486564V13.524Z"
          fill="url(#paint4_linear_512_149)"
        />
        <path
          d="M9.39033 12.2679C13.076 12.2679 16.0639 9.59593 16.0639 6.29986C16.0639 3.00378 13.076 0.331787 9.39033 0.331787C5.70464 0.331787 2.7168 3.00378 2.7168 6.29986C2.7168 9.59593 5.70464 12.2679 9.39033 12.2679Z"
          fill="url(#paint5_linear_512_149)"
        />
        <path
          d="M7.87411 0.486619V3.43522L4.77797 1.98667C3.97691 2.67194 3.36657 3.53392 3.0249 4.50265L6.33537 6.04848L3.08139 8.24966C3.44585 9.19285 4.0672 10.0288 4.8708 10.6907L7.87406 8.66178V12.1132C8.36117 12.2144 8.86868 12.2679 9.39026 12.2679C9.91179 12.2679 10.4194 12.2144 10.9065 12.1132V8.66178L13.9097 10.6907C14.7133 10.0288 15.3347 9.1929 15.6991 8.24966L12.4452 6.04848L15.7556 4.50265C15.414 3.53397 14.8036 2.67194 14.0026 1.98667L10.9065 3.43517V0.486568C10.9065 0.486568 10.9065 0.486568 10.9064 0.486568C10.8461 0.474027 10.7854 0.46225 10.7245 0.451187C10.3327 0.379764 9.84577 0.323533 9.28672 0.331282C8.7372 0.338929 8.25942 0.406427 7.87411 0.486619Z"
          fill="url(#paint6_linear_512_149)"
        />
        <path
          d="M9.39038 10.6305C12.0648 10.6305 14.2329 8.69161 14.2329 6.29987C14.2329 3.90813 12.0648 1.96924 9.39038 1.96924C6.71592 1.96924 4.54785 3.90813 4.54785 6.29987C4.54785 8.69161 6.71592 10.6305 9.39038 10.6305Z"
          fill="url(#paint7_linear_512_149)"
        />
        <path
          d="M13.4847 2.22907L10.9065 3.43527V0.99382C10.4217 0.883345 9.91369 0.824463 9.39033 0.824463C8.86696 0.824463 8.35904 0.883294 7.87412 0.99382V3.43527L5.29599 2.22907C4.46995 2.89401 3.84753 3.75716 3.52197 4.73476L6.33553 6.04858L3.54573 7.93577C3.88042 8.89308 4.50126 9.73716 5.31837 10.3885L7.87422 8.66188V11.6061C8.35909 11.7166 8.86706 11.7755 9.39043 11.7755C9.91379 11.7755 10.4217 11.7166 10.9066 11.6061V8.66188L13.4625 10.3885C14.2797 9.73716 14.9005 8.89308 15.2351 7.93577L12.4453 6.04858L15.2589 4.73476C14.9332 3.75716 14.3108 2.89401 13.4847 2.22907Z"
          fill="url(#paint8_linear_512_149)"
        />
        <path
          d="M9.39011 10.144C11.764 10.144 13.6884 8.42299 13.6884 6.30003C13.6884 4.17706 11.764 2.45605 9.39011 2.45605C7.01622 2.45605 5.0918 4.17706 5.0918 6.30003C5.0918 8.42299 7.01622 10.144 9.39011 10.144Z"
          fill="url(#paint9_linear_512_149)"
        />
        <path
          d="M3.08154 9.66064C3.446 10.6038 4.06735 11.4398 4.87095 12.1016V10.6907L3.08154 8.24976V9.66064Z"
          fill="url(#paint10_linear_512_149)"
        />
        <path
          d="M6.55322 8.08821V7.53278C6.55322 7.36862 6.61414 7.20446 6.73594 7.04031C6.85778 6.87615 7.00506 6.72922 7.17758 6.59938L7.70013 6.2081C7.88085 6.07775 8.03364 5.94148 8.1586 5.79955C8.28355 5.65762 8.34605 5.51951 8.34605 5.38523C8.34605 5.27781 8.3095 5.18294 8.23649 5.1004C8.16339 5.01797 8.05383 4.97672 7.90777 4.97672C7.78088 4.97672 7.67709 5.01221 7.59638 5.08307C7.51563 5.15393 7.47525 5.26619 7.47525 5.41974C7.47525 5.51033 7.43309 5.58915 7.34892 5.65604C7.2646 5.72302 7.15326 5.75642 7.0147 5.75642C6.75256 5.75642 6.62154 5.60276 6.62154 5.2954C6.62154 4.9653 6.74899 4.70061 7.00394 4.50143C7.25889 4.3022 7.55825 4.20264 7.90206 4.20264C8.25643 4.20264 8.567 4.30669 8.83378 4.51479C9.10056 4.72289 9.23398 5.00344 9.23398 5.35653C9.23398 5.58665 9.17295 5.80347 9.05096 6.00678C8.92896 6.21009 8.78214 6.38083 8.61059 6.51883C8.43904 6.65693 8.26652 6.78357 8.09309 6.89863C7.9196 7.0137 7.77135 7.1259 7.64838 7.23521C7.52537 7.34461 7.46394 7.44525 7.46394 7.53732V7.58906H8.9648C9.03729 7.58906 9.10025 7.62704 9.15378 7.7029C9.20721 7.77886 9.23398 7.86797 9.23398 7.97039C9.23398 8.07286 9.20742 8.16391 9.15429 8.2436C9.10112 8.32328 9.03852 8.3631 8.96648 8.3631H6.94032C6.85299 8.3631 6.76673 8.33643 6.68134 8.28295C6.59589 8.22948 6.55322 8.16458 6.55322 8.08821Z"
          fill="url(#paint11_linear_512_149)"
        />
        <path
          d="M9.43848 7.33875C9.43848 7.19075 9.47544 7.08833 9.54946 7.03139C9.62343 6.97444 9.73447 6.94599 9.88241 6.94599C10.0607 6.94599 10.1793 6.97444 10.2381 7.03139C10.2969 7.08828 10.3263 7.16995 10.3263 7.27609C10.3263 7.38621 10.3727 7.47155 10.4657 7.53222C10.5587 7.59293 10.6772 7.62327 10.8215 7.62327C11.1667 7.62327 11.3394 7.44499 11.3394 7.08828V6.98015C11.3394 6.79428 11.2947 6.65484 11.2057 6.56181C11.1164 6.46887 10.9922 6.42237 10.8328 6.42237H9.96776C9.84255 6.42237 9.7543 6.39301 9.70312 6.33418C9.65188 6.2754 9.62629 6.18904 9.62629 6.0752C9.62629 6.04869 9.63001 5.99368 9.63766 5.91012L9.79131 4.47584C9.81023 4.29374 9.86335 4.20264 9.95068 4.20264H11.7662C11.8459 4.20264 11.9085 4.24062 11.9541 4.31648C11.9996 4.39244 12.0223 4.48349 12.0223 4.58968C12.0223 4.69597 11.9996 4.78703 11.9541 4.86288C11.9085 4.93884 11.8459 4.97672 11.7662 4.97672H10.5198L10.4629 5.73939H10.8329C11.2578 5.73939 11.5964 5.83809 11.8488 6.03533C12.1011 6.23268 12.2273 6.54758 12.2273 6.9801V7.08823C12.2273 7.53599 12.1002 7.86609 11.846 8.07852C11.5917 8.29106 11.2578 8.39725 10.8443 8.39725C10.4079 8.39725 10.0645 8.29295 9.8141 8.08423C9.56363 7.87572 9.43848 7.62714 9.43848 7.33875Z"
          fill="url(#paint12_linear_512_149)"
        />
        <path
          d="M9.39027 11.7754C12.7717 11.7754 15.513 9.32392 15.513 6.29991C15.513 3.2759 12.7717 0.824463 9.39027 0.824463C6.0088 0.824463 3.26758 3.2759 3.26758 6.29991C3.26758 9.32392 6.0088 11.7754 9.39027 11.7754Z"
          fill="url(#paint13_linear_512_149)"
        />
        <path
          d="M26.078 14.4749C26.078 11.1788 23.0902 8.50684 19.4045 8.50684C15.7188 8.50684 12.731 11.1788 12.731 14.4749V15.8858C12.731 19.1819 15.7188 21.8539 19.4045 21.8539C23.0902 21.8539 26.078 19.1819 26.078 15.8858C26.078 15.8812 26.078 14.4795 26.078 14.4749Z"
          fill="url(#paint14_linear_512_149)"
        />
        <path
          d="M24.9007 11.0897C23.6969 9.52955 21.6844 8.50684 19.4045 8.50684C17.1245 8.50684 15.112 9.52955 13.9082 11.0897V19.271C15.112 20.8312 17.1245 21.8539 19.4045 21.8539C21.6844 21.8539 23.6969 20.8312 24.9007 19.271V11.0897Z"
          fill="url(#paint15_linear_512_149)"
        />
        <path
          d="M23.9238 20.2764C24.7274 19.6146 25.3488 18.7787 25.7132 17.8354V16.4246L23.9238 18.8656V20.2764Z"
          fill="url(#paint16_linear_512_149)"
        />
        <path
          d="M15.4902 20.7195C16.5899 21.4329 17.9421 21.8539 19.4045 21.8539C20.8669 21.8539 22.2191 21.4329 23.3188 20.7195V9.6412C22.2192 8.92783 20.8669 8.50684 19.4045 8.50684C17.9421 8.50684 16.5899 8.92783 15.4902 9.6412V20.7195Z"
          fill="url(#paint17_linear_512_149)"
        />
        <path
          d="M17.8887 21.6991C18.3758 21.8003 18.8833 21.8539 19.4049 21.8539C19.9264 21.8539 20.434 21.8003 20.9211 21.6991V8.66161C20.434 8.56042 19.9265 8.50684 19.4049 8.50684C18.8834 8.50684 18.3758 8.56042 17.8887 8.66161V21.6991Z"
          fill="url(#paint18_linear_512_149)"
        />
        <path
          d="M19.4045 20.443C23.0902 20.443 26.078 17.771 26.078 14.4749C26.078 11.1788 23.0902 8.50684 19.4045 8.50684C15.7188 8.50684 12.731 11.1788 12.731 14.4749C12.731 17.771 15.7188 20.443 19.4045 20.443Z"
          fill="url(#paint19_linear_512_149)"
        />
        <path
          d="M17.8883 8.66169H17.8882V11.6103L14.7921 10.1618C13.9911 10.8471 13.3807 11.7091 13.0391 12.6778L16.3495 14.2236L13.0955 16.4248C13.46 17.368 14.0814 18.2039 14.885 18.8658L17.8882 16.8369V20.2883C18.3753 20.3895 18.8828 20.4431 19.4044 20.4431C19.926 20.4431 20.4335 20.3895 20.9206 20.2883V16.8369L23.9239 18.8658C24.7275 18.2039 25.3488 17.368 25.7133 16.4248L22.4593 14.2236L25.7698 12.6778C25.4281 11.7091 24.8178 10.8471 24.0167 10.1618L20.9206 11.6103V8.66169H20.9206C20.4905 8.57298 19.9441 8.49952 19.3109 8.50742C18.7599 8.51425 18.2791 8.58104 17.8883 8.66169Z"
          fill="url(#paint20_linear_512_149)"
        />
        <path
          d="M19.4045 18.8056C22.079 18.8056 24.2471 16.8667 24.2471 14.4749C24.2471 12.0832 22.079 10.1443 19.4045 10.1443C16.7301 10.1443 14.562 12.0832 14.562 14.4749C14.562 16.8667 16.7301 18.8056 19.4045 18.8056Z"
          fill="url(#paint21_linear_512_149)"
        />
        <path
          d="M23.4989 10.4039L20.9208 11.6101V9.16862C20.4359 9.05815 19.928 8.99927 19.4046 8.99927C18.8812 8.99927 18.3733 9.0581 17.8884 9.16862V11.6101L15.3103 10.4039C14.4841 11.0688 13.8617 11.9319 13.5361 12.9096L16.3497 14.2234L13.5599 16.1106C13.8946 17.0679 14.5154 17.912 15.3325 18.5633L17.8884 16.8367V19.7809C18.3733 19.8914 18.8812 19.9503 19.4046 19.9503C19.928 19.9503 20.4359 19.8914 20.9208 19.7809V16.8367L23.4766 18.5633C24.2938 17.912 24.9147 17.0679 25.2493 16.1106L22.4595 14.2234L25.273 12.9096C24.9474 11.9319 24.325 11.0688 23.4989 10.4039Z"
          fill="url(#paint22_linear_512_149)"
        />
        <path
          d="M19.4048 18.3188C21.7786 18.3188 23.7031 16.5978 23.7031 14.4748C23.7031 12.3519 21.7786 10.6309 19.4048 10.6309C17.0309 10.6309 15.1064 12.3519 15.1064 14.4748C15.1064 16.5978 17.0309 18.3188 19.4048 18.3188Z"
          fill="url(#paint23_linear_512_149)"
        />
        <path
          d="M13.0957 17.8354C13.4602 18.7787 14.0815 19.6146 14.8851 20.2764V18.8656L13.0957 16.4246V17.8354Z"
          fill="url(#paint24_linear_512_149)"
        />
        <path
          d="M18.0103 15.5136C18.0103 15.3656 18.0472 15.2631 18.1212 15.2062C18.1952 15.1493 18.3062 15.1208 18.4542 15.1208C18.6325 15.1208 18.751 15.1492 18.8099 15.2062C18.8687 15.2631 18.8981 15.3448 18.8981 15.4509C18.8981 15.561 18.9445 15.6464 19.0375 15.707C19.1304 15.7677 19.249 15.7981 19.3933 15.7981C19.7385 15.7981 19.9112 15.6198 19.9112 15.2631V15.155C19.9112 14.9691 19.8665 14.8296 19.7774 14.7366C19.6882 14.6437 19.564 14.5972 19.4046 14.5972H18.5395C18.4143 14.5972 18.3261 14.5678 18.2748 14.509C18.2236 14.4502 18.198 14.3638 18.198 14.25C18.198 14.2235 18.2017 14.1684 18.2094 14.0849L18.3631 12.6506C18.382 12.4685 18.4351 12.3774 18.5225 12.3774H20.338C20.4177 12.3774 20.4803 12.4154 20.5258 12.4913C20.5714 12.5672 20.5941 12.6583 20.5941 12.7645C20.5941 12.8708 20.5714 12.9618 20.5258 13.0377C20.4803 13.1136 20.4177 13.1515 20.338 13.1515H19.0916L19.0347 13.9142H19.4047C19.8296 13.9142 20.1682 14.0129 20.4206 14.2101C20.6729 14.4075 20.7991 14.7224 20.7991 15.155V15.2631C20.7991 15.7108 20.6719 16.0409 20.4178 16.2534C20.1635 16.4659 19.8296 16.5721 19.416 16.5721C18.9796 16.5721 18.6363 16.4678 18.3859 16.2591C18.1355 16.0505 18.0103 15.802 18.0103 15.5136Z"
          fill="url(#paint25_linear_512_149)"
        />
        <path
          d="M19.4044 19.9502C22.7859 19.9502 25.5271 17.4987 25.5271 14.4747C25.5271 11.4507 22.7859 8.99927 19.4044 8.99927C16.023 8.99927 13.2817 11.4507 13.2817 14.4747C13.2817 17.4987 16.023 19.9502 19.4044 19.9502Z"
          fill="url(#paint26_linear_512_149)"
        />
        <path
          d="M13.4251 18.2891C13.4251 14.993 10.4373 12.321 6.75161 12.321C3.06593 12.321 0.078125 14.993 0.078125 18.2891V19.7C0.078125 22.9961 3.06598 25.6681 6.75166 25.6681C10.4373 25.6681 13.4252 22.9961 13.4252 19.7C13.4251 19.6954 13.4251 18.2938 13.4251 18.2891Z"
          fill="url(#paint27_linear_512_149)"
        />
        <path
          d="M12.2479 14.9039C11.0441 13.3438 9.03156 12.321 6.75162 12.321C4.47167 12.321 2.45917 13.3438 1.25537 14.9039V23.0852C2.45917 24.6454 4.47172 25.6681 6.75162 25.6681C9.03151 25.6681 11.0441 24.6454 12.2479 23.0852V14.9039Z"
          fill="url(#paint28_linear_512_149)"
        />
        <path
          d="M11.271 24.0909C12.0746 23.4291 12.6959 22.5931 13.0604 21.6499V20.239L11.271 22.68V24.0909Z"
          fill="url(#paint29_linear_512_149)"
        />
        <path
          d="M2.8374 24.5337C3.937 25.2471 5.28925 25.6681 6.75168 25.6681C8.2141 25.6681 9.5663 25.2471 10.666 24.5337V13.4554C9.56636 12.742 8.2141 12.321 6.75168 12.321C5.28925 12.321 3.93705 12.742 2.8374 13.4554V24.5337Z"
          fill="url(#paint30_linear_512_149)"
        />
        <path
          d="M5.23535 25.5133C5.72247 25.6145 6.22998 25.6681 6.75156 25.6681C7.27309 25.6681 7.78065 25.6145 8.26777 25.5133V12.4759C7.78065 12.3747 7.27314 12.321 6.75156 12.321C6.23003 12.321 5.72247 12.3746 5.23535 12.4759V25.5133Z"
          fill="url(#paint31_linear_512_149)"
        />
        <path
          d="M6.75166 24.2572C10.4374 24.2572 13.4252 21.5852 13.4252 18.2891C13.4252 14.993 10.4374 12.321 6.75166 12.321C3.06597 12.321 0.078125 14.993 0.078125 18.2891C0.078125 21.5852 3.06597 24.2572 6.75166 24.2572Z"
          fill="url(#paint32_linear_512_149)"
        />
        <path
          d="M5.23544 12.4759L5.23538 15.4245L2.13929 13.976C1.33824 14.6613 0.727901 15.5233 0.38623 16.492L3.69669 18.0378L0.442717 20.239C0.807176 21.1822 1.42852 22.0181 2.23213 22.68L5.23538 20.6511V24.1025C5.7225 24.2037 6.23001 24.2572 6.75159 24.2572C7.27312 24.2572 7.78068 24.2037 8.2678 24.1025V20.6511L11.2711 22.68C12.0747 22.0181 12.696 21.1822 13.0605 20.239L9.80649 18.0378L13.117 16.492C12.7753 15.5233 12.1649 14.6612 11.3639 13.976L8.2678 15.4245V12.4759H8.26775C7.86031 12.3918 7.34633 12.321 6.75149 12.321C6.15675 12.321 5.64282 12.3918 5.23544 12.4759Z"
          fill="url(#paint33_linear_512_149)"
        />
        <path
          d="M6.75171 22.6198C9.42616 22.6198 11.5942 20.6809 11.5942 18.2891C11.5942 15.8974 9.42616 13.9585 6.75171 13.9585C4.07725 13.9585 1.90918 15.8974 1.90918 18.2891C1.90918 20.6809 4.07725 22.6198 6.75171 22.6198Z"
          fill="url(#paint34_linear_512_149)"
        />
        <path
          d="M10.8461 14.2183L8.26796 15.4245V12.9831C7.78309 12.8726 7.27512 12.8137 6.75176 12.8137C6.22839 12.8137 5.72047 12.8726 5.23555 12.9831V15.4245L2.65742 14.2183C1.83128 14.8833 1.20886 15.7464 0.883301 16.724L3.69686 18.0378L0.907058 19.925C1.24174 20.8823 1.86253 21.7264 2.6797 22.3777L5.23555 20.6511V23.5954C5.72042 23.7058 6.22839 23.7647 6.75176 23.7647C7.27512 23.7647 7.78304 23.7058 8.26796 23.5954V20.6511L10.8238 22.3777C11.641 21.7264 12.2618 20.8823 12.5965 19.925L9.80665 18.0378L12.6202 16.724C12.2947 15.7464 11.6722 14.8833 10.8461 14.2183Z"
          fill="url(#paint35_linear_512_149)"
        />
        <path
          d="M6.75144 22.1333C9.12533 22.1333 11.0497 20.4123 11.0497 18.2893C11.0497 16.1663 9.12533 14.4453 6.75144 14.4453C4.37754 14.4453 2.45312 16.1663 2.45312 18.2893C2.45312 20.4123 4.37754 22.1333 6.75144 22.1333Z"
          fill="url(#paint36_linear_512_149)"
        />
        <path
          d="M0.442871 21.6499C0.80733 22.5931 1.42868 23.429 2.23228 24.0909V22.68L0.442871 20.239V21.6499Z"
          fill="url(#paint37_linear_512_149)"
        />
        <path
          d="M4.34131 16.9831C4.34131 16.8464 4.39627 16.7459 4.50638 16.6814L5.25763 16.2261C5.29933 16.1996 5.35439 16.1863 5.42265 16.1863C5.5213 16.1863 5.61138 16.2129 5.693 16.266C5.77457 16.3191 5.81536 16.3855 5.81536 16.4652V20.085C5.81536 20.1723 5.77167 20.2396 5.68444 20.287C5.59711 20.3345 5.4928 20.3582 5.37142 20.3582C5.24993 20.3582 5.14563 20.3345 5.0584 20.287C4.97107 20.2396 4.92748 20.1723 4.92748 20.085V17.188L4.76241 17.2905C4.71688 17.321 4.66937 17.336 4.62012 17.336C4.54044 17.336 4.47401 17.3 4.42094 17.2279C4.36782 17.1557 4.34131 17.0741 4.34131 16.9831Z"
          fill="url(#paint38_linear_512_149)"
        />
        <path
          d="M6.36768 19.0489V17.5407C6.36768 17.0778 6.49477 16.7382 6.74901 16.5219C7.0032 16.3056 7.34094 16.1975 7.76209 16.1975C8.18701 16.1975 8.52659 16.3056 8.78083 16.5219C9.03502 16.7382 9.16217 17.0778 9.16217 17.5407V19.0489C9.16217 19.5119 9.03497 19.8514 8.78083 20.0677C8.52659 20.284 8.18696 20.3921 7.76209 20.3921C7.34089 20.3921 7.00315 20.284 6.74901 20.0677C6.49477 19.8514 6.36768 19.5119 6.36768 19.0489ZM7.25555 19.0489C7.25555 19.4284 7.42435 19.6181 7.76209 19.6181C8.10356 19.6181 8.27434 19.4284 8.27434 19.0489V17.5407C8.27434 17.1613 8.10361 16.9715 7.76209 16.9715C7.42435 16.9715 7.25555 17.1613 7.25555 17.5407V19.0489Z"
          fill="url(#paint39_linear_512_149)"
        />
        <path
          d="M6.7516 23.7646C10.1331 23.7646 12.8743 21.3132 12.8743 18.2892C12.8743 15.2652 10.1331 12.8137 6.7516 12.8137C3.37013 12.8137 0.628906 15.2652 0.628906 18.2892C0.628906 21.3132 3.37013 23.7646 6.7516 23.7646Z"
          fill="url(#paint40_linear_512_149)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_512_149"
          x1="2.7168"
          y1="7.00527"
          x2="16.0639"
          y2="7.00527"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4F2FF" />
          <stop offset={1} stopColor="#B2CBFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_512_149"
          x1="9.39034"
          y1="10.6978"
          x2="9.39034"
          y2="15.705"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3F3CED" stopOpacity={0} />
          <stop offset="0.2796" stopColor="#3C3DE4" stopOpacity="0.28" />
          <stop offset="0.7305" stopColor="#3541CC" stopOpacity="0.731" />
          <stop offset={1} stopColor="#2F43BB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_512_149"
          x1="14.2109"
          y1="9.66625"
          x2="15.4194"
          y2="10.7036"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#00ADFB" />
          <stop offset={1} stopColor="#3F3CED" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_512_149"
          x1="9.39035"
          y1="11.3497"
          x2="9.39035"
          y2="15.2624"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3F3CED" stopOpacity={0} />
          <stop offset="0.2796" stopColor="#3C3DE4" stopOpacity="0.28" />
          <stop offset="0.7305" stopColor="#3541CC" stopOpacity="0.731" />
          <stop offset={1} stopColor="#2F43BB" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_512_149"
          x1="10.3237"
          y1="11.3926"
          x2="10.7673"
          y2="13.4775"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#00ADFB" />
          <stop offset={1} stopColor="#3F3CED" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_512_149"
          x1="6.4061"
          y1="3.18807"
          x2="16.4079"
          y2="13.6174"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4F2FF" />
          <stop offset={1} stopColor="#B2CBFF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_512_149"
          x1="7.83602"
          y1="3.24991"
          x2="11.9559"
          y2="11.3341"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#00ADFB" />
          <stop offset={1} stopColor="#3F3CED" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_512_149"
          x1="6.89672"
          y1="3.08709"
          x2="12.532"
          y2="10.3475"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#00ADFB" />
          <stop offset={1} stopColor="#3F3CED" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_512_149"
          x1="11.2386"
          y1="8.19944"
          x2="3.49505"
          y2="0.24084"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#00ADFB" stopOpacity={0} />
          <stop offset={1} stopColor="#18CEFB" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_512_149"
          x1="7.15232"
          y1="3.79026"
          x2="12.1306"
          y2="9.37358"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4F2FF" />
          <stop offset={1} stopColor="#B2CBFF" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_512_149"
          x1="3.30066"
          y1="9.2243"
          x2="4.80611"
          y2="11.5766"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#00ADFB" />
          <stop offset={1} stopColor="#3F3CED" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_512_149"
          x1="7.37396"
          y1="5.4174"
          x2="9.16576"
          y2="9.56266"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#00ADFB" />
          <stop offset={1} stopColor="#3F3CED" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_512_149"
          x1="9.99105"
          y1="4.28609"
          x2="11.7829"
          y2="8.43146"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#00ADFB" />
          <stop offset={1} stopColor="#3F3CED" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_512_149"
          x1="9.37294"
          y1="6.27468"
          x2="3.88577"
          y2="-1.70666"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_512_149"
          x1="12.731"
          y1="15.1803"
          x2="26.078"
          y2="15.1803"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4F2FF" />
          <stop offset={1} stopColor="#B2CBFF" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_512_149"
          x1="19.4045"
          y1="18.8728"
          x2="19.4045"
          y2="23.8801"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3F3CED" stopOpacity={0} />
          <stop offset="0.2796" stopColor="#3C3DE4" stopOpacity="0.28" />
          <stop offset="0.7305" stopColor="#3541CC" stopOpacity="0.731" />
          <stop offset={1} stopColor="#2F43BB" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_512_149"
          x1="24.2251"
          y1="17.8411"
          x2="25.4336"
          y2="18.8785"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.3043" stopColor="#FFCD3E" />
          <stop offset="0.8558" stopColor="#FFAD2B" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_512_149"
          x1="19.4045"
          y1="19.9617"
          x2="19.4045"
          y2="23.8744"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3F3CED" stopOpacity={0} />
          <stop offset="0.2796" stopColor="#3C3DE4" stopOpacity="0.28" />
          <stop offset="0.7305" stopColor="#3541CC" stopOpacity="0.731" />
          <stop offset={1} stopColor="#2F43BB" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_512_149"
          x1="20.3383"
          y1="19.5676"
          x2="20.782"
          y2="21.6525"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.3043" stopColor="#FFCD3E" />
          <stop offset="0.8558" stopColor="#FFAD2B" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_512_149"
          x1="16.4203"
          y1="11.3632"
          x2="26.4222"
          y2="21.7924"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4F2FF" />
          <stop offset={1} stopColor="#B2CBFF" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_512_149"
          x1="17.8502"
          y1="11.425"
          x2="21.97"
          y2="19.5092"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.3043" stopColor="#FFCD3E" />
          <stop offset="0.8558" stopColor="#FFAD2B" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_512_149"
          x1="16.9109"
          y1="11.2622"
          x2="22.5462"
          y2="18.5225"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.3043" stopColor="#FFCD3E" />
          <stop offset="0.8558" stopColor="#FFAD2B" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_512_149"
          x1="21.2528"
          y1="16.3742"
          x2="13.5093"
          y2="8.41564"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" stopOpacity={0} />
          <stop offset={1} stopColor="#FBED21" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_512_149"
          x1="17.167"
          y1="11.9651"
          x2="22.1453"
          y2="17.5484"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4F2FF" />
          <stop offset={1} stopColor="#B2CBFF" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_512_149"
          x1="13.3148"
          y1="17.3991"
          x2="14.8202"
          y2="19.7513"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.3043" stopColor="#FFCD3E" />
          <stop offset="0.8558" stopColor="#FFAD2B" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_512_149"
          x1="18.5629"
          y1="12.4609"
          x2="20.3547"
          y2="16.6063"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.3043" stopColor="#FFCD3E" />
          <stop offset="0.8558" stopColor="#FFAD2B" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_512_149"
          x1="19.3871"
          y1="14.4495"
          x2="13.9"
          y2="6.46819"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_512_149"
          x1="0.078125"
          y1="18.9945"
          x2="13.4252"
          y2="18.9945"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4F2FF" />
          <stop offset={1} stopColor="#B2CBFF" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_512_149"
          x1="6.75162"
          y1="22.687"
          x2="6.75162"
          y2="27.6943"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3F3CED" stopOpacity={0} />
          <stop offset="0.2796" stopColor="#3C3DE4" stopOpacity="0.28" />
          <stop offset="0.7305" stopColor="#3541CC" stopOpacity="0.731" />
          <stop offset={1} stopColor="#2F43BB" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_512_149"
          x1="11.5722"
          y1="21.6555"
          x2="12.7807"
          y2="22.6929"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7044" />
          <stop offset={1} stopColor="#F82814" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_512_149"
          x1="6.75168"
          y1="23.339"
          x2="6.75168"
          y2="27.2517"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3F3CED" stopOpacity={0} />
          <stop offset="0.2796" stopColor="#3C3DE4" stopOpacity="0.28" />
          <stop offset="0.7305" stopColor="#3541CC" stopOpacity="0.731" />
          <stop offset={1} stopColor="#2F43BB" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_512_149"
          x1="7.68501"
          y1="23.3819"
          x2="8.12864"
          y2="25.4667"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7044" />
          <stop offset={1} stopColor="#F82814" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_512_149"
          x1="3.76742"
          y1="15.1773"
          x2="13.7693"
          y2="25.6066"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4F2FF" />
          <stop offset={1} stopColor="#B2CBFF" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_512_149"
          x1="5.19735"
          y1="15.2392"
          x2="9.31718"
          y2="23.3234"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7044" />
          <stop offset={1} stopColor="#F82814" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_512_149"
          x1="4.25805"
          y1="15.0764"
          x2="9.89332"
          y2="22.3367"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7044" />
          <stop offset={1} stopColor="#F82814" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_512_149"
          x1="8.6"
          y1="20.1887"
          x2="0.856485"
          y2="12.2301"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7044" stopOpacity={0} />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_512_149"
          x1="4.51365"
          y1="15.7795"
          x2="9.49194"
          y2="21.3629"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4F2FF" />
          <stop offset={1} stopColor="#B2CBFF" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_512_149"
          x1="0.661985"
          y1="21.2136"
          x2="2.16739"
          y2="23.5658"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7044" />
          <stop offset={1} stopColor="#F82814" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_512_149"
          x1="4.79539"
          y1="17.0131"
          x2="7.23302"
          y2="22.3602"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7044" />
          <stop offset={1} stopColor="#F82814" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_512_149"
          x1="6.77022"
          y1="16.1129"
          x2="9.20779"
          y2="21.4599"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7044" />
          <stop offset={1} stopColor="#F82814" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_512_149"
          x1="6.73427"
          y1="18.2639"
          x2="1.24715"
          y2="10.2826"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_512_149">
          <rect width={26} height={26} fill="white" transform="translate(0.078125)" />
        </clipPath>
      </defs>
    </svg>
  );
}
