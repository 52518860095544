import classNames from 'classnames/bind';
import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import styles from '../../../styles/components/affiliate/affiliate-management/total-commission-reward.module.scss';
import CommissionRewardDolar from '../../Icons/CommissionRewardDolar';
import CommissionRewardToken from '../../Icons/CommissionRewardToken';
import { RefRewardStatistic } from 'game-profile-js/lib/client/entities/all.entity';
import { useAppSelector } from '../../../stores';

const cx = classNames.bind(styles);

const TotalCommissionReward = () => {
  const gameProfile = useAppSelector((state) => state.app.gameProfileClient);
  const [gameData, setGameData] = useState<RefRewardStatistic>();

  useEffect(() => {
    getGameData();
  }, []);

  const getGameData = async () => {
    const gameData = await gameProfile.getRewardStatistic();
    if (gameData) {
      setGameData(gameData);
    }
  };

  return (
    <div className={cx('container')}>
      <div className={cx('title')}>Total Rewards</div>
      <div className={cx('content')}>
        <div className={cx('commission-reward')}>
          <CommissionRewardDolar />
          <div className={cx('data')}>
            <div className={cx('sub-title')}>Locked Rewards</div>
            <div className={cx('value')}>
              ${new BigNumber(gameData?.totalDistributionReward || 0).toFixed(2)}
            </div>
          </div>
        </div>
        <div className={cx('divider')} />
        <div className={cx('commission-reward-received')}>
          <CommissionRewardToken />
          <div className={cx('data')}>
            <div className={cx('sub-title')}>Rewards Received in Total</div>
            <div className={cx('value')}>${gameData?.totalReceivedReward.toFixed(2) || '0.00'}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalCommissionReward;
