import classNames from 'classnames/bind';
import { GameStatsTotal } from 'game-profile-js/lib/client/entities/all.entity';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { defaultSymbol, listGame } from '../../constant/data-home-page';
import { useAppSelector } from '../../stores';
import styles from '../../styles/components/home/top-wining.module.scss';
import { convertToCurrency } from '../../utils/convertCurrency';
import NextLoadIcon from '../Icons/NextLoadIcon';

const cx = classNames.bind(styles);

const TopWining = () => {
  const navigate = useNavigate();
  const gameProfile = useAppSelector((state) => state.app.gameProfileClient);
  const [gameWinningList, setGameWinningList] = useState<any>();
  useEffect(() => {
    getGameStatsTotal();
  }, []);
  const getGameStatsTotal = async () => {
    const gameStatsTotal = await gameProfile.getGameStatsTotal();
    const gameWinningList = listGame.map((item: any) => {
      const game = gameStatsTotal.find(
        (game: GameStatsTotal) => game.gameType === item.type && game.symbol === defaultSymbol
      );
      if (game) {
        item.topWiningAmount = game.winAmount;
        return item;
      }
    });
    gameWinningList.sort((a: any, b: any) => b.topWiningAmount - a.topWiningAmount);
    setGameWinningList(gameWinningList);
  };
  const onClickGameItem = (link: string) => {
    navigate(link);
  };
  return (
    <div className={cx('wrapper')}>
      <div className={cx('title-component')}>Top wining</div>
      <ul className={cx('list')}>
        {gameWinningList ? (
          gameWinningList.map((item: any) => {
            if (!item) {
              return null;
            }
            return (
              <li key={item.topWiningImageIndex} className={cx('item')}>
                <div className={cx('desc')}>
                  <div className={cx('item-img')}>
                    <img
                      src={`/images/top-wining/item${item.topWiningImageIndex}.png`}
                      alt="image"
                    />
                  </div>

                  <div className={cx('content')}>
                    <div className={cx('price')}>
                      Profit: ${convertToCurrency(item.topWiningAmount)}
                    </div>
                    <div className={cx('desc')}>In {item.value}</div>
                  </div>
                </div>

                <div className={cx('icon')} onClick={() => onClickGameItem(item.link)}>
                  <NextLoadIcon />
                </div>
              </li>
            );
          })
        ) : (
          <div className={cx('loading')}>Loading...</div>
        )}
      </ul>
    </div>
  );
};

export default TopWining;
