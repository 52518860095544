import classNames from 'classnames/bind';
import React from 'react';
import styles from '../styles/components/nodata.module.scss';

const cx = classNames.bind(styles);

const NoData = () => {
  return (
    <div className={cx('nodata')}>
      <div>
        <img src="/images/ufo.png" alt="ufo" />
      </div>
      <div>Oops! There is no data yet!</div>
    </div>
  );
};

export default NoData;
