import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import MetaverseClient from 'metaverse-js';
import { AssetBalance } from 'metaverse-js/lib/proto/model/asset';

interface BalanceState {
  balances: AssetBalance[];
  isBalanceLoading: boolean;
  isBalanceError: boolean;
}

const initialState: BalanceState = {
  balances: [],
  isBalanceLoading: false,
  isBalanceError: false
};

export const getAllMyBalance = createAsyncThunk(
  'GET_ALL_MY_BALANCE',
  async (metaverseClient: MetaverseClient) => {
    const res = await metaverseClient.getMyAssetBalances();
    return res;
  }
);

export const myBalancesSlice = createSlice({
  name: 'myBalances',
  initialState,
  reducers: {
    clearBalance: (state) => {
      state.balances = [];
    },
    setLoadingMyBalance: (state, action) => {
      state.isBalanceLoading = action.payload;
    },
    updateAssetBalance: (state, action: PayloadAction<AssetBalance>) => {
      const assetBalance = action.payload;
      const index = state.balances.findIndex((b) => b.assetSymbol === assetBalance.assetSymbol);
      if (index !== -1) {
        state.balances[index] = assetBalance;
      } else {
        state.balances.push(assetBalance);
      }
    },
    decreaseAssetBalance: (state, action: PayloadAction<AssetBalance>) => {
      const assetBalance = action.payload;
      const index = state.balances.findIndex((b) => b.assetSymbol === assetBalance.assetSymbol);
      if (index !== -1) {
        const currentAssetBalance = state.balances[index];
        const currentTotalAssetAmount = parseFloat(currentAssetBalance.totalAssetAmount || '0');
        const currentAvailableAssetAmount = parseFloat(
          currentAssetBalance.availableAssetAmount || '0'
        );
        const newTotalAssetAmount =
          currentTotalAssetAmount - parseFloat(assetBalance.totalAssetAmount || '0');
        const newAvailableAssetAmount =
          currentAvailableAssetAmount - parseFloat(assetBalance.availableAssetAmount || '0');
        if (newTotalAssetAmount >= 0 && newAvailableAssetAmount >= 0) {
          state.balances[index].totalAssetAmount = newTotalAssetAmount.toString();
          state.balances[index].availableAssetAmount = newAvailableAssetAmount.toString();
        }
      }
    }
  },
  extraReducers: (build) => {
    build.addCase(getAllMyBalance.pending, (state) => {
      state.isBalanceLoading = true;
      state.balances = [];
      state.isBalanceError = false;
    });
    build.addCase(getAllMyBalance.fulfilled, (state, action) => {
      state.isBalanceLoading = false;
      state.balances = action.payload;
      state.isBalanceError = false;
    });
    build.addCase(getAllMyBalance.rejected, (state) => {
      state.isBalanceLoading = false;
      state.balances = [];
      state.isBalanceError = true;
    });
  }
});
export const { clearBalance, updateAssetBalance, decreaseAssetBalance, setLoadingMyBalance } =
  myBalancesSlice.actions;

export const myBalancesReducer = myBalancesSlice.reducer;
