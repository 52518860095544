import React from 'react';
import { useSearchParams } from 'react-router-dom';

const useQuery = () => {
  const [searchParams] = useSearchParams() as any;
  const search = Object.fromEntries([...searchParams]);
  return search;
};

export default useQuery;
