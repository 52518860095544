import { CrashGameTurnType } from 'bybet-game-js/lib/schema/CrashGame';
import classnames from 'classnames/bind';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GAME_INTRODUCTION } from '../../../constant/game';
import { ROUTES } from '../../../constant/routes';
import { RootState, useAppSelector } from '../../../stores';
import { setCrashGameIns } from '../../../stores/CrashGameSlice';
import styles from '../../../styles/components/games/crash.module.scss';
import GameInfo from '../GameInfo';
import LatestBetAndRace from '../LatestBetAndRace';
import GamePlay from './GamePlay';
import Room from './Room';

const cx = classnames.bind(styles);

const Crash: React.FC = () => {
  const accessToken = useAppSelector((state: RootState) => state.account.accountInfo.accessToken);
  const crashGameIns = useAppSelector((state: RootState) => state.crashGame.crashGameIns);
  const currentTurn = useAppSelector((state: RootState) => state.crashGame.currentTurn);
  const bybetGameClient = useAppSelector((state) => state.app.bybetGameClient);

  const dispatch = useDispatch();

  useEffect(() => {
    if (bybetGameClient) {
      const crashGameIns = bybetGameClient.getCrashGame();
      dispatch(setCrashGameIns(crashGameIns));
      crashGameIns?.connect();
    }
    return () => {
      crashGameIns?.disconnect();
    };
  }, [bybetGameClient]);

  useEffect(() => {
    if (bybetGameClient) {
      bybetGameClient.setTokenId(accessToken);
    }
    // dispatch(setCrashGameIns(bybetGame(accessToken).getCrashGame()));
  }, [accessToken, bybetGameClient]);

  // useEffect(() => {
  //   if (!crashGameIns) return;
  //   crashGameIns?.connect();
  //   return () => {
  //     crashGameIns.disconnect();
  //   };
  // }, [crashGameIns]);

  return (
    <div className={cx('container')}>
      <div className={cx('game-container')}>
        <div className={cx('game-play-container')}>
          <GamePlay />
        </div>
        <div className={cx('room-container')}>
          <Room />
        </div>
      </div>
      <GameInfo
        name="Crash"
        imageUrl="/images/games/crash.png"
        releaseDate="--"
        gameInfo={GAME_INTRODUCTION.CRASH}
        linkDetail={ROUTES.CRASH.DETAIL}
      />
      <LatestBetAndRace
        isResult={currentTurn === CrashGameTurnType.FINISHED}
        gameIns={crashGameIns}
      />
    </div>
  );
};

export default Crash;
