import classnames from 'classnames/bind';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constant/routes';
import { AUTH } from '../../../interfaces/Account';
import { storageService } from '../../../services/storage';
import { RootState, useAppDispatch, useAppSelector } from '../../../stores';
import { setAuthModalType } from '../../../stores/AccountSlice';
import { toggleModal } from '../../../stores/ModalSlice';
import { getUserInfo } from '../../../stores/UserInformation';
import styles from '../../../styles/components/global-setting/two-factor.module.scss';
import LayoutComponentSideMenu from '../../LayoutComponentSideMenu';
import DisableTwoFactor from './DisableTwoFactor';
import Enable2FA from './Enable2FA';

const cx = classnames.bind(styles);

export default function TwoFA() {
  const userInfo = useAppSelector((state: RootState) => state.userInfo.userInfo);
  const userIdentityClient = useAppSelector((state) => state.app.userIdentityClient);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const accountData = storageService.getAccount();
    if (!accountData) {
      navigate(ROUTES.HOME);
      dispatch(setAuthModalType(AUTH.SIGN_IN));
      dispatch(toggleModal({ modalName: 'authModal', state: true }));
    } else {
      if (!userInfo) {
        dispatch(getUserInfo({ userIdentityClient, id: accountData.id }));
      }
    }
  }, []);
  return (
    <LayoutComponentSideMenu>
      <div className={cx('container')}>
        <p className={cx('title')}>Two - Factor Authentication</p>
        <div className={cx('body')}>
          {userInfo?.isEnableTwoFactor ? <DisableTwoFactor /> : <Enable2FA />}
        </div>
      </div>
    </LayoutComponentSideMenu>
  );
}
