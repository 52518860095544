import classNames from 'classnames/bind';

import styles from '../../../styles/components/wallet/deposit.module.scss';
import ArrowDownIcon from '../../Icons/ArrowDownIcon';
import ModalDeposit from './ModalDeposit';
import useModal from '../../../hooks/useModal';
import { Asset, AssetBalance } from 'metaverse-js/lib/proto/model/asset';
import Skeleton from 'react-loading-skeleton';
import CurrencyAmount from '../../UI/CurrencyAmount';
import { find } from 'lodash';

const cx = classNames.bind(styles);

interface PropsType {
  assets?: Asset[];
  tokenSupport?: Asset;
  setTokenSupport?: (value: Asset) => void;
  onClickLinkHistory?: () => void;
  balances: AssetBalance[];
  title?: string;
  showHistory?: boolean;
  assetBalance?: AssetBalance;
  loading?: boolean;
}

const DepositCurrency = ({
  assets,
  tokenSupport = assets && assets[0],
  setTokenSupport,
  onClickLinkHistory,
  balances,
  assetBalance,
  title = 'Deposit Currency',
  showHistory,
  loading
}: PropsType) => {
  const { isShowing, toggle } = useModal();

  const propsModal = {
    isShowing,
    hide: toggle,
    assets,
    tokenSupport,
    setTokenSupport,
    balances
  };

  return (
    <>
      <ModalDeposit {...propsModal} />
      <div className={cx('deposit-currency')}>
        <div className={cx('title-total')}>
          <div>{title}</div>
        </div>
        <div className={cx('input-deposit')}>
          <button onClick={toggle} type="button" className={cx('select-deposit')}>
            {loading ? (
              CurrencySekeleton()
            ) : (
              <>
                <div className={cx('name-deposit')}>
                  {tokenSupport && (
                    <>
                      <div className={cx('icon-crypto')}>
                        <img
                          src={`/images/tokens/${tokenSupport.symbol?.toLowerCase()}.svg?v=2`}
                          alt={''}
                        />
                      </div>
                      <div className={cx('name')}>{tokenSupport.symbol}</div>
                    </>
                  )}
                </div>
                <div className={cx('icon')}>
                  <ArrowDownIcon />
                </div>
              </>
            )}
          </button>
          <div className={cx('balance-deposit')}>
            <div className={cx('content')}>
              <div className={cx('title')}>Balance</div>
              <div className={cx('balance')}>
                <CurrencyAmount
                  amount={assetBalance?.availableAssetAmount || '0'}
                  decimalPlaces={
                    find(assets, { symbol: assetBalance?.assetSymbol, chain: 'CHT' })?.decimalPlaces
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CurrencySekeleton = () => {
  return <Skeleton width={100} baseColor={'rgba(255, 255, 255, 0.05)'} />;
};

export default DepositCurrency;
