import { ROUTES } from './routes';

export const listGame = [
  {
    key: 'crash',
    isComing: false,
    link: ROUTES.CRASH.INDEX,
    value: 'Crash',
    type: 'CRASH',
    topWiningImageIndex: 2,
    topWiningAmount: 0
  },
  {
    key: 'ring_of_fortune',
    isComing: false,
    link: ROUTES.RING_OF_FORTUNE.INDEX,
    value: 'Ring of Fortune',
    type: 'RING',
    topWiningImageIndex: 3,
    topWiningAmount: 0
  },
  {
    key: 'classic_dice',
    isComing: false,
    link: ROUTES.CLASSIC_DICE.INDEX,
    value: 'Classic Dice',
    type: 'CLASSIC_DICE',
    topWiningImageIndex: 1,
    topWiningAmount: 0
  },
  {
    key: 'baccarat',
    isComing: true,
    link: '#',
    value: 'Baccarat'
  },
  {
    key: 'roulette',
    isComing: true,
    link: '#',
    value: 'Roulette'
  },
  {
    key: 'monster',
    isComing: true,
    link: '#',
    value: 'Monster'
  },
  {
    key: 'poker',
    isComing: true,
    link: '#',
    value: 'Poker'
  },
  {
    key: 'fish_cards',
    isComing: true,
    link: '#',
    value: 'Fish Cards'
  },
  {
    key: 'slots',
    isComing: true,
    link: '#',
    value: 'Slots'
  },
  {
    key: 'augu_poker',
    isComing: true,
    link: '#',
    value: 'Augu Poker'
  }
];

export const topWining = [
  {
    top: 1,
    profit: 68587,
    game: 'classic dice'
  },
  {
    top: 2,
    profit: 25008,
    game: 'crash'
  },
  {
    top: 3,
    profit: 14254,
    game: 'ring of fortune'
  }
];
export const listNavigatePage = [
  {
    key: 'staking',
    icon: 'coin',
    link: ROUTES.STAKING,
    value: 'Staking',
    content: 'Get started today!'
  },
  {
    key: 'affiliate',
    icon: 'kind',
    link: ROUTES.AFFILIATE,
    value: 'Affiliate',
    content: 'Join us right now!'
  },
  {
    key: 'help_center',
    icon: 'question',
    link: ROUTES.HELP.GENERAL,
    value: 'Help Center',
    content: 'Click now!'
  }
];
export const acceptedCurrencies = [
  {
    key: 'BTC',
    fullName: 'Bitcoin'
  },
  {
    key: 'USDT',
    fullName: 'Tether USD'
  },
  {
    key: 'BUSD',
    fullName: 'Binance USD'
  },
  {
    key: 'BNB',
    fullName: 'Binance Coin'
  },
  {
    key: 'USDC',
    fullName: 'USD Coin'
  },
  {
    key: 'AVAX',
    fullName: 'Avalanche'
  },
  {
    key: 'BBET',
    fullName: 'Bybet Token'
  },
  {
    key: 'DOGE',
    fullName: 'DOGE Coin'
  },
  {
    key: 'ETH',
    fullName: 'Etherium'
  },
  {
    key: 'SOL',
    fullName: 'Solana'
  },
  {
    key: 'SHIB',
    fullName: 'Shiba Inu'
  },
  {
    key: 'DOT',
    fullName: 'Polkadot'
  },
  {
    key: 'POLYGON',
    fullName: 'Polygon'
  },
  {
    key: 'NEAR',
    fullName: 'Near Protocol'
  },
  {
    key: 'AAVE',
    fullName: 'Aave'
  },
  {
    key: 'CRO',
    fullName: 'Cronos'
  },
  {
    key: 'TRX',
    fullName: 'Tron'
  },
  {
    key: 'XRP',
    fullName: 'Ripple'
  }
];
export enum TAB_HOME {
  LOPPY = 'loppy',
  ORIGINAL = 'original',
  LIVE_CASINO = 'live_casino',
  TABLE_GAME = 'table_game',
  GAME_SHOW = 'game_show',
  SLOTS = 'slots'
}

export const defaultSymbol = 'USDT';
