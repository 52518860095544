import React from 'react';

const EyePasswordIcon = () => {
  return (
    <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_691_21249)">
        <path
          d="M13.4974 9.99873C13.4974 11.5876 12.153 12.8756 10.4946 12.8756C8.83617 12.8756 7.49177 11.5876 7.49177 9.99873C7.49177 8.40987 8.83617 7.12187 10.4946 7.12187C12.153 7.12185 13.4974 8.40988 13.4974 9.99873ZM10.5 4.00867C8.78292 4.01628 7.0031 4.4343 5.31827 5.23462C4.0673 5.85332 2.84817 6.72622 1.7899 7.80363C1.27013 8.35362 0.607183 9.14998 0.5 9.99967C0.512667 10.7357 1.30217 11.6442 1.7899 12.1957C2.78228 13.2308 3.96967 14.0793 5.31827 14.7654C6.88945 15.5279 8.62853 15.9669 10.5 15.9913C12.2187 15.9836 13.9981 15.5607 15.6811 14.7654C16.9321 14.1467 18.1518 13.2731 19.2101 12.1957C19.7299 11.6457 20.3928 10.8494 20.5 9.99967C20.4873 9.26365 19.6978 8.35515 19.2101 7.8036C18.2177 6.7685 17.0297 5.92062 15.6811 5.23458C14.1107 4.47265 12.3674 4.03665 10.5 4.00867ZM10.4987 5.49613C13.1007 5.49613 15.21 7.5127 15.21 10.0003C15.21 12.4879 13.1007 14.5045 10.4987 14.5045C7.89675 14.5045 5.78748 12.4879 5.78748 10.0003C5.78748 7.5127 7.89675 5.49613 10.4987 5.49613Z"
          fill="#A2A3A4"
        />
      </g>
      <defs>
        <clipPath id="clip0_691_21249">
          <rect width={20} height={20} fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EyePasswordIcon;
