import classnames from 'classnames/bind';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GameInformation } from '../../interfaces/Game';
import styles from '../../styles/components/games/game-info.module.scss';
import ArrowDownIcon from '../Icons/ArrowDownIcon';

const cx = classnames.bind(styles);

interface PropsType {
  name: string;
  imageUrl?: string;
  releaseDate?: string;
  gameInfo: GameInformation;
  linkDetail: string;
}
export default function GameInfo({ name, imageUrl, releaseDate, gameInfo, linkDetail }: PropsType) {
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();

  const linkToDetailGame = () => {
    navigate(linkDetail);
  };

  return (
    <div className={cx('container')}>
      <div className={cx('bg')}>
        <div className={cx('info-wrapper')}>
          <div className={cx('info')}>
            <img src={imageUrl} alt={name} />
            <div className={cx('name-date-wrapper')}>
              <div className={cx('name')}>{name}</div>
              <div className={cx('provider')}>
                <span>By</span> {gameInfo.provider}
              </div>
              <div className={cx('release-date')}>Release: {releaseDate}</div>
            </div>
          </div>
          <div className={cx('more-info')} onClick={linkToDetailGame}>
            More info
          </div>
        </div>
        {showMore && (
          <div className={cx('game-info')}>
            <div className={cx('info')}>
              <div className={cx('title')}>Game Info</div>
              <div className={cx('info-content')}>
                <div className={cx('row')}>
                  <div className={cx('ceil')}>
                    <div className={cx('label')}>RTP (Return to Player)</div>
                    <div className={cx('value-number')}>{gameInfo.rtp}%</div>
                  </div>
                  <div className={cx('ceil')}>
                    <div className={cx('label')}>Provider</div>
                    <div className={cx('value-text')}>{gameInfo.provider}</div>
                  </div>
                </div>
                <div className={cx('row')}>
                  <div className={cx('ceil', !gameInfo.maxWin && 'disable')}>
                    <div className={cx('label')}>Max Win</div>
                    <div className={cx('value-number')}>{gameInfo.maxWin || '--'}</div>
                  </div>
                  <div className={cx('ceil')}>
                    <div className={cx('label')}>Type</div>
                    <div className={cx('value-text')}>{gameInfo.type}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={cx('about-game')}>
              <div className={cx('title')}>About this game</div>
              <div
                className={cx('content')}
                dangerouslySetInnerHTML={{ __html: gameInfo.shortDescription }}
              />
            </div>
          </div>
        )}
        <div className={cx('footer')}>
          <div
            className={cx('show-more', showMore && 'show-less')}
            onClick={() => setShowMore(!showMore)}>
            Show {showMore ? 'less' : 'more'} <ArrowDownIcon />
          </div>
        </div>
      </div>
    </div>
  );
}
