import { useEffect, useState } from 'react';

export default function useCountDown(timeInit: number) {
  const [time, setTime] = useState(timeInit);
  const [config, setConfig] = useState({ isStart: false, timeInit: timeInit });

  const startCountDown = (timeInitRefresh?: number) => {
    setConfig({ isStart: true, timeInit: timeInitRefresh || timeInit });
  };

  const endCountDown = () => {
    setConfig({ isStart: false, timeInit: timeInit });
  };

  useEffect(() => {
    if (config.isStart) {
      let timeInit = config.timeInit;
      let countDown = setInterval(() => {
        setTime(timeInit - 100 >= 0 ? timeInit - 100 : 0);
        timeInit = timeInit - 100;
      }, 100);
      return () => {
        clearInterval(countDown);
      };
    } else {
      setTime(config.timeInit);
    }
  }, [config.isStart, config.timeInit]);

  return { time, startCountDown, endCountDown };
}
