import { ReactNode } from 'react';
import ChangePassword from '../components/ChangePsw/ChangePsw';
import TwoFA from '../components/GlobalSetting/2FA';
import LinkEmail from '../components/GlobalSetting/LinkEmail';
import UserInformation from '../components/UserInformation';
import Deposit from '../components/Wallet/Deposit';
import InternalTransfers from '../components/Wallet/InternalTranfers';
import Transactions from '../components/Wallet/Transactions';
import Withdraw from '../components/Wallet/Withdraw';
import { ROUTES } from '../constant/routes';
import AffiliatePage from '../pages/affiliate';
import ClassicDicePage from '../pages/classic-dice';
import ClassicDiceDetailPage from '../pages/classic-dice/detail';
import CrashGamePage from '../pages/crash';
import CrashGameDetailPage from '../pages/crash/detail';
import ErrorPage from '../pages/error';
import GlobalSettingPage from '../pages/global-setting';
import FAQPage from '../pages/help/faq';
import FeePage from '../pages/help/fee';
import GeneralPage from '../pages/help/general';
import SupportPage from '../pages/help/support';
import TermOfServicePage from '../pages/help/terms-service';
import HomePage from '../pages/home';
import RingOfFortunePage from '../pages/ring-of-fortune';
import RingOfFortuneDetailPage from '../pages/ring-of-fortune/detail';
import StakingPage from '../pages/staking';
import WalletPage from '../pages/wallet';
interface Route {
  path: string;
  element: ReactNode;
}

const { WALLET } = ROUTES;
const { GLOBAL_SETTING } = ROUTES;

export const routes = [
  { path: ROUTES.ERROR, element: <ErrorPage /> },
  // _______
  { path: ROUTES.HOME, element: <HomePage /> },
  { path: ROUTES.HOME_RESETPASSWORD, element: <HomePage /> },

  //game
  { path: ROUTES.CRASH.INDEX, element: <CrashGamePage /> },
  { path: ROUTES.CRASH.DETAIL, element: <CrashGameDetailPage /> },

  { path: ROUTES.RING_OF_FORTUNE.INDEX, element: <RingOfFortunePage /> },
  { path: ROUTES.RING_OF_FORTUNE.DETAIL, element: <RingOfFortuneDetailPage /> },

  { path: ROUTES.CLASSIC_DICE.INDEX, element: <ClassicDicePage /> },
  { path: ROUTES.CLASSIC_DICE.DETAIL, element: <ClassicDiceDetailPage /> },

  // ROUTES WALLET
  { path: WALLET.MAIN, element: <WalletPage /> },
  { path: WALLET.DEPOSIT, element: <Deposit /> },
  { path: WALLET.WITHDRAW, element: <Withdraw /> },
  { path: WALLET.TRANSACTIONS, element: <Transactions /> },
  { path: WALLET.INTERNAL_TRANSFERS, element: <InternalTransfers /> },

  // -------
  { path: ROUTES.STAKING, element: <StakingPage /> },
  { path: ROUTES.HELP.TERMS, element: <TermOfServicePage /> },
  { path: ROUTES.HELP.FAQ, element: <FAQPage /> },
  { path: ROUTES.HELP.SUPPORT, element: <SupportPage /> },
  { path: ROUTES.HELP.FEE, element: <FeePage /> },
  { path: ROUTES.HELP.GENERAL, element: <GeneralPage /> },

  // ROUTES GLOBAL SETTING
  { path: GLOBAL_SETTING.INDEX, element: <GlobalSettingPage /> },
  { path: GLOBAL_SETTING.USER_INFO, element: <UserInformation /> },
  { path: ROUTES.GLOBAL_SETTING.CHANGE_PWD, element: <ChangePassword /> },
  { path: GLOBAL_SETTING.E2FA, element: <TwoFA /> },
  { path: GLOBAL_SETTING.LINK_EMAIL, element: <LinkEmail /> },

  // -------
  { path: ROUTES.AFFILIATE, element: <AffiliatePage /> }
] as Route[];
