const DiceShadow = (props: any) => {
  return (
    <svg width={91} height={27} viewBox="0 0 91 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_f_1022_24875)">
        <ellipse cx="45.5" cy="13.5" rx="35.5" ry="3.5" fill="#5B5B5B" />
      </g>
      <defs>
        <filter
          id="filter0_f_1022_24875"
          x={0}
          y={0}
          width={91}
          height={27}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation={5} result="effect1_foregroundBlur_1022_24875" />
        </filter>
      </defs>
    </svg>
  );
};

export default DiceShadow;
