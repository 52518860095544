export default function ContactColorIcon() {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.49984 1.66675C5.3165 1.66675 3.5415 3.44175 3.5415 5.62508C3.5415 7.76675 5.2165 9.50008 7.39984 9.57508C7.4665 9.56675 7.53317 9.56675 7.58317 9.57508C7.59984 9.57508 7.60817 9.57508 7.62484 9.57508C7.63317 9.57508 7.63317 9.57508 7.6415 9.57508C9.77484 9.50008 11.4498 7.76675 11.4582 5.62508C11.4582 3.44175 9.68317 1.66675 7.49984 1.66675Z"
        fill="url(#paint0_linear_1960_39307)"
      />
      <path
        d="M11.7333 11.7917C9.4083 10.2417 5.61663 10.2417 3.27497 11.7917C2.21663 12.5 1.6333 13.4583 1.6333 14.4833C1.6333 15.5083 2.21663 16.4583 3.26663 17.1583C4.4333 17.9417 5.96663 18.3333 7.49997 18.3333C9.0333 18.3333 10.5666 17.9417 11.7333 17.1583C12.7833 16.45 13.3666 15.5 13.3666 14.4667C13.3583 13.4417 12.7833 12.4917 11.7333 11.7917Z"
        fill="url(#paint1_linear_1960_39307)"
      />
      <path
        d="M16.6583 6.11659C16.7916 7.73325 15.6416 9.14992 14.05 9.34159C14.0416 9.34159 14.0416 9.34159 14.0333 9.34159H14.0083C13.9583 9.34159 13.9083 9.34159 13.8666 9.35825C13.0583 9.39992 12.3166 9.14159 11.7583 8.66659C12.6166 7.89992 13.1083 6.74992 13.0083 5.49992C12.95 4.82492 12.7166 4.20825 12.3666 3.68325C12.6833 3.52492 13.05 3.42492 13.425 3.39159C15.0583 3.24992 16.5166 4.46659 16.6583 6.11659Z"
        fill="url(#paint2_linear_1960_39307)"
      />
      <path
        d="M18.3249 13.8249C18.2582 14.6333 17.7415 15.3333 16.8749 15.8083C16.0415 16.2666 14.9915 16.4833 13.9499 16.4583C14.5499 15.9166 14.8999 15.2416 14.9665 14.5249C15.0499 13.4916 14.5582 12.4999 13.5749 11.7083C13.0165 11.2666 12.3665 10.9166 11.6582 10.6583C13.4999 10.1249 15.8165 10.4833 17.2415 11.6333C18.0082 12.2499 18.3999 13.0249 18.3249 13.8249Z"
        fill="url(#paint3_linear_1960_39307)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1960_39307"
          x1="3.5415"
          y1="5.62091"
          x2="11.4582"
          y2="5.62091"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1960_39307"
          x1="1.6333"
          y1="14.4812"
          x2="13.3666"
          y2="14.4812"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1960_39307"
          x1="11.7583"
          y1="6.3715"
          x2="16.6688"
          y2="6.3715"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1960_39307"
          x1="11.6582"
          y1="13.4382"
          x2="18.334"
          y2="13.4382"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
      </defs>
    </svg>
  );
}
