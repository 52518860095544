import classnames from 'classnames/bind';
import { RootState, useAppSelector } from '../../stores';
import styles from '../../styles/components/affiliate/table.module.scss';
import Coin from '../Icons/Coin';
import CoinColor from '../Icons/CoinColor';

const cx = classnames.bind(styles);

export interface Table {
  id: string;
  name: string;
  vipLevel: string;
  totalXp: number;
  rewards: string;
}

interface PropsType {
  table: Table[];
}

const TableAffiliate = ({ table }: PropsType) => {
  const isLoggedIn = useAppSelector((state: RootState) => state.account.isLoggedIn);

  return (
    <div>
      <div className={cx('table-root')}>
        {!isLoggedIn ? (
          <>
            <div className={cx('table-list')}>
              <div className={cx('header')}>
                <div className={cx('level', 'title')}>Invitees Level</div>
                <div className={cx('total', 'title')}>Total XP</div>
                <div className={cx('reward', 'title')}>Rewards</div>
              </div>
              <div className={cx('list')}>
                {table.map((list, index) =>
                  list.vipLevel == '0' ? (
                    <></>
                  ) : list.name === 'color' ? (
                    <div className={cx('record-color')} key={list.vipLevel + index}>
                      <div className={cx('level')}>{list.vipLevel}</div>
                      <div className={cx('total')}>{list.totalXp}</div>
                      <div className={cx('reward')}>
                        <Coin />
                        {list.rewards}
                      </div>
                    </div>
                  ) : (
                    <div className={cx('record')} key={list.vipLevel + index}>
                      <div className={cx('level')}>{list.vipLevel}</div>
                      <div className={cx('total')}>{list.totalXp}</div>
                      <div className={cx('reward')}>
                        <CoinColor />
                        {list.rewards}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={cx('list-login')}>
              <div className={cx('header')}>
                <div className={cx('level', 'title')}>Invitees’ VIP Level</div>
                <div className={cx('total', 'title')}>Total XP</div>
                <div className={cx('reward', 'title')}>Rewards</div>
              </div>
              <div className={cx('list')}>
                {table.map((list, index) =>
                  list.vipLevel == '0' ? (
                    <></>
                  ) : list.name === 'color' ? (
                    <div className={cx('record-color')} key={list.vipLevel + index}>
                      <div className={cx('level')}>{list.vipLevel}</div>
                      <div className={cx('total')}>{list.totalXp}</div>
                      <div className={cx('reward')}>
                        <Coin />
                        {list.rewards}
                      </div>
                    </div>
                  ) : (
                    <div className={cx('record')} key={list.vipLevel + index}>
                      <div className={cx('level')}>{list.vipLevel}</div>
                      <div className={cx('total')}>{list.totalXp}</div>
                      <div className={cx('reward')}>
                        <CoinColor />
                        {list.rewards}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TableAffiliate;
