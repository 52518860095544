import React from 'react';

interface PropsType {
  width?: number;
  height?: number;
}

const ClosedIcon = ({ width, height }: PropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.7619 2.74088C15.1768 2.32594 15.1768 1.65321 14.7619 1.23828C14.3469 0.82334 13.6742 0.82334 13.2592 1.23828L14.7619 2.74088ZM1.23845 13.2591C0.823511 13.674 0.823511 14.3468 1.23845 14.7617C1.65338 15.1767 2.32611 15.1767 2.74105 14.7617L1.23845 13.2591ZM13.2592 14.7616C13.6742 15.1765 14.3469 15.1765 14.7619 14.7616C15.1768 14.3466 15.1768 13.674 14.7619 13.2591L13.2592 14.7616ZM2.74105 1.2382C2.32611 0.82327 1.65338 0.82327 1.23845 1.2382C0.823511 1.65314 0.823511 2.32587 1.23845 2.7408L2.74105 1.2382ZM13.2592 1.23828L1.23845 13.2591L2.74105 14.7617L14.7619 2.74088L13.2592 1.23828ZM14.7619 13.2591L2.74105 1.2382L1.23845 2.7408L13.2592 14.7616L14.7619 13.2591Z"
        fill="#A2A3A4"
      />
    </svg>
  );
};

export default ClosedIcon;
