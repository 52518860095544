import classnames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../stores';
import styles from '../../styles/Layout/header.module.scss';
import MessageIcon from '../Icons/MessageIcon';
import NotificationIcon from '../Icons/NotificationIcon';
import SearchIcon from '../Icons/SearchIcon';
import WalletIcon from '../Icons/WalletIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constant/routes';
import ModalAuth from '../Modal/ModalAuth';
import Dropdown from '../UI/Dropdown';
import ModalUserInformation from '../Modal/ModalUserInformation';
import ModalConfirmResetPassword from '../Modal/ModalConfirmResetPassword';
import { toggleModal } from '../../stores/ModalSlice';
import ModalSecurity2FA from '../Modal/ModalSecurity2FA';
import ProfileAccount from './ProfileAccount';
import ModalSelectToken from '../Modal/ModalSelectToken';
import Amount from '../shared/Amount';
import { setAuthModalType } from '../../stores/AccountSlice';
import { AUTH } from '../../interfaces/Account';
import BadgeIcon from '../Icons/BadgeIcon';
import { getUserInfo } from '../../stores/UserInformation';

const cx = classnames.bind(styles);

interface PropsType {
  handleShowChat: () => void;
  handleShowNoti: () => void;
}

const Header = ({ handleShowChat, handleShowNoti }: PropsType) => {
  const {
    userIdentityClient,
    reward,
    displayCashAnimation,
    account,
    isLoggedIn,
    userProfileModal,
    security2FAModal,
    authModal,
    confirmResetPasswordModal,
    authModalType,
    user
  } = useAppSelector((state) => ({
    userIdentityClient: state.app.userIdentityClient,
    account: state.account.accountInfo,
    isLoggedIn: state.account.isLoggedIn,
    userProfileModal: state.modal.userProfileModal,
    authModal: state.modal.authModal,
    security2FAModal: state.modal.security2FAModal,
    currency: state.account.currency,
    displayCashAnimation: state.account.displayCashAnimation,
    reward: state.account.reward,
    authModalType: state.account.authModalType,
    confirmResetPasswordModal: state.modal.confirmResetPasswordModal,
    user: state.userInfo.userInfo
  }));
  const unSeenCount = useAppSelector((state) => state.notification.unSeenCount);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [slideStart, setSlideStart] = useState<number>(NaN);
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramReset = query.get('code');

  const handleTypeModalAuth = (type: AUTH) => {
    dispatch(setAuthModalType(type));
  };
  const onClickButtonAuth = (type: AUTH) => {
    dispatch(setAuthModalType(type));
    dispatch(toggleModal({ modalName: 'authModal', state: true }));
  };

  const fetchUser = () => {
    dispatch(
      getUserInfo({
        userIdentityClient,
        id: account.id
      })
    );
  };

  const handleReload = () => {
    fetchUser();
  };

  useEffect(() => {
    if (paramReset) {
      dispatch(toggleModal({ modalName: 'confirmResetPasswordModal', state: true }));
    }
  }, []);
  return (
    <div className={cx('header-root')}>
      <div className={cx('header-logo')}>
        <img src="/images/logo.svg" alt="logo" />
      </div>
      <InputSearch />
      {isLoggedIn ? (
        <div className={cx('header-group-right')}>
          <div className={cx('wallet')}>
            <ModalSelectToken />
            {displayCashAnimation && (
              <div className={cx('cash-animation')}>
                <Amount amount={reward} />
              </div>
            )}
            <button
              onClick={() => navigate(ROUTES.WALLET.MAIN)}
              type="button"
              className={cx('button', 'button-primary')}>
              <div className={cx('button-wallet')}>
                <div className={cx('icon')}>
                  <WalletIcon />
                </div>
                <div>Wallet</div>
              </div>
            </button>
          </div>
          <div className={cx('header-group-right-item')} onClick={handleShowChat}>
            <div className={cx('message')}>
              <MessageIcon />
            </div>
          </div>
          <div className={cx('header-group-right-item')} onClick={handleShowNoti}>
            {unSeenCount > 0 && (
              <div className={cx('badge')}>
                <BadgeIcon />
              </div>
            )}
            <NotificationIcon />
          </div>
          <div className={cx('header-group-right-item')}>
            <Dropdown
              name="globalSetting"
              placement="right"
              overlay={<ProfileAccount user={user} setSlideStart={setSlideStart} />}>
              <img
                src={user?.avatar || account.photoURL || '/images/avatar.png'}
                alt="avatar"
                className={cx('header-avatar')}
              />
            </Dropdown>
          </div>
        </div>
      ) : (
        <div className={cx('button-group')}>
          <button
            onClick={() => onClickButtonAuth(AUTH.SIGN_IN)}
            type="button"
            className={cx('button', 'button-outline')}>
            Sign in
          </button>
          <button
            onClick={() => onClickButtonAuth(AUTH.SIGN_UP)}
            type="button"
            className={cx('button', 'button-primary')}>
            Sign up
          </button>
          <div className={cx('message')} onClick={handleShowChat}>
            <MessageIcon />
          </div>
          <ModalAuth
            handleTypeModalAuth={handleTypeModalAuth}
            isShowing={authModal}
            toggle={() => {
              dispatch(toggleModal({ modalName: 'authModal', state: !authModal }));
            }}
            type={authModalType}
          />
        </div>
      )}
      <ModalUserInformation
        user={{
          name: user?.nickName || account.displayName,
          userId: user?.id || '',
          avatar: user?.avatar || account.photoURL || '/images/avatar.png',
          totalWins: 0,
          totalBets: 0,
          totalWagered: 0,
          favoriteGames: [],
          wagerContest: []
        }}
        slideStart={slideStart}
        isShowing={userProfileModal}
        toggle={() =>
          dispatch(toggleModal({ modalName: 'userProfileModal', state: !userProfileModal }))
        }
        reload={handleReload}
      />
      <ModalSecurity2FA
        isShowing={security2FAModal}
        toggle={() => {
          dispatch(toggleModal({ modalName: 'security2FAModal', state: !security2FAModal }));
        }}
      />
      <ModalConfirmResetPassword
        paramReset={paramReset}
        isShowing={confirmResetPasswordModal}
        toggle={() => {
          dispatch(
            toggleModal({
              modalName: 'confirmResetPasswordModal',
              state: !confirmResetPasswordModal
            })
          );
        }}
      />
    </div>
  );
};

export default Header;

const InputSearch = () => {
  return (
    <div className={cx('header-input-search-container')}>
      <input placeholder="Search..." className={cx('header-input-search')} />
      <SearchIcon />
    </div>
  );
};
