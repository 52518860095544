import React from 'react';

const MushroomNodata = () => {
  return (
    <svg
      width={131}
      height={132}
      viewBox="0 0 131 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1221_20655)">
        <path
          d="M94.6428 66.1075C96.6057 62.9433 98.0834 59.3935 98.9419 55.5382C102.998 37.3222 91.7434 19.216 73.8031 15.0972C55.8629 10.9784 38.0313 22.4064 33.9749 40.6227C32.9362 45.2878 32.9037 49.9449 33.7284 54.3625L94.6428 66.1075Z"
          fill="#3C4148"
        />
        <path
          d="M98.9409 55.539C98.085 59.3928 96.6072 62.9429 94.642 66.1078L85.5638 64.3575C87.5265 61.1921 89.0043 57.642 89.8627 53.7887C93.6639 36.7305 84.0351 19.7675 68.0605 14.2993C69.9623 14.3942 71.8818 14.6546 73.8052 15.0967C91.7429 19.2166 102.999 37.3225 98.9409 55.539Z"
          fill="#30353A"
        />
        <path
          d="M130.321 65.7914C132.788 52.6335 105.77 36.3559 69.9735 29.4344C34.1771 22.5129 3.15794 27.5685 0.69027 40.7264C-1.77739 53.8843 25.2409 70.1619 61.0373 77.0834C96.8337 84.0049 127.853 78.9493 130.321 65.7914Z"
          fill="#3C4148"
        />
        <path
          d="M23.0632 39.0455C26.1829 39.0455 28.7119 36.4776 28.7119 33.3099C28.7119 30.1422 26.1829 27.5743 23.0632 27.5743C19.9435 27.5743 17.4146 30.1422 17.4146 33.3099C17.4146 36.4776 19.9435 39.0455 23.0632 39.0455Z"
          fill="#30353A"
        />
        <path
          d="M130.343 65.7086C127.882 78.8717 96.856 83.9467 61.0415 77.0415C41.1604 73.2081 23.9842 66.4897 13.0591 59.0165C23.6972 64.7132 37.9163 69.6508 53.8809 72.7291C89.6928 79.6341 120.721 74.5621 123.183 61.3961C124.279 55.5353 119.528 49.0606 110.757 43.0703C124.001 50.1535 131.709 58.4089 130.343 65.7086Z"
          fill="#30353A"
        />
        <path
          d="M101.609 72.1517C102.887 65.3372 86.7941 56.5011 65.6645 52.4155C44.5348 48.3299 26.3698 50.5421 25.0918 57.3565C23.8138 64.1709 39.9068 73.0071 61.0364 77.0927C82.1661 81.1782 100.331 78.9661 101.609 72.1517Z"
          fill="#898989"
        />
        <path
          d="M89.2863 125.257L17.3579 111.388C15.7786 111.084 14.8412 109.419 15.3838 107.882L35.434 51.1239C42.9634 49.7647 53.8463 50.0759 65.6593 52.3537C77.4723 54.6314 87.71 58.3924 94.2245 62.4594L92.3885 122.73C92.339 124.361 90.8653 125.562 89.2863 125.257Z"
          fill="#3C4148"
        />
        <path
          d="M94.224 62.4584C87.7095 58.3914 77.4715 54.6302 65.6588 52.3527C53.8458 50.0749 42.9629 49.7637 35.4336 51.1229L32.9019 58.2855C37.6961 57.2493 44.0654 56.9577 51.272 57.4991L43.6104 88.6524C43.3478 89.7198 43.9871 90.801 45.0383 91.0676C45.198 91.1081 45.358 91.1274 45.5152 91.1274C46.3944 91.1274 47.1945 90.5229 47.4169 89.6174L55.2245 57.8701C58.1764 58.2027 61.2383 58.6678 64.3649 59.2706C67.4923 59.8736 70.5094 60.5808 73.3765 61.3702L71.454 76.2336C71.313 77.3244 72.0694 78.3247 73.1437 78.4681C73.2303 78.4797 73.3164 78.4853 73.4017 78.4853C74.3713 78.4853 75.2146 77.7552 75.3443 76.7526L77.1884 62.4963C84.0888 64.6751 89.9094 67.3166 93.994 70.0647L94.224 62.4584Z"
          fill="#30353A"
        />
        <path
          d="M89.2856 125.256L82.5522 123.958L84.5705 57.7257C88.3073 59.1983 91.5758 60.803 94.2238 62.4584L92.3878 122.729C92.3383 124.36 90.8646 125.561 89.2856 125.256Z"
          fill="#30353A"
        />
        <path
          d="M20.3756 47.0922C20.7795 44.9386 19.126 42.8098 16.6825 42.3373C14.2389 41.8648 11.9306 43.2276 11.5267 45.3812C11.1228 47.5347 12.7763 49.6635 15.2199 50.136C17.6634 50.6085 19.9718 49.2457 20.3756 47.0922Z"
          fill="#DCDCDC"
        />
        <path
          d="M44.1642 39.4229C44.5681 37.2693 42.9146 35.1405 40.471 34.668C38.0275 34.1955 35.7192 35.5583 35.3153 37.7119C34.9114 39.8654 36.5649 41.9942 39.0085 42.4667C41.452 42.9392 43.7603 41.5764 44.1642 39.4229Z"
          fill="#DCDCDC"
        />
        <path
          d="M72.1579 42.2321C72.5617 40.0785 70.9083 37.9497 68.4647 37.4772C66.0211 37.0047 63.7128 38.3675 63.3089 40.5211C62.905 42.6746 64.5585 44.8034 67.0021 45.2759C69.4457 45.7484 71.754 44.3856 72.1579 42.2321Z"
          fill="#DCDCDC"
        />
        <path
          d="M118.561 66.0693C118.965 63.9158 117.311 61.787 114.868 61.3145C112.424 60.842 110.116 62.2048 109.712 64.3583C109.308 66.5119 110.961 68.6407 113.405 69.1132C115.848 69.5857 118.157 68.2229 118.561 66.0693Z"
          fill="#DCDCDC"
        />
        <path
          d="M99.2096 50.0621C99.6135 47.9086 97.96 45.7798 95.5165 45.3073C93.0729 44.8348 90.7646 46.1976 90.3607 48.3511C89.9568 50.5047 91.6103 52.6335 94.0539 53.106C96.4974 53.5785 98.8057 52.2157 99.2096 50.0621Z"
          fill="#DCDCDC"
        />
        <path
          d="M34.9631 7.27902C34.197 6.5012 32.9552 6.50094 32.1884 7.27902L31.1433 8.34017L30.0982 7.27902C29.3319 6.5012 28.0901 6.5012 27.3235 7.27902C26.5575 8.0571 26.5575 9.31806 27.3235 10.0961L28.3686 11.1573L27.3235 12.2184C26.5575 12.9963 26.5575 14.2575 27.3235 15.0356C27.7067 15.4246 28.2087 15.619 28.7109 15.619C29.2129 15.619 29.7151 15.4243 30.0982 15.0356L31.1433 13.9744L32.1884 15.0356C32.5715 15.4246 33.0738 15.619 33.5757 15.619C34.0777 15.619 34.5799 15.4243 34.9631 15.0356C35.7291 14.2575 35.7291 12.9965 34.9631 12.2184L33.918 11.1573L34.9631 10.0961C35.7291 9.31806 35.7291 8.05684 34.9631 7.27902Z"
          fill="#3C4148"
        />
        <path
          d="M100.813 15.4991C101.197 15.8881 101.699 16.0825 102.201 16.0825C102.703 16.0825 103.205 15.8879 103.588 15.4991C104.354 14.7213 104.354 13.4601 103.588 12.682L102.543 11.6208L103.588 10.5597C104.354 9.78184 104.354 8.52062 103.588 7.74254C102.822 6.96472 101.58 6.96472 100.813 7.74254L99.7685 8.80344L98.7237 7.74254C97.9574 6.96472 96.7156 6.96472 95.949 7.74254C95.183 8.52037 95.183 9.78158 95.949 10.5597L96.9941 11.6208L95.949 12.682C95.183 13.4598 95.183 14.721 95.949 15.4991C96.3322 15.8881 96.8344 16.0825 97.3364 16.0825C97.8384 16.0825 98.3406 15.8879 98.7237 15.4991L99.7685 14.4382L100.813 15.4991Z"
          fill="#3C4148"
        />
        <path
          d="M107.407 96.8655L108.452 95.8044C109.218 95.0263 109.218 93.7653 108.452 92.9873C107.686 92.2095 106.444 92.2095 105.678 92.9873L104.633 94.0482L103.588 92.9873C102.822 92.2095 101.58 92.2092 100.813 92.9873C100.047 93.7651 100.047 95.0263 100.813 95.8044L101.858 96.8655L100.813 97.9267C100.047 98.7048 100.047 99.9657 100.813 100.744C101.196 101.133 101.699 101.327 102.201 101.327C102.703 101.327 103.205 101.133 103.588 100.744L104.633 99.6829L105.678 100.744C106.061 101.133 106.563 101.327 107.065 101.327C107.567 101.327 108.069 101.133 108.452 100.744C109.218 99.966 109.218 98.7048 108.452 97.9267L107.407 96.8655Z"
          fill="#6B6B6B"
        />
        <path
          d="M111.971 34.3952C115.091 34.3952 117.62 31.8273 117.62 28.6596C117.62 25.492 115.091 22.9241 111.971 22.9241C108.851 22.9241 106.322 25.492 106.322 28.6596C106.322 31.8273 108.851 34.3952 111.971 34.3952Z"
          fill="#706F6F"
        />
        <path
          d="M117.619 28.6591C117.619 31.8279 115.09 34.3965 111.971 34.3965C110.926 34.3965 109.945 34.1082 109.106 33.6024C111.323 33.0834 112.975 31.0678 112.975 28.6591C112.975 26.2529 111.326 24.2348 109.109 23.7158C109.948 23.2126 110.926 22.9243 111.972 22.9243C115.09 22.9241 117.619 25.4926 117.619 28.6591Z"
          fill="#646464"
        />
        <path
          d="M23.0632 34.3952C26.1829 34.3952 28.7119 31.8273 28.7119 28.6596C28.7119 25.492 26.1829 22.9241 23.0632 22.9241C19.9435 22.9241 17.4146 25.492 17.4146 28.6596C17.4146 31.8273 19.9435 34.3952 23.0632 34.3952Z"
          fill="#706F6F"
        />
        <path
          d="M28.7115 28.6591C28.7115 31.8279 26.1818 34.3965 23.0636 34.3965C22.0182 34.3965 21.0371 34.1082 20.1982 33.6024C22.4156 33.0834 24.0678 31.0678 24.0678 28.6591C24.0678 26.2529 22.4184 24.2348 20.201 23.7158C21.0399 23.2126 22.0182 22.9243 23.0638 22.9243C26.1818 22.9241 28.7115 25.4926 28.7115 28.6591Z"
          fill="#646464"
        />
      </g>
      <defs>
        <clipPath id="clip0_1221_20655">
          <rect width={130} height={132} fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MushroomNodata;
