export default function Letter() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path
        d="M12.667 0.916504H4.33366C1.83366 0.916504 0.166992 2.1665 0.166992 5.08317V10.9165C0.166992 13.8332 1.83366 15.0832 4.33366 15.0832H12.667C15.167 15.0832 16.8337 13.8332 16.8337 10.9165V5.08317C16.8337 2.1665 15.167 0.916504 12.667 0.916504ZM13.0587 5.9915L10.4503 8.07484C9.90033 8.5165 9.20033 8.73317 8.50033 8.73317C7.80033 8.73317 7.09199 8.5165 6.55033 8.07484L3.94199 5.9915C3.67533 5.77484 3.63366 5.37484 3.84199 5.10817C4.05866 4.8415 4.45033 4.7915 4.71699 5.00817L7.32533 7.0915C7.95866 7.59984 9.03366 7.59984 9.66699 7.0915L12.2753 5.00817C12.542 4.7915 12.942 4.83317 13.1503 5.10817C13.367 5.37484 13.3253 5.77484 13.0587 5.9915Z"
        fill="#A2A3A4"
      />
    </svg>
  );
}
