import classNames from 'classnames/bind';
import styles from '../../styles/components/modal/modal-auth.module.scss';
import ModalUI from '../UI/Modal';
import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import ModalTermsOfUse from './ModalTermsOfUse';
import LayoutPopup from '../../components/layouts/LayoutPopup';
import ConfirmResetPassword from '../ContentPopup/ConfirmResetPassword';

const cx = classNames.bind(styles);

interface PropsType {
  paramReset: string | null;
  isShowing: boolean;
  toggle: () => void;
}

const ModalConfirmResetPassword = ({ paramReset, isShowing, toggle }: PropsType) => {
  const [openTermOfUse, setOpenTermOfUse] = useState(false);

  return (
    <>
      <ModalUI hide={toggle} isShowing={isShowing}>
        <LayoutPopup>
          <div className={cx('form')}>
            <ConfirmResetPassword paramReset={paramReset} toggle={toggle}></ConfirmResetPassword>
          </div>
        </LayoutPopup>
      </ModalUI>

      <ModalTermsOfUse isShowing={openTermOfUse} toggle={() => setOpenTermOfUse(!openTermOfUse)} />
    </>
  );
};

export default ModalConfirmResetPassword;
