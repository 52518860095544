import classNames from 'classnames/bind';
import styles from '../../styles/components/affiliate/message.module.scss';

const cx = classNames.bind(styles);

const Message = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('message')}>
        <img src="/images/ufo.svg" alt="" />
        <div className={cx('text')}>
          If you have any special offers regarding cooperation or you have access to a large
          audience. Please feel free to contact our manager by <span>Telegram</span>. We're happy to
          discuss individual terms of the affiliate program tailored to you.
        </div>
      </div>
    </div>
  );
};

export default Message;
