export default function LetterIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_2159_37007)">
        <path
          d="M19.4061 7.12708L16.9475 5.07526V2.16434C16.9475 1.91301 16.7282 1.72108 16.4723 1.72108H12.8942L11.4593 0.523807C10.6139 -0.175362 9.3892 -0.175362 8.5438 0.528377L7.1089 1.72108H3.4851C3.23376 1.72108 3.00984 1.90844 3.00984 2.16434V5.12096L0.597023 7.12708C0.496489 7.2139 0.437082 7.34642 0.441652 7.47895H0.450792V17.7106C0.450792 18.9718 1.46527 20 2.72195 20H17.2766C18.5378 20 19.5477 18.9673 19.5477 17.7106V7.47895H19.5569C19.566 7.34185 19.5112 7.2139 19.4061 7.12708ZM16.9475 6.2634L18.387 7.46067L16.9475 8.52999V6.2634ZM9.12872 1.22755C9.63596 0.807131 10.3717 0.811701 10.8789 1.23212L11.4593 1.72108H8.53923L9.12872 1.22755ZM3.92379 2.63503H16.0336V9.21088L10.8287 13.086C10.3351 13.447 9.66795 13.447 9.17899 13.086L3.92379 9.17432V2.63503ZM3.00984 6.30452V8.49343L1.61607 7.4561L3.00984 6.30452ZM18.6383 17.706C18.6429 18.4646 18.0351 19.0815 17.2811 19.0861H2.72195C1.96794 19.0815 1.36017 18.4646 1.36474 17.7106V8.38832L8.63976 13.8217C9.4486 14.4204 10.5545 14.4204 11.3633 13.8217L18.6383 8.38832V17.706Z"
          fill="#FFE036"
        />
        <path
          d="M9.63655 6.29102H7.16889C6.91755 6.29102 6.71191 6.49665 6.71191 6.74799C6.71191 6.99932 6.91755 7.20496 7.16889 7.20496H9.63655C9.88788 7.20496 10.0935 6.99932 10.0935 6.74799C10.0935 6.49665 9.88788 6.29102 9.63655 6.29102Z"
          fill="#FFE036"
        />
        <path
          d="M12.8354 8.57568H7.16889C6.91755 8.57568 6.71191 8.78132 6.71191 9.03266C6.71191 9.28399 6.91755 9.48963 7.16889 9.48963H12.8354C13.0867 9.48963 13.2923 9.28399 13.2923 9.03266C13.2923 8.78132 13.0867 8.57568 12.8354 8.57568Z"
          fill="#FFE036"
        />
      </g>
      <defs>
        <clipPath id="clip0_2159_37007">
          <rect width={20} height={20} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
