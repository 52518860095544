import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import classNames from 'classnames/bind';

import styles from '../../../styles/components/wallet/deposit.module.scss';
import ArrowPreviewIcon from '../../Icons/ArrowPreviewIcon';
import ArrowNextIcon from '../../Icons/ArrowNextIcon';
import { NETWORK_CONVERT_NAME } from '../../../constant/network';

const cx = classNames.bind(styles);

interface PropsType {
  networkActive?: string;
  setNetWorkActive: (network: string) => void;
  networks: (string | undefined)[];
}

const ChooseNetwork = ({ networkActive, setNetWorkActive, networks }: PropsType) => {
  const [swiper, setSwiper] = useState<any>();

  return (
    <div className={cx('choose-network')}>
      <div className={cx('title')}>Choose Network</div>
      <div className={cx('content')}>
        {networks.length > 10 && (
          <button type="button" onClick={() => swiper.slidePrev()} className={cx('button-action')}>
            <ArrowPreviewIcon />
          </button>
        )}
        <div className={cx('slide')}>
          <Swiper
            onInit={(ev) => setSwiper(ev)}
            slidesPerView={'auto'}
            spaceBetween={10}
            className="mySwiper">
            {networks.map((item, index) => {
              const active = networkActive === item;
              return (
                <SwiperSlide
                  onClick={() => item && setNetWorkActive(item)}
                  key={index}
                  className={cx('slide-choose', active && 'slide-active')}>
                  {item ? NETWORK_CONVERT_NAME[item] : item}
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        {networks.length > 10 && (
          <button type="button" onClick={() => swiper.slideNext()} className={cx('button-action')}>
            <ArrowNextIcon />
          </button>
        )}
      </div>
    </div>
  );
};

export default ChooseNetwork;
