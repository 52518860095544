import classNames from 'classnames/bind';
import { cloneDeep } from 'lodash';
import { useRef, useState } from 'react';

import styles from '../../styles/UI/input-code.module.scss';

const cx = classNames.bind(styles);

interface PropsType {
  length: number;
  label?: string;
  loading?: boolean;
  inputSize?: number;
  onChange?: (code: string) => void;
}

const numbericKeyCode = {
  '48': 0,
  '49': 1,
  '50': 2,
  '51': 3,
  '52': 4,
  '53': 5,
  '54': 6,
  '55': 7,
  '56': 8,
  '57': 9
} as any;
const numbericBackSpace = 8;

const InputCodeV2 = ({ length, label, inputSize, onChange }: PropsType) => {
  const [code, setCode] = useState([...Array(length)].map(() => ''));
  const inputs = useRef<HTMLInputElement[]>([]);

  const onKeyUp = (e: any, slot: number) => {
    const newCode = cloneDeep(code);
    const keyCodeInput = e.keyCode;
    const keyCodeFormat = numbericKeyCode[`${keyCodeInput}`];
    if (!/[^0-9]/.test(`${keyCodeFormat}`)) {
      newCode[slot] = keyCodeFormat;

      if (slot !== newCode.length - 1) {
        inputs.current[slot + 1].focus();
      }
    }

    if (keyCodeInput === numbericBackSpace) {
      newCode[slot] = '';
      if (slot !== 0) {
        inputs.current[slot - 1].focus();
      }
    }

    setCode(newCode);
    if (onChange) {
      onChange(newCode.join(''));
    }
  };

  return (
    <div className={cx('code-input')}>
      <label className={cx('code-label')}>{label}</label>
      <div className={cx('code-inputs')}>
        {code.map((num, index) => {
          return (
            <input
              key={index}
              type="text"
              inputMode="numeric"
              maxLength={1}
              value={num}
              // autoFocus={!code[0].length && index === 0}
              readOnly={true}
              //   onChange={(e) => processInput(e, index)}
              onKeyUp={(e) => onKeyUp(e, index)}
              ref={(ref) => ref && inputs.current.push(ref)}
              style={{ width: inputSize, height: inputSize }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default InputCodeV2;
