import React from 'react';

const StatisticsIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4915 1.66663H6.50817C3.47484 1.66663 1.6665 3.47496 1.6665 6.50829V13.4833C1.6665 16.525 3.47484 18.3333 6.50817 18.3333H13.4832C16.5165 18.3333 18.3248 16.525 18.3248 13.4916V6.50829C18.3332 3.47496 16.5248 1.66663 13.4915 1.66663ZM14.0665 9.60829C14.0665 9.93329 13.8082 10.1916 13.4832 10.1916C13.1582 10.1916 12.8998 9.93329 12.8998 9.60829V9.45829L10.6332 11.725C10.5082 11.85 10.3415 11.9083 10.1665 11.8916C9.9915 11.875 9.83317 11.7833 9.7415 11.6333L8.8915 10.3666L6.90817 12.35C6.7915 12.4666 6.64984 12.5166 6.49984 12.5166C6.34984 12.5166 6.19984 12.4583 6.0915 12.35C5.8665 12.125 5.8665 11.7583 6.0915 11.525L8.57484 9.04163C8.69984 8.91663 8.8665 8.85829 9.0415 8.87496C9.2165 8.89163 9.37484 8.98329 9.4665 9.13329L10.3165 10.4L12.0748 8.64163H11.9248C11.5998 8.64163 11.3415 8.38329 11.3415 8.05829C11.3415 7.73329 11.5998 7.47496 11.9248 7.47496H13.4748C13.5498 7.47496 13.6248 7.49163 13.6998 7.51663C13.8415 7.57496 13.9582 7.69163 14.0165 7.83329C14.0498 7.90829 14.0582 7.98329 14.0582 8.05829V9.60829H14.0665Z"
        fill="#A2A3A4"
      />
    </svg>
  );
};

export default StatisticsIcon;
