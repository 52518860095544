const DiceIcon = (props: any) => {
  return (
    <svg width={81} height={80} viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1022_24869)">
        <path
          d="M75.3944 19.6509C74.8006 18.6865 73.976 17.8851 72.9945 17.3193L44.0565 0.753785C41.8983 -0.459595 39.2634 -0.459595 37.1052 0.753785L8.16724 17.3193C7.18579 17.8851 6.36121 18.6865 5.76672 19.6509C5.08191 20.7263 4.71814 21.9751 4.71875 23.2507V56.4368C4.73157 58.8861 6.04505 61.1444 8.16724 62.3682L37.1052 78.9331C39.2634 80.1471 41.8983 80.1471 44.0565 78.9331L72.9945 62.3682C75.1167 61.1444 76.4296 58.8861 76.443 56.4368V23.2507C76.4436 21.9751 76.0799 20.7263 75.3944 19.6509Z"
          fill="url(#paint0_linear_1022_24869)"
        />
        <path
          d="M76.443 23.2507V56.4368C76.4296 58.8861 75.1167 61.1444 72.9945 62.3682L44.0565 78.9331C42.9957 79.5313 41.7982 79.8444 40.5806 79.8438V39.8438L75.3944 19.6509C76.0798 20.7263 76.4436 21.9751 76.443 23.2507Z"
          fill="url(#paint1_linear_1022_24869)"
        />
        <path
          d="M40.5806 39.8438V79.8438C39.3629 79.8444 38.166 79.5313 37.1052 78.9331L8.16724 62.3682C6.04505 61.1444 4.73157 58.8861 4.71875 56.4368V23.2507C4.71814 21.9751 5.08191 20.7263 5.76672 19.6509L40.5806 39.8438Z"
          fill="url(#paint2_linear_1022_24869)"
        />
        <path
          d="M54.3653 64.466C54.0875 66.4777 52.649 68.1361 50.6964 68.6951C49.9286 69.007 49.0528 68.8898 48.393 68.3875C47.7338 67.8846 47.3883 67.0716 47.4854 66.2482C47.76 64.2377 49.2011 62.5819 51.1548 62.0325C51.9202 61.7133 52.7979 61.825 53.4595 62.3261C54.1205 62.8272 54.466 63.642 54.3653 64.466Z"
          fill="#A9A9A9"
        />
        <path
          d="M10.2441 53.4316C10.5218 55.4434 11.9604 57.1017 13.913 57.6608C14.6814 57.9727 15.5572 57.8555 16.2164 57.3532C16.8762 56.8508 17.2211 56.0372 17.124 55.2139C16.85 53.2034 15.4083 51.5475 13.4552 50.9982C12.6892 50.679 11.8115 50.7913 11.1505 51.2924C10.4889 51.7929 10.144 52.6077 10.2441 53.4316Z"
          fill="#B8B8B8"
        />
        <path
          d="M28.1753 64.466C28.453 66.4777 29.8922 68.1361 31.8441 68.6951C32.6125 69.007 33.4884 68.8899 34.1482 68.3875C34.8074 67.8846 35.1522 67.0716 35.0552 66.2482C34.7811 64.2378 33.3401 62.5819 31.3863 62.0325C30.6203 61.7133 29.7427 61.825 29.0817 62.3261C28.42 62.8272 28.0752 63.642 28.1753 64.466Z"
          fill="#B8B8B8"
        />
        <path
          d="M10.2441 34.1213C10.5218 36.133 11.9604 37.7913 13.913 38.3504C14.6814 38.6623 15.5572 38.5451 16.2164 38.0428C16.8762 37.5399 17.2211 36.7269 17.124 35.9035C16.85 33.893 15.4083 32.2371 13.4552 31.6878C12.6892 31.3686 11.8115 31.4803 11.1505 31.9814C10.4889 32.4825 10.144 33.2973 10.2441 34.1213Z"
          fill="#B8B8B8"
        />
        <path
          d="M28.1753 45.156C28.453 47.1677 29.8922 48.826 31.8441 49.3845C32.6125 49.697 33.4884 49.5798 34.1482 49.0775C34.8074 48.5745 35.1522 47.7616 35.0552 46.9376C34.7811 44.9277 33.3401 43.2718 31.3863 42.7225C30.6203 42.4033 29.7427 42.515 29.0817 43.0161C28.42 43.5172 28.0752 44.332 28.1753 45.156Z"
          fill="#B8B8B8"
        />
        <path
          d="M70.9175 34.1213C70.6398 36.133 69.2006 37.7913 67.2487 38.3504C66.4803 38.6623 65.6044 38.5451 64.9446 38.0428C64.2854 37.5399 63.9406 36.7269 64.0376 35.9035C64.3117 33.893 65.7527 32.2371 67.7065 31.6878C68.4725 31.3686 69.3501 31.4803 70.0112 31.9814C70.6728 32.4825 71.0176 33.2973 70.9175 34.1213Z"
          fill="#D2D2D2"
        />
        <path
          d="M62.6418 49.293C62.364 51.3048 60.9248 52.9631 58.9723 53.5222C58.2045 53.8341 57.3286 53.7175 56.6688 53.2146C56.0097 52.7122 55.6642 51.8986 55.7613 51.0753C56.0359 49.0648 57.477 47.4089 59.4307 46.8602C60.1961 46.541 61.0744 46.6527 61.7354 47.1538C62.3964 47.6549 62.7418 48.4697 62.6418 49.293Z"
          fill="#BBBBBB"
        />
        <path
          d="M43.777 22.0356C42.1804 23.5181 39.7756 23.7128 37.9616 22.5062C37.1346 22.0221 36.5822 21.1786 36.4693 20.2271C36.3558 19.2756 36.6951 18.3265 37.3848 17.6618C38.9833 16.1744 41.3924 15.9729 43.2155 17.1747C44.0383 17.6673 44.5852 18.5138 44.695 19.4666C44.8055 20.4194 44.4655 21.3685 43.777 22.0356Z"
          fill="url(#paint3_linear_1022_24869)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1022_24869"
          x1="40.5809"
          y1="-0.15625"
          x2="40.5809"
          y2="79.8436"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E7E7E7" />
          <stop offset="0.253142" stopColor="#EDEDED" />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1022_24869"
          x1="40.5806"
          y1="49.7473"
          x2="76.443"
          y2="49.7473"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#D2D2D2" />
          <stop offset={1} stopColor="#F8F8F8" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1022_24869"
          x1="4.71875"
          y1="49.7473"
          x2="40.5806"
          y2="49.7473"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F4F3F3" />
          <stop offset={1} stopColor="#DFDFDF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1022_24869"
          x1="40.2315"
          y1="23.2947"
          x2="41.5536"
          y2="16.5997"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C7C7C7" />
          <stop offset="0.557292" stopColor="#DDDDDD" />
          <stop offset={1} stopColor="#CDCDCD" stopOpacity="0.91" />
        </linearGradient>
        <clipPath id="clip0_1022_24869">
          <rect width={80} height={80} fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DiceIcon;
