import FireIcon from '../Icons/FireIcon';
import classNames from 'classnames/bind';

import styles from '../../styles/components/home/banner.module.scss';
import { GameBanner } from './Banner';
import { useNavigate } from 'react-router-dom';
const cx = classNames.bind(styles);

const BannerItem = ({ image, name, description, houseEdge, url }: GameBanner) => {
  const navigate = useNavigate();

  return (
    <div className={cx('wrapper')}>
      <img src={image} />
      <div className={cx('content')}>
        <div className={cx('title')}>{name}</div>
        <div className={cx('desc')}>{description}</div>
        <div className={cx('house-edge')}>
          <div className={cx('icon')}>
            <FireIcon />
          </div>
          <div>House Edge: ${houseEdge}%</div>
        </div>
      </div>

      <div className={cx('action')} onClick={() => navigate(url)}>
        <button className={cx('button-play')}>Play now</button>
      </div>
    </div>
  );
};

export default BannerItem;
