import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactProvider } from '@web3-react/core';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import App from './App';
import { firebaseConfig } from './constant/config';
import { store } from './stores';
import './styles/index.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const getLibrary = (provider: any): Web3Provider => {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12_000;
  return library;
};

const root = document.getElementById('root');
render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
    <Provider store={store}>
      <App />
    </Provider>
  </Web3ReactProvider>,
  root
);
