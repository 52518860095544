export default function EditIcon() {
  return (
    <svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.69324 10.5443L5.95566 13.8068L14.2961 5.46948L11.0305 2.20394L2.69324 10.5443ZM0.5183 15.6192C0.446427 15.9098 0.590173 16.0536 0.88079 15.9817L5.23067 14.5317L1.96826 11.2693L0.5183 15.6192ZM15.746 0.75398C14.2992 -0.695982 12.8461 0.39149 12.8461 0.39149C12.6086 0.572735 11.7586 1.47896 11.7586 1.47896L15.021 4.74137C15.021 4.74137 15.9241 3.8914 16.1085 3.6539C16.1085 3.6539 17.196 2.20707 15.746 0.75398Z"
        fill="#A2A3A4"
      />
    </svg>
  );
}
