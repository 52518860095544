import React from 'react';
import styles from '../styles/components/coming-soon.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const ComingSoon = () => {
  return (
    <div className={cx('wrapper')}>
      <img src="/images/coming_soon.png" alt="" />
      <div className={cx('text')}>
        <span>Stay tuned</span> with us...
      </div>
    </div>
  );
};

export default ComingSoon;
