import styles from '../../../styles/components/affiliate/rewards-rule/usd-rewards-rule.module.scss';
import classnames from 'classnames/bind';
import ModalUI from '../../UI/Modal';
import { ConfigReward } from 'game-profile-js/lib/client/entities/all.entity';
import { useState, useEffect } from 'react';
import TableAffiliate, { Table } from '../Table';
import { useAppSelector } from '../../../stores';

const cx = classnames.bind(styles);

interface PropsType {
  isModalOpen: boolean;
  toggle: () => void;
}

const USDRewardsRule = ({ isModalOpen, toggle }: PropsType) => {
  const gameProfile = useAppSelector((state) => state.app.gameProfileClient);
  const [tableData, setTableData] = useState<Table[]>();

  useEffect(() => {
    getConfig();
  }, []);
  const getConfig = async () => {
    const res = await gameProfile.getConfig();
    const colorMileStones = [1, 5, 7, 9];
    if (res) {
      const tableData: Table[] = res.data.map((levelRecord: ConfigReward, index) => ({
        id: index + '',
        name: colorMileStones.includes(index) ? 'color' : '',
        vipLevel: levelRecord.level.toString(),
        totalXp: levelRecord.exp,
        rewards: levelRecord.reward.toFixed(2)
      }));
      setTableData(tableData);
    }
  };

  return (
    <ModalUI
      isShowing={isModalOpen}
      hide={toggle}
      backgroundHeader="#181818"
      title={<div className={cx('title')}>USD Rewards Rules</div>}>
      <div className={cx('content')}>
        <div className={cx('text')}>
          Your invitees'VIP Level upgrade will bring you different amount of rewards(USD). Refer the
          sample model below:
        </div>
        <div className={cx('item-body')}>{tableData && <TableAffiliate table={tableData} />}</div>
      </div>
    </ModalUI>
  );
};

export default USDRewardsRule;
