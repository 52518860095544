import { useEffect } from 'react';
import AffiliateReward from './AffiliateReward';
import Banner from './Banner';
import Message from './Message';
import StartEarning from './StartEarning';

export default function Affiliate() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Banner />
      <StartEarning />
      <AffiliateReward />
      <Message />
    </div>
  );
}
