import React from 'react';
import ComingSoon from '../components/ComingSoon';

export default function ErrorPage() {
  return (
    <div>
      <ComingSoon />
    </div>
  );
}
