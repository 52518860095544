export default function RingOfFortuneIcon() {
  return (
    <svg width={27} height={26} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_512_730)">
        <path
          d="M13.0781 26C20.2578 26 26.0781 20.1797 26.0781 13C26.0781 5.8203 20.2578 0 13.0781 0C5.89842 0 0.078125 5.8203 0.078125 13C0.078125 20.1797 5.89842 26 13.0781 26Z"
          fill="url(#paint0_linear_512_730)"
        />
        <path
          d="M13.078 25.2484C19.8427 25.2484 25.3265 19.7645 25.3265 12.9999C25.3265 6.23528 19.8427 0.751465 13.078 0.751465C6.31341 0.751465 0.82959 6.23528 0.82959 12.9999C0.82959 19.7645 6.31341 25.2484 13.078 25.2484Z"
          fill="url(#paint1_linear_512_730)"
        />
        <path
          d="M13.0781 0C5.8984 0 0.078125 5.82028 0.078125 13C0.078125 20.1797 5.8984 26 13.0781 26C20.2578 26 26.0781 20.1797 26.0781 13C26.0781 5.82028 20.2578 0 13.0781 0ZM13.0781 25.2484C6.31349 25.2484 0.829678 19.7646 0.829678 13C0.829678 6.23536 6.31349 0.751553 13.0781 0.751553C19.8428 0.751553 25.3266 6.23536 25.3266 13C25.3266 19.7646 19.8428 25.2484 13.0781 25.2484Z"
          fill="url(#paint2_linear_512_730)"
        />
        <path
          d="M13.0782 24.2548C19.294 24.2548 24.333 19.2159 24.333 13.0001C24.333 6.78428 19.294 1.74536 13.0782 1.74536C6.8624 1.74536 1.82349 6.78428 1.82349 13.0001C1.82349 19.2159 6.8624 24.2548 13.0782 24.2548Z"
          fill="url(#paint3_linear_512_730)"
        />
        <path
          d="M13.0782 23.465C18.8578 23.465 23.5431 18.7797 23.5431 13.0001C23.5431 7.22045 18.8578 2.53516 13.0782 2.53516C7.29858 2.53516 2.61328 7.22045 2.61328 13.0001C2.61328 18.7797 7.29858 23.465 13.0782 23.465Z"
          fill="url(#paint4_linear_512_730)"
        />
        <path
          d="M13.0782 23.465C18.8578 23.465 23.5431 18.7797 23.5431 13.0001C23.5431 7.22045 18.8578 2.53516 13.0782 2.53516C7.29858 2.53516 2.61328 7.22045 2.61328 13.0001C2.61328 18.7797 7.29858 23.465 13.0782 23.465Z"
          fill="url(#paint5_radial_512_730)"
        />
        <path
          d="M23.4967 13.9817L13.3547 12.9969L23.4746 11.8051C23.3869 11.0332 23.2154 10.2869 22.969 9.57534L13.2919 12.8806L21.8925 7.35688C21.4792 6.71264 20.9971 6.11694 20.4567 5.5796L13.2231 12.7759L18.6086 4.11514C17.9661 3.71438 17.2771 3.38143 16.5514 3.12617L13.1069 12.7287L14.1847 2.59338C13.821 2.55514 13.4519 2.53516 13.078 2.53516C12.6815 2.53516 12.2905 2.558 11.9055 2.60092L12.9835 12.7378L9.46656 3.17516C8.74677 3.43985 8.06414 3.78127 7.42857 4.18967L12.8752 12.7941L5.48214 5.80203C4.96076 6.35206 4.49857 6.95872 4.10557 7.61173L12.7897 12.884L3.11282 9.79803C2.88418 10.5101 2.72986 11.2554 2.65869 12.0248L12.7347 13.0032L2.68051 14.1873C2.76718 14.9545 2.93643 15.6967 3.17981 16.4044L12.7975 13.1195L4.24439 18.6127C4.6535 19.2553 5.13068 19.8503 5.66602 20.3874L12.8663 13.2242L7.49948 21.855C8.13817 22.2582 8.82344 22.5942 9.54553 22.8531L12.9826 13.2714L11.9055 23.3992C12.2905 23.4422 12.6815 23.465 13.078 23.465C13.4519 23.465 13.8211 23.445 14.1847 23.4068L13.1059 13.2624L16.6306 22.8461C17.3541 22.585 18.0405 22.2465 18.6799 21.8405L13.2142 13.206L20.6424 20.2313C21.1688 19.6808 21.6358 19.073 22.0329 18.4181L13.2997 13.1161L23.037 16.2213C23.2684 15.5054 23.4247 14.7558 23.4967 13.9817Z"
          fill="url(#paint6_radial_512_730)"
        />
        <path
          d="M13.0782 18.9398C16.3586 18.9398 19.0179 16.2804 19.0179 13C19.0179 9.71961 16.3586 7.0603 13.0782 7.0603C9.79773 7.0603 7.13843 9.71961 7.13843 13C7.13843 16.2804 9.79773 18.9398 13.0782 18.9398Z"
          fill="url(#paint7_linear_512_730)"
        />
        <path
          d="M13.0782 18.9398C16.3586 18.9398 19.0179 16.2804 19.0179 13C19.0179 9.71961 16.3586 7.0603 13.0782 7.0603C9.79773 7.0603 7.13843 9.71961 7.13843 13C7.13843 16.2804 9.79773 18.9398 13.0782 18.9398Z"
          fill="url(#paint8_radial_512_730)"
        />
        <path
          d="M24.2759 17.969L16.3029 9.99595C15.4981 9.13244 14.3515 8.5918 13.078 8.5918C10.6434 8.5918 8.66968 10.5655 8.66968 13.0002C8.66968 14.2736 9.21032 15.4203 10.0738 16.225L18.0469 24.1981C20.8168 22.967 23.045 20.7389 24.2759 17.969Z"
          fill="url(#paint9_linear_512_730)"
        />
        <path
          d="M7.60596 13.6875L10.1086 16.1902V13.6875H7.60596Z"
          fill="url(#paint10_linear_512_730)"
        />
        <path
          d="M13.9145 7.73462L15.6678 9.48789H13.1282V7.73462H13.9145Z"
          fill="url(#paint11_linear_512_730)"
        />
        <path
          d="M13.0782 17.4084C15.5129 17.4084 17.4866 15.4348 17.4866 13.0001C17.4866 10.5655 15.5129 8.5918 13.0782 8.5918C10.6436 8.5918 8.66992 10.5655 8.66992 13.0001C8.66992 15.4348 10.6436 17.4084 13.0782 17.4084Z"
          fill="url(#paint12_radial_512_730)"
        />
        <path
          d="M13.0781 16.935C15.2513 16.935 17.0131 15.1732 17.0131 13C17.0131 10.8267 15.2513 9.06494 13.0781 9.06494C10.9048 9.06494 9.14307 10.8267 9.14307 13C9.14307 15.1732 10.9048 16.935 13.0781 16.935Z"
          fill="url(#paint13_linear_512_730)"
        />
        <path
          d="M15.0502 9.05664C15.3475 9.65005 15.5151 10.3197 15.5151 11.0286C15.5151 13.4633 13.5414 15.437 11.1067 15.437C10.3978 15.437 9.72818 15.2694 9.13477 14.972C9.85853 16.4166 11.3524 17.4084 13.0781 17.4084C15.5128 17.4084 17.4865 15.4347 17.4865 13.0001C17.4865 11.2743 16.4948 9.78046 15.0502 9.05664Z"
          fill="url(#paint14_linear_512_730)"
        />
        <path
          d="M16.059 11.1133C16.059 12.2445 14.7244 13.1616 13.0782 13.1616C11.432 13.1616 10.0974 12.2445 10.0974 11.1133C10.0974 9.98203 11.4319 9.06494 13.0782 9.06494C14.7244 9.06494 16.059 9.98203 16.059 11.1133Z"
          fill="url(#paint15_linear_512_730)"
        />
        <path
          d="M17.4851 13.0598H8.67139C8.67475 13.3112 8.69932 13.5574 8.74312 13.797L8.7449 13.7987L12.2813 17.3351C12.5399 17.3824 12.8059 17.4083 13.0782 17.4083C15.4928 17.4083 17.4529 15.4668 17.4851 13.0598Z"
          fill="url(#paint16_linear_512_730)"
        />
        <path
          d="M17.3979 12.1175L13.9607 8.6803C13.6756 8.62233 13.3804 8.5918 13.0781 8.5918V12.7755H17.4808C17.4695 12.5513 17.4414 12.3316 17.3979 12.1175Z"
          fill="url(#paint17_linear_512_730)"
        />
        <path
          d="M12.5684 7.91895H13.588V18.081H12.5684V7.91895Z"
          fill="url(#paint18_linear_512_730)"
        />
        <path
          d="M12.7976 7.91895H13.5879V18.081H12.7976V7.91895Z"
          fill="url(#paint19_linear_512_730)"
        />
        <path
          d="M13.588 10.0085V7.91895H12.5684V8.98892L13.588 10.0085Z"
          fill="url(#paint20_linear_512_730)"
        />
        <path
          d="M13.588 15.5483V13.4587H12.5684V14.5287L13.588 15.5483Z"
          fill="url(#paint21_linear_512_730)"
        />
        <path
          d="M13.0781 9.12596C13.6011 9.12596 14.0251 8.70197 14.0251 8.17895C14.0251 7.65593 13.6011 7.23193 13.0781 7.23193C12.5551 7.23193 12.1311 7.65593 12.1311 8.17895C12.1311 8.70197 12.5551 9.12596 13.0781 9.12596Z"
          fill="url(#paint22_radial_512_730)"
        />
        <path
          d="M13.078 9.02421C13.5449 9.02421 13.9234 8.64573 13.9234 8.17885C13.9234 7.71198 13.5449 7.3335 13.078 7.3335C12.6111 7.3335 12.2327 7.71198 12.2327 8.17885C12.2327 8.64573 12.6111 9.02421 13.078 9.02421Z"
          fill="url(#paint23_linear_512_730)"
        />
        <path
          d="M13.5018 7.33179C13.5657 7.45929 13.6017 7.6031 13.6017 7.75543C13.6017 8.27849 13.1777 8.7025 12.6546 8.7025C12.5023 8.7025 12.3585 8.66651 12.231 8.60263C12.3864 8.91294 12.7074 9.12599 13.0781 9.12599C13.6012 9.12599 14.0252 8.70199 14.0252 8.17898C14.0252 7.80825 13.8121 7.48733 13.5018 7.33179Z"
          fill="url(#paint24_linear_512_730)"
        />
        <path
          d="M13.7185 7.77356C13.7185 8.01658 13.4318 8.21357 13.0781 8.21357C12.7245 8.21357 12.4377 8.01653 12.4377 7.77356C12.4377 7.53053 12.7245 7.3335 13.0781 7.3335C13.4318 7.3335 13.7185 7.53048 13.7185 7.77356Z"
          fill="url(#paint25_linear_512_730)"
        />
        <path
          d="M13.0781 18.768C13.6011 18.768 14.0251 18.3441 14.0251 17.821C14.0251 17.298 13.6011 16.874 13.0781 16.874C12.5551 16.874 12.1311 17.298 12.1311 17.821C12.1311 18.3441 12.5551 18.768 13.0781 18.768Z"
          fill="url(#paint26_radial_512_730)"
        />
        <path
          d="M13.078 18.6665C13.5449 18.6665 13.9234 18.2881 13.9234 17.8212C13.9234 17.3543 13.5449 16.9758 13.078 16.9758C12.6111 16.9758 12.2327 17.3543 12.2327 17.8212C12.2327 18.2881 12.6111 18.6665 13.078 18.6665Z"
          fill="url(#paint27_linear_512_730)"
        />
        <path
          d="M13.5018 16.9739C13.5657 17.1014 13.6017 17.2452 13.6017 17.3975C13.6017 17.7206 13.4399 18.0059 13.1929 18.1768C13.0401 18.2826 12.8545 18.3445 12.6546 18.3445C12.5023 18.3445 12.3585 18.3085 12.231 18.2447C12.3864 18.555 12.7074 18.768 13.0781 18.768C13.6012 18.768 14.0252 18.344 14.0252 17.821C14.0252 17.4503 13.8121 17.1294 13.5018 16.9739Z"
          fill="url(#paint28_linear_512_730)"
        />
        <path
          d="M13.7185 17.4159C13.7185 17.6589 13.4318 17.8559 13.0781 17.8559C12.7245 17.8559 12.4377 17.6589 12.4377 17.4159C12.4377 17.1729 12.7245 16.9758 13.0781 16.9758C13.4318 16.9758 13.7185 17.1729 13.7185 17.4159Z"
          fill="url(#paint29_linear_512_730)"
        />
        <path
          d="M18.1592 13.5098V12.4902H7.99707V13.5098H18.1592Z"
          fill="url(#paint30_linear_512_730)"
        />
        <path
          d="M18.1592 13.5098V12.7195H7.99707V13.5098H18.1592Z"
          fill="url(#paint31_linear_512_730)"
        />
        <path
          d="M7.99712 13.5098H10.0709L9.06164 12.5007C9.06164 12.5007 9.0593 12.4971 9.05491 12.4902H7.99707V13.5098H7.99712Z"
          fill="url(#paint32_linear_512_730)"
        />
        <path
          d="M13.5486 13.5098H15.6223L14.6132 12.5007C14.6132 12.5007 14.6108 12.4971 14.6064 12.4902H13.5486V13.5098Z"
          fill="url(#paint33_linear_512_730)"
        />
        <path
          d="M17.8992 13.947C18.4222 13.947 18.8462 13.523 18.8462 13C18.8462 12.477 18.4222 12.053 17.8992 12.053C17.3761 12.053 16.9521 12.477 16.9521 13C16.9521 13.523 17.3761 13.947 17.8992 13.947Z"
          fill="url(#paint34_radial_512_730)"
        />
        <path
          d="M17.8993 13.8453C18.3662 13.8453 18.7447 13.4668 18.7447 12.9999C18.7447 12.533 18.3662 12.1545 17.8993 12.1545C17.4324 12.1545 17.054 12.533 17.054 12.9999C17.054 13.4668 17.4324 13.8453 17.8993 13.8453Z"
          fill="url(#paint35_linear_512_730)"
        />
        <path
          d="M18.3228 12.1528C18.3867 12.2803 18.4227 12.4241 18.4227 12.5765C18.4227 13.0995 17.9987 13.5235 17.4756 13.5235C17.3234 13.5235 17.1795 13.4876 17.052 13.4237C17.2075 13.734 17.5284 13.947 17.8991 13.947C18.4222 13.947 18.8462 13.523 18.8462 13C18.8462 12.6292 18.6331 12.3083 18.3228 12.1528Z"
          fill="url(#paint36_linear_512_730)"
        />
        <path
          d="M18.5395 12.5946C18.5395 12.8376 18.2528 13.0346 17.8992 13.0346C17.5455 13.0346 17.2588 12.8376 17.2588 12.5946C17.2588 12.3516 17.5455 12.1545 17.8992 12.1545C18.2528 12.1545 18.5395 12.3516 18.5395 12.5946Z"
          fill="url(#paint37_linear_512_730)"
        />
        <path
          d="M8.25707 13.947C8.78009 13.947 9.20408 13.523 9.20408 13C9.20408 12.477 8.78009 12.053 8.25707 12.053C7.73405 12.053 7.31006 12.477 7.31006 13C7.31006 13.523 7.73405 13.947 8.25707 13.947Z"
          fill="url(#paint38_radial_512_730)"
        />
        <path
          d="M8.25698 13.8453C8.72386 13.8453 9.10234 13.4668 9.10234 12.9999C9.10234 12.533 8.72386 12.1545 8.25698 12.1545C7.7901 12.1545 7.41162 12.533 7.41162 12.9999C7.41162 13.4668 7.7901 13.8453 8.25698 13.8453Z"
          fill="url(#paint39_linear_512_730)"
        />
        <path
          d="M8.68075 12.1528C8.74463 12.2803 8.78062 12.4241 8.78062 12.5765C8.78062 12.8995 8.61886 13.1849 8.37186 13.3558C8.21902 13.4616 8.0335 13.5235 7.83356 13.5235C7.68128 13.5235 7.53741 13.4875 7.40991 13.4236C7.5654 13.7339 7.88632 13.947 8.25705 13.947C8.78006 13.947 9.20412 13.523 9.20412 13C9.20412 12.6292 8.99107 12.3083 8.68075 12.1528Z"
          fill="url(#paint40_linear_512_730)"
        />
        <path
          d="M8.89743 12.5946C8.89743 12.8376 8.61072 13.0346 8.25706 13.0346C7.90341 13.0346 7.6167 12.8376 7.6167 12.5946C7.6167 12.3516 7.90341 12.1545 8.25706 12.1545C8.61072 12.1545 8.89743 12.3516 8.89743 12.5946Z"
          fill="url(#paint41_linear_512_730)"
        />
        <path
          d="M13.0782 14.6314C13.9791 14.6314 14.7095 13.901 14.7095 13C14.7095 12.099 13.9791 11.3687 13.0782 11.3687C12.1772 11.3687 11.4468 12.099 11.4468 13C11.4468 13.901 12.1772 14.6314 13.0782 14.6314Z"
          fill="url(#paint42_radial_512_730)"
        />
        <path
          d="M13.078 14.4561C13.8823 14.4561 14.5342 13.8041 14.5342 12.9999C14.5342 12.1957 13.8823 11.5437 13.078 11.5437C12.2738 11.5437 11.6218 12.1957 11.6218 12.9999C11.6218 13.8041 12.2738 14.4561 13.078 14.4561Z"
          fill="url(#paint43_linear_512_730)"
        />
        <path
          d="M13.8079 11.5408C13.918 11.7604 13.98 12.0082 13.98 12.2706C13.98 13.1715 13.2496 13.9019 12.3486 13.9019C12.0863 13.9019 11.8385 13.8399 11.6189 13.7299C11.8867 14.2645 12.4395 14.6315 13.0782 14.6315C13.9791 14.6315 14.7095 13.9011 14.7095 13.0001C14.7095 12.3615 14.3425 11.8086 13.8079 11.5408Z"
          fill="url(#paint44_linear_512_730)"
        />
        <path
          d="M14.1813 12.3017C14.1813 12.7204 13.6874 13.0598 13.0782 13.0598C12.4689 13.0598 11.9751 12.7204 11.9751 12.3017C11.9751 11.8831 12.4689 11.5437 13.0782 11.5437C13.6874 11.5437 14.1813 11.8831 14.1813 12.3017Z"
          fill="url(#paint45_linear_512_730)"
        />
        <path
          d="M21.3626 7.35384L19.3824 5.37366C19.1825 5.15918 18.8977 5.0249 18.5814 5.0249C17.9767 5.0249 17.4866 5.51508 17.4866 6.11976C17.4866 6.43604 17.6209 6.72082 17.8353 6.92066L19.8155 8.90084C20.5034 8.59521 21.0568 8.04182 21.3626 7.35384Z"
          fill="url(#paint46_linear_512_730)"
        />
        <path
          d="M19.6763 6.11976C19.6763 6.72444 19.1861 7.21461 18.5814 7.21461C17.9767 7.21461 17.4866 6.72444 17.4866 6.11976C17.4866 5.51508 17.9767 5.0249 18.5814 5.0249C19.1861 5.0249 19.6763 5.51508 19.6763 6.11976Z"
          fill="url(#paint47_linear_512_730)"
        />
        <path
          d="M19.5586 6.11987C19.5586 6.6596 19.121 7.09717 18.5813 7.09717C18.0416 7.09717 17.604 6.6596 17.604 6.11987C17.604 5.58014 18.0416 5.14258 18.5813 5.14258C19.121 5.14258 19.5586 5.58014 19.5586 6.11987Z"
          fill="url(#paint48_linear_512_730)"
        />
        <path
          d="M19.071 5.14038C19.1448 5.28777 19.1864 5.45406 19.1864 5.63015C19.1864 6.23483 18.6963 6.725 18.0916 6.725C17.9155 6.725 17.7492 6.68335 17.6018 6.60953C17.7816 6.96828 18.1526 7.21462 18.5812 7.21462C19.1859 7.21462 19.676 6.72444 19.676 6.11977C19.676 5.69117 19.4298 5.32014 19.071 5.14038Z"
          fill="url(#paint49_linear_512_730)"
        />
        <path
          d="M19.3217 5.65131C19.3217 5.93226 18.9902 6.16004 18.5814 6.16004C18.1725 6.16004 17.8411 5.93226 17.8411 5.65131C17.8411 5.37036 18.1725 5.14258 18.5814 5.14258C18.9902 5.14258 19.3217 5.37031 19.3217 5.65131Z"
          fill="url(#paint50_linear_512_730)"
        />
        <path
          d="M13.9131 22.651L11.9329 20.6708C11.733 20.4563 11.4482 20.322 11.132 20.322C10.5273 20.322 10.0371 20.8122 10.0371 21.4169C10.0371 21.7332 10.1714 22.0179 10.3859 22.2178L12.366 24.198C13.054 23.8923 13.6074 23.3389 13.9131 22.651Z"
          fill="url(#paint51_linear_512_730)"
        />
        <path
          d="M11.132 22.5117C11.7366 22.5117 12.2268 22.0215 12.2268 21.4169C12.2268 20.8122 11.7366 20.322 11.132 20.322C10.5273 20.322 10.0371 20.8122 10.0371 21.4169C10.0371 22.0215 10.5273 22.5117 11.132 22.5117Z"
          fill="url(#paint52_linear_512_730)"
        />
        <path
          d="M11.1318 22.394C11.6716 22.394 12.1091 21.9565 12.1091 21.4167C12.1091 20.877 11.6716 20.4395 11.1318 20.4395C10.5921 20.4395 10.1545 20.877 10.1545 21.4167C10.1545 21.9565 10.5921 22.394 11.1318 22.394Z"
          fill="url(#paint53_linear_512_730)"
        />
        <path
          d="M11.6217 20.4373C11.6956 20.5846 11.7372 20.7509 11.7372 20.927C11.7372 21.5317 11.247 22.0219 10.6424 22.0219C10.4663 22.0219 10.3 21.9802 10.1526 21.9064C10.3323 22.2652 10.7034 22.5115 11.132 22.5115C11.7367 22.5115 12.2268 22.0213 12.2268 21.4166C12.2268 20.988 11.9805 20.617 11.6217 20.4373Z"
          fill="url(#paint54_linear_512_730)"
        />
        <path
          d="M11.8722 20.9482C11.8722 21.2291 11.5407 21.4569 11.1319 21.4569C10.723 21.4569 10.3916 21.2291 10.3916 20.9482C10.3916 20.6672 10.723 20.4395 11.1319 20.4395C11.5407 20.4395 11.8722 20.6672 11.8722 20.9482Z"
          fill="url(#paint55_linear_512_730)"
        />
        <path
          d="M13.078 25.2484C19.8427 25.2484 25.3265 19.7645 25.3265 12.9999C25.3265 6.23528 19.8427 0.751465 13.078 0.751465C6.31341 0.751465 0.82959 6.23528 0.82959 12.9999C0.82959 19.7645 6.31341 25.2484 13.078 25.2484Z"
          fill="url(#paint56_linear_512_730)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_512_730"
          x1="3.44283"
          y1="3.36471"
          x2="22.5637"
          y2="22.4856"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.3043" stopColor="#FFCD3E" />
          <stop offset="0.8558" stopColor="#FFAD2B" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_512_730"
          x1="13.7074"
          y1="13.6293"
          x2="3.57356"
          y2="3.49543"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" stopOpacity={0} />
          <stop offset={1} stopColor="#FBED21" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_512_730"
          x1="13.2944"
          y1="13.205"
          x2="29.429"
          y2="28.4984"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E87264" stopOpacity={0} />
          <stop offset={1} stopColor="#FF7044" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_512_730"
          x1="20.3684"
          y1="20.2903"
          x2="4.55017"
          y2="4.47205"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8A7889" />
          <stop offset={1} stopColor="#6E566E" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_512_730"
          x1="6.29957"
          y1="6.22145"
          x2="21.0078"
          y2="20.9296"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8A7889" />
          <stop offset={1} stopColor="#6E566E" />
        </linearGradient>
        <radialGradient
          id="paint5_radial_512_730"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.0782 13.0001) rotate(180) scale(11.1651)">
          <stop stopColor="#6E566E" stopOpacity={0} />
          <stop offset="0.5262" stopColor="#6D546D" stopOpacity="0.526" />
          <stop offset="0.7797" stopColor="#684C68" stopOpacity="0.78" />
          <stop offset="0.9745" stopColor="#5F3F60" stopOpacity="0.975" />
          <stop offset={1} stopColor="#5E3D5F" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_512_730"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.0777 13.0001) scale(10.442)">
          <stop stopColor="#FF7044" />
          <stop offset="0.2185" stopColor="#FF6B41" />
          <stop offset="0.4693" stopColor="#FD5D38" />
          <stop offset="0.7347" stopColor="#FB4728" />
          <stop offset={1} stopColor="#F82814" />
        </radialGradient>
        <linearGradient
          id="paint7_linear_512_730"
          x1="10.7636"
          y1="10.6855"
          x2="19.5976"
          y2="19.5195"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#4AB272" />
          <stop offset={1} stopColor="#077565" />
        </linearGradient>
        <radialGradient
          id="paint8_radial_512_730"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.4605 13.6117) scale(7.27363)">
          <stop stopColor="#005A01" stopOpacity={0} />
          <stop offset="0.8587" stopColor="#005A01" stopOpacity="0.859" />
          <stop offset={1} stopColor="#005A01" />
        </radialGradient>
        <linearGradient
          id="paint9_linear_512_730"
          x1="17.3159"
          y1="17.2247"
          x2="11.5041"
          y2="11.7188"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#005A01" stopOpacity={0} />
          <stop offset={1} stopColor="#005A01" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_512_730"
          x1="9.98628"
          y1="15.606"
          x2="8.03628"
          y2="12.2923"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#005A01" stopOpacity={0} />
          <stop offset={1} stopColor="#005A01" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_512_730"
          x1="14.4903"
          y1="9.64475"
          x2="12.8972"
          y2="6.93759"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#005A01" stopOpacity={0} />
          <stop offset={1} stopColor="#005A01" />
        </linearGradient>
        <radialGradient
          id="paint12_radial_512_730"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.8086 11.3072) scale(5.49278)">
          <stop stopColor="#FFD945" />
          <stop offset="0.4169" stopColor="#FFD744" />
          <stop offset="0.6278" stopColor="#FFCF3F" />
          <stop offset="0.7926" stopColor="#FFC237" />
          <stop offset="0.9326" stopColor="#FFAF2C" />
          <stop offset={1} stopColor="#FFA325" />
        </radialGradient>
        <linearGradient
          id="paint13_linear_512_730"
          x1="13.8439"
          y1="13.7658"
          x2="8.5704"
          y2="8.49228"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" stopOpacity={0} />
          <stop offset={1} stopColor="#FBED21" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_512_730"
          x1="14.9509"
          y1="13.1657"
          x2="23.1628"
          y2="6.21484"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E87264" stopOpacity={0} />
          <stop offset={1} stopColor="#FF7044" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_512_730"
          x1="13.0782"
          y1="11.6442"
          x2="13.0782"
          y2="7.4873"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_512_730"
          x1="13.5915"
          y1="16.1715"
          x2="12.7928"
          y2="11.5832"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E87264" stopOpacity={0} />
          <stop offset={1} stopColor="#FF7044" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_512_730"
          x1="15.3126"
          y1="11.0293"
          x2="12.5937"
          y2="10.5704"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E87264" stopOpacity={0} />
          <stop offset={1} stopColor="#FF7044" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_512_730"
          x1="12.6193"
          y1={13}
          x2="13.4076"
          y2={13}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.4169" stopColor="#FFD744" />
          <stop offset="0.6278" stopColor="#FFCF3F" />
          <stop offset="0.7926" stopColor="#FFC237" />
          <stop offset="0.9326" stopColor="#FFAF2C" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_512_730"
          x1="13.4601"
          y1="13.0343"
          x2="12.2205"
          y2="12.8754"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" stopOpacity={0} />
          <stop offset={1} stopColor="#FBED21" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_512_730"
          x1="13.9659"
          y1="9.66457"
          x2="13.0313"
          y2="8.39006"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E87264" stopOpacity={0} />
          <stop offset={1} stopColor="#FF7044" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_512_730"
          x1="13.9659"
          y1="15.2044"
          x2="13.0313"
          y2="13.9299"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E87264" stopOpacity={0} />
          <stop offset={1} stopColor="#FF7044" />
        </linearGradient>
        <radialGradient
          id="paint22_radial_512_730"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.8054 7.81525) scale(1.17999)">
          <stop stopColor="#FFD945" />
          <stop offset="0.4169" stopColor="#FFD744" />
          <stop offset="0.6278" stopColor="#FFCF3F" />
          <stop offset="0.7926" stopColor="#FFC237" />
          <stop offset="0.9326" stopColor="#FFAF2C" />
          <stop offset={1} stopColor="#FFA325" />
        </radialGradient>
        <linearGradient
          id="paint23_linear_512_730"
          x1="13.2425"
          y1="8.34337"
          x2="12.1097"
          y2="7.21048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" stopOpacity={0} />
          <stop offset={1} stopColor="#FBED21" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_512_730"
          x1="13.4804"
          y1="8.21456"
          x2="15.2446"
          y2="6.7213"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E87264" stopOpacity={0} />
          <stop offset={1} stopColor="#FF7044" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_512_730"
          x1="13.0781"
          y1="7.8876"
          x2="13.0781"
          y2="6.99458"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <radialGradient
          id="paint26_radial_512_730"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.8054 17.4573) scale(1.17999)">
          <stop stopColor="#FFD945" />
          <stop offset="0.4169" stopColor="#FFD744" />
          <stop offset="0.6278" stopColor="#FFCF3F" />
          <stop offset="0.7926" stopColor="#FFC237" />
          <stop offset="0.9326" stopColor="#FFAF2C" />
          <stop offset={1} stopColor="#FFA325" />
        </radialGradient>
        <linearGradient
          id="paint27_linear_512_730"
          x1="13.2425"
          y1="17.9857"
          x2="12.1097"
          y2="16.8528"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" stopOpacity={0} />
          <stop offset={1} stopColor="#FBED21" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_512_730"
          x1="13.4804"
          y1="17.8566"
          x2="15.2446"
          y2="16.3634"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E87264" stopOpacity={0} />
          <stop offset={1} stopColor="#FF7044" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_512_730"
          x1="13.0781"
          y1="17.5299"
          x2="13.0781"
          y2="16.6369"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_512_730"
          x1="13.0781"
          y1="12.5412"
          x2="13.0781"
          y2="13.3295"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.4169" stopColor="#FFD744" />
          <stop offset="0.6278" stopColor="#FFCF3F" />
          <stop offset="0.7926" stopColor="#FFC237" />
          <stop offset="0.9326" stopColor="#FFAF2C" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_512_730"
          x1="13.1124"
          y1="13.382"
          x2="12.9535"
          y2="12.1424"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" stopOpacity={0} />
          <stop offset={1} stopColor="#FBED21" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_512_730"
          x1="9.71016"
          y1="13.379"
          x2="8.86047"
          y2="13.107"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E87264" stopOpacity={0} />
          <stop offset={1} stopColor="#FF7044" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_512_730"
          x1="15.2616"
          y1="13.379"
          x2="14.4119"
          y2="13.107"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E87264" stopOpacity={0} />
          <stop offset={1} stopColor="#FF7044" />
        </linearGradient>
        <radialGradient
          id="paint34_radial_512_730"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.6264 12.6363) scale(1.17999)">
          <stop stopColor="#FFD945" />
          <stop offset="0.4169" stopColor="#FFD744" />
          <stop offset="0.6278" stopColor="#FFCF3F" />
          <stop offset="0.7926" stopColor="#FFC237" />
          <stop offset="0.9326" stopColor="#FFAF2C" />
          <stop offset={1} stopColor="#FFA325" />
        </radialGradient>
        <linearGradient
          id="paint35_linear_512_730"
          x1="18.0638"
          y1="13.1644"
          x2="16.9309"
          y2="12.0315"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" stopOpacity={0} />
          <stop offset={1} stopColor="#FBED21" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_512_730"
          x1="18.3014"
          y1="13.0356"
          x2="20.0656"
          y2="11.5423"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E87264" stopOpacity={0} />
          <stop offset={1} stopColor="#FF7044" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_512_730"
          x1="17.8992"
          y1="12.7086"
          x2="17.8992"
          y2="11.8156"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <radialGradient
          id="paint38_radial_512_730"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7.98433 12.6363) scale(1.17999)">
          <stop stopColor="#FFD945" />
          <stop offset="0.4169" stopColor="#FFD744" />
          <stop offset="0.6278" stopColor="#FFCF3F" />
          <stop offset="0.7926" stopColor="#FFC237" />
          <stop offset="0.9326" stopColor="#FFAF2C" />
          <stop offset={1} stopColor="#FFA325" />
        </radialGradient>
        <linearGradient
          id="paint39_linear_512_730"
          x1="8.42149"
          y1="13.1644"
          x2="7.28861"
          y2="12.0315"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" stopOpacity={0} />
          <stop offset={1} stopColor="#FBED21" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_512_730"
          x1="8.65939"
          y1="13.0356"
          x2="10.4236"
          y2="11.5423"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E87264" stopOpacity={0} />
          <stop offset={1} stopColor="#FF7044" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_512_730"
          x1="8.25706"
          y1="12.7086"
          x2="8.25706"
          y2="11.8156"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <radialGradient
          id="paint42_radial_512_730"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.6083 12.3735) scale(2.03269)">
          <stop stopColor="#FFD945" />
          <stop offset="0.4169" stopColor="#FFD744" />
          <stop offset="0.6278" stopColor="#FFCF3F" />
          <stop offset="0.7926" stopColor="#FFC237" />
          <stop offset="0.9326" stopColor="#FFAF2C" />
          <stop offset={1} stopColor="#FFA325" />
        </radialGradient>
        <linearGradient
          id="paint43_linear_512_730"
          x1="13.3614"
          y1="13.2833"
          x2="11.4099"
          y2="11.3318"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" stopOpacity={0} />
          <stop offset={1} stopColor="#FBED21" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_512_730"
          x1="13.7712"
          y1="13.0614"
          x2="16.8101"
          y2="10.4891"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E87264" stopOpacity={0} />
          <stop offset={1} stopColor="#FF7044" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_512_730"
          x1="13.0782"
          y1="12.4982"
          x2="13.0782"
          y2="10.9599"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_512_730"
          x1="20.2385"
          y1="7.77693"
          x2="17.7399"
          y2="5.27833"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#6E566E" stopOpacity={0} />
          <stop offset={1} stopColor="#6E566E" />
        </linearGradient>
        <linearGradient
          id="paint47_linear_512_730"
          x1="17.4866"
          y1="6.11976"
          x2="19.6763"
          y2="6.11976"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4F2FF" />
          <stop offset={1} stopColor="#B2CBFF" />
        </linearGradient>
        <linearGradient
          id="paint48_linear_512_730"
          x1="18.7715"
          y1="6.31008"
          x2="17.4617"
          y2="5.00034"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_512_730"
          x1="19.0463"
          y1="6.16091"
          x2="21.0859"
          y2="4.43456"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3F3CED" stopOpacity={0} />
          <stop offset="0.2796" stopColor="#3C3DE4" stopOpacity="0.28" />
          <stop offset="0.7305" stopColor="#3541CC" stopOpacity="0.731" />
          <stop offset={1} stopColor="#2F43BB" />
        </linearGradient>
        <linearGradient
          id="paint50_linear_512_730"
          x1="18.5814"
          y1="5.95306"
          x2="18.5814"
          y2="4.92066"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint51_linear_512_730"
          x1="12.7891"
          y1="23.074"
          x2="10.2905"
          y2="20.5754"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#6E566E" stopOpacity={0} />
          <stop offset={1} stopColor="#6E566E" />
        </linearGradient>
        <linearGradient
          id="paint52_linear_512_730"
          x1="10.0371"
          y1="21.4169"
          x2="12.2268"
          y2="21.4169"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4F2FF" />
          <stop offset={1} stopColor="#B2CBFF" />
        </linearGradient>
        <linearGradient
          id="paint53_linear_512_730"
          x1="11.322"
          y1="21.607"
          x2="10.0123"
          y2="20.2972"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint54_linear_512_730"
          x1="11.5971"
          y1="21.4578"
          x2="13.6366"
          y2="19.7315"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3F3CED" stopOpacity={0} />
          <stop offset="0.2796" stopColor="#3C3DE4" stopOpacity="0.28" />
          <stop offset="0.7305" stopColor="#3541CC" stopOpacity="0.731" />
          <stop offset={1} stopColor="#2F43BB" />
        </linearGradient>
        <linearGradient
          id="paint55_linear_512_730"
          x1="11.1318"
          y1="21.25"
          x2="11.1318"
          y2="20.2176"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint56_linear_512_730"
          x1="14.2296"
          y1="15.2267"
          x2="-0.757838"
          y2="-13.7541"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_512_730">
          <rect width={26} height={26} fill="white" transform="translate(0.078125)" />
        </clipPath>
      </defs>
    </svg>
  );
}
