import React from 'react';

export default function DeleteYellowIcon() {
  return (
    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.25 4.25L3.75 11.75"
        stroke="url(#paint0_linear_1837_32979)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 4.25L11.25 11.75"
        stroke="url(#paint1_linear_1837_32979)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1837_32979"
          x1="3.75"
          y1={8}
          x2="11.25"
          y2={8}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1837_32979"
          x1="3.75"
          y1={8}
          x2="11.25"
          y2={8}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
      </defs>
    </svg>
  );
}
