import classNames from 'classnames/bind';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import styles from '../../styles/UI/modal.module.scss';
import ClosedIcon from '../Icons/ClosedIcon';

const cx = classNames.bind(styles);

interface PropsType {
  width?: string;
  height?: string;
  padding?: string;
  backgroundColor?: string;
  opacity?: string;
  isShowing: boolean;
  children: React.ReactNode;
  hide: () => void;
  title?: string | React.ReactNode;
  backgroundHeader?: string;
  footer?: React.ReactNode;
}

const ModalUI = ({
  isShowing,
  width,
  padding,
  height,
  children,
  hide,
  backgroundColor,
  opacity,
  title,
  backgroundHeader,
  footer
}: PropsType) => {
  useEffect(() => {
    if (isShowing) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'visible';
  }, [isShowing]);
  return isShowing
    ? ReactDOM.createPortal(
        <>
          <div className={cx('modal-container')}>
            <div className={cx('background')} style={{ opacity: opacity }} onClick={hide} />
            <div
              className={cx('modal')}
              style={{ background: backgroundColor, width: width, height: height }}>
              <div
                className={cx('modal-header')}
                style={{ background: backgroundHeader, padding: padding }}>
                <div className={cx('title')}>{title}</div>
                <button type="button" className={cx('modal-close-button')} onClick={hide}>
                  <ClosedIcon width={16} height={16} />
                </button>
              </div>
              <div className={cx('modal-content')}>{children}</div>
              {footer && <div className={cx('modal-footer')}>{footer}</div>}
            </div>
          </div>
        </>,
        document.body
      )
    : null;
};

export default ModalUI;
