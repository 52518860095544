import classnames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constant/routes';
import styles from '../../styles/UI/warning-modal.module.scss';
import ModalUI from '../UI/Modal';

const cx = classnames.bind(styles);

interface PropsType {
  isShowing: boolean;
  toggle: () => void;
}

export default function ModalWarning({ isShowing, toggle }: PropsType) {
  return (
    <ModalUI isShowing={isShowing} hide={toggle}>
      <div className={cx('body')}>
        <img src="/images/alert.png" alt="alert" />
        <p>
          You need to input the login email and login password first. Please complete{' '}
          <Link to={ROUTES.GLOBAL_SETTING.LINK_EMAIL}>Email Verification</Link> to enable 2FA
        </p>
      </div>
    </ModalUI>
  );
}
