import React from 'react';

const VolumeIcon = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3276_57092)">
        <path
          d="M20.4836 3.51619C15.7951 -1.17225 8.20605 -1.17318 3.51668 3.51619C-1.17176 8.20463 -1.1727 15.7937 3.51668 20.4831C8.20512 25.1715 15.7942 25.1724 20.4836 20.4831C25.172 15.7946 25.1725 8.20557 20.4836 3.51619ZM12.4848 17.0049C12.4848 17.8056 11.5778 18.272 10.9267 17.807L7.68012 15.4876H5.99356C5.44934 15.4876 5.00824 15.0465 5.00824 14.5023V9.49697C5.00824 8.95275 5.44934 8.51166 5.99356 8.51166H7.68012L10.9267 6.19229C11.5782 5.72682 12.4848 6.19416 12.4848 6.99432V17.0049ZM15.9887 13.5015C15.9887 14.0457 15.5476 14.4868 15.0034 14.4868C14.4592 14.4868 14.0181 14.0457 14.0181 13.5015V10.4982C14.0181 9.954 14.4592 9.51291 15.0034 9.51291C15.5476 9.51291 15.9887 9.954 15.9887 10.4982V13.5015ZM18.992 15.0029C18.992 15.5471 18.5509 15.9882 18.0067 15.9882C17.4625 15.9882 17.0214 15.5471 17.0214 15.0029V8.99635C17.0214 8.45213 17.4625 8.01104 18.0067 8.01104C18.5509 8.01104 18.992 8.45213 18.992 8.99635V15.0029Z"
          fill="#A2A3A4"
        />
      </g>
      <defs>
        <clipPath id="clip0_3276_57092">
          <rect width={24} height={24} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VolumeIcon;
