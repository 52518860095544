import React from 'react';

const ZoomOutIcon = () => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.33317 15C9.81232 14.9997 11.2488 14.5045 12.414 13.5933L16.0773 17.2566L17.2557 16.0783L13.5923 12.415C14.504 11.2497 14.9995 9.81282 14.9998 8.33329C14.9998 4.65746 12.009 1.66663 8.33317 1.66663C4.65734 1.66663 1.6665 4.65746 1.6665 8.33329C1.6665 12.0091 4.65734 15 8.33317 15ZM4.99984 7.49996H11.6665V9.16663H4.99984V7.49996Z"
        fill="#A2A3A4"
      />
    </svg>
  );
};

export default ZoomOutIcon;
