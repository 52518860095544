import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import CrashGame from 'bybet-game-js/lib/game/crash';
import { Player } from 'bybet-game-js/lib/schema/BaseGame';
import { CrashGameTurnType } from 'bybet-game-js/lib/schema/CrashGame';
export interface CrashState {
  crashGameIns: CrashGame | undefined;
  isConnect: boolean;
  players: Player[];
  currentTurn: CrashGameTurnType | undefined;
}

const initialState: CrashState = {
  crashGameIns: undefined,
  isConnect: false,
  players: [],
  currentTurn: undefined
};

export const crashGameSlice = createSlice({
  name: 'crash-game',
  initialState,
  reducers: {
    setCrashGameIns: (state, action: PayloadAction<CrashGame>) => {
      state.crashGameIns = action.payload;
    },
    setConnect: (state, action: PayloadAction<boolean>) => {
      state.isConnect = action.payload;
    },
    setPlayers: (state, action: PayloadAction<Player[]>) => {
      state.players = action.payload;
    },
    setCurrentTurn: (state, action: PayloadAction<CrashGameTurnType>) => {
      state.currentTurn = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setCrashGameIns, setConnect, setPlayers, setCurrentTurn } = crashGameSlice.actions;

export default crashGameSlice.reducer;
