import classNames from 'classnames/bind';
import styles from '../../styles/components/modal/modal-user-information.module.scss';
import ModalUI from '../UI/Modal';
import { Swiper, SwiperSlide } from 'swiper/react';

import { EffectCreative } from 'swiper';
import { useEffect, useState } from 'react';
import ArrowPreviewIcon from '../Icons/ArrowPreviewIcon';
import EditNickname from './EditNickname';
import EditAvatar from './EditAvatar';
import UserInformation from './UserInformationPopup';
import StatisticDetail from './StatisticDetail';
import { SLIDE_USER } from '../../constant';

import { Swiper as SwiperType } from 'swiper/types';

interface User {
  name: string;
  avatar?: string;
  userId: string;
  totalWins: number;
  totalBets: number;
  totalWagered: number;
  favoriteGames: [];
  wagerContest: [];
}

interface PropsType {
  user: User;
  reload: () => void;
  swiper?: SwiperType;
  onInit: (ev: SwiperType) => void;
  setSlideActive: (index: number) => void;
}

const cx = classNames.bind(styles);

const SlideUserInformation = ({ swiper, user, reload, onInit, setSlideActive }: PropsType) => {
  return (
    <div className={cx('wrapper')}>
      <Swiper
        grabCursor={true}
        effect={'creative'}
        onInit={onInit}
        onActiveIndexChange={(index) => setSlideActive(index.realIndex)}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400]
          },
          next: {
            translate: ['100%', 0, 0]
          }
        }}
        modules={[EffectCreative]}
        // autoHeight={true}
        allowTouchMove={false}
        className="mySwiper">
        <SwiperSlide>
          <UserInformation
            user={user}
            nextSlideDetail={() => swiper && swiper.slideNext()}
            nextSlideEdit={() => swiper && swiper.slideTo(SLIDE_USER.EDIT_NAME)}
          />
        </SwiperSlide>
        <SwiperSlide>
          <StatisticDetail user={user} />
        </SwiperSlide>
        <SwiperSlide>
          <EditNickname
            isShowing={true}
            openModalEditAvatar={() => swiper && swiper.slideTo(SLIDE_USER.EDIT_AVATAR)}
            name={user.name}
            avatar={user.avatar}
            id={user.userId}
            reload={reload}
          />
        </SwiperSlide>
        <SwiperSlide>
          <EditAvatar reload={reload} userId={user.userId} defaultAvt={user.avatar} />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default SlideUserInformation;
