import React from 'react';
import GameDetail from '../../components/Games/Detail';
import { GAME_INTRODUCTION } from '../../constant/game';
import { ROUTES } from '../../constant/routes';

export default function ClassicDiceDetailPage() {
  return (
    <GameDetail
      name="Classic Dice"
      imageUrl="/images/games/classic-dice.png"
      releaseDate="--"
      gameInfo={GAME_INTRODUCTION.CLASSIC_DICE}
      linkGame={ROUTES.CLASSIC_DICE.INDEX}
      listSlide={[
        'classic-dice-slide-1',
        'classic-dice-slide-2',
        'classic-dice-slide-3',
        'classic-dice-slide-4'
      ]}
    />
  );
}
