import classnames from 'classnames/bind';
import useModal from '../../hooks/useModal';
import InfoIcon from '../Icons/InfoIcon';
import ModalUI from '../UI/Modal';
import styles from '../../styles/Layout/chat-rules.module.scss';

const cx = classnames.bind(styles);

const RULE_PROHIBITIONS = [
  'Spam, harass, or be offensive to other users and use CAPS.',
  'Beg or ask for loans and tips coins.',
  'Spark scams even without causing harm to others.',
  'Promote in any forms of advertising/ trading/ selling/ buying or offering services.'
];

const RULE_ADVICES = [
  'Be polite and friendly in the BYBET community',
  'No suspicious behavior that can be easy to misunderstand',
  "Don't use URL shortener. Always submit the original link."
];

export default function ModalChatRules() {
  const { isShowing, toggle } = useModal();
  return (
    <>
      <div onClick={toggle}>
        <InfoIcon />
      </div>
      <ModalUI
        title={<p className={cx('title')}>Chat rules</p>}
        isShowing={isShowing}
        hide={toggle}>
        <div className={cx('body')}>
          <p className={cx('title')}>Prohibitions</p>
          <ul>
            {RULE_PROHIBITIONS.map((rule, index) => (
              <li key={index}>{rule}</li>
            ))}
          </ul>

          <p>You will be banned if you violate these prohibitions.</p>
          <p className={cx('title')}>Some advice</p>
          <ul>
            {RULE_ADVICES.map((rule, index) => (
              <li key={index}>{rule}</li>
            ))}
          </ul>
        </div>
      </ModalUI>
    </>
  );
}
