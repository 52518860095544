import classnames from 'classnames/bind';
import { motion } from 'framer-motion';
import { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useBreakpoints from '../../hooks/useBreakpoints';
import { RootState } from '../../stores';
import { updateLoadingGlobal } from '../../stores/LoadingSlice';
import styles from '../../styles/layout.module.scss';
import Chat from '../Chat';
import Loading from '../Loading';
import Footer from './Footer';
import Header from './Header';
import MenuMobile from './MenuMobile';
import Notification from './Notification';
import Sidebar from './Sidebar';

const cx = classnames.bind(styles);

interface PropsType {
  children: ReactNode;
}

export default function Layout({ children }: PropsType) {
  const [isShowChat, setIsShowChat] = useState(false);
  const [isShowNoti, setIsShowNoti] = useState(false);
  const { isTablet } = useBreakpoints();
  const loading = useSelector((state: RootState) => state.loading.loading);
  const dispatch = useDispatch();

  const handleShowChat = () => {
    setIsShowChat(!isShowChat);
    setIsShowNoti(false);
  };

  const handleShowNoti = () => {
    setIsShowChat(false);
    setIsShowNoti(!isShowNoti);
  };

  useEffect(() => {
    if (!isTablet) {
      dispatch(updateLoadingGlobal(true));
      const timeInit = setTimeout(() => {
        // setIsShowChat(true);
        dispatch(updateLoadingGlobal(false));
      }, 1000);

      return () => {
        clearTimeout(timeInit);
      };
    }
  }, [isTablet]);

  const body = {
    open: {
      width: '100%',
      transition: {
        type: 'spring',
        stiffness: 50,
        restDelta: 2
      }
    },
    closed: {
      width: !isTablet ? 'calc(100% - 267px)' : '100%',
      transition: {
        // delay: 0.5,
        type: 'spring',
        stiffness: 100,
        damping: 40
      }
    }
  };

  const chat = {
    open: (width = 267) => ({
      width,
      overflow: 'hidden',
      transition: {
        type: 'spring',
        stiffness: 50,
        restDelta: 2
      }
    }),
    closed: {
      width: 0,
      overflow: 'hidden',
      transition: {
        // delay: 0.5,
        type: 'spring',
        stiffness: 100,
        damping: 40
      }
    }
  };

  // const noti = {
  //   open: (width = 267) => ({
  //     width,
  //     overflow: 'hidden',
  //     transition: {
  //       type: 'spring',
  //       stiffness: 50,
  //       restDelta: 2
  //     }
  //   }),
  //   closed: {
  //     width: 0,
  //     overflow: 'hidden',
  //     transition: {
  //       // delay: 0.5,
  //       type: 'spring',
  //       stiffness: 100,
  //       damping: 40
  //     }
  //   }
  // };

  return (
    <>
      <div className={cx('layout-first-root')}>
        <div className={cx('side-bar')}>
          <Sidebar />
        </div>
        <div className={cx('layout-second-root')}>
          <Header handleShowChat={handleShowChat} handleShowNoti={handleShowNoti} />
          <div className={cx('layout-third-root')}>
            <motion.div
              initial={false}
              animate={!isShowChat && !isShowNoti ? 'open' : 'closed'}
              variants={body}
              className={cx('layout-fourth-root')}>
              {loading.global ? (
                <Loading />
              ) : (
                <>
                  <div className={cx('body-root')}>{children}</div>
                  <Footer />
                </>
              )}
            </motion.div>
            <motion.div
              className={cx('chat-root')}
              initial={false}
              animate={isShowChat || isShowNoti ? 'open' : 'closed'}
              variants={chat}>
              <motion.div
                initial={false}
                animate={isShowChat ? 'open' : 'closed'}
                className={cx('chat-container')}
                variants={chat}>
                <Chat handleShowChat={handleShowChat} isShowChat={isShowChat} />
              </motion.div>
              <motion.div
                initial={false}
                animate={isShowNoti ? 'open' : 'closed'}
                className={cx('chat-container')}
                variants={chat}>
                <Notification handleShowNoti={handleShowNoti} isShowNoti={isShowNoti} />
              </motion.div>
            </motion.div>
          </div>
        </div>
      </div>
      <MenuMobile />
    </>
  );
}
