import React from 'react';

const TotalWageredIcon = () => {
  return (
    <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_783_26372)">
        <path
          d="M14.25 0C12.7839 0 8 0.135508 8 1.875C8 3.61449 12.7839 3.75 14.25 3.75C15.7161 3.75 20.5 3.61449 20.5 1.875C20.5 0.135508 15.7161 0 14.25 0Z"
          fill="url(#paint0_linear_783_26372)"
        />
        <path
          d="M7.375 6.25C3.58414 6.25 0.5 9.33414 0.5 13.125C0.5 16.9159 3.58414 20 7.375 20C11.1659 20 14.25 16.9159 14.25 13.125C14.25 9.33414 11.1659 6.25 7.375 6.25ZM7.375 18.75C4.2732 18.75 1.75 16.2268 1.75 13.125C1.75 10.0232 4.2732 7.5 7.375 7.5C10.4768 7.5 13 10.0232 13 13.125C13 16.2268 10.4768 18.75 7.375 18.75Z"
          fill="url(#paint1_linear_783_26372)"
        />
        <path
          d="M7.375 8.75C7.72047 8.75 8 9.02953 8 9.375V10.1151C8.7259 10.374 9.25 11.0612 9.25 11.875C9.25 12.2205 8.97047 12.5 8.625 12.5C8.27953 12.5 8 12.2205 8 11.875C8 11.5302 7.71984 11.25 7.375 11.25C7.03016 11.25 6.75 11.5302 6.75 11.875C6.75 12.2198 7.03016 12.5 7.375 12.5C8.40891 12.5 9.25 13.3411 9.25 14.375C9.25 15.1888 8.7259 15.876 8 16.1349V16.875C8 17.2205 7.72047 17.5 7.375 17.5C9.78711 17.5 11.75 15.5371 11.75 13.125C11.75 10.7129 9.78711 8.75 7.375 8.75Z"
          fill="url(#paint2_linear_783_26372)"
        />
        <path
          d="M7.375 13.75C6.34109 13.75 5.5 12.9089 5.5 11.875C5.5 11.0612 6.0241 10.374 6.75 10.1151V9.375C6.75 9.02953 7.02953 8.75 7.375 8.75C4.96289 8.75 3 10.7129 3 13.125C3 15.5371 4.96289 17.5 7.375 17.5C7.02953 17.5 6.75 17.2205 6.75 16.875V16.1349C6.0241 15.876 5.5 15.1888 5.5 14.375C5.5 14.0295 5.77953 13.75 6.125 13.75C6.47047 13.75 6.75 14.0295 6.75 14.375C6.75 14.7198 7.03016 15 7.375 15C7.71984 15 8 14.7198 8 14.375C8 14.0302 7.71984 13.75 7.375 13.75Z"
          fill="url(#paint3_linear_783_26372)"
        />
        <path
          d="M14.25 5C12.7875 5 8 4.8625 8 3.125V5.03168C10.6073 5.23164 12.8706 6.66406 14.2104 8.7498C14.2225 8.7498 14.2384 8.75 14.25 8.75C15.7125 8.75 20.5 8.6125 20.5 6.875V3.125C20.5 4.8625 15.7125 5 14.25 5Z"
          fill="url(#paint4_linear_783_26372)"
        />
        <path
          d="M14.8701 9.99199C15.275 10.9567 15.4999 12.015 15.4999 13.125C15.4999 13.3261 15.4841 13.5232 15.4697 13.7207C17.4442 13.6295 20.4999 13.2638 20.4999 11.875V8.125C20.4999 9.665 16.7436 9.9475 14.8701 9.99199Z"
          fill="url(#paint5_linear_783_26372)"
        />
        <path
          d="M15.2782 14.979C14.9418 16.4124 14.2275 17.6992 13.2427 18.7297C13.6473 18.7452 13.9996 18.75 14.25 18.75C15.7125 18.75 20.5 18.6125 20.5 16.875V13.125C20.5 14.5598 17.2393 14.9028 15.2782 14.979Z"
          fill="url(#paint6_linear_783_26372)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_783_26372"
          x1={8}
          y1="1.875"
          x2="20.5"
          y2="1.875"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_783_26372"
          x1="0.5"
          y1="13.125"
          x2="14.25"
          y2="13.125"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_783_26372"
          x1="6.75"
          y1="13.125"
          x2="11.75"
          y2="13.125"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_783_26372"
          x1={3}
          y1="13.125"
          x2={8}
          y2="13.125"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_783_26372"
          x1={8}
          y1="5.9375"
          x2="20.5"
          y2="5.9375"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_783_26372"
          x1="14.8701"
          y1="10.9229"
          x2="20.4999"
          y2="10.9229"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_783_26372"
          x1="13.2427"
          y1="15.9375"
          x2="20.5"
          y2="15.9375"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <clipPath id="clip0_783_26372">
          <rect width={20} height={20} fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TotalWageredIcon;
