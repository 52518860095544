import { GiphyFetch } from '@giphy/js-fetch-api';
import { IGif } from '@giphy/js-types';
import { Gif } from '@giphy/react-components';
import classnames from 'classnames/bind';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { GIPHY_API_KEY } from '../../constant/chat';
import styles from '../../styles/UI/giphy-picker.module.scss';

const cx = classnames.bind(styles);

const apiKey = GIPHY_API_KEY;

const giphyFetch = new GiphyFetch(apiKey);

const limit = 30;
const offset = 0;

export default function GiphyPicker({ onGifClick }: any) {
  const [searchValue, setSearchValue] = useState('');
  const [gifs, setGifs] = useState<IGif[]>([]);

  const handleDebounceFn = (e: any) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), []);

  const fetchGifs = async (offset: number) => {
    try {
      const res = await giphyFetch.trending({ offset, limit });
      if (res) {
        setGifs(res.data);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleSearch = async () => {
    try {
      const res = await giphyFetch.search(searchValue, { offset, limit });
      if (res) {
        setGifs(res.data);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (searchValue.length > 0) {
      handleSearch();
    } else {
      fetchGifs(offset);
    }
  }, [searchValue]);

  return (
    <div className={cx('root')}>
      <input placeholder="Search GIF" onChange={debounceFn} />

      <div className={cx('container')}>
        {gifs.map((gif) => (
          <div key={gif.id} className={cx('gif-item')}>
            <Gif gif={gif} width={95} height={100} onGifClick={onGifClick} />
          </div>
        ))}
      </div>

      <div className={cx('img-container')}>
        <img src="/images/giphy-brand.gif" alt="Powered by GIPHY" />
      </div>
    </div>
  );
}
