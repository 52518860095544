export default function ClassicDiceIcon() {
  return (
    <svg width={27} height={26} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_512_791)">
        <path
          d="M22.8281 0.8125H10.6406C9.29443 0.8125 8.20312 1.90381 8.20312 3.25V14.625C8.20312 15.9712 9.29443 17.0625 10.6406 17.0625H22.8281C24.1743 17.0625 25.2656 15.9712 25.2656 14.625V3.25C25.2656 1.90381 24.1743 0.8125 22.8281 0.8125Z"
          fill="#C4DCFC"
        />
        <path
          d="M25.2656 14.625V3.25C25.2656 2.60353 25.0088 1.98355 24.5517 1.52643C24.0946 1.06931 23.4746 0.8125 22.8281 0.8125H10.6406C9.99416 0.8125 9.37417 1.06931 8.91705 1.52643C8.45993 1.98355 8.20312 2.60353 8.20312 3.25V3.85938C8.20312 11.1513 13.5687 17.0625 20.1875 17.0625H22.8281C23.4746 17.0625 24.0946 16.8057 24.5517 16.3486C25.0088 15.8915 25.2656 15.2715 25.2656 14.625Z"
          fill="#DEEAFD"
        />
        <path
          d="M11.4531 3.25V3.85938C11.4531 11.0061 16.6081 16.8227 23.0447 17.0516C23.6511 16.9977 24.2154 16.7187 24.6264 16.2696C25.0374 15.8205 25.2655 15.2338 25.2656 14.625V3.25C25.2656 2.60353 25.0088 1.98355 24.5517 1.52643C24.0946 1.06931 23.4746 0.8125 22.8281 0.8125H13.8906C13.2442 0.8125 12.6242 1.06931 12.1671 1.52643C11.7099 1.98355 11.4531 2.60353 11.4531 3.25Z"
          fill="#F8F7FE"
        />
        <path
          d="M16.7344 11.7812C17.8562 11.7812 18.7656 10.8718 18.7656 9.75C18.7656 8.62817 17.8562 7.71875 16.7344 7.71875C15.6125 7.71875 14.7031 8.62817 14.7031 9.75C14.7031 10.8718 15.6125 11.7812 16.7344 11.7812Z"
          fill="#0F213B"
        />
        <path
          d="M16.7344 7.71877C16.4313 7.7196 16.1323 7.78869 15.8596 7.9209C15.5868 8.05311 15.3474 8.24505 15.1589 8.48246C15.2762 9.19405 15.6135 9.851 16.1235 10.361C16.6334 10.8709 17.2904 11.2082 18.002 11.3255C18.331 11.0638 18.5703 10.7061 18.6867 10.3021C18.803 9.89807 18.7906 9.46786 18.6512 9.07122C18.5118 8.67459 18.2524 8.33122 17.9088 8.08884C17.5653 7.84647 17.1548 7.71712 16.7344 7.71877Z"
          fill="#25364E"
        />
        <path
          d="M16.7344 7.71878C16.6007 7.71907 16.4674 7.73251 16.3364 7.7589C16.3338 7.81328 16.3281 7.86691 16.3281 7.9219C16.3284 8.57369 16.5134 9.21205 16.8615 9.76306C17.2097 10.3141 17.7068 10.7552 18.2953 11.0354C18.5424 10.7398 18.6998 10.3798 18.7491 9.99771C18.7984 9.61564 18.7374 9.22746 18.5733 8.8789C18.4093 8.53034 18.149 8.23594 17.8232 8.0304C17.4974 7.82486 17.1196 7.71674 16.7344 7.71878Z"
          fill="#3C4C60"
        />
        <path
          d="M20.7969 7.3125C21.9187 7.3125 22.8281 6.40308 22.8281 5.28125C22.8281 4.15942 21.9187 3.25 20.7969 3.25C19.675 3.25 18.7656 4.15942 18.7656 5.28125C18.7656 6.40308 19.675 7.3125 20.7969 7.3125Z"
          fill="#0F213B"
        />
        <path
          d="M20.7969 3.25002C20.4938 3.25085 20.1948 3.31994 19.9221 3.45215C19.6493 3.58436 19.4099 3.7763 19.2214 4.01371C19.3387 4.7253 19.676 5.38225 20.186 5.89221C20.6959 6.40216 21.3529 6.73949 22.0645 6.85675C22.3935 6.59502 22.6328 6.23731 22.7492 5.83331C22.8655 5.42932 22.8531 4.99911 22.7137 4.60247C22.5743 4.20584 22.3149 3.86247 21.9713 3.62009C21.6278 3.37772 21.2173 3.24837 20.7969 3.25002Z"
          fill="#25364E"
        />
        <path
          d="M20.7969 3.25003C20.6632 3.25032 20.5299 3.26376 20.3989 3.29015C20.3963 3.34453 20.3906 3.39816 20.3906 3.45315C20.3909 4.10494 20.5759 4.7433 20.924 5.29431C21.2722 5.84532 21.7693 6.28645 22.3578 6.5666C22.6049 6.27105 22.7623 5.91103 22.8116 5.52896C22.8609 5.14689 22.7999 4.75871 22.6358 4.41015C22.4718 4.06159 22.2115 3.76719 21.8857 3.56165C21.5599 3.35611 21.1821 3.24799 20.7969 3.25003Z"
          fill="#3C4C60"
        />
        <path
          d="M18.24 9.12809L6.33285 6.54434C5.01762 6.25894 3.72006 7.09379 3.43467 8.40903L1.02317 19.5224C0.737773 20.8376 1.57262 22.1352 2.88785 22.4206L14.795 25.0043C16.1103 25.2897 17.4078 24.4549 17.6932 23.1397L20.1047 12.0263C20.3901 10.711 19.5553 9.41348 18.24 9.12809Z"
          fill="#C4DCFC"
        />
        <path
          d="M17.7102 23.1388L20.1164 12.0212C20.1841 11.7083 20.1895 11.3852 20.1324 11.0702C20.0752 10.7553 19.9566 10.4547 19.7832 10.1856C19.6099 9.91645 19.3852 9.68412 19.1221 9.50184C18.8589 9.31956 18.5625 9.1909 18.2496 9.12319L6.33791 6.54518C6.02505 6.47745 5.70191 6.472 5.38694 6.52916C5.07198 6.58632 4.77135 6.70495 4.50224 6.8783C4.23313 7.05164 4.00079 7.27629 3.8185 7.53943C3.63621 7.80256 3.50754 8.09903 3.43982 8.4119L3.31109 9.00751C1.76861 16.1344 5.76236 23.0468 12.2314 24.4469L14.8122 25.0055C15.1251 25.0732 15.4482 25.0787 15.7632 25.0215C16.0781 24.9643 16.3788 24.8457 16.6479 24.6724C16.917 24.499 17.1493 24.2744 17.3316 24.0112C17.5138 23.7481 17.6425 23.4516 17.7102 23.1388Z"
          fill="#DEEAFD"
        />
        <path
          d="M6.61641 9.09937L6.48748 9.69499C4.97577 16.6799 8.78371 23.4554 15.0262 25.0406C15.6303 25.1162 16.2408 24.9629 16.7375 24.6109C17.2343 24.259 17.5813 23.7338 17.7102 23.1388L20.1164 12.0212C20.1841 11.7083 20.1895 11.3852 20.1323 11.0702C20.0752 10.7553 19.9565 10.4547 19.7832 10.1856C19.6098 9.91645 19.3852 9.68412 19.122 9.50184C18.8589 9.31956 18.5624 9.1909 18.2496 9.12319L9.51435 7.23265C8.88252 7.09591 8.22224 7.21576 7.67877 7.56584C7.13531 7.91591 6.75317 8.46754 6.61641 9.09937Z"
          fill="#F8F7FE"
        />
        <path
          d="M15.3191 15.0925C16.4409 15.0925 17.3503 14.1831 17.3503 13.0613C17.3503 11.9395 16.4409 11.03 15.3191 11.03C14.1973 11.03 13.2878 11.9395 13.2878 13.0613C13.2878 14.1831 14.1973 15.0925 15.3191 15.0925Z"
          fill="#0F213B"
        />
        <path
          d="M15.7489 11.076C15.4525 11.0127 15.1457 11.017 14.8512 11.0885C14.5567 11.16 14.2821 11.2969 14.0477 11.4891C14.0118 12.2094 14.2026 12.9228 14.5931 13.5291C14.9837 14.1354 15.5544 14.6041 16.2251 14.8692C16.6021 14.683 16.9117 14.384 17.1109 14.0138C17.3101 13.6435 17.389 13.2204 17.3366 12.8032C17.2843 12.386 17.1033 11.9955 16.8188 11.6859C16.5343 11.3764 16.1604 11.1631 15.7491 11.0758L15.7489 11.076Z"
          fill="#25364E"
        />
        <path
          d="M15.7487 11.076C15.618 11.048 15.4848 11.0329 15.3512 11.031C15.3372 11.0837 15.3203 11.1348 15.3087 11.1884C15.1711 11.8255 15.2168 12.4886 15.4405 13.1008C15.6643 13.7129 16.0568 14.2492 16.5727 14.6475C16.8767 14.4109 17.1068 14.0924 17.2357 13.7294C17.3647 13.3664 17.3872 12.9741 17.3006 12.5987C17.214 12.2233 17.0219 11.8805 16.747 11.6107C16.472 11.3409 16.1256 11.1553 15.7487 11.0758V11.076Z"
          fill="#3C4C60"
        />
        <path
          d="M7.38354 13.4001C8.50537 13.4001 9.41479 12.4907 9.41479 11.3689C9.41479 10.2471 8.50537 9.33765 7.38354 9.33765C6.26172 9.33765 5.35229 10.2471 5.35229 11.3689C5.35229 12.4907 6.26172 13.4001 7.38354 13.4001Z"
          fill="#0F213B"
        />
        <path
          d="M7.81311 9.38359C7.51673 9.32032 7.20991 9.3246 6.91541 9.39612C6.62091 9.46763 6.3463 9.60454 6.11194 9.79669C6.07605 10.517 6.26679 11.2304 6.65734 11.8367C7.04789 12.443 7.61861 12.9117 8.28929 13.1768C8.66626 12.9907 8.97587 12.6917 9.17508 12.3214C9.37429 11.9511 9.45321 11.528 9.40087 11.1109C9.34853 10.6937 9.16754 10.3032 8.88303 9.9936C8.59853 9.68403 8.22465 9.47078 7.81337 9.38349L7.81311 9.38359Z"
          fill="#25364E"
        />
        <path
          d="M7.81315 9.38362C7.68244 9.35562 7.54929 9.34055 7.41563 9.33862C7.40161 9.39123 7.38476 9.44242 7.37313 9.49605C7.23557 10.1331 7.28129 10.7962 7.505 11.4083C7.72871 12.0205 8.12125 12.5568 8.63717 12.9551C8.94117 12.7185 9.1712 12.3999 9.30016 12.037C9.42911 11.674 9.45161 11.2817 9.36501 10.9063C9.27841 10.5309 9.08632 10.1882 8.81137 9.91836C8.53642 9.64855 8.19007 9.46296 7.81315 9.38346V9.38362Z"
          fill="#3C4C60"
        />
        <path
          d="M5.81909 20.5308C6.94092 20.5308 7.85034 19.6213 7.85034 18.4995C7.85034 17.3777 6.94092 16.4683 5.81909 16.4683C4.69726 16.4683 3.78784 17.3777 3.78784 18.4995C3.78784 19.6213 4.69726 20.5308 5.81909 20.5308Z"
          fill="#0F213B"
        />
        <path
          d="M6.24866 16.5144C5.95228 16.4512 5.64546 16.4555 5.35097 16.527C5.05647 16.5985 4.78186 16.7354 4.54749 16.9275C4.5116 17.6478 4.70231 18.3612 5.09281 18.9674C5.48331 19.5737 6.05397 20.0423 6.72458 20.3075C7.10156 20.1213 7.41116 19.8223 7.61038 19.4521C7.80959 19.0818 7.88851 18.6587 7.83617 18.2415C7.78383 17.8244 7.60283 17.4338 7.31832 17.1243C7.03382 16.8147 6.65995 16.6015 6.24866 16.5142V16.5144Z"
          fill="#25364E"
        />
        <path
          d="M6.2487 16.5142C6.11799 16.4862 5.98484 16.4712 5.85118 16.4692C5.83717 16.5219 5.82031 16.573 5.80868 16.6267C5.67111 17.2638 5.71683 17.9268 5.94054 18.539C6.16425 19.1512 6.5568 19.6875 7.07272 20.0858C7.37672 19.8492 7.60674 19.5306 7.73569 19.1676C7.86465 18.8046 7.88715 18.4123 7.80055 18.037C7.71395 17.6616 7.52186 17.3188 7.24691 17.049C6.97196 16.7792 6.62562 16.5936 6.2487 16.5141V16.5142Z"
          fill="#3C4C60"
        />
        <path
          d="M13.6826 22.3389C14.8044 22.3389 15.7139 21.4294 15.7139 20.3076C15.7139 19.1858 14.8044 18.2764 13.6826 18.2764C12.5608 18.2764 11.6514 19.1858 11.6514 20.3076C11.6514 21.4294 12.5608 22.3389 13.6826 22.3389Z"
          fill="#0F213B"
        />
        <path
          d="M14.1122 18.3223C13.8158 18.259 13.509 18.2633 13.2145 18.3349C12.92 18.4064 12.6454 18.5433 12.411 18.7354C12.3751 19.4557 12.5659 20.1692 12.9564 20.7755C13.347 21.3818 13.9177 21.8504 14.5884 22.1156C14.9653 21.9294 15.2749 21.6304 15.4741 21.2601C15.6733 20.8899 15.7522 20.4668 15.6998 20.0496C15.6475 19.6324 15.4665 19.2419 15.182 18.9324C14.8975 18.6228 14.5237 18.4096 14.1124 18.3222L14.1122 18.3223Z"
          fill="#25364E"
        />
        <path
          d="M14.1122 18.3223C13.9815 18.2943 13.8484 18.2792 13.7148 18.2773C13.7007 18.33 13.6838 18.3811 13.6722 18.4348C13.5346 19.0719 13.5804 19.7349 13.8041 20.3471C14.0278 20.9593 14.4203 21.4955 14.9362 21.8938C15.2403 21.6572 15.4703 21.3387 15.5993 20.9757C15.7282 20.6127 15.7507 20.2204 15.6641 19.845C15.5775 19.4696 15.3854 19.1268 15.1105 18.857C14.8355 18.5872 14.4892 18.4016 14.1122 18.3221V18.3223Z"
          fill="#3C4C60"
        />
      </g>
      <defs>
        <clipPath id="clip0_512_791">
          <rect width={26} height={26} fill="white" transform="translate(0.078125)" />
        </clipPath>
      </defs>
    </svg>
  );
}
