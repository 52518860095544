export interface Account {
  accessToken: string;
  displayName: string;
  email: string;
  photoURL: string;
  id: string;
  refreshToken: string;
}

export enum AUTH {
  SIGN_IN = 'sign_in',
  SIGN_UP = 'sign_up',
  RESET_PASSWORD = 'reset_password'
}
