/* eslint-disable no-unused-expressions */
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import classNames from 'classnames/bind';
import { Signer } from 'ethers';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import LayoutPopup from '../../components/layouts/LayoutPopup';
import { EXPIRED_TIME } from '../../constant';
import { FIREBASE_ERRORS } from '../../constant/firebase';
import { useInactiveListener } from '../../hooks/useInactiveListener';
import { LOGIN_TYPE } from '../../interfaces';
import { Account, AUTH } from '../../interfaces/Account';
import { injected } from '../../lib/connectors';
import { authService } from '../../services/auth';
import { firebaseService } from '../../services/firebase';
import { storageService } from '../../services/storage';
import { useAppSelector } from '../../stores';
import { setAccountInfo, setAuthModalType, setLoggedIn } from '../../stores/AccountSlice';
import { toggleDropdown } from '../../stores/DropdownSlice';
import styles from '../../styles/components/modal/modal-auth.module.scss';
import ResetPassword from '../ContentPopup/ResetPassword';
import SignIn from '../ContentPopup/SignIn';
import SignUp from '../ContentPopup/SignUp';
import VerificationCode from '../ContentPopup/VerificationCode';
import VerificationComplete from '../ContentPopup/VerificationComplete';
import ModalUI from '../UI/Modal';
import ModalTermsOfUse from './ModalTermsOfUse';
enum ConnectorNames {
  Injected = 'Metamask'
}

const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected
};

const cx = classNames.bind(styles);

interface PropsType {
  isShowing: boolean;
  toggle: () => void;
  type: string;
  handleTypeModalAuth: Function;
}

type RegisterForm = {
  email: string;
  password: string;
  confirmPassword: string;
  registerEmailAddress: string;
};

const ModalAuth = ({ isShowing, toggle, type, handleTypeModalAuth }: PropsType) => {
  const userIdentityClient = useAppSelector((state) => state.app.userIdentityClient);
  const accessToken = useAppSelector((state) => state.account.accountInfo.accessToken);
  const [loginInfo, setLoginInfo] = useState({ email: '', password: '' });
  const checkTypeSignIn = type === AUTH.SIGN_IN;
  const checkTypeSignUp = type === AUTH.SIGN_UP;
  const resetPassword = type === AUTH.RESET_PASSWORD;
  const context = useWeb3React<Web3Provider>();
  const { library, account, activate } = context;
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    clearErrors,
    formState: { errors }
  } = useForm<RegisterForm>();
  useInactiveListener();

  const [selectedLoginType, setSelectedLoginType] = useState<LOGIN_TYPE | undefined>();
  const [openTermOfUse, setOpenTermOfUse] = useState(false);
  const [agreeTermsOfUse, setAgreeTermsOfUse] = useState(true);
  const [signedUpSuccess, setSignedUpSuccess] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [successVerification, setSuccessVerification] = useState(false);
  const [banned, setBanned] = useState(false);
  const onClickButtonAuth = (type: AUTH) => {
    dispatch(setAuthModalType(type));
  };

  useEffect(() => {
    resetModal();
  }, [isShowing]);

  useEffect(() => {
    clearErrors();
  }, [selectedLoginType]);

  useEffect(() => {
    setBanned(false);
  }, [type]);

  useEffect(() => {
    if (isShowing && selectedLoginType === LOGIN_TYPE.METAMASK) signinWithMetamask();
  }, [library, account, isShowing]);

  const onSubmit: SubmitHandler<RegisterForm> = (data) => {
    if (checkTypeSignIn) {
      signIn(data);
    }
    if (checkTypeSignUp) {
      signUp(data);
    }
  };

  const signIn = async (data: any) => {
    try {
      const res = await firebaseService.signIn(data.email, data.password);
      if (res && res.user) {
        saveAccount(res.user);
      }
      dispatch(toggleDropdown({ name: 'globalSetting', state: false }));
    } catch (error: any) {
      handleBannedUser(error);
    }
  };

  const signUp = async (data: any) => {
    try {
      const res = await firebaseService.signUp(data.email, data.password);
      if (res && res.user) {
        setSignedUpSuccess(true);
        const account: Account = {
          displayName: res.user.displayName,
          accessToken: res.user.accessToken,
          id: res.user.uid,
          email: res.user.email,
          photoURL: res.user.photoURL,
          refreshToken: res.user.refreshToken
        };
        dispatch(setAccountInfo(account));
        setLoginInfo({ email: data.email, password: data.password });
      }
    } catch (error: any) {
      toast.error(FIREBASE_ERRORS[error?.code] || error?.message);
    }
  };

  const signinWithMetamask = async () => {
    if (!account) return;
    try {
      const res = await authService.getNonceMetamask(account);
      signMessage(account, res?.data);
      dispatch(toggleDropdown({ name: 'globalSetting', state: false }));
    } catch (error: any) {
      if (error.message === 'User disabled') {
        setBanned(true);
      } else {
        toast.error('Oops! Something went wrong. Please try again!');
      }
    }
  };

  async function signMessage(address: string | null | undefined, nonce: string) {
    if (!address || !library) return;
    try {
      const signer: Signer = library.getSigner(address);
      const signature = await signer.signMessage(`BYBET_LOGIN_CODE:${nonce}`);

      const res = await authService.signWallet(address, signature);
      const { idToken: accessToken, localId: id, refreshToken } = res.data;
      const account: Account = {
        displayName: '',
        photoURL: '',
        email: '',
        accessToken,
        id,
        refreshToken
      };
      storageService.saveAccount(account);
      storageService.saveExpiredTimeAccessToken(accessToken, EXPIRED_TIME);
      dispatch(setAccountInfo(account));
      dispatch(setLoggedIn(true));
      updateUser(id);
    } catch (error: any) {
      console.log(error);
    }
  }

  const signInWithGoogle = async () => {
    try {
      const res = await firebaseService.signInWithGoogle();
      if (res && res.user) {
        saveAccount(res.user);
        dispatch(toggleDropdown({ name: 'globalSetting', state: false }));
      }
    } catch (error: any) {
      if (error?.customData?._tokenResponse?.errorMessage === 'USER_DISABLED') {
        setBanned(true);
      } else {
        toast.error('User or password is not valid');
      }
    }
  };
  const signInWithFacebook = async () => {
    try {
      const res = await firebaseService.signInWithFacebook();
      if (res && res.user) {
        saveAccount(res.user);
        dispatch(toggleDropdown({ name: 'globalSetting', state: false }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleAgreeTermsOfUse = () => {
    return setAgreeTermsOfUse(!agreeTermsOfUse);
  };
  const handleOpenTermOfUse = () => {
    return setOpenTermOfUse(true);
  };
  const handleLoginWith = (name: LOGIN_TYPE) => {
    setSelectedLoginType(name);
    switch (name) {
      case LOGIN_TYPE.GOOGLE:
        signInWithGoogle();
        break;
      case LOGIN_TYPE.METAMASK:
        !account ? activate(connectorsByName['Metamask']) : signinWithMetamask();
        break;
      case LOGIN_TYPE.FACEBOOK:
        signInWithFacebook();
        return;
      default:
        break;
    }
  };
  const saveAccount = (user: any) => {
    const account: Account = {
      displayName: user.displayName,
      accessToken: user.accessToken,
      id: user.uid,
      email: user.email,
      photoURL: user.photoURL,
      refreshToken: user.refreshToken
    };
    storageService.saveAccount(account);
    storageService.saveExpiredTimeAccessToken(user.accessToken, EXPIRED_TIME);
    updateUser(user.uid);
    dispatch(setAccountInfo(account));
    dispatch(setLoggedIn(true));
  };
  const onCompleteInput = (code: any) => {
    if (code) {
      setVerificationCode(code);
    }
  };
  const handleBannedUser = (error: any) => {
    if (error?.customData?._tokenResponse?.error?.message === 'USER_DISABLED') {
      setBanned(true);
    } else {
      toast.error('User or password is not valid');
    }
  };
  const verify = async () => {
    if (!verificationCode) return;
    try {
      const verifcationRes = await authService.confirmVerificationCode(
        verificationCode,
        accessToken
      );
      if (verifcationRes && verifcationRes.status) {
        const res = await firebaseService.signIn(loginInfo.email, loginInfo.password);
        if (res) {
          const account: Account = {
            displayName: res.user.displayName,
            accessToken: res.user.accessToken,
            id: res.user.uid,
            email: res.user.email,
            photoURL: res.user.photoURL,
            refreshToken: res.user.refreshToken
          };
          storageService.saveAccount(account);
          storageService.saveExpiredTimeAccessToken(res.user.accessToken, EXPIRED_TIME);
          dispatch(setAccountInfo(account));
          updateUser(res.user.uid);
          setSuccessVerification(true);
        }
      }
    } catch (error) {
      toast.error('Verification code is not valid');
    }
  };

  const updateUser = async (id: string) => {
    try {
      await userIdentityClient.updateUser(id, { signedInAt: moment().unix() });
    } catch (error) {
      console.log({ error });
    }
  };
  const resetModal = () => {
    setAgreeTermsOfUse(true);
    setSignedUpSuccess(false);
    setVerificationCode('');
    setSuccessVerification(false);
    setSelectedLoginType(undefined);
    reset();
    clearErrors();
    setBanned(false);
  };
  return (
    <>
      <ModalUI hide={toggle} isShowing={isShowing}>
        <LayoutPopup>
          {signedUpSuccess ? (
            <div className={cx('verification')}>
              <VerificationCode
                successVerification={successVerification}
                verificationCode={verificationCode}
                onCompleteInput={onCompleteInput}
                verify={verify}
              />

              <VerificationComplete
                successVerification={successVerification}
                handleTypeModalAuth={handleTypeModalAuth}
                resetModal={resetModal}
              />
            </div>
          ) : (
            <div className={cx('form')}>
              {resetPassword && <ResetPassword hide={toggle} />}
              <form onSubmit={handleSubmit(onSubmit)}>
                {checkTypeSignIn && (
                  <SignIn
                    checkTypeSignIn={checkTypeSignIn}
                    onClickButtonAuth={onClickButtonAuth}
                    handleTypeModalAuth={handleTypeModalAuth}
                    handleLoginWith={handleLoginWith}
                    register={register}
                    errors={errors}
                    banned={banned}
                  />
                )}
                {checkTypeSignUp && (
                  <SignUp
                    register={register}
                    errors={errors}
                    getValues={getValues}
                    checkTypeSignIn={checkTypeSignIn}
                    handleAgreeTermsOfUse={handleAgreeTermsOfUse}
                    agreeTermsOfUse={agreeTermsOfUse}
                    handleOpenTermOfUse={handleOpenTermOfUse}
                    handleTypeModalAuth={handleTypeModalAuth}
                    handleLoginWith={handleLoginWith}
                  />
                )}
              </form>
            </div>
          )}
        </LayoutPopup>
      </ModalUI>

      <ModalTermsOfUse isShowing={openTermOfUse} toggle={() => setOpenTermOfUse(!openTermOfUse)} />
    </>
  );
};

export default ModalAuth;
