import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import LayoutWallet from '../LayoutWallet';
import ListCrypto from './ListCrypto';
import DepositCurrency from './DepositCurrency';

import styles from '../../../styles/components/wallet/deposit.module.scss';
import DepositAddress from './DepositAddress';
import ChooseNetwork from './ChooseNetwork';
import { Asset, AssetBalance } from 'metaverse-js/lib/proto/model/asset';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constant/routes';
import { useAppSelector } from '../../../stores';

const cx = classNames.bind(styles);

enum STATUS_METADATA {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED'
}

const Deposit = () => {
  const { metaverseClient, myBalances, account } = useAppSelector((state) => ({
    metaverseClient: state.app.metaverseClient,
    myBalances: state.myBalances.balances,
    account: state.account.accountInfo
  }));
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramReset = query.get('currency');
  const [assets, setAssets] = useState<Asset[]>([]);
  const [tokenSupport, setTokenSupport] = useState<Asset>();
  const navigate = useNavigate();
  const [networkActive, setNetWorkActive] = useState<string>();
  const [balance, setBalance] = useState<AssetBalance>();
  const [networks, setNetworks] = useState<(string | undefined)[]>([]);
  const [isLoadingAsset, setIsLoadingAsset] = useState<boolean>(false);

  const fetchAssets = async () => {
    setIsLoadingAsset(true);
    try {
      const { data } = await metaverseClient.getAssets({
        chain: 'CHT',
        depositStatus: STATUS_METADATA.ENABLED
      });
      setIsLoadingAsset(false);
      setAssets(data);
      const dataTokenSupport = data.find((infoData) => {
        return infoData.symbol === paramReset;
      });
      if (dataTokenSupport) setTokenSupport(dataTokenSupport);
      else setTokenSupport(data[0]);
    } catch (error) {
      setIsLoadingAsset(false);
      console.log(error);
    }
  };

  const fetchNetworks = async (symbol: string) => {
    try {
      const res = await metaverseClient.getSupportChain(symbol, {
        depositStatus: STATUS_METADATA.ENABLED
      });
      setNetworks(res);
      if (res[0] && res.length > 0) {
        setNetWorkActive(res[0]);
      } else {
        setNetWorkActive(undefined);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowAssetBalance = (assetBalances: AssetBalance[], tokenSupport: Asset) => {
    setBalance(
      assetBalances
        .filter((fil) => fil.assetType === tokenSupport?.type)
        .filter((fil) => fil.assetSymbol === tokenSupport?.symbol)
        .find((find) => find.tokenId === tokenSupport?.tokenId)
    );
  };

  useEffect(() => {
    if (myBalances && tokenSupport) handleShowAssetBalance(myBalances, tokenSupport);
  }, [myBalances, tokenSupport]);

  useEffect(() => {
    if (tokenSupport?.symbol) fetchNetworks(tokenSupport.symbol);
  }, [tokenSupport?.symbol, account.accessToken]);

  useEffect(() => {
    fetchAssets();
  }, [account.accessToken]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <LayoutWallet>
      <div className={cx('content')}>
        <ListCrypto assets={assets} tokenSupport={tokenSupport} setTokenSupport={setTokenSupport} />
        <DepositCurrency
          loading={isLoadingAsset}
          balances={myBalances}
          setTokenSupport={setTokenSupport}
          tokenSupport={tokenSupport}
          assets={assets}
          assetBalance={balance}
          onClickLinkHistory={() => navigate(`${ROUTES.WALLET.TRANSACTIONS}?category=deposit`)}
        />
        {networks.length > 0 && (
          <ChooseNetwork
            networks={networks}
            setNetWorkActive={setNetWorkActive}
            networkActive={networkActive}
          />
        )}

        <DepositAddress
          networks={networks}
          networkActive={networkActive}
          tokenSupport={tokenSupport}
        />
        {/* <Warning /> */}
      </div>
    </LayoutWallet>
  );
};

export default Deposit;
