import classNames from 'classnames/bind';
import { watch } from 'fs';
import React, { useState, useRef, useEffect } from 'react';

import styles from '../../styles/UI/input-code.module.scss';

const cx = classNames.bind(styles);

interface PropsType {
  length: number;
  label?: string;
  loading?: boolean;
  onComplete?: Function;
  inputSize?: number;
}

const InputCode = ({ length, label, loading, onComplete, inputSize }: PropsType) => {
  const [code, setCode] = useState([...Array(length)].map(() => ''));
  const inputs = useRef<HTMLInputElement[]>([]);
  const processInput = (e: any, slot: number) => {
    const num = e.target.value;

    if (/[^0-9]/.test(num)) return;
    const newCode = [...code];
    newCode[slot] = num;

    setCode(newCode);
    if (slot !== length - 1) {
      inputs.current[slot + 1].focus();
    }
    if (newCode.every((num) => num !== '')) {
      onComplete && onComplete(newCode.join(''));
    }
  };
  const onKeyUp = (e: any, slot: number) => {
    if (e.keyCode === 8 && !code[slot] && slot !== 0) {
      const newCode = [...code];
      newCode[slot - 1] = '';
      setCode(newCode);
      inputs.current[slot - 1].focus();
    }
  };

  return (
    <div className={cx('code-input')}>
      <label className={cx('code-label')}>{label}</label>
      <div className={cx('code-inputs')}>
        {code.map((num, idx) => {
          return (
            <input
              key={idx}
              type="text"
              inputMode="numeric"
              maxLength={1}
              value={num}
              // autoFocus={!code[0].length && idx === 0}
              readOnly={loading}
              onChange={(e) => processInput(e, idx)}
              onKeyUp={(e) => onKeyUp(e, idx)}
              ref={(ref) => ref && inputs.current.push(ref)}
              style={{ width: inputSize, height: inputSize }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default InputCode;
