import { ReactNode, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ROUTES } from '../../constant/routes';
import AffiliateIcon from '../Icons/AffiliateIcon';
import BaccaratIcon from '../Icons/BaccaratIcon';
import BlackJackIcon from '../Icons/BlackJackIcon';
import ClassicDiceIcon from '../Icons/ClassicDiceIcon';
import CrashIcon from '../Icons/CrashIcon';
import CrazyTimeIcon from '../Icons/CrazyTimeIcon';
import HomeIcon from '../Icons/HomeIcon';
import LotteryIcon from '../Icons/LotteryIcon';
import MegaBallIcon from '../Icons/MegaBallIcon';
import RingOfFortuneIcon from '../Icons/RingOfFortuneIcon';
import RouteLetteIcon from '../Icons/RouteLetteIcon';
import StakingIcon from '../Icons/StakingIcon';
import Divider from '../UI/Divider';
import styles from '../../styles/Layout/sidebar.module.scss';
import classnames from 'classnames/bind';
import HelpCenterIcon from '../Icons/HelpCenterIcon';
import useBreakpoints from '../../hooks/useBreakpoints';

const cx = classnames.bind(styles);

export default function Sidebar({ handleCloseSideMenu }: { handleCloseSideMenu?: () => void }) {
  const list1 = [
    { icon: <HomeIcon />, name: 'home', title: 'Home', link: ROUTES.HOME },
    { icon: <CrashIcon />, name: 'crash', title: 'Crash', link: ROUTES.CRASH.INDEX },
    {
      icon: <RingOfFortuneIcon />,
      name: 'ring-of-fortune',
      title: 'Ring of Fortune',
      link: ROUTES.RING_OF_FORTUNE.INDEX
    },
    {
      icon: <ClassicDiceIcon />,
      name: 'classic-dice',
      title: 'Classic Dice',
      link: ROUTES.CLASSIC_DICE.INDEX
    }
  ];
  const list2 = [
    { icon: <LotteryIcon />, name: 'lottery', title: 'Lottery', link: ROUTES.LOTTERY },
    { icon: <AffiliateIcon />, name: 'affilate', title: 'Affiliate', link: ROUTES.AFFILIATE },
    { icon: <StakingIcon />, name: 'staking', title: 'Staking', link: ROUTES.STAKING },
    {
      icon: <HelpCenterIcon />,
      name: 'help-center',
      title: 'Help Center',
      link: ROUTES.HELP.GENERAL
    }
  ];
  const list3 = [
    { icon: <BaccaratIcon />, name: '', title: 'Lottery', link: ROUTES.BACCARAT },
    { icon: <RouteLetteIcon />, name: '', title: 'Affiliate', link: ROUTES.ROULETTE },
    { icon: <BlackJackIcon />, name: '', title: 'Blackjack', link: ROUTES.BLACKJACK },
    { icon: <CrazyTimeIcon />, name: '', title: 'Crazy Time', link: ROUTES.CRAZY_TIME },
    { icon: <MegaBallIcon />, name: '', title: 'Mega Ball', link: ROUTES.MEGA_BALL }
  ];

  return (
    <div className={cx('sidebar-root')}>
      <div className={cx('logo-container')}>
        <Link to={ROUTES.HOME}>
          <img src="/images/logo.svg" alt="logo" />
        </Link>
      </div>
      <div>
        {list1.map((item) => (
          <MenuItem key={item.title} {...item} handleCloseSideMenu={handleCloseSideMenu} />
        ))}

        <div className={cx('menu-divider')}>
          <Divider />
        </div>

        {list2.map((item) => (
          <MenuItem key={item.title} {...item} handleCloseSideMenu={handleCloseSideMenu} />
        ))}

        {/* <div className={cx('menu-divider')}>
          <Divider />
        </div> */}

        {/* <div
          className={cx('menu-collapse')}
          onClick={() => {
            setIsCollapse((prevIsCollapse) => !prevIsCollapse);
          }}>
          <p>Live Casino</p>
          <ArrowDownIcon />
        </div> */}

        {/* <Collapse collapsed={isCollapse}>
          {list3.map((item) => (
            <MenuItem key={item.title} {...item} />
          ))}
        </Collapse> */}
      </div>
    </div>
  );
}

interface MenuItemPropsType {
  link: string;
  title: string;
  icon: ReactNode;
  name: string;
  handleCloseSideMenu?: () => void;
}

const MenuItem = ({ link, title, icon, handleCloseSideMenu, name }: MenuItemPropsType) => {
  const { isMobile } = useBreakpoints();
  return (
    <NavLink
      end
      to={link}
      key={title}
      onClick={() => handleCloseSideMenu && handleCloseSideMenu()}
      className={({ isActive }) => cx('nav-link', isActive && 'activated')}>
      <div className={cx('menu-item-container')}>
        {isMobile ? <img src={`/images/icon-menu/${name.toLowerCase()}.svg?v=2`} /> : icon}
        <p className={cx('menu-item-title')}>{title}</p>
      </div>
    </NavLink>
  );
};
