import classnames from 'classnames/bind';
import { useEffect, useState } from 'react';
import styles from '../../../styles/components/affiliate/add-referral-popup/add-referral-popup.module.scss';
import Letter from '../../Icons/Letter';
import { toast } from 'react-toastify';
import { CheckExist, RefInviter } from 'game-profile-js/lib/client/entities/all.entity';
import ModalUI from '../../UI/Modal';
import { RootState, useAppSelector } from '../../../stores';

const cx = classnames.bind(styles);

interface PropsType {
  isModalOpen: boolean;
  toggle: () => void;
}

const AddReferralPopup = ({ isModalOpen, toggle }: PropsType) => {
  const [isReadOnly, setIsReadOnly] = useState(false);
  const { gameProfile, userIdentitySDK } = useAppSelector((state) => ({
    gameProfile: state.app.gameProfileClient,
    userIdentitySDK: state.app.userIdentityClient
  }));
  const [inputReferral, setInputReferral] = useState('');
  const [checkExist, setCheckExist] = useState<CheckExist>();
  const [userIdentifier, setUserIdentifier] = useState('');
  const [refInviter, setRefInviter] = useState<RefInviter>();
  const userIdentityClients = useAppSelector((state: RootState) => state.account.accountInfo.id);

  const getRefCode = async () => {
    try {
      const res = await gameProfile.checkRefCode(inputReferral);
      setCheckExist(res);
    } catch (error) {
      setCheckExist(undefined);
    }
  };

  const getRefInviter = async () => {
    const refInviter = await gameProfile.getRefInviter();
    if (refInviter) {
      setRefInviter(refInviter);
      if (refInviter.inviterId) {
        const refInviterCode = await gameProfile.getRefCode(refInviter.inviterId);
        setInputReferral(refInviterCode);
        setIsReadOnly(true);
      }
    }
  };

  const updateRefCode = async () => {
    try {
      await gameProfile.updateInviter(inputReferral);
      toast.success('Success');
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Error');
      setIsReadOnly(false);
    }
  };

  const getUserProfile = async (userId: string) => {
    try {
      const res = await userIdentitySDK.getUser(userId);
      setUserIdentifier(res.identifier || '');
    } catch (error) {
      setUserIdentifier('');
    }
  };

  useEffect(() => {
    getRefInviter();
  }, []);

  useEffect(() => {
    getRefCode();
  }, [inputReferral]);

  useEffect(() => {
    getUserProfile((inputReferral && checkExist?.userId) || '');
  }, [inputReferral, checkExist, userIdentityClients]);

  const handleClick = () => {
    if (inputReferral.trim().length === 0) {
      toast.error('Referral code cannot empty');
      setIsReadOnly(false);
    } else {
      updateRefCode();
      setIsReadOnly(true);
    }
  };

  return (
    <ModalUI isShowing={isModalOpen} hide={toggle}>
      <div className={cx('content')}>
        <div className={cx('title')}>
          <div className={cx('title-header')}>My Inviter</div>
          <div className={cx('user-identifier')}>
            <Letter />
            User Identifier:<div className={cx('identifier')}> {userIdentifier}</div>
          </div>
        </div>
        <div className={cx('referral-container')}>
          <p>Referral/ Promo Code (Optional)</p>
          <input
            readOnly={isReadOnly}
            defaultValue={inputReferral}
            onChange={(e) => setInputReferral(e.target.value)}></input>
          <p>When you submit “Referral/ Promo Code” you couldn’t change this again</p>
        </div>
        <div className={cx('button-referral')}>
          <button type="submit" disabled={isReadOnly} onClick={handleClick}>
            Submit
          </button>
        </div>
      </div>
    </ModalUI>
  );
};

export default AddReferralPopup;
