export default function BestSeller() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="30" viewBox="0 0 27 30" fill="none">
      <path
        d="M26.7153 26.2407L22.5235 26.485L20.2115 30.0001L15.3242 21.9362C15.4183 21.8799 15.4935 21.8234 15.5874 21.7483C16.3769 21.9738 17.1287 21.9738 17.8242 21.6918C18.5009 21.4099 19.0272 20.8648 19.422 20.1505C20.2491 20.0565 20.9633 19.7745 21.4708 19.2483C21.6964 19.0227 21.8844 18.7784 22.016 18.4775L26.7153 26.2407Z"
        fill="#FFCE33"
      />
      <path
        d="M11.6772 21.936L6.78991 29.9999L4.47793 26.4848L0.286133 26.2405L4.98539 18.4773C5.11697 18.7781 5.305 19.0225 5.53056 19.248C6.03814 19.7743 6.75231 20.0563 7.57938 20.1502C7.97418 20.8645 8.50055 21.4097 9.17719 21.6916C9.87268 21.9736 10.6245 21.9736 11.414 21.748C11.508 21.8233 11.5831 21.8797 11.6772 21.936Z"
        fill="#FFCE33"
      />
      <path
        d="M23.9708 13.3647C24.1964 14.1541 24.1964 14.906 23.9144 15.6015C23.6325 16.2782 23.0873 16.8045 22.373 17.1992C22.3167 17.6692 22.2039 18.1015 22.016 18.4774C21.8844 18.7782 21.6964 19.0226 21.4708 19.2482C20.9632 19.7744 20.2491 20.0564 19.422 20.1504C19.0272 20.8646 18.5008 21.4098 17.8242 21.6917C17.1287 21.9737 16.3769 21.9737 15.5873 21.7482C15.4934 21.8233 15.4182 21.8797 15.3242 21.9361C14.7602 22.3497 14.14 22.5564 13.5008 22.5564C12.8617 22.5564 12.2415 22.3497 11.6776 21.9361C11.5835 21.8797 11.5083 21.8233 11.4144 21.7482C10.6249 21.9737 9.87305 21.9737 9.17756 21.6917C8.50086 21.4098 7.97455 20.8646 7.57975 20.1504C6.75268 20.0564 6.03851 19.7744 5.53093 19.2482C5.30537 19.0226 5.11734 18.7782 4.98576 18.4774C4.79785 18.1016 4.68507 17.6692 4.62873 17.1992C3.91445 16.8045 3.36928 16.2782 3.08738 15.6015C2.80536 14.906 2.80536 14.1541 3.03093 13.3647C2.50467 12.7255 2.22266 12.0114 2.22266 11.2782C2.22266 10.5451 2.50467 9.83079 3.03093 9.19175C2.80536 8.40221 2.80536 7.65039 3.08738 6.9549C3.36928 6.27815 3.91445 5.75189 4.62873 5.3571C4.72272 4.53003 5.00456 3.81574 5.53093 3.30828C6.03851 2.7819 6.75268 2.49989 7.57975 2.40596C7.97455 1.69167 8.50092 1.14662 9.17756 0.864605C9.87305 0.582708 10.6249 0.582708 11.4144 0.808272C12.0534 0.281897 12.7677 0 13.5008 0C14.2339 0 14.9482 0.281897 15.5873 0.808272C16.3768 0.582708 17.1286 0.582708 17.8241 0.864605C18.5008 1.14662 19.0271 1.69167 19.4219 2.40596C20.249 2.49995 20.9632 2.7819 21.4708 3.30828C21.9971 3.81574 22.279 4.53003 22.373 5.3571C23.0872 5.75189 23.6324 6.27815 23.9143 6.9549C24.1963 7.65039 24.1963 8.40221 23.9708 9.19175C24.497 9.83079 24.779 10.5451 24.779 11.2782C24.779 12.0114 24.4971 12.7255 23.9708 13.3647Z"
        fill="#FFFA50"
      />
      <path
        d="M13.5003 18.7971C17.6528 18.7971 21.0191 15.4309 21.0191 11.2783C21.0191 7.12581 17.6528 3.75952 13.5003 3.75952C9.34773 3.75952 5.98145 7.12581 5.98145 11.2783C5.98145 15.4309 9.34773 18.7971 13.5003 18.7971Z"
        fill="#FFCE33"
      />
      <path
        d="M15.3018 11.8636L17.0756 10.1165L14.6136 9.74566L13.5002 7.5188L12.3867 9.74566L9.9248 10.1165L11.6986 11.8636L11.2904 14.3196L13.5002 13.1725L15.71 14.3196L15.3018 11.8636Z"
        fill="#FFFA50"
      />
      <path
        d="M22.0159 18.4774C21.8843 18.7783 21.6963 19.0226 21.4708 19.2482C20.9632 19.7744 20.249 20.0565 19.4219 20.1504C19.0271 20.8647 18.5008 21.4098 17.8241 21.6917C17.1286 21.9738 16.3768 21.9738 15.5873 21.7482C15.4933 21.8233 15.4182 21.8798 15.3241 21.9361L14.9482 21.297L21.452 17.5376L22.0159 18.4774Z"
        fill="#FFA65C"
      />
      <path
        d="M12.053 21.2972L11.6772 21.9363C11.5831 21.88 11.5079 21.8236 11.414 21.7484C10.6245 21.974 9.87264 21.974 9.17715 21.692C8.50046 21.4101 7.97414 20.8649 7.57934 20.1506C6.75227 20.0566 6.0381 19.7747 5.53052 19.2484C5.30496 19.0229 5.11693 18.7785 4.98535 18.4777L5.54938 17.5378L12.053 21.2972Z"
        fill="#FFA65C"
      />
    </svg>
  );
}
