import classNames from 'classnames/bind';
import React from 'react';
import Home from '../components/Home';
import styles from '../styles/pages/home.module.scss';

const cx = classNames.bind(styles);

export default function HomePage() {
  return (
    <div className={cx('wrapper')}>
      <div className={cx('content')}>
        <Home />
      </div>
    </div>
  );
}
