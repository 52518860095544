import styles from '../../../styles/components/wallet/transactions.module.scss';
import classNames from 'classnames/bind';
import _ from 'lodash';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { convertStringNoSpace } from '../../../utils';

const cx = classNames.bind(styles);

const listTab = ['Deposit', 'Withdraw', 'Internal Transfer'];

const CategoryTransaction = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onClickTabCategory = (value: string) => {
    const query = _.pickBy(
      {
        category: value
      },
      _.identity
    );

    navigate({
      search: createSearchParams(query).toString()
    });
  };

  return (
    <div className={cx('filter-transaction')}>
      <ul className={cx('list-tab-category')}>
        {listTab.map((item) => {
          const active = searchParams.get('category') === convertStringNoSpace(item);
          return (
            <li
              onClick={() => onClickTabCategory(convertStringNoSpace(item))}
              className={cx(active && 'tab-active')}
              key={item}>
              {item}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CategoryTransaction;
