import React from 'react';

interface PropsType {
  active?: boolean;
}

const DepositIcon = ({ active }: PropsType) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.1 7H3.9C2.9 7 2 7.9 2 8.9V20.1C2 21.1 2.9 22 3.9 22H20.1C21.2 22 22 21.2 22 20.1V19H17C14.8 19 13 17.2 13 15C13 12.8 14.8 11 17 11H22V8.9C22 7.9 21.1 7 20.1 7Z"
        fill={active ? 'white' : '#A2A3A4'}
      />
      <path
        d="M15 15C15 16.1 15.9 17 17 17H22V13H17C15.9 13 15 13.9 15 15ZM18 14C18.6 14 19 14.4 19 15C19 15.6 18.6 16 18 16C17.4 16 17 15.6 17 15C17 14.4 17.5 14 18 14Z"
        fill={active ? 'white' : '#A2A3A4'}
      />
      <path d="M18 6L14 2L10 6H18Z" fill={active ? 'white' : '#A2A3A4'} />
      <path d="M11.1 3.1L10 2L6 6H8.2L11.1 3.1Z" fill={active ? 'white' : '#A2A3A4'} />
    </svg>
  );
};

export default DepositIcon;
