import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { listNavigatePage } from '../../constant/data-home-page';
import styles from '../../styles/components/home/list-navigate-page.module.scss';

const cx = classNames.bind(styles);

const NavigatePages = () => {
  const navigate = useNavigate();

  const onClickGameItem = (link: string) => {
    navigate(link);
  };

  return (
    <div className={cx('wrapper')}>
      <ul className={cx('list-navigate-page')}>
        {listNavigatePage.map((item) => {
          return (
            <li onClick={() => onClickGameItem(item.link)} className={cx('item')} key={item.key}>
              <div className={cx('background')}>
                <img src={`/images/list-navigate-page/${item.key}.png`} alt={item.key} />
              </div>
              <div className={cx('content')}>
                <div className={cx('content-text')}>
                  <h1>{item.value}</h1>
                  <p>{item.content}</p>
                </div>
                <div className={cx('content-img')}>
                  <img src={`/images/list-navigate-page/${item.icon}.png`} alt={item.icon} />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default NavigatePages;
