import classnames from 'classnames/bind';
import { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import { useNavigate } from 'react-router-dom';
import { GameInformation } from '../../../interfaces/Game';
import styles from '../../../styles/components/games/game-detail.module.scss';
import ArrowDownIcon from '../../Icons/ArrowDownIcon';
import ArrowNextIcon from '../../Icons/ArrowNextIcon';
import ArrowPreviewIcon from '../../Icons/ArrowPreviewIcon';

const cx = classnames.bind(styles);

interface PropsType {
  name: string;
  imageUrl?: string;
  releaseDate?: string;
  gameInfo: GameInformation;
  linkGame: string;
  listSlide?: string[];
}

const responsive = {
  supperDesktop: {
    breakpoint: { max: 5000, min: 1920 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  desktop: {
    breakpoint: { max: 1920, min: 1024 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

export default function GameDetail({
  name,
  imageUrl,
  releaseDate,
  gameInfo,
  linkGame,
  listSlide = []
}: PropsType) {
  const [showMoreGameReview, setShowMoreGameReview] = useState(false);
  const [nextSlideImg, setNextSlideImg] = useState(1000);
  const [currentSlideImg, setCurrentSlideImg] = useState(1000);
  const navigate = useNavigate();

  const handleShowMore = () => {
    setShowMoreGameReview(!showMoreGameReview);
  };

  const linkToGame = () => {
    navigate(linkGame);
  };

  const CustomButtonGroupAsArrowsImgSlide = ({
    next,
    previous,
    currentSlideImg,
    setCurrentSlideImg,
    nextSlideImg
  }: any) => {
    useEffect(() => {
      if (nextSlideImg > currentSlideImg) {
        setCurrentSlideImg(nextSlideImg);
        return next;
      } else if (nextSlideImg < currentSlideImg) {
        setCurrentSlideImg(nextSlideImg);
        return previous;
      }
    }, [currentSlideImg]);

    return <></>;
  };

  return (
    <div className={cx('root')}>
      <div className={cx('back-btn')} onClick={linkToGame}>
        <ArrowPreviewIcon />
        <p>Back</p>
      </div>

      <div className={cx('introduction')}>
        <div className={cx('banner-container')}>
          <div className={cx('banner-info')}>
            <img src={imageUrl} alt={name} />
            <div className={cx('name-date-wrapper')}>
              <div className={cx('name')}>{name}</div>
              <div className={cx('provider')}>
                <span>By</span> {gameInfo.provider}
              </div>
              <div className={cx('release-date')}>Release: {releaseDate}</div>
            </div>
          </div>

          <button className={cx('play-now-btn')} onClick={linkToGame}>
            Play Now
          </button>
        </div>

        <div className={cx('game-review-container')}>
          <p className={cx('title')}>{name} Review</p>
          <div
            className={cx('content', !showMoreGameReview ? 'content-less' : 'content-more')}
            dangerouslySetInnerHTML={{ __html: gameInfo.shortDescription }}
          />
          {showMoreGameReview ? (
            <div className={cx('show-btn', 'show-less')} onClick={handleShowMore}>
              <p>Show less</p>
              <ArrowDownIcon />
            </div>
          ) : (
            <div className={cx('show-btn', 'show-more')} onClick={handleShowMore}>
              <p>Show more</p>
              <ArrowDownIcon />
            </div>
          )}
        </div>

        {listSlide && listSlide.length > 0 && (
          <div className={cx('game-preview-container')}>
            <div className={cx('header')}>
              <p>Game Preview</p>
              <div className={cx('control-carousel')}>
                <div className={cx('btn')} onClick={() => setNextSlideImg(currentSlideImg - 1)}>
                  <ArrowPreviewIcon />
                </div>
                <div className={cx('btn')} onClick={() => setNextSlideImg(currentSlideImg + 1)}>
                  <ArrowNextIcon />
                </div>
              </div>
            </div>
            <div className={cx('image-container')}>
              <Carousel
                responsive={responsive}
                swipeable={true}
                draggable={false}
                showDots={true}
                renderDotsOutside={true}
                arrows={false}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={2000}
                keyBoardControl={true}
                // customTransition="transform 600ms ease-in-out"
                transitionDuration={600}
                removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
                shouldResetAutoplay={false}
                itemClass={cx('slide-item')}
                customDot={
                  <CustomDotGamePlay
                    onMove={undefined}
                    index={undefined}
                    onClick={undefined}
                    active={undefined}
                  />
                }
                customButtonGroup={
                  <CustomButtonGroupAsArrowsImgSlide
                    next={undefined}
                    previous={undefined}
                    currentSlideImg={currentSlideImg}
                    nextSlideImg={nextSlideImg}
                    setCurrentSlideImg={setCurrentSlideImg}
                  />
                }
                renderButtonGroupOutside={true}>
                {listSlide.map((slide, index) => (
                  <div key={index + slide} className={cx('slide-item-container')}>
                    <img src={`/images/games/${slide}.png?v=2`} alt={slide} />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        )}
      </div>

      <div className={cx('game-info-container')}>
        <p className={cx('title')}>Game Info</p>
        <div className={cx('info-content')}>
          <div className={cx('row')}>
            <div className={cx('ceil')}>
              <div className={cx('label')}>RTP (Return to Player)</div>
              <div className={cx('value-number')}>{gameInfo.rtp}%</div>
            </div>
            <div className={cx('ceil')}>
              <div className={cx('label')}>Provider</div>
              <div className={cx('value-text')}>{gameInfo.provider}</div>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('ceil', !gameInfo.maxWin && 'disable')}>
              <div className={cx('label')}>Max Win</div>
              <div className={cx('value-number')}>{gameInfo.maxWin || '--'}</div>
            </div>
            <div className={cx('ceil')}>
              <div className={cx('label')}>Type</div>
              <div className={cx('value-text')}>{gameInfo.type}</div>
            </div>
          </div>
        </div>
      </div>

      <div className={cx('about-this-game-container')}>
        <p className={cx('title')}>About this game</p>
        <div className={cx('content')} dangerouslySetInnerHTML={{ __html: gameInfo.description }} />
      </div>

      <div className={cx('social-network-container')}></div>
    </div>
  );
}

const CustomDotGamePlay = ({ onMove, index, onClick, active }: any) => {
  // onMove means if dragging or swiping in progress.
  // active is provided by this lib for checking if the item is active or not.
  return (
    <li
      className={cx('dot-custom', active ? 'dot-active' : 'dot-inactive')}
      onClick={() => onClick()}></li>
  );
};
