import classNames from 'classnames/bind';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../stores';
import { toggleDropdown } from '../../stores/DropdownSlice';
import styles from '../../styles/UI/dropdown.module.scss';

const cx = classNames.bind(styles);

interface PropsType {
  overlay: React.ReactNode;
  children: React.ReactNode;
  placement?: 'right' | 'left' | 'top' | 'bottom';
  hover?: React.ReactNode;
  styleOverlay?: React.CSSProperties;
  name: string;
}

const Dropdown = ({
  name,
  overlay,
  children,
  placement = 'left',
  hover,
  styleOverlay
}: PropsType) => {
  const dropdown = useRef<any>(null);
  const { dropdownShowing } = useAppSelector((state) => ({
    dropdownShowing: state.dropdown[name]
  }));
  const dispatch = useAppDispatch();
  const handleShowing = (event: any) => {
    if (dropdown.current && !dropdown.current.contains(_.get(event, 'target'))) {
      dispatch(
        toggleDropdown({
          name,
          state: false
        })
      );
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleShowing);
    if (hover) {
      document.addEventListener('mouseover', handleShowing);
    }
    return () => {
      document.removeEventListener('mousedown', handleShowing);
      if (hover) {
        document.removeEventListener('mouseover', handleShowing);
      }
    };
  }, [dropdown]);

  return (
    <div ref={dropdown} className={cx('wrapper')}>
      <div
        onClick={() =>
          dispatch(
            toggleDropdown({
              name,
              state: !dropdownShowing
            })
          )
        }>
        {children}
      </div>
      {dropdownShowing && (
        <div style={styleOverlay} className={cx(`dropdown-${placement}`)}>
          {overlay}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
