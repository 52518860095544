import BigNumber from 'bignumber.js';
import RingGame from 'bybet-game-js/lib/game/ring';
import { CurrencyConfig, GameInformation } from 'bybet-game-js/lib/schema/BaseGame';
import { RingGameBetType, RingGameResult, RingGameState } from 'bybet-game-js/lib/schema/RingGame';
import { TransactionStateType } from 'bybet-game-js/lib/schema/Transaction';
import classnames from 'classnames/bind';
import { AssetBalance } from 'metaverse-js/lib/proto/model/asset';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { MAX_BETTING_TOKEN, RING_TIME_SECONDS } from '../../../constant';
import { BET_DECIMAL_CONFIG, GAME_INTRODUCTION, RING_CONFIG } from '../../../constant/game';
import { ROUTES } from '../../../constant/routes';
import { GameConfigType, VOLUME } from '../../../interfaces';
import { AUTH } from '../../../interfaces/Account';
import { storageService } from '../../../services/storage';
import { RootState, useAppSelector } from '../../../stores';
import { setAuthModalType, setReward } from '../../../stores/AccountSlice';
import { decreaseAssetBalance } from '../../../stores/BalanceSlice';
import { toggleModal } from '../../../stores/ModalSlice';
import styles from '../../../styles/components/games/ring-of-fortune.module.scss';
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';
import { handleBetDataChange, handleDivision } from '../../../utils/game';
import InfoYellowIcon from '../../Icons/InfoYellowIcon';
import MuteVolumeIcon from '../../Icons/MuteVolumeIcon';
import BluePiece from '../../Icons/RingGame/BluePiece';
import GrayPiece from '../../Icons/RingGame/GrayPiece';
import GreenPiece from '../../Icons/RingGame/GreenPiece';
import RedPiece from '../../Icons/RingGame/RedPiece';
import VolumeIcon from '../../Icons/VolumeIcon';
import Amount from '../../shared/Amount';
import CurrencyAmount from '../../UI/CurrencyAmount';
import Tooltip from '../../UI/Tooltip';
import AutoBet from '../AutoBet';
import GameInfo from '../GameInfo';
import InputAmount from '../InputAmount';
import LastestBetAndRace from '../LatestBetAndRace';
import MinMaxSlider from '../MinMaxSlider';
import Ring from './Ring';
import WinPopup from './WinPopup';
const cx = classnames.bind(styles);

enum PLAY_MODE {
  MANUAL = 'Manual',
  AUTO = 'Auto'
}
interface BET_DATA {
  [key: string]: {
    amount: string;
    type: RingGameBetType;
  };
}
const DEFAULT_VALUE = '0.00000000';
const initialBetData: BET_DATA = {
  X2: {
    amount: DEFAULT_VALUE,
    type: RingGameBetType.X2
  },
  X3: {
    amount: DEFAULT_VALUE,
    type: RingGameBetType.X3
  },
  X6: {
    amount: DEFAULT_VALUE,
    type: RingGameBetType.X6
  },
  X99: {
    amount: DEFAULT_VALUE,
    type: RingGameBetType.X99
  }
};
const Icons: any = {
  X2: <GrayPiece />,
  X3: <BluePiece />,
  X6: <RedPiece />,
  X99: <GreenPiece />
};

const ringNumbers = {
  X2: [
    3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35, 37, 39, 41, 43, 45, 47, 49, 51,
    53
  ],
  X3: [4, 6, 8, 14, 16, 18, 24, 26, 28, 30, 32, 38, 40, 42, 48, 50, 52],
  X6: [2, 10, 12, 20, 22, 34, 36, 44],
  X99: [1]
};
const gameAudios = {
  playing: './audios/playing_ring.mp3',
  lose: './audios/lose.wav',
  win: './audios/winning_ring.mp3'
};

const RingOfFortune: React.FC = () => {
  const dispatch = useDispatch();
  const audioRef = useRef<any>();
  const currency = useSelector((state: RootState) => state.account.currency);
  const accessToken = useAppSelector((state: RootState) => state.account.accountInfo.accessToken);
  const bybetGame = useAppSelector((state: RootState) => state.app.bybetGameClient);

  const [ringGameIns, setRingGameIns] = useState<RingGame>();
  const [betData, setBetData] = useState<BET_DATA>(initialBetData);
  const [autoBetData, setAutoBetData] = useState<BET_DATA>(initialBetData);
  const [isReady, setIsReady] = useState<boolean | undefined>(false);
  const [playMode, setPlayMode] = useState(PLAY_MODE.MANUAL);
  const [state, setState] = useState<RingGameState>();
  const [numberOfBets, setNumberOfBets] = useState(0);
  const [tempNumberOfBet, setTempNumberOfBets] = useState(0);
  const [increaseOnWin, setIncreaseOnWin] = useState(0);
  const [increaseOnLose, setIncreaseOnLose] = useState(0);
  const [result, setResult] = useState<RingGameResult>();
  const [resultNumber, setResultNumber] = useState(1);
  const [stopOnWin, setStopOnWin] = useState(DEFAULT_VALUE);
  const [stopOnLose, setStopOnLose] = useState(DEFAULT_VALUE);
  const [isPlayingAuto, setIsPlayingAuto] = useState(false);
  const [totalWinAmount, setTotalWinAmount] = useState(0);
  const [totalLoseAmount, setTotalLoseAmount] = useState(0);
  const [isNotify, setIsNotify] = useState(false);
  const [recentList, setRecentList] = useState<Array<RingGameResult>>([]);
  const [displayPopupWin, setDisplayPopupWin] = useState(false);
  const [gameInformation, setGameInformation] = useState<GameInformation>();
  const [currencyConfig, setCurrencyConfig] = useState<CurrencyConfig>();
  const [isResult, setIsResult] = useState(false);
  const [volume, setVolume] = useState(VOLUME.NORMAL);
  useEffect(() => {
    if (!accessToken) {
      ringGameIns?.disconnect();
      setRecentList([]);
    }
    setRingGameIns(bybetGame.getRingGame());
  }, [accessToken]);

  useEffect(() => {
    const currencyConfig = gameInformation?.currencyConfig?.find(
      (currencyConfig) => currencyConfig.symbol === currency.symbol
    );
    if (currencyConfig) {
      setCurrencyConfig(currencyConfig);
    }
  }, [currency, gameInformation]);
  useEffect(() => {
    if (!ringGameIns) return;
    ringGameIns.connect();
    ringGameIns.onGameResult((result) => {
      console.log('Ring game result: ' + JSON.stringify(result));
      const { resultType } = result;
      audioRef.current.src = gameAudios.playing;
      audioRef.current.play();
      const resultNumber =
        //@ts-ignore
        ringNumbers[resultType][Math.floor(Math.random() * ringNumbers[resultType].length)];
      setResult(result);
      setResultNumber(resultNumber);
      setTimeout(() => {
        setIsResult(true);
      }, RING_TIME_SECONDS * 1000);
    });
    ringGameIns.onTransactionState((txnState) => {
      console.log('Ring game transaction state: ' + JSON.stringify(txnState));
      if (txnState.state === TransactionStateType.CREATED) {
        const assetBalance = {
          assetSymbol: txnState.assetSymbol,
          availableAssetAmount: txnState.assetAmount,
          totalAssetAmount: txnState.assetAmount
        } as AssetBalance;
        dispatch(decreaseAssetBalance(assetBalance));
      }
    });

    ringGameIns.onError((code, error) => {
      toast.error(capitalizeFirstLetter(error || 'error'));
      console.log('Ring game error: ' + error + ' code: ' + code);
    });

    ringGameIns.onStateChange((state) => {
      console.log('Ring game state: ' + JSON.stringify(state));
      console.log('ringGameIns.isReady()', ringGameIns.isReady());
      setIsReady(ringGameIns.isReady());
      setState(state);
    });
    getGameInformation(ringGameIns);
    getRecentList(ringGameIns);
  }, [ringGameIns]);

  useEffect(() => {
    if (state && ringGameIns && isPlayingAuto && !isNotify) {
      const player = Object.keys(state.players)[0];
      //@ts-ignore
      if (!state.players[player].isPlaying) {
        playAuto();
      }
    }
  }, [JSON.stringify(state), isPlayingAuto, isNotify]);

  useEffect(() => {
    if (result) {
      setIsNotify(true);
      setTimeout(() => {
        recentList.unshift(result);
        if (recentList.length > 20) {
          recentList.pop();
        }
        setRecentList(recentList);
        if (result.isWin) {
          dispatch(setReward(result.rewardAmount));
          handleAnimation();
          audioRef.current.src = gameAudios.win;
        } else {
          audioRef.current.src = gameAudios.lose;
        }
        audioRef.current.play();
        if (playMode === PLAY_MODE.AUTO) {
          if (result.isWin) {
            if (increaseOnWin > 0) {
              increaseBetData(increaseOnWin);
            }
          } else {
            if (increaseOnLose > 0) {
              increaseBetData(increaseOnLose);
            }
          }
        }
        setIsNotify(false);
      }, RING_TIME_SECONDS * 1000);
    }
  }, [JSON.stringify(result)]);

  useEffect(() => {
    if (
      isPlayingAuto &&
      ((new BigNumber(stopOnWin).gt(0) && new BigNumber(totalWinAmount).gte(stopOnWin)) ||
        (new BigNumber(stopOnLose).gt(0) && new BigNumber(totalLoseAmount).gte(stopOnLose)) ||
        (numberOfBets > 0 && tempNumberOfBet === 0))
    ) {
      setIsPlayingAuto(false);
    }
  }, [
    isPlayingAuto,
    stopOnWin,
    stopOnLose,
    totalWinAmount,
    numberOfBets,
    totalLoseAmount,
    tempNumberOfBet
  ]);

  const playAuto = async () => {
    if (!ringGameIns || !ringGameIns.isReady()) return;
    ringGameIns.sendBet({
      symbol: currency.symbol,
      options: [autoBetData.X2, autoBetData.X3, autoBetData.X6, autoBetData.X99]
    });
    setIsResult(false);
    if (numberOfBets > 0) {
      setTempNumberOfBets(tempNumberOfBet - 1);
    }
  };

  useEffect(() => {
    setTempNumberOfBets(numberOfBets);
  }, [numberOfBets]);

  useEffect(() => {
    setAutoBetData(betData);
  }, [betData]);
  useEffect(() => {
    const ringConfig = storageService.getGameConfig(RING_CONFIG);
    if (ringConfig[GameConfigType.SOUND_DISABLE]) {
      audioRef.current.volume = 0;
      setVolume(VOLUME.MUTE);
    }
  }, []);

  const handleVolume = () => {
    const newVolume = volume === VOLUME.MUTE ? VOLUME.NORMAL : VOLUME.MUTE;
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
    storageService.saveGameConfig(RING_CONFIG, GameConfigType.SOUND_DISABLE, !Boolean(newVolume));
  };

  const calculateLoseAmount = () => {
    const loseAmount = new BigNumber(getTotalAmount(betData)).toNumber();
    setTotalLoseAmount(new BigNumber(totalLoseAmount).plus(loseAmount).toNumber());
    return loseAmount;
  };

  const increaseBetData = (increasePercent: number) => {
    setAutoBetData({
      ...betData,
      X2: {
        ...betData.X2,
        amount: new BigNumber(betData.X2.amount)
          .multipliedBy(100 + increasePercent)
          .dividedBy(100)
          .toString()
      },
      X3: {
        ...betData.X3,
        amount: new BigNumber(betData.X3.amount)
          .multipliedBy(100 + increasePercent)
          .dividedBy(100)
          .toString()
      },
      X6: {
        ...betData.X6,
        amount: new BigNumber(betData.X6.amount)
          .multipliedBy(100 + increasePercent)
          .dividedBy(100)
          .toString()
      },
      X99: {
        ...betData.X99,
        amount: new BigNumber(betData.X99.amount)
          .multipliedBy(100 + increasePercent)
          .dividedBy(100)
          .toString()
      }
    });
  };
  const bet = () => {
    if (!accessToken) {
      dispatch(setAuthModalType(AUTH.SIGN_IN));
      dispatch(toggleModal({ modalName: 'authModal', state: true }));
      return;
    }
    if (!getTotalAmount(betData)) {
      toast.error('Amount is not valid');
      return;
    }
    if (playMode === PLAY_MODE.AUTO) {
      setIsPlayingAuto(!isPlayingAuto);
      return;
    }
    if (!ringGameIns || !ringGameIns.isReady()) return;
    setIsResult(false);
    ringGameIns.sendBet({
      symbol: currency.symbol,
      //amount: '100
      options: [betData.X2, betData.X3, betData.X6, betData.X99]
    });
  };
  const reset = () => {
    setBetData(initialBetData);
  };
  const getRecentList = async (ringGameIns: RingGame) => {
    if (!ringGameIns) return;
    const res = await ringGameIns.getMyBets({ limit: 20 });
    setRecentList(res);
  };
  const getGameInformation = async (ringGameIns: RingGame) => {
    if (!ringGameIns) return;
    const res = await ringGameIns.getGameInformation();
    if (res) {
      setGameInformation(res);
    }
  };
  const handleAnimation = async () => {
    setDisplayPopupWin(true);
    await new Promise((resolve) => setTimeout(resolve, RING_TIME_SECONDS * 1000));
    setDisplayPopupWin(false);
  };

  const handleBetAmount = (type: any, amount: string) => {
    if (!amount) return;
    setBetData({
      ...betData,
      [type]: {
        ...betData[type],
        amount
      }
    });
  };
  const getTotalAmount = (betData: BET_DATA) => {
    const amount = Object.keys(betData).reduce((prev, acc) => {
      return new BigNumber(prev).plus(betData[acc].amount).toNumber();
    }, 0);
    return amount;
  };
  const getRemainAmount = (betData: BET_DATA, type: any) => {
    const amount = Object.keys(betData).reduce((prev, acc) => {
      return new BigNumber(prev).plus(acc == type ? 0 : betData[acc].amount).toNumber();
    }, 0);
    return new BigNumber(currencyConfig?.maxBetAmount || MAX_BETTING_TOKEN)
      .minus(amount)
      .toFixed(BET_DECIMAL_CONFIG);
  };

  return (
    <div className={cx('container')}>
      <div className={cx('game')}>
        <div className={cx('setting')}>
          <div className={cx('row', !isReady && 'disable-select-tab', 'tabs')}>
            <div
              className={cx('common-button', 'tab', playMode === PLAY_MODE.MANUAL && 'active')}
              onClick={() => {
                setPlayMode(PLAY_MODE.MANUAL);
                setIsPlayingAuto(false);
              }}>
              {PLAY_MODE.MANUAL}
            </div>
            <div
              className={cx(
                'common-button',
                'tab',
                playMode === PLAY_MODE.AUTO && 'active',
                'disable'
              )}
              // onClick={() => setPlayMode(PLAY_MODE.AUTO)}
            >
              {PLAY_MODE.AUTO}
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('amount-wrapper')}>
              <div className={cx('amount')}>
                <img
                  src={`/images/tokens/${(currency.symbol || 'FBET').toLowerCase()}.svg?v=2`}
                  alt={currency.symbol}
                />{' '}
                Amount{' '}
              </div>
              <div className={cx('amount')}>
                <Tooltip
                  title={
                    <>
                      <span style={{ marginRight: '5px' }}>Max profit:</span>
                      <CurrencyAmount amount={currencyConfig?.maxProfit || '0'} />
                    </>
                  }>
                  <InfoYellowIcon />
                </Tooltip>
                0
              </div>
            </div>
            <div className={cx('reset-btn')} onClick={() => reset()}>
              Reset
            </div>
          </div>
          {Object.keys(RingGameBetType).map((type) => (
            <InputAmount
              nameDropdown={`ringOfFotune-${type}`}
              key={type}
              value={betData[type].amount}
              icon={Icons[type]}
              minMaxSlider={
                <MinMaxSlider
                  betAmount={betData[type].amount}
                  minAmount={currencyConfig?.minBetAmount}
                  maxAmount={getRemainAmount(betData, type)}
                  setBetAmount={(amount: string) => handleBetAmount(type, amount)}
                />
              }
              onDataChange={(amount: string) => handleBetAmount(type, amount)}
              onBlur={(amount) =>
                handleBetAmount(type, handleBetDataChange(amount, currencyConfig))
              }
              handleDivision={() =>
                handleBetAmount(type, handleDivision(betData[type].amount, 2, currencyConfig))
              }
              handleMultiplication={() =>
                handleBetAmount(
                  type,
                  new BigNumber(betData[type].amount).multipliedBy(2).toFixed(BET_DECIMAL_CONFIG)
                )
              }
            />
          ))}
          {playMode === PLAY_MODE.AUTO && (
            <AutoBet
              numberOfBets={numberOfBets}
              setNumberOfBets={setNumberOfBets}
              increaseOnWin={increaseOnWin}
              setIncreaseOnWin={setIncreaseOnWin}
              increaseOnLose={increaseOnLose}
              setIncreaseOnLose={setIncreaseOnLose}
              stopOnLose={stopOnLose}
              setStopOnLose={setStopOnLose}
              stopOnWin={stopOnWin}
              setStopOnWin={setStopOnWin}
            />
          )}
          <div
            className={cx(
              'bet-btn',
              ((accessToken && !isReady && !isPlayingAuto) ||
                (playMode === PLAY_MODE.MANUAL && isNotify) ||
                getTotalAmount(betData) === 0) &&
                'disable'
            )}
            onClick={() => bet()}>
            {playMode === PLAY_MODE.AUTO
              ? isPlayingAuto
                ? 'Stop Auto Bet'
                : 'Start Auto Bet'
              : 'Bet'}
          </div>
        </div>
        <div className={cx('ring')}>
          <div className={cx('row', 'info')}>
            <div className={cx('row')}>
              <GrayPiece width={30} height={30} /> 2.00x
            </div>
            <div className={cx('row')}>
              <BluePiece width={30} height={30} /> 3.00x
            </div>
            <div className={cx('row')}>
              <RedPiece width={30} height={30} /> 6.00x
            </div>
            <div className={cx('row')}>
              <GreenPiece width={30} height={30} /> 99.00x
            </div>
          </div>
          <div className={cx('volume')} onClick={() => handleVolume()}>
            {volume === VOLUME.NORMAL ? <VolumeIcon /> : <MuteVolumeIcon />}
          </div>
          <div className={cx('ring-wrapper')}>
            <Ring spinNumber={resultNumber} ringGameResult={result} />
            <div className={cx('total')}>
              <div className={cx('second-ring')}>
                <div className={cx('bet-total-label')}>BET TOTAL</div>
                <div className={cx('total-number')}>
                  <CurrencyAmount
                    fontSize={'18px'}
                    color={'#0c9e4f'}
                    amount={getTotalAmount(
                      playMode === PLAY_MODE.MANUAL ? betData : autoBetData
                    ).toString()}
                    decimalPlaces={BET_DECIMAL_CONFIG}
                  />

                  <img
                    src={`/images/tokens/${(currency.symbol || 'FBET').toLowerCase()}.svg?v=2`}
                    alt={currency.symbol}
                  />
                </div>
                {displayPopupWin && result && (
                  <WinPopup
                    profit={new BigNumber(result.rewardAmount)
                      .dividedBy(getTotalAmount(betData))
                      .toFixed(2)}
                    reward={result?.rewardAmount}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={cx('recent-list')}>
            {recentList.map((result: RingGameResult) => (
              <div key={result.betId} className={cx('result', result.resultType)} />
            ))}
          </div>
        </div>
      </div>
      <GameInfo
        name="Ring Of Fortune"
        imageUrl="/images/games/ring-of-fortune.png"
        releaseDate="--"
        gameInfo={GAME_INTRODUCTION.RING_OF_FORTUNE}
        linkDetail={ROUTES.RING_OF_FORTUNE.DETAIL}
      />
      <LastestBetAndRace isResult={isResult} gameIns={ringGameIns} />
      <audio ref={audioRef} src={gameAudios.playing} />
    </div>
  );
};

export default RingOfFortune;
