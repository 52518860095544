import styles from '../../styles/UI/table-game.module.scss';
import classnames from 'classnames/bind';
import { Columns } from '../../interfaces/Table';
import React from 'react';

const cx = classnames.bind(styles);

interface PropsType {
  columns: Columns[];
  dataTable: any[];
  footer?: React.ReactNode;
  footerOutSide?: React.ReactNode;
}

export default function TableGame({ columns, dataTable, footer, footerOutSide }: PropsType) {
  return (
    <div>
      <div className={cx('table-root')}>
        <table className={cx('table-container')}>
          <thead>
            <tr>
              {columns.map((col) => (
                <th
                  key={col.key}
                  className={cx('table-header-item')}
                  style={{ textAlign: col.align || 'center' }}>
                  {col.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataTable.length > 0 ? (
              dataTable.map((data: any, index) => {
                return (
                  <tr key={index}>
                    {columns.map((col) => (
                      <td key={col.key + index} className={cx('data-item')}>
                        {col.render ? col.render(data[col.key]) : data[col.key]}
                      </td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <tr className={cx('nodata')}>
                <td colSpan={columns.length} className={cx('nodata-item')}>
                  No Data
                </td>
              </tr>
            )}

            {footer && (
              <tr className={cx('nodata')}>
                <td colSpan={columns.length} className={cx('nodata-item')}>
                  {footer}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {footerOutSide && footerOutSide}
    </div>
  );
}
