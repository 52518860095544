import { BYBET_ACCOUNT, CURRENCY_LOCAL } from '../constant';
import { GameConfigType, CurrencyLocal, LOGIN_TYPE } from '../interfaces';
import { Account } from '../interfaces/Account';
import cookie from 'cookie';
import { CLASSIC_DICE_CONFIG, RING_CONFIG } from '../constant/game';

export const storageService = {
  saveAccount: (data: Account) => {
    localStorage.setItem(BYBET_ACCOUNT, JSON.stringify(data));
  },
  getAccount: () => {
    const data = localStorage.getItem(BYBET_ACCOUNT);
    return data ? JSON.parse(data) : undefined;
  },
  removeAccount: () => {
    localStorage.removeItem(BYBET_ACCOUNT);
  },
  saveConnector: (connector: LOGIN_TYPE) => {
    let accountInfo: any = {};
    const data = localStorage.getItem(BYBET_ACCOUNT);
    if (data) {
      accountInfo = JSON.parse(data);
    }
    accountInfo['connector'] = connector;
    localStorage.setItem(BYBET_ACCOUNT, accountInfo);
  },
  saveExpiredTimeAccessToken: (accessToken: string, expiredTime: number) => {
    window.document.cookie = cookie.serialize('token', accessToken, {
      maxAge: expiredTime,
      path: '/'
    });
  },
  getCurrency: () => {
    if (typeof window !== 'undefined') {
      const getItem = localStorage.getItem(CURRENCY_LOCAL);
      return getItem !== null && JSON.parse(getItem);
    }
  },
  setCurrency: (currency: CurrencyLocal) => {
    localStorage.setItem(CURRENCY_LOCAL, JSON.stringify(currency));
  },
  removeCurrency: () => {
    localStorage.removeItem(CURRENCY_LOCAL);
  },
  saveGameConfig: (configName: string, key: GameConfigType, value: any) => {
    const oldConfig = localStorage.getItem(configName);
    const parsedConfig = (oldConfig && JSON.parse(oldConfig)) || {};
    parsedConfig[key] = value;
    localStorage.setItem(configName, JSON.stringify(parsedConfig));
  },
  getGameConfig: (configName: string) => {
    const config = localStorage.getItem(configName);
    const parsedConfig = (config && JSON.parse(config)) || {};
    return parsedConfig;
  },
  removeGameConfig: (configName: string) => {
    localStorage.removeItem(configName);
  }
};
