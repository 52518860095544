import classNames from 'classnames/bind';
import styles from '../../styles/UI/switch.module.scss';

const cx = classNames.bind(styles);

interface SwitchProps {
  value: boolean;
  setValue: (value: boolean) => void;
}
const Switch = ({ value, setValue }: SwitchProps) => {
  return (
    <div>
      <label className={cx('switch')}>
        <input type="checkbox" defaultChecked={value} onClick={() => setValue(!value)} />
        <span className={cx('slider', 'round')} />
      </label>
    </div>
  );
};

export default Switch;
