import React from 'react';

const ClockIcon = () => {
  return (
    <svg width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0001 2.49268C5.40841 2.49268 1.66675 6.23434 1.66675 10.826C1.66675 15.4177 5.40841 19.1593 10.0001 19.1593C14.5917 19.1593 18.3334 15.4177 18.3334 10.826C18.3334 6.23434 14.5917 2.49268 10.0001 2.49268ZM13.6251 13.801C13.5084 14.001 13.3001 14.1093 13.0834 14.1093C12.9751 14.1093 12.8667 14.0843 12.7667 14.0177L10.1834 12.476C9.54175 12.0927 9.06675 11.251 9.06675 10.5093V7.09268C9.06675 6.75101 9.35008 6.46768 9.69175 6.46768C10.0334 6.46768 10.3167 6.75101 10.3167 7.09268V10.5093C10.3167 10.8093 10.5667 11.251 10.8251 11.401L13.4084 12.9427C13.7084 13.1177 13.8084 13.501 13.6251 13.801Z"
        fill="#A2A3A4"
      />
    </svg>
  );
};

export default ClockIcon;
