import React from 'react';

const UndoIcon = () => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.94189 15.2584H12.6086C14.9086 15.2584 16.7752 13.3917 16.7752 11.0917C16.7752 8.79172 14.9086 6.92505 12.6086 6.92505H3.44189"
        stroke="#A2A3A4"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.35843 9.00837L3.2251 6.87503L5.35843 4.7417"
        stroke="#A2A3A4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UndoIcon;
