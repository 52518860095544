import React from 'react';

const CloseChat = () => {
  return (
    <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.21084 11.7598C5.92907 11.4395 5.92907 10.9202 6.21084 10.5999L10.2576 6L6.21084 1.40007C5.92907 1.07979 5.92907 0.5605 6.21084 0.240214C6.49261 -0.0800724 6.94945 -0.0800724 7.23122 0.240214L11.7882 5.42007C11.9235 5.57388 11.9995 5.78248 11.9995 6C11.9995 6.21752 11.9235 6.42612 11.7882 6.57993L7.23122 11.7598C6.94945 12.0801 6.49261 12.0801 6.21084 11.7598Z"
        fill="#535353"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.809554 9.99976C0.480822 9.67947 0.480822 9.16018 0.809554 8.8399L3.96724 5.99996L0.809049 3.15973C0.480317 2.83944 0.480317 2.32016 0.809049 1.99987C1.13778 1.67958 1.67076 1.67958 1.99949 1.99987L5.75291 5.42003C5.91077 5.57383 5.99946 5.78244 5.99946 5.99996C5.99946 6.21747 5.91077 6.42608 5.75291 6.57989L2 9.99976C1.67127 10.32 1.13829 10.32 0.809554 9.99976Z"
        fill="#535353"
      />
    </svg>
  );
};

export default CloseChat;
