import BigNumber from 'bignumber.js';
import DiceGame from 'bybet-game-js/lib/game/dice';
import { CurrencyConfig, GameInformation } from 'bybet-game-js/lib/schema/BaseGame';
import { DiceGameBetType, DiceGameResult } from 'bybet-game-js/lib/schema/DiceGame';
import { RingGameResult, RingGameState } from 'bybet-game-js/lib/schema/RingGame';
import { TransactionStateType } from 'bybet-game-js/lib/schema/Transaction';
import classnames from 'classnames/bind';
import { AssetBalance } from 'metaverse-js/lib/proto/model/asset';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { DICE_TIME_SECONDS } from '../../../constant';
import {
  BET_DECIMAL_CONFIG,
  CLASSIC_DICE_CONFIG,
  DEFAULT_VALUE,
  GAME_INTRODUCTION
} from '../../../constant/game';
import { ROUTES } from '../../../constant/routes';
import useModal from '../../../hooks/useModal';
import { GameConfigType, VOLUME } from '../../../interfaces';
import { AUTH } from '../../../interfaces/Account';
import { storageService } from '../../../services/storage';
import { RootState, useAppSelector } from '../../../stores';
import { setAuthModalType, setDisplayCashAnimation, setReward } from '../../../stores/AccountSlice';
import { decreaseAssetBalance } from '../../../stores/BalanceSlice';
import { toggleModal } from '../../../stores/ModalSlice';
import styles from '../../../styles/components/games/classic-dice.module.scss';
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';
import { handleBetDataChange, handleDivision } from '../../../utils/game';
import InfoYellowIcon from '../../Icons/InfoYellowIcon';
import MuteVolumeIcon from '../../Icons/MuteVolumeIcon';
import VolumeIcon from '../../Icons/VolumeIcon';
import CurrencyAmount from '../../UI/CurrencyAmount';
import Tooltip from '../../UI/Tooltip';
import AutoBet from '../AutoBet';
import GameInfo from '../GameInfo';
import InputAmount from '../InputAmount';
import LastestBetAndRace from '../LatestBetAndRace';
import MaximumProfitWarningModal from '../MaximumProfitWarningModal';
import MinMaxSlider from '../MinMaxSlider';
import Dice from './Dice';
import SettingIcon from './SettingIcon';
const cx = classnames.bind(styles);

enum PLAY_MODE {
  MANUAL = 'Manual',
  AUTO = 'Auto'
}
export enum BET_MODE {
  UNDER,
  OVER
}

const MIN_BET_VALUE = 0.01;
const MAX_BET_VALUE = 98;
const MAX_ROLL_OVER = 99.99;
const MIN_ROLL_OVER = 2;
const gameAudios = {
  playing: './audios/playing_dice.wav',
  lose: './audios/lose.wav',
  win: './audios/winning_dice.mp3'
};
const ClassicDice: React.FC = () => {
  const dispatch = useDispatch();
  const audioRef = useRef<any>();
  const audioPlayRef = useRef<any>();
  const { isShowing, toggle } = useModal();
  const currency = useSelector((state: RootState) => state.account.currency);
  const accessToken = useAppSelector((state: RootState) => state.account.accountInfo.accessToken);
  const bybetGame = useAppSelector((state: RootState) => state.app.bybetGameClient);

  const [classicDiceIns, setClassicDiceIns] = useState<DiceGame>();
  const [betAmount, setBetAmount] = useState<string>(DEFAULT_VALUE);
  const [autoBetData, setAutoBetData] = useState<string>();
  const [isReady, setIsReady] = useState<boolean | undefined>(false);
  const [playMode, setPlayMode] = useState(PLAY_MODE.MANUAL);
  const [state, setState] = useState<RingGameState>();
  const [numberOfBets, setNumberOfBets] = useState(0);
  const [tempNumberOfBet, setTempNumberOfBets] = useState(0);
  const [increaseOnWin, setIncreaseOnWin] = useState(0);
  const [increaseOnLose, setIncreaseOnLose] = useState(0);
  const [result, setResult] = useState<RingGameResult>();
  const [resultNumber, setResultNumber] = useState({ prev: 50, current: 50 });
  const [stopOnWin, setStopOnWin] = useState(DEFAULT_VALUE);
  const [stopOnLose, setStopOnLose] = useState(DEFAULT_VALUE);
  const [isPlayingAuto, setIsPlayingAuto] = useState(false);
  const [totalWinAmount, setTotalWinAmount] = useState(0);
  const [totalLoseAmount, setTotalLoseAmount] = useState(0);
  const [isNotify, setIsNotify] = useState(false);
  const [rollNumber, setRollNumber] = useState<string | number>('50');
  const [betMode, setBetMode] = useState<DiceGameBetType>(DiceGameBetType.ROLL_UNDER);
  const [recentList, setRecentList] = useState<Array<DiceGameResult>>([]);
  const [winChance, setWinChance] = useState('0.00');
  const [gameInformation, setGameInformation] = useState<GameInformation>();
  const [currencyConfig, setCurrencyConfig] = useState<CurrencyConfig>();
  const [payout, setPayout] = useState<number | string>();
  const [isResult, setIsResult] = useState(false);
  const [volume, setVolume] = useState(VOLUME.NORMAL);

  const [ignoreMaxProfit, setIgnoreMaxProfit] = useState(false);

  useEffect(() => {
    if (!accessToken) {
      classicDiceIns?.disconnect();
      setRecentList([]);
    }
    setClassicDiceIns(bybetGame.getDiceGame());
  }, [accessToken]);

  useEffect(() => {
    const currencyConfig = gameInformation?.currencyConfig?.find(
      (currencyConfig) => currencyConfig.symbol === currency.symbol
    );
    if (currencyConfig) {
      setCurrencyConfig(currencyConfig);
    }
  }, [currency, gameInformation]);

  useEffect(() => {
    if (!classicDiceIns) return;
    classicDiceIns.connect();
    classicDiceIns.onGameResult((result) => {
      audioPlayRef.current.play();
      console.log('Ring game result: ' + JSON.stringify(result));
      const { resultNumber: resultNum } = result;
      setResult(result);

      setTimeout(() => {
        setIsResult(true);
      }, DICE_TIME_SECONDS * 1000);

      if (resultNum) {
        setResultNumber({ prev: resultNumber.current, current: resultNum });
      }
    });
    classicDiceIns.onTransactionState((txnState) => {
      console.log('Dice game transaction state: ' + JSON.stringify(txnState));
      if (txnState.state === TransactionStateType.CREATED) {
        const assetBalance = {
          assetSymbol: txnState.assetSymbol,
          availableAssetAmount: txnState.assetAmount,
          totalAssetAmount: txnState.assetAmount
        } as AssetBalance;
        dispatch(decreaseAssetBalance(assetBalance));
      }
    });

    classicDiceIns.onError((code, message) => {
      toast.error(capitalizeFirstLetter(message || 'error'));
    });

    classicDiceIns.onStateChange((state) => {
      console.log('Ring game state: ' + JSON.stringify(state));
      console.log('classicDiceIns.isReady()', classicDiceIns.isReady());
      setIsReady(classicDiceIns.isReady());
      setState(state);
    });

    setPayout(classicDiceIns.getPayoutRate(Number(rollNumber), betMode));
    getGameInformation(classicDiceIns);
    getRecentList(classicDiceIns);
  }, [classicDiceIns]);

  const getRecentList = async (classicDiceIns: DiceGame) => {
    if (!classicDiceIns) return;
    const res = await classicDiceIns.getMyBets({ limit: 7 });
    setRecentList(res.reverse());
  };
  const getGameInformation = async (classicDiceIns: DiceGame) => {
    if (!classicDiceIns) return;
    const res = await classicDiceIns.getGameInformation();
    if (res) {
      setGameInformation(res);
    }
  };
  useEffect(() => {
    if (state && classicDiceIns && isPlayingAuto && !isNotify) {
      const player = Object.keys(state.players)[0];
      //@ts-ignore
      if (!state.players[player].isPlaying) {
        playAuto();
      }
    }
  }, [JSON.stringify(state), isPlayingAuto, isNotify]);
  useEffect(() => {
    if (result) {
      setIsNotify(true);
      setTimeout(() => {
        if (result.isWin) {
          audioRef.current.src = gameAudios.win;
          dispatch(setReward(result.rewardAmount));
          handleAnimation();
        } else {
          audioRef.current.src = gameAudios.lose;
        }
        audioRef.current.play();
        setRecentList([...recentList, result]);
        if (playMode === PLAY_MODE.AUTO) {
          if (result.isWin) {
            if (increaseOnWin > 0) {
              increaseBetData(increaseOnWin);
            }
          } else {
            if (increaseOnLose > 0) {
              increaseBetData(increaseOnLose);
            }
          }
        }
        setIsNotify(false);
      }, DICE_TIME_SECONDS * 1000);
    }
  }, [JSON.stringify(result)]);

  useEffect(() => {
    if (
      isPlayingAuto &&
      ((new BigNumber(stopOnWin).gt(0) && new BigNumber(totalWinAmount).gte(stopOnWin)) ||
        (new BigNumber(stopOnLose).gt(0) && new BigNumber(totalLoseAmount).gte(stopOnLose)) ||
        (numberOfBets > 0 && tempNumberOfBet === 0))
    ) {
      setIsPlayingAuto(false);
    }
  }, [
    isPlayingAuto,
    stopOnWin,
    stopOnLose,
    totalWinAmount,
    numberOfBets,
    totalLoseAmount,
    tempNumberOfBet
  ]);

  const playAuto = async () => {
    if (!classicDiceIns || !classicDiceIns.isReady()) return;
    classicDiceIns.sendBet({
      symbol: currency.symbol
    });
    setIsResult(false);
    if (numberOfBets > 0) {
      setTempNumberOfBets(tempNumberOfBet - 1);
    }
  };

  useEffect(() => {
    setTempNumberOfBets(numberOfBets);
  }, [numberOfBets]);

  useEffect(() => {
    setAutoBetData(betAmount);
  }, [betAmount]);

  useEffect(() => {
    const diceConfig = storageService.getGameConfig(CLASSIC_DICE_CONFIG);
    if (diceConfig[GameConfigType.SOUND_DISABLE]) {
      audioRef.current.volume = 0;
      audioPlayRef.current.volume = 0;
      setVolume(VOLUME.MUTE);
    }
  }, []);

  const handleVolume = () => {
    const newVolume = volume === VOLUME.MUTE ? VOLUME.NORMAL : VOLUME.MUTE;
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
    audioPlayRef.current.volume = newVolume;

    storageService.saveGameConfig(
      CLASSIC_DICE_CONFIG,
      GameConfigType.SOUND_DISABLE,
      !Boolean(newVolume)
    );
  };
  useEffect(() => {
    setWinChance(
      (betMode === DiceGameBetType.ROLL_UNDER
        ? Number(rollNumber)
        : 100 - Number(rollNumber)
      ).toFixed(2)
    );
    setPayout(classicDiceIns?.getPayoutRate(Number(rollNumber), betMode));
  }, [betMode, rollNumber]);

  const increaseBetData = (increasePercent: number) => {};
  const bet = () => {
    if (!accessToken) {
      dispatch(setAuthModalType(AUTH.SIGN_IN));
      dispatch(toggleModal({ modalName: 'authModal', state: true }));
      return;
    }
    if (!betAmount) {
      toast.error('Amount is not valid');
      return;
    }
    if (playMode === PLAY_MODE.AUTO) {
      setIsPlayingAuto(!isPlayingAuto);
      return;
    }
    if (!classicDiceIns || !classicDiceIns.isReady()) return;
    classicDiceIns.sendBet({
      symbol: currency.symbol,
      options: [
        {
          amount: betAmount,
          type: betMode,
          value: rollNumber.toString()
        }
      ]
    });

    setIsResult(false);
  };

  const handleWinChance = () => {
    const winChanceNumber = Number(winChance);
    let validatedWinChance = winChance;
    if (winChanceNumber < MIN_BET_VALUE) {
      validatedWinChance = MIN_BET_VALUE.toFixed(2);
    } else if (winChanceNumber > MAX_BET_VALUE) {
      validatedWinChance = MAX_BET_VALUE.toFixed(2);
    }
    setWinChance(validatedWinChance);
    setRollNumber(
      betMode === DiceGameBetType.ROLL_UNDER
        ? Number(validatedWinChance)
        : 100 - Number(validatedWinChance)
    );
  };
  const handleAnimation = async () => {
    dispatch(setDisplayCashAnimation(true));
    await new Promise((resolve) => setTimeout(resolve, DICE_TIME_SECONDS * 1000));
    dispatch(setDisplayCashAnimation(false));
  };

  const hanldePayout = () => {
    if (classicDiceIns) {
      const minPayout = classicDiceIns.getPayoutRate(MAX_BET_VALUE, DiceGameBetType.ROLL_UNDER);
      if (Number(payout) < minPayout) {
        setPayout(minPayout);
      } else {
        const rollNumber = classicDiceIns.getRollNumber(Number(payout), betMode);
        setRollNumber(rollNumber);
      }
    }
  };
  const handleRollNumber = (value: number) => {
    let newRollNumber = Number(rollNumber) + value;
    if (betMode === DiceGameBetType.ROLL_UNDER) {
      if (newRollNumber > MAX_BET_VALUE) {
        newRollNumber = MAX_BET_VALUE;
      }
      if (newRollNumber < MIN_BET_VALUE) {
        newRollNumber = MIN_BET_VALUE;
      }
    } else {
      if (newRollNumber < MIN_ROLL_OVER) {
        newRollNumber = MIN_ROLL_OVER;
      }
      if (newRollNumber > MAX_ROLL_OVER) {
        newRollNumber = MAX_ROLL_OVER;
      }
    }
    setRollNumber(newRollNumber.toFixed(2));
  };
  const handleBet = () => {
    if (
      new BigNumber(betAmount)
        .multipliedBy(classicDiceIns?.getPayoutRate(Number(rollNumber), betMode) || 0)
        .minus(betAmount)
        .gt(currencyConfig?.maxProfit || 0)
    ) {
      const classicDiceConfig = storageService.getGameConfig(CLASSIC_DICE_CONFIG);
      if (classicDiceConfig[GameConfigType.IGNORE_MAX_CONFIG]) {
        bet();
      } else {
        toggle();
      }
      return;
    } else {
      bet();
    }
  };
  const confirmMaxProfitWarning = () => {
    if (ignoreMaxProfit) {
      storageService.saveGameConfig(
        CLASSIC_DICE_CONFIG,
        GameConfigType.IGNORE_MAX_CONFIG,
        ignoreMaxProfit
      );
    }
    toggle();
    bet();
  };

  useEffect(() => {}, []);
  return (
    <>
      <div className={cx('container')}>
        <div className={cx('game')}>
          <div className={cx('setting')}>
            {/* phase 2 */}
            {/* <div className={cx('bank-roll')}>
            <div className={cx('treasure-wrapper')}>
              <img src="/images/games/treasure-chest.png" />
            </div>
            <div>
              <div className={cx('bankroll-text')}>Bankroll</div>
              <div className={cx('bankroll-number')}>
                100070495648
                <img src={`/images/tokens/${currency.symbol}.svg?v=2`} alt={currency.symbol} />
              </div>
            </div>
          </div>
          <DividerIcon /> */}
            <div className={cx('row', !isReady && 'disable-select-tab', 'tabs')}>
              <div
                className={cx('common-button', 'tab', playMode === PLAY_MODE.MANUAL && 'active')}
                onClick={() => {
                  setPlayMode(PLAY_MODE.MANUAL);
                  setIsPlayingAuto(false);
                }}>
                {PLAY_MODE.MANUAL}
              </div>
              <div
                className={cx(
                  'common-button',
                  'tab',
                  playMode === PLAY_MODE.AUTO && 'active',
                  'disable'
                )}
                // onClick={() => setPlayMode(PLAY_MODE.AUTO)}
              >
                {PLAY_MODE.AUTO}
              </div>
            </div>
            <div className={cx('row')}>
              <div className={cx('amount')}>
                Amount{' '}
                <Tooltip
                  title={
                    <>
                      <span style={{ marginRight: '5px' }}>Max profit:</span>
                      <CurrencyAmount amount={currencyConfig?.maxProfit || '0'} />
                    </>
                  }>
                  <InfoYellowIcon />
                </Tooltip>
              </div>
              <div className={cx('amount')}>0</div>
            </div>

            <InputAmount
              nameDropdown="classicDice"
              icon={
                <img
                  className={cx('currency')}
                  src={`/images/tokens/${(currency.symbol || 'FBET').toLowerCase()}.svg?v=2`}
                  alt={currency.symbol}
                />
              }
              value={betAmount}
              minMaxSlider={
                <MinMaxSlider
                  betAmount={betAmount}
                  setBetAmount={setBetAmount}
                  minAmount={currencyConfig?.minBetAmount}
                  maxAmount={currencyConfig?.maxBetAmount}
                />
              }
              onDataChange={(amount) => setBetAmount(amount)}
              onBlur={(amount) => setBetAmount(handleBetDataChange(amount, currencyConfig))}
              handleDivision={() => setBetAmount(handleDivision(betAmount, 2, currencyConfig))}
              handleMultiplication={() =>
                setBetAmount(new BigNumber(betAmount).multipliedBy(2).toFixed(BET_DECIMAL_CONFIG))
              }
            />
            <div className={cx('row')}>
              <div className={cx('amount')}>Win amount</div>
              <div className={cx('amount')}>0</div>
            </div>

            <div className={cx('input-amount', 'win-amount')}>
              <img
                src={`/images/tokens/${(currency.symbol || 'FBET').toLowerCase()}.svg?v=2`}
                alt={currency.symbol}
              />
              <input
                readOnly
                value={new BigNumber(betAmount)
                  .multipliedBy(classicDiceIns?.getPayoutRate(Number(rollNumber), betMode) || 0)
                  .toFixed(BET_DECIMAL_CONFIG)}
              />
            </div>
            {playMode === PLAY_MODE.AUTO && (
              <AutoBet
                numberOfBets={numberOfBets}
                setNumberOfBets={setNumberOfBets}
                increaseOnWin={increaseOnWin}
                setIncreaseOnWin={setIncreaseOnWin}
                increaseOnLose={increaseOnLose}
                setIncreaseOnLose={setIncreaseOnLose}
                stopOnLose={stopOnLose}
                setStopOnLose={setStopOnLose}
                stopOnWin={stopOnWin}
                setStopOnWin={setStopOnWin}
              />
            )}
            <div
              className={cx(
                'bet-btn',
                ((accessToken && !isReady && !isPlayingAuto) ||
                  (playMode === PLAY_MODE.MANUAL && isNotify) ||
                  new BigNumber(betAmount).eq(0)) &&
                  'disable'
              )}
              onClick={() => handleBet()}>
              <img className={cx('roll-icon')} src="/images/games/roll-icon.png" />
              {playMode === PLAY_MODE.AUTO
                ? isPlayingAuto
                  ? 'Stop Auto Bet'
                  : 'Start Auto Bet'
                : 'Roll now'}
            </div>
          </div>
          <div className={cx('dice-bg')}>
            <div className={cx('dice')}>
              <div className={cx('dice-header')}>
                {recentList.length ? (
                  <div className={cx('row', 'recent-list')}>
                    {recentList
                      .slice(recentList.length - 6, recentList.length)
                      .map((result: DiceGameResult) => (
                        <div
                          className={cx('recent-result', result.isWin ? 'win' : 'lose')}
                          key={result.resultHash}>
                          {result.resultNumber?.toFixed(2)}
                        </div>
                      ))}
                  </div>
                ) : (
                  <div className={cx('game-result')}> Game results will be displayed here</div>
                )}
                <div className={cx('volume')} onClick={() => handleVolume()}>
                  {volume === VOLUME.NORMAL ? <VolumeIcon /> : <MuteVolumeIcon />}
                </div>
              </div>
              <Dice
                resultNumber={resultNumber}
                result={result}
                min={MIN_ROLL_OVER}
                max={MAX_BET_VALUE}
                value={Number(rollNumber)}
                onChange={setRollNumber}
                mode={betMode}
              />

              <div className={cx('row', 'payout-wrapper')}>
                <div className={cx('payout-block')}>
                  <div>Payout</div>
                  <div className={cx('input-amount', 'payout')}>
                    <input
                      value={payout}
                      type="number"
                      onChange={(event) => setPayout(event.target.value)}
                      onBlur={() => hanldePayout()}
                    />
                    <div className={cx('percent')}>x</div>
                  </div>
                </div>
                <div className={cx('payout-block')}>
                  <div>Roll {betMode === DiceGameBetType.ROLL_OVER ? 'Over' : 'Under'}</div>
                  <div
                    className={cx('input-amount', 'payout', 'roll-over')}
                    onClick={() => {
                      setBetMode(
                        betMode === DiceGameBetType.ROLL_UNDER
                          ? DiceGameBetType.ROLL_OVER
                          : DiceGameBetType.ROLL_UNDER
                      );
                      setRollNumber(100 - Number(rollNumber));
                    }}>
                    <input readOnly value={Number(rollNumber).toFixed(2)} />
                    <SettingIcon />
                  </div>
                </div>
                <div className={cx('payout-block')}>
                  <div>Win Chance</div>
                  <div className={cx('input-amount')}>
                    <input
                      type="number"
                      step="0.01"
                      value={winChance}
                      onChange={(event) => setWinChance(event.target.value)}
                      onBlur={() => handleWinChance()}
                    />
                    <div className={cx('percent')}>%</div>
                    <div className={cx('row', 'amount-wrapper')}>
                      <div
                        className={cx('amount')}
                        onClick={() => {
                          setWinChance(MIN_BET_VALUE + '');
                          const rollNumber =
                            betMode === DiceGameBetType.ROLL_UNDER
                              ? MIN_BET_VALUE
                              : 100 - MIN_BET_VALUE;
                          setRollNumber(rollNumber);
                        }}>
                        Min
                      </div>
                      <div
                        className={cx('amount')}
                        onClick={() =>
                          handleRollNumber(betMode === DiceGameBetType.ROLL_UNDER ? -5 : +5)
                        }>
                        -5
                      </div>
                      <div
                        className={cx('amount')}
                        onClick={() =>
                          handleRollNumber(betMode === DiceGameBetType.ROLL_UNDER ? +5 : -5)
                        }>
                        +5
                      </div>

                      <div
                        className={cx('amount')}
                        onClick={() => {
                          setWinChance(MAX_BET_VALUE + '');
                          const rollNumber =
                            betMode === DiceGameBetType.ROLL_UNDER
                              ? MAX_BET_VALUE
                              : 100 - MAX_BET_VALUE;
                          setRollNumber(rollNumber);
                        }}>
                        Max
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GameInfo
          name="Classic Dice"
          imageUrl="/images/games/classic-dice.png"
          releaseDate="--"
          gameInfo={GAME_INTRODUCTION.CLASSIC_DICE}
          linkDetail={ROUTES.CLASSIC_DICE.DETAIL}
        />
        <LastestBetAndRace isResult={isResult} gameIns={classicDiceIns} />
      </div>
      <MaximumProfitWarningModal
        isShowing={isShowing}
        toggle={toggle}
        onConfirm={confirmMaxProfitWarning}
        ignoreMaxProfit={ignoreMaxProfit}
        setIgnoreMaxProfit={setIgnoreMaxProfit}
      />
      <audio ref={audioPlayRef} src={gameAudios.playing} />
      <audio ref={audioRef} src={gameAudios.win} />
    </>
  );
};

export default ClassicDice;
