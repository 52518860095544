import moment from 'moment';
import { useEffect, useState } from 'react';
import { ColumnType } from '../../../types/tablet';
import { convertToCurrency } from '../../../utils/convertCurrency';
import LayoutWallet from '../LayoutWallet';
import CategoryTransaction from './CategoryTransaction';
import TabletTransaction from './TabletTransaction';
import styles from '../../../styles/components/wallet/transactions.module.scss';
import classNames from 'classnames/bind';
import { Transaction } from 'metaverse-js/lib/proto/model/wallet';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { PAGE_SIZE, STATE_TRANSACTION_CONVERT, TIME_DATE_FORMAT } from '../../../constant';
import Pagination from '../../UI/Pagination';
import useQuery from '../../../hooks/useQuery';
import _, { find } from 'lodash';
import { TransactionQueryRequest } from 'metaverse-js/lib/proto/service/transaction';
import { shortenAddress } from '../../../lib/shortenAddress';
import { getEnv } from '../../../utils';
import { NETWORK } from '../../../interfaces';
import { useAppDispatch, useAppSelector } from '../../../stores';
import { NETWORK_CONVERT_NAME } from '../../../constant/network';
import { copyText } from '../../../utils/copyText';
import CurrencyAmount from '../../UI/CurrencyAmount';
import { getAssets } from '../../../stores/AssetsSlice';

const cx = classNames.bind(styles);

const pageSize = PAGE_SIZE;
interface FilterType {
  category: string;
  fromDate?: string;
  toDate?: string;
  page?: number;
}

const Transactions = () => {
  const [total, setTotal] = useState<number>(0);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [dataTransactions, setDataTransactions] = useState<any[]>([]);
  const { userInfo } = useAppSelector((state) => ({
    userInfo: state.userInfo.userInfo
  }));

  const navigate = useNavigate();

  const query = useQuery() as FilterType;

  const { assets, metaverseClient } = useAppSelector((state) => ({
    metaverseClient: state.app.metaverseClient,
    assets: state.assets.assets
  }));
  const dispatch = useAppDispatch();

  const queryRequest = () => {
    const page = Number(query.page);
    const transactionQuery: TransactionQueryRequest = {
      limit: pageSize,
      offset: (page - 1) * pageSize || undefined,
      fromDate: Number(query.fromDate) || undefined,
      toDate: Number(query.toDate) || undefined,
      orders: ['updatedAt desc', 'createdAt desc'],
      tags: [query.category.toLocaleUpperCase()] || undefined
    };
    return transactionQuery;
  };

  const fetchTransactions = async (transactionQuery: TransactionQueryRequest) => {
    try {
      const res = await metaverseClient.getMyTransactions(transactionQuery);
      setTransactions(res.data);
      setTotal(res.meta.total);
    } catch (error) {
      console.log(error);
    }
  };

  const changePage = (page: number) => {
    const queryParams = _.pickBy(
      {
        ...query,
        page: `${page}`
      },
      _.identity
    );

    navigate({
      search: createSearchParams(queryParams).toString()
    });
  };

  const onClickHash = (hash: string, network: string) => {
    if (hash.trim()) {
      switch (network) {
        case NETWORK.BSC:
          {
            const url = `${getEnv('REACT_APP_BSC_SCAN_URL')}tx/${hash}`;
            window.open(url, '_blank');
          }
          break;

        case NETWORK.ETH:
          {
            const url = `${getEnv('REACT_APP_ETH_SCAN_URL')}/tx/${hash}`;
            window.open(url, '_blank');
          }
          break;

        case NETWORK.TRON:
          {
            const url = `${getEnv('REACT_APP_TRON_SCAN_URL')}/#/transaction/${hash}`;
            window.open(url, '_blank');
          }
          break;

        default:
          break;
      }
    }
  };

  const updateData = (transactions: Transaction[]) => {
    const dataTransaction = transactions.map((transaction) => {
      if (query.category !== 'internal_transfer') {
        return {
          key: transaction.id,
          name: transaction.assetSymbol,
          time: transaction.updatedAt
            ? moment.unix(transaction.updatedAt).format(TIME_DATE_FORMAT)
            : '',
          transaction: transaction.onchainHash,
          amount: transaction.assetAmount,
          state: transaction.state,
          isDeposit: transaction.fromChain !== 'CHT',
          assetType: transaction.assetType,
          fromChain: transaction.fromChain,
          network: transaction.fromChain !== 'CHT' ? transaction.fromChain : transaction.toChain,
          toChain: transaction.toChain
        };
      }
      return {
        key: transaction.id,
        name: transaction.assetSymbol,
        time: transaction.updatedAt
          ? moment.unix(transaction.updatedAt).format(TIME_DATE_FORMAT)
          : '',
        transaction: transaction.onchainHash,
        amount: transaction.assetAmount,
        state: transaction.state,
        isFromUser: transaction.fromUser !== userInfo?.id,
        assetType: transaction.assetType,
        fromChain: transaction.fromChain,
        toChain: transaction.toChain,
        fromToAccount:
          transaction.fromUser !== userInfo?.identifier ? transaction.fromUser : transaction.toUser
      };
    });
    setDataTransactions(dataTransaction);
  };

  const columns: ColumnType[] = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (name) => (
        <div className={cx('name-crypto')}>
          <div className={cx('crypto')}>
            <img src={`/images/tokens/${`${name}`.toLowerCase()}.svg?v=2`} alt="" />
          </div>
          <div>{name}</div>
        </div>
      )
    },
    {
      title: 'Time',
      key: 'time',
      dataIndex: 'time',
      render: (time) => <div className={cx('time')}>{time}</div>
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      render: (amount, record: any) => (
        <span className={cx(record.isDeposit ? 'amount-deposit' : 'amount-withdraw')}>
          {record.isDeposit ? '+' : '-'}
          <CurrencyAmount
            amount={amount || '0'}
            color={record.isDeposit ? '#0c9e4f;' : '#f82814'}
            decimalPlaces={find(assets, { symbol: record.name })?.decimalPlaces}
          />
        </span>
      )
    },
    {
      title: 'Network',
      key: 'network',
      dataIndex: 'network',
      render: (network) => (network ? NETWORK_CONVERT_NAME[network] : network)
    },
    {
      title: 'Status',
      key: 'state',
      dataIndex: 'state',
      render: (state) => (
        <button className={cx('button-state', `button-state-${String(state)?.toLowerCase()}`)}>
          {state && STATE_TRANSACTION_CONVERT[state]}
        </button>
      )
    },
    {
      title: 'Transaction',
      key: 'transaction',
      dataIndex: 'transaction',
      render: (hash, record: any) => (
        <div
          onClick={() =>
            onClickHash(String(hash), record.isDeposit ? record.fromChain : record.toChain)
          }
          style={{ color: query.category === 'bill' ? '#ffe036' : '#2295FF' }}
          className={cx('column-transaction')}>
          {hash ? shortenAddress(String(hash)) : ''}
        </div>
      )
    }
  ];

  const columnInteralTransacion: ColumnType[] = [
    {
      title: 'Currency',
      key: 'name',
      dataIndex: 'name',
      render: (name) => (
        <div className={cx('name-crypto')}>
          <div className={cx('crypto')}>
            <img src={`/images/tokens/${`${name}`.toLowerCase()}.svg?v=2`} alt="" />
          </div>
          <div>{name}</div>
        </div>
      )
    },
    {
      title: 'Timeline',
      key: 'time',
      dataIndex: 'time',
      render: (time) => <div className={cx('time')}>{time}</div>
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      render: (amount, record: any) => (
        <span className={cx(record.isFromUser ? 'amount-deposit' : 'amount-withdraw')}>
          {record.isFromUser ? '+' : '-'}
          <CurrencyAmount
            amount={amount || '0'}
            color={record.isFromUser ? '#0c9e4f;' : '#f82814'}
            decimalPlaces={find(assets, { symbol: record.name })?.decimalPlaces}
          />
        </span>
      )
    },
    {
      title: 'Status',
      key: 'state',
      dataIndex: 'state',
      render: (state) => (
        <button className={cx('button-state', `button-state-${String(state)?.toLowerCase()}`)}>
          {state && STATE_TRANSACTION_CONVERT[state]}
        </button>
      )
    },

    {
      title: 'From/To Account',
      key: 'fromToAccount',
      dataIndex: 'fromToAccount',
      render: (hash) => (
        <div
          style={{ color: query.category === 'bill' ? '#ffe036' : '#2295FF', cursor: 'pointer' }}
          onClick={() => copyText(String(hash))}>
          {hash ? shortenAddress(String(hash)) : ''}
        </div>
      )
    }
  ];

  useEffect(() => {
    const transactionQuery = queryRequest();
    fetchTransactions(transactionQuery);
  }, [query.category, query.fromDate, query.toDate, query.page]);

  useEffect(() => {
    updateData(transactions);
  }, [transactions]);

  useEffect(() => {
    dispatch(getAssets({ metaverseClient, assetQuery: {} }));
  }, [metaverseClient]);

  return (
    <LayoutWallet>
      <CategoryTransaction />
      <TabletTransaction
        columns={query.category === 'internal_transfer' ? columnInteralTransacion : columns}
        dataSource={dataTransactions}
      />
      {dataTransactions.length > 0 && (
        <Pagination
          changePage={changePage}
          pagination={{ page: Number(query.page) || 1, pageSize, total }}
        />
      )}
    </LayoutWallet>
  );
};

export default Transactions;
