import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserIdentityClient } from 'user-identity-js';
import { UserEntity } from 'user-identity-js/lib/entities/user.entity';

interface UserType {
  userInfo: UserEntity;
  isUserLoading: boolean;
  isUserError: boolean;
}

const initialState: UserType = {
  userInfo: {},
  isUserLoading: false,
  isUserError: false
};

export const getUserInfo = createAsyncThunk(
  'GET_USER_INFO',
  async (query: { userIdentityClient: UserIdentityClient; id: string }) => {
    const { userIdentityClient, id } = query;
    const res = await userIdentityClient.getUser(id);
    return res;
  }
);

export const userInfo = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<UserEntity>) => {
      state.userInfo = action.payload;
    }
  },
  extraReducers: (build) => {
    build.addCase(getUserInfo.pending, (state) => {
      state.isUserLoading = true;
      state.userInfo = {};
      state.isUserError = false;
    });
    build.addCase(getUserInfo.fulfilled, (state, action) => {
      state.isUserLoading = false;
      state.userInfo = action.payload;
      state.isUserError = false;
    });
    build.addCase(getUserInfo.rejected, (state) => {
      state.isUserLoading = false;
      state.userInfo = {};
      state.isUserError = true;
    });
  }
});

export const { setUserInfo } = userInfo.actions;

export const userInfoReducer = userInfo.reducer;
