import React from 'react';

export default function HiddenIcon() {
  return (
    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1352_32535)">
        <path
          d="M7.52854 5.29784C8.9325 5.29784 10.3061 4.99091 11.3963 4.43347C11.4045 4.42935 11.419 4.42237 11.4394 4.41275C11.68 4.30026 11.9329 4.15686 12.1913 3.98818C11.9626 2.17154 11.4541 1.0855 10.6442 0.677061C9.65314 0.177299 8.5616 0.853186 7.9094 1.25705C7.78409 1.33464 7.6209 1.43569 7.52854 1.48021C7.43631 1.43569 7.273 1.33452 7.1478 1.25705C6.4956 0.853186 5.40407 0.177184 4.41301 0.677061C3.61215 1.08092 3.10621 2.14751 2.87378 3.92718C3.08858 4.08476 3.31758 4.23846 3.49977 4.34832C4.61774 4.96058 6.04825 5.29784 7.52854 5.29784Z"
          fill="#A2A3A4"
        />
        <path
          d="M2.73223 8.91202C4.02049 9.42449 5.72383 9.7067 7.52844 9.7067C9.33317 9.7067 11.0365 9.42449 12.3248 8.91202C13.7411 8.34851 14.5212 7.54869 14.5212 6.66005C14.5212 6.16991 14.2793 5.44755 13.1265 4.78963C13.0272 4.73299 12.9242 4.67931 12.8161 4.62782C12.4725 4.86185 12.1337 5.05868 11.8121 5.20917C11.8046 5.21272 11.7988 5.21547 11.7945 5.21741C10.5655 5.84535 9.09044 6.17723 7.52844 6.17723C5.89949 6.17723 4.31586 5.80003 3.06926 5.1151C3.04328 5.1008 2.67867 4.87684 2.30319 4.59863C2.17204 4.65894 2.04867 4.72211 1.93046 4.78963C0.777694 5.44767 0.535767 6.16991 0.535767 6.66005C0.535767 7.5488 1.31591 8.34851 2.73223 8.91202Z"
          fill="#A2A3A4"
        />
        <path
          d="M14.0816 11.7517H13.5578C13.1496 10.7709 12.1813 10.0796 11.0545 10.0796C9.92156 10.0796 8.94904 10.7784 8.54472 11.7675C7.88715 11.4302 7.09796 11.4315 6.44061 11.7753C6.03824 10.782 5.06355 10.0796 3.92761 10.0796C2.80082 10.0796 1.83254 10.7709 1.42433 11.7517H0.908432C0.665588 11.7517 0.46875 11.9485 0.46875 12.1914C0.46875 12.4342 0.665588 12.631 0.908432 12.631H1.22223C1.21914 12.6836 1.21742 12.7364 1.21742 12.7898C1.21742 14.2841 2.43324 15.5 3.92761 15.5C5.42209 15.5 6.6378 14.2841 6.6378 12.7898C6.6378 12.7568 6.63699 12.7241 6.63585 12.6915C7.13699 12.2993 7.84309 12.2965 8.34685 12.6774C8.34525 12.7147 8.34434 12.7521 8.34434 12.7898C8.34434 14.2841 9.56004 15.5 11.0545 15.5C12.5489 15.5 13.7646 14.2841 13.7646 12.7898C13.7646 12.7364 13.7629 12.6836 13.7599 12.631H14.0816C14.3244 12.631 14.5213 12.4342 14.5213 12.1914C14.5213 11.9485 14.3244 11.7517 14.0816 11.7517Z"
          fill="#A2A3A4"
        />
      </g>
      <defs>
        <clipPath id="clip0_1352_32535">
          <rect width={15} height={15} fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
