/* eslint-disable no-unused-expressions */
import { Asset, AssetBalance } from 'metaverse-js/lib/proto/model/asset';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ROUTES } from '../../../constant/routes';
import ChooseNetwork from '../Deposit/ChooseNetwork';
import DepositCurrency from '../Deposit/DepositCurrency';
import Warning from '../Deposit/Warning';
import LayoutWallet from '../LayoutWallet';

import BigNumber from 'bignumber.js';
import classNames from 'classnames/bind';
import { REGEX } from '../../../constant';
import { errors2FA, lengthVerificationCode } from '../../../constant/2fa';
import { useAppSelector } from '../../../stores';
import styles from '../../../styles/components/wallet/withdraw.module.scss';
import VerificationCode from '../VerificationCode';
import WithdrawAddress from '../WithdrawAddress';
import WithdrawAmount from '../WithdrawAmount';
import CurrencyAmount from '../../UI/CurrencyAmount';
import { find } from 'lodash';

const cx = classNames.bind(styles);

interface FormWithdraw {
  assetSymbol?: string;
  assetType?: string;
  assetAmount: string;
  toAccount: string;
  toChain?: string;
  nonce: string;
}

enum STATUS_METADATA {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED'
}

const note =
  ' For security purposes, large or suspicious withdrawal may take 1-6 hours for audit process We appreciate your patience!';

const Withdraw = () => {
  const { metaverseClient, myBalances, isEnable2FA } = useAppSelector((state) => ({
    metaverseClient: state.app.metaverseClient,
    myBalances: state.myBalances.balances,
    isEnable2FA: state.userInfo.userInfo?.isEnableTwoFactor,
    accessToken: state.account.accountInfo.accessToken
  }));
  const [tokenSupport, setTokenSupport] = useState<Asset>();
  const [balance, setBalance] = useState<AssetBalance>();
  const [assets, setAssets] = useState<Asset[]>([]);
  const [networkActive, setNetWorkActive] = useState<string>('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [networks, setNetworks] = useState<(string | undefined)[]>([]);
  const [isLoadingAsset, setIsLoadingAsset] = useState<boolean>(false);
  const userIdentityClient = useAppSelector((state) => state.app.userIdentityClient);
  const [assetNetwork, setAssetNetWork] = useState<Asset>();
  const [platFormFee, setPlatFormFee] = useState<string>();

  const nonce = (Math.random() + 1).toString(36).substring(2);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: { toAccount: '', assetAmount: '', twoFACode: '' } as FieldValues
  });

  const fetchNetworks = async (symbol: string) => {
    try {
      const res = await metaverseClient.getSupportChain(symbol, {
        withdrawStatus: STATUS_METADATA.ENABLED
      });
      setNetworks(res);
      res[0] && setNetWorkActive(res[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    tokenSupport?.symbol && fetchNetworks(tokenSupport.symbol);
  }, [tokenSupport?.symbol]);

  const fetchAssets = async () => {
    setIsLoadingAsset(true);
    try {
      const { data } = await metaverseClient.getAssets({
        chain: 'CHT',
        withdrawStatus: STATUS_METADATA.ENABLED
      });
      setIsLoadingAsset(false);
      setAssets(data);
      setTokenSupport(data[0]);
    } catch (error) {
      setIsLoadingAsset(false);
      console.log(error);
    }
  };

  const fetchAssetsNetWork = async (networkActive: string) => {
    try {
      const { data } = await metaverseClient.getAssets({
        chain: networkActive,
        withdrawStatus: STATUS_METADATA.ENABLED,
        symbol: tokenSupport?.symbol
      });
      setAssetNetWork(data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const withdrawSDK = async (transaction: FormWithdraw) => {
    await metaverseClient.withdraw({
      ...transaction
    });
    toast.success('Request successful. Please wait for confirmation');
  };

  const handleWithdraw = async (value: FieldValues) => {
    const cryptoAccuracyLimit = BigNumber.sum(assetNetwork?.cryptoAccuracyLimit || 0, 3).toNumber();
    const assetAmountRoundDown = new BigNumber(value.assetAmount)
      .toFormat(cryptoAccuracyLimit, BigNumber.ROUND_DOWN)
      .toString();
    const assetAmountAbs = new BigNumber(assetAmountRoundDown).abs().toString();
    const data: FormWithdraw = {
      assetAmount: assetAmountAbs,
      toAccount: value.toAccount,
      assetSymbol: tokenSupport?.symbol,
      assetType: tokenSupport?.type,
      toChain: networkActive,
      nonce
    };
    setLoading(true);
    try {
      if (isEnable2FA && value.twoFACode?.length < lengthVerificationCode) {
        throw Error(errors2FA.verificationCode);
      }
      const tokenTwoFactorRes = await userIdentityClient.getTokenTwoFactor({
        tfaCode: value.twoFACode
      });
      if (tokenTwoFactorRes) {
        const idToken = tokenTwoFactorRes.data.idToken;
        await metaverseClient.setTwoFactorToken(idToken);
      }
      await withdrawSDK(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message || error?.response?.data?.Message || error?.message || error
      );
    }
  };

  const handleChangeCode = (code: string) => {
    setValue('twoFACode', code);
  };

  const handleShowAssetBalance = (assetBalances: AssetBalance[], tokenSupport: Asset) => {
    setBalance(
      assetBalances
        .filter((fil) => fil.assetType === tokenSupport?.type)
        .filter((fil) => fil.assetSymbol === tokenSupport?.symbol)
        .find((find) => find.tokenId === tokenSupport?.tokenId)
    );
  };

  const handleClickButtonWithdrawAmount = (percent: number | string) => {
    if (percent === 'min') {
      setValue('assetAmount', `${assetNetwork?.minPerOneOrder || 0}`);
    } else if (percent === 'max') {
      setValue(
        'assetAmount',
        `${assetNetwork?.maxPerOneDay || balance?.availableAssetAmount || 0}`
      );
    } else {
      setValue(
        'assetAmount',
        `${new BigNumber(balance?.availableAssetAmount || 0).multipliedBy(percent)}`
      );
    }
  };

  useEffect(() => {
    if (new RegExp(REGEX.ONLY_NUMBER).test(watch('assetAmount'))) {
      const withdrawAmount = new BigNumber(watch('assetAmount')).toString();
      const transactionPercent =
        new BigNumber(assetNetwork?.transactionFeePercent || 0).toNumber() / 100;
      const transactionFee = new BigNumber(withdrawAmount).multipliedBy(transactionPercent);
      const platFormFee = BigNumber.sum(assetNetwork?.flatFee || 0, transactionFee).toString();
      setPlatFormFee(platFormFee);
    } else {
      setPlatFormFee(undefined);
    }
  }, [watch('assetAmount')]);

  useEffect(() => {
    fetchAssetsNetWork(networkActive);
  }, [networkActive, tokenSupport]);

  useEffect(() => {
    if (myBalances && tokenSupport) handleShowAssetBalance(myBalances, tokenSupport);
  }, [myBalances, tokenSupport]);

  useEffect(() => {
    fetchAssets();
  }, []);

  return (
    <LayoutWallet>
      <form onSubmit={handleSubmit(handleWithdraw)}>
        <DepositCurrency
          loading={isLoadingAsset}
          title="Withdraw Currency"
          setTokenSupport={setTokenSupport}
          assets={assets}
          assetBalance={balance}
          balances={myBalances}
          tokenSupport={tokenSupport}
          onClickLinkHistory={() => navigate(`${ROUTES.WALLET.TRANSACTIONS}?category=withdraw`)}
          showHistory
        />
        <ChooseNetwork
          networks={networks}
          setNetWorkActive={setNetWorkActive}
          networkActive={networkActive}
        />
        <WithdrawAddress register={register} network={networkActive} />
        <div className={cx('error-message')}>{errors.toAccount && errors.toAccount?.message}</div>
        <WithdrawAmount
          handleClickButtonWithdrawAmount={handleClickButtonWithdrawAmount}
          balance={balance}
          register={register}
          minAmount={`${assetNetwork?.minPerOneOrder || 0}`}
          maxAmount={`${assetNetwork?.maxPerOneDay || balance?.availableAssetAmount || 0}`}
          fee={platFormFee}
        />
        <div className={cx('error-message')}>
          {errors.assetAmount && errors.assetAmount?.message}
        </div>

        <VerificationCode
          fee={
            platFormFee && (
              <>
                <CurrencyAmount
                  color="yellow"
                  amount={platFormFee}
                  decimalPlaces={assetNetwork?.cryptoAccuracyLimit && assetNetwork?.decimalPlaces}
                />
                <span style={{ marginLeft: '5px' }}>{assetNetwork?.symbol}</span>
              </>
            )
          }
          disable={loading || networks.length === 0}
          note={networks.length === 0 ? 'Not support' : note}
          handleChangeCode={handleChangeCode}
        />
      </form>
      {!isEnable2FA && <Warning />}
    </LayoutWallet>
  );
};

export default Withdraw;
