export const getEnv = (variable: string, defaultValue?: number | string): any => {
  return process.env[variable] || defaultValue;
};

export const convertStringNoSpace = (string: string) => {
  return string.toLocaleLowerCase().replace(/\s+/g, '_');
};

export const convertStringAddSpace = (string: string) => {
  return string.toLocaleLowerCase().replace(/_/g, ' ');
};

export const subName = (name: string, maxLength = 10) => {
  if (name.length > maxLength) {
    return `${name.substring(0, maxLength - 3)}...`;
  }
  return name;
};
