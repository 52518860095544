import React from 'react';
import GameDetail from '../../components/Games/Detail';
import { GAME_INTRODUCTION } from '../../constant/game';
import { ROUTES } from '../../constant/routes';

export default function CrashGameDetailPage() {
  return (
    <GameDetail
      name="Crash"
      imageUrl="/images/games/crash.png"
      releaseDate="--"
      gameInfo={GAME_INTRODUCTION.CRASH}
      linkGame={ROUTES.CRASH.INDEX}
      listSlide={['crash-slide-1', 'crash-slide-2', 'crash-slide-3', 'crash-slide-4']}
    />
  );
}
