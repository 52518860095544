import classNames from 'classnames/bind';
import styles from '../../styles/components/modal/modal-auth.module.scss';

const cx = classNames.bind(styles);
interface LayoutProps {
  children: React.ReactNode;
}

const LayoutPopup = ({ children }: LayoutProps) => {
  return (
    <div className={cx('layout-form')}>
      <div className={cx('col-image')}>
        <img src="/images/BGlogin.png" alt="image-login" />
      </div>
      <div className={cx('col-form')}>
        <div className={cx('welcome')}>
          <p className={cx('title')}>
            Welcome to <span className={cx('name')}>BYBET.IO</span>
          </p>
          <p className={cx('desc')}>Start your game journey now</p>
        </div>
        {children}
      </div>
    </div>
  );
};

export default LayoutPopup;
