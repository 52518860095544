import Layout from '../LayoutComponentSideMenu';
import styles from '../../styles/components/help/term-of-services.module.scss';
const cx = classNames.bind(styles);
import classNames from 'classnames/bind';
import { useEffect } from 'react';

const General = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <div className={cx('general-page')}>
        <div className={cx('sub-title')}>Online Betting Platform</div>
        <div className={cx('content')}>
          Online Betting Platform is considered to be a long-lasting term, since those platforms
          became available many years back, they have been used a lot. Now it’s not in short demand
          or options, there are thousands of betting platforms to pick from – so it becomes a matter
          of what you like and what payment options you would like to see on those platforms.
        </div>

        <div className={cx('content')}>
          Users are always looking for something new and trendy which will help make the gaming
          experience so much better and more accessible. Understanding that habit and mentality,
          developers are seeking ways to provide a betting platform that is attached to the latest
          technologies to bring absolute fairness, transparency, and decentralization to users.
        </div>

        <div className={cx('content')}>
          And that is what Bybet is trying to solve, our goals and vision are to make Bybet the
          revolution of decentralized online betting platform which is integrated with all the
          latest DeFi 2.0 technologies.
        </div>
        <div className={cx('sub-title')}>What are the advantages of Bybet platform?</div>
        <div className={cx('content')}>
          <div>
            &#x2022; Decentralization: Bybet platform provides fair, transparent, and fast
            transactions for all users. Therefore, users can easily cash out their balance or
            winnings to their wallet and exchange them for any other available digital currency.
            Moreover, Bybet’s shareholders (governance token’s holder) officially have decisive
            power on making changes to the Bybet platform.
          </div>
          <div>
            &#x2022; Liquidity: Bybet implements binary models of liquidity provision and yield
            farming programs, with which we can solve the shortage of liquidation.
          </div>
          <div>
            &#x2022; Security: At Bybet, user’s deposit is fully secured and available for
            withdrawal on a daily basis. All online transactions are coded and encrypted for their
            protection.
          </div>
        </div>
        <div className={cx('sub-title')}>What games can we find on Bybet platform?</div>
        <div className={cx('content')}>
          <div>
            After all, it is said that bonuses deposit all the other fun in a betting platform. What
            makes the best user experience for an online betting platform is what games you can find
            at the casino. A player needs to be able to find a few to choose from, and it needs to
            be some sort of balance to keep the market open for more than one type of player.
          </div>
          <div>And on Bybet platform, we offer:</div>
          <div>
            &#x2022; Sports Betting: Players are able to predict sports results and placing a wager
            on the outcome.
          </div>
          <div>
            &#x2022; Crash: The Crash is a multiplayer game where you can choose between Classic and
            Trenball modes. In classic mode, you are allowed to withdraw money after betting. The
            amount you can win will depend on how many players bet on the round. For the Trenball
            mode, you will be required to bet on the line color that can be a factor in your win
          </div>
          <div>
            &#x2022; Ring of Fortune: Do you have what it takes to win amazing prizes? We're talking
            about the Ring of Fortune in exciting roulette style at Bybet, where you simply choose
            among a variety of colors: green, orange, purple, or gray, and win if the roulette lands
            on your color.
          </div>
          <div>
            &#x2022; Classic Dice: Bybet's Classic Dice is a perfect example of why this game has
            led the industry for many years. It's a game of probability established by computing and
            algorithmicizing the hash dice of the blockchain. Hold tight while the dice roll! The
            closer your predicted number is to the randomly generated number, the higher your
            probability of winning!
          </div>
          <div>
            &#x2022; Hash Dice: Hash Dice at Bybet is a variation of the dice game offered on this
            outstanding betting platform. At its core, Hash Dice is a game of probability developed
            using blockchain algorithms and hash value computation. This fact along contributes to
            the thrilling and exctiting nature of the game, especially as players become more
            engaged, and keep their predictions and bets up to date.
          </div>
          <div>
            &#x2022; Coin Flip: Coin Flip is the best example of a game of probability. Players need
            to choose between two possible outcomes, Bybet takes this simple concept and puts its
            own spin on it as a game of chance, offering players lucrative opportunities to win big
            prizes.
          </div>
          <div>
            &#x2022; Wheel: The wheel is divided into many sections, with each being represented by
            a symbol or number. The object of the game is to select one or more sections and place
            your bets on the corresponding area on the layout. You will notice each symbol pays out
            different odd’s, this is due to the amount of times each appears on the wheel.
          </div>
        </div>
        <div className={cx('sub-title')}>Can I use Bybet on mobile or tablet?</div>
        <div className={cx('content')}>
          Bybet will surely be available on iOS/ Android operating systems. According to our
          roadmap, mobile application will be released in Phase IV. Betting platforms that offer
          mobile apps are getting more hits in a day, we all know that no one wants to sit in front
          of a computer anymore for their online betting platform. The best is that you can access
          to those platforms anywhere anytime!
        </div>
      </div>
    </Layout>
  );
};

export default General;
