export default function CrashIcon() {
  return (
    <svg width={26} height={26} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_512_695)">
        <path
          d="M19.5899 20.6324C20.7198 20.1124 21.7781 19.3872 22.7089 18.4564C26.9217 14.2436 26.9288 7.41707 22.7292 3.19563C18.5012 -1.05425 11.6777 -1.06746 7.43493 3.1677C6.49344 4.10715 5.76219 5.17711 5.23965 6.31969C4.88571 7.0936 4.33118 7.76188 3.63598 8.25293C3.17336 8.57946 2.73207 8.94965 2.3177 9.36403C1.84696 9.83477 1.38079 10.3923 0.932387 11.0159C0.588598 11.4938 1.03852 12.1336 1.60778 11.9823C2.07141 11.8589 2.54165 11.7695 3.01543 11.7137C2.67571 12.1047 2.34207 12.5475 2.02368 13.032C1.47676 13.8632 1.62301 14.9662 2.35426 15.6406C2.38727 15.6711 2.41926 15.702 2.45125 15.7335C3.92645 17.2087 3.74008 19.7869 2.03536 21.4916C1.7261 21.8009 1.3879 22.0598 1.03344 22.268C-0.0324563 22.8942 -0.25437 24.3526 0.612973 25.2322L0.63227 25.2514C0.637856 25.257 0.643442 25.2626 0.649028 25.2682C1.44832 26.0579 2.66555 26.2117 3.67102 25.712C4.57645 25.2621 5.61645 25.1981 6.54118 25.5241C7.55832 25.8827 8.66789 25.8923 9.67082 25.4967C11.4091 24.8107 13.0193 23.8692 14.1695 22.8693C14.17 22.8693 14.1695 22.8693 14.17 22.8698C14.0086 23.9834 15.3167 24.6771 16.1404 23.9276C16.2724 23.8077 16.3988 23.6869 16.5197 23.566C16.9351 23.1506 17.3068 22.7073 17.6343 22.2427C18.1295 21.5414 18.8099 20.9914 19.5899 20.6324Z"
          fill="url(#paint0_linear_512_695)"
        />
        <path
          d="M15.0678 20.3982C20.36 20.3982 24.6502 16.108 24.6502 10.8158C24.6502 5.5236 20.36 1.2334 15.0678 1.2334C9.77555 1.2334 5.48535 5.5236 5.48535 10.8158C5.48535 16.108 9.77555 20.3982 15.0678 20.3982Z"
          fill="url(#paint1_linear_512_695)"
        />
        <path
          d="M8.29212 4.04014C7.42224 4.91002 6.75497 5.90584 6.28931 6.9687L18.9156 19.5949C19.9784 19.1293 20.9737 18.462 21.8436 17.5921C25.5857 13.8501 25.5857 7.78272 21.8436 4.04014C18.1015 0.297564 12.0342 0.298071 8.29212 4.04014Z"
          fill="url(#paint2_linear_512_695)"
        />
        <path
          d="M11.5811 1.88795V19.7442C12.6617 20.1667 13.8378 20.3987 15.0677 20.3987C20.3601 20.3987 24.6501 16.1082 24.6501 10.8163C24.6501 5.52439 20.3596 1.23389 15.0677 1.23389C13.8378 1.23338 12.6617 1.46545 11.5811 1.88795Z"
          fill="url(#paint3_linear_512_695)"
        />
        <path
          d="M21.1077 19.7772C21.6724 19.3959 22.2087 18.9561 22.7084 18.4564C26.9212 14.2436 26.9283 7.41707 22.7287 3.19563C18.5006 -1.05425 11.6771 -1.06746 7.43437 3.1677C6.93164 3.66942 6.48934 4.20871 6.10645 4.77594L21.1077 19.7772Z"
          fill="url(#paint4_linear_512_695)"
        />
        <path
          d="M5.23965 6.31974C4.88571 7.09365 4.33118 7.76244 3.63598 8.25299C3.17336 8.57951 2.73207 8.94971 2.3177 9.36408C1.84696 9.83482 1.38079 10.3924 0.932387 11.016C0.588598 11.4938 1.03852 12.1337 1.60778 11.9824C2.07141 11.859 2.54165 11.7696 3.01543 11.7137C2.67571 12.1047 2.34207 12.5476 2.02368 13.032C1.47676 13.8633 1.62301 14.9663 2.35426 15.6406C2.38727 15.6711 2.41926 15.7021 2.45125 15.7336C3.92645 17.2088 3.74008 19.7869 2.03536 21.4917C1.7261 21.8009 1.3879 22.0599 1.03344 22.2681C-0.0324563 22.8942 -0.25437 24.3527 0.612973 25.2322L0.63227 25.2515C0.637856 25.2571 0.643442 25.2627 0.649028 25.2683C0.650551 25.2693 0.651567 25.2708 0.652583 25.2718L22.7292 3.19568C18.5012 -1.0542 11.6777 -1.0669 7.43493 3.16775C6.49344 4.10771 5.76168 5.17767 5.23965 6.31974Z"
          fill="url(#paint5_linear_512_695)"
        />
        <path
          d="M0.32194 19.9449C-0.183334 19.4396 0.176705 18.2595 0.682486 17.7537C1.18776 17.2484 2.00737 17.2484 2.51315 17.7537C3.01842 18.259 3.01842 19.0786 2.51315 19.5844C2.00737 20.0902 0.827213 20.4502 0.32194 19.9449Z"
          fill="url(#paint6_linear_512_695)"
        />
        <path
          d="M9.93084 23.0435C11.0653 22.1432 13.1468 22.9105 15.5757 20.4816C17.4566 18.6007 17.1144 16.5278 17.0854 15.8824C16.9326 14.5417 16.2501 13.1183 15.0826 11.9509C13.6176 10.4858 11.7493 9.78405 10.1477 9.93487C7.02361 10.0999 5.41131 12.7766 4.84865 13.9588C4.7867 14.0893 4.9624 14.2741 5.09748 14.2193C5.10205 14.2172 5.10713 14.2152 5.1117 14.2137C5.42197 14.0898 5.82873 14.494 5.71549 14.8109C4.88014 17.1453 4.01787 20.3648 5.34377 21.6902C6.17557 22.522 8.08443 22.1615 9.56115 21.9776C9.69268 21.9614 9.83182 22.1066 9.80744 22.2351C9.75717 22.4976 9.72924 22.7221 9.71451 22.8937C9.70436 23.0055 9.85061 23.1075 9.93084 23.0435Z"
          fill="url(#paint7_linear_512_695)"
        />
        <path
          d="M24.6528 10.8133C24.6528 16.1082 20.3587 20.3937 15.0724 20.3937C9.77746 20.3937 5.4834 16.1082 5.4834 10.8133C5.4834 5.52695 9.77746 1.2334 15.0724 1.2334C20.3587 1.2334 24.6528 5.52746 24.6528 10.8133Z"
          fill="#57B3FE"
        />
        <g opacity="0.6">
          <path
            d="M24.6493 10.8175C24.6493 16.1058 20.3608 20.3943 15.0724 20.3943C11.6061 20.3943 8.56125 18.553 6.87988 15.7966C8.29973 16.6375 9.97144 17.124 11.7468 17.124C19.3314 17.124 23.7728 8.77199 19.92 2.55078C22.7511 4.21387 24.6493 7.29477 24.6493 10.8175Z"
            fill="#5094FF"
          />
        </g>
        <path
          d="M24.6518 10.8098C24.6502 16.1083 20.3547 20.3978 15.0719 20.3978C11.9783 20.3978 9.21579 18.9277 7.47095 16.6421C9.0863 17.8938 11.114 18.6291 13.3108 18.6291C21.2906 18.6291 25.705 9.45347 20.9036 3.21753C23.1847 4.96694 24.6528 7.72335 24.6518 10.8098Z"
          fill="#5094FF"
        />
        <g opacity="0.5">
          <path
            d="M15.3729 3.48823C16.612 4.72729 16.318 7.85136 14.2095 9.95979C12.1011 12.0682 8.97756 12.3628 7.73799 11.1232C6.49842 9.88362 6.75537 6.72249 8.86381 4.61405C10.9722 2.50561 14.1334 2.24866 15.3729 3.48823Z"
            fill="white"
          />
        </g>
        <path
          d="M11.6031 4.39042C12.0221 4.80936 11.7839 5.8976 10.9658 6.71569C10.1477 7.53377 9.05947 7.77194 8.64053 7.35299C8.22158 6.93405 8.34041 5.72698 9.1585 4.90838C9.97658 4.08979 11.1842 3.97147 11.6031 4.39042Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M16.6745 15.1041C17.809 14.2037 19.8905 14.971 22.3188 12.5422C24.1998 10.6612 23.8575 8.58834 23.8286 7.94291C23.6757 6.60229 22.9932 5.17889 21.8258 4.01143C20.3607 2.54639 18.4925 1.84459 16.8908 1.99541C13.7668 2.16045 12.1545 4.83713 11.5918 6.01932C11.5299 6.14983 11.7056 6.33467 11.8406 6.27983C11.8452 6.2778 11.8503 6.27576 11.8549 6.27424C12.1651 6.15033 12.5719 6.55455 12.4587 6.87143C11.6233 9.20584 10.761 12.4254 12.0869 13.7508C12.9187 14.5826 14.8276 14.222 16.3048 14.0382C16.4363 14.0219 16.5755 14.1672 16.5511 14.2956C16.5008 14.5582 16.4729 14.7826 16.4582 14.9543C16.4475 15.066 16.5943 15.1681 16.6745 15.1041Z"
          fill="url(#paint8_linear_512_695)"
        />
        <path
          d="M20.713 12.2841C20.6084 12.2404 20.6033 12.0936 20.7039 12.0423C21.917 11.4274 21.9861 11.3218 20.6282 11.8692C20.5236 11.9113 20.4165 11.8113 20.452 11.7041C21.3808 8.88629 21.2406 8.89696 20.1341 11.5863C20.091 11.6909 19.9447 11.6955 19.8934 11.5945C19.2541 10.3346 19.1881 10.3529 19.7233 11.6732C19.7659 11.7783 19.6669 11.8854 19.5593 11.8494C18.4685 11.4827 17.464 11.1643 17.4392 11.2319C17.4153 11.2964 18.3817 11.7234 19.444 12.1678C19.5486 12.2115 19.5537 12.3582 19.4531 12.409C18.1633 13.0625 18.2588 13.0976 19.5298 12.5786C19.6344 12.5359 19.7421 12.636 19.7065 12.7436C18.8133 15.4569 18.8758 15.6554 20.0244 12.8615C20.0676 12.7568 20.2133 12.7523 20.2651 12.8533C20.5307 13.3743 20.7582 13.7989 20.7912 13.7841C20.8273 13.7674 20.6567 13.3175 20.4347 12.7792C20.3911 12.6741 20.4906 12.5664 20.5983 12.6025C21.6885 12.9686 22.6976 13.286 22.7214 13.2215C22.7453 13.1539 21.7754 12.7284 20.713 12.2841Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_512_695"
          x1="6.91415"
          y1="18.9693"
          x2="22.4468"
          y2="3.43668"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FEF0AE" />
          <stop offset={1} stopColor="#FAC600" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_512_695"
          x1="9.87632"
          y1="10.8137"
          x2="18.604"
          y2="10.8137"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#5A5A5A" />
          <stop offset={1} stopColor="#444444" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_512_695"
          x1="17.3395"
          y1="8.54518"
          x2="20.7725"
          y2="5.11216"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#433F43" stopOpacity={0} />
          <stop offset={1} stopColor="#1A1A1A" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_512_695"
          x1="15.4409"
          y1="10.8862"
          x2="23.77"
          y2="12.5201"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#433F43" stopOpacity={0} />
          <stop offset={1} stopColor="#1A1A1A" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_512_695"
          x1="15.4276"
          y1="10.4558"
          x2="22.6693"
          y2="3.2141"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FEA613" stopOpacity={0} />
          <stop offset={1} stopColor="#E94444" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_512_695"
          x1="9.43285"
          y1="10.6557"
          x2="5.25254"
          y2="3.9264"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FEA613" stopOpacity={0} />
          <stop offset="0.1732" stopColor="#FC9D18" stopOpacity="0.173" />
          <stop offset="0.4605" stopColor="#F78324" stopOpacity="0.461" />
          <stop offset="0.8243" stopColor="#EE5A39" stopOpacity="0.824" />
          <stop offset={1} stopColor="#E94444" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_512_695"
          x1="0.529638"
          y1="19.9108"
          x2="3.02762"
          y2="17.005"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FEF0AE" />
          <stop offset={1} stopColor="#FAC600" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_512_695"
          x1="5.80418"
          y1="21.275"
          x2="11.7042"
          y2="15.3751"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FEA613" stopOpacity={0} />
          <stop offset="0.1732" stopColor="#FC9D18" stopOpacity="0.173" />
          <stop offset="0.4605" stopColor="#F78324" stopOpacity="0.461" />
          <stop offset="0.8243" stopColor="#EE5A39" stopOpacity="0.824" />
          <stop offset={1} stopColor="#E94444" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_512_695"
          x1="10.6016"
          y1="15.2816"
          x2="23.6014"
          y2="2.2818"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E41F2D" stopOpacity={0} />
          <stop offset="0.4091" stopColor="#DB1D2B" stopOpacity="0.409" />
          <stop offset={1} stopColor="#C41926" />
        </linearGradient>
        <clipPath id="clip0_512_695">
          <rect width="25.7954" height={26} fill="white" transform="translate(0.078125)" />
        </clipPath>
      </defs>
    </svg>
  );
}
