import classNames from 'classnames/bind';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import styles from '../../styles/components/wallet/withdraw.module.scss';
import { REGEX } from '../../constant';
import { NETWORK } from '../../interfaces';

const cx = classNames.bind(styles);

interface PropsType {
  register: UseFormRegister<FieldValues>;
  network: string;
  title?: string;
  addressMsgRequired?: string;
  placeholder?: string;
}

const WithdrawAddress = ({
  register,
  network,
  title = 'Withdraw Address',
  addressMsgRequired = 'Please enter the address',
  placeholder = 'Enter withdraw address'
}: PropsType) => {
  const handlePatternValidate = (network: string) => {
    switch (network) {
      case NETWORK.ETH:
        return {
          value: new RegExp(REGEX.WALLET_ADDRESS_ETH),
          message: 'Invalid address'
        };

      case NETWORK.BSC:
        return {
          value: new RegExp(REGEX.WALLET_ADDRESS_ETH),
          message: 'Invalid address'
        };

      case NETWORK.TRON:
        return {
          value: new RegExp(REGEX.WALLET_ADDRESS_TRX),
          message: 'Invalid address'
        };

      default:
        return undefined;
    }
  };

  return (
    <div className={cx('withdraw-address')}>
      <div className={cx('title-total')}>
        <div>{title}</div>
      </div>
      <div className={cx('input-withdraw')}>
        <div className={cx('input')}>
          <input
            {...register('toAccount', {
              required: addressMsgRequired,
              pattern: handlePatternValidate(network)
            })}
            placeholder={placeholder}
            type="text"
          />
        </div>
      </div>
    </div>
  );
};

export default WithdrawAddress;
