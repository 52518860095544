import classnames from 'classnames/bind';
import { InfoUser } from 'game-profile-js/lib/client/entities/all.entity';
import { useEffect, useState } from 'react';
import { UserEntity } from 'user-identity-js/lib/entities';
import { shortenAddress } from '../../lib/shortenAddress';
import { useAppSelector } from '../../stores';
import styles from '../../styles/components/modal/modal-chat-information.module.scss';
import { copyText } from '../../utils/copyText';
import Copy from '../Icons/Copy';
import ModalUI from '../UI/Modal';

const cx = classnames.bind(styles);

interface PropsType {
  isShowing: boolean;
  toggle: () => void;
  id: string;
}

const ModalChatInformation = ({ isShowing, toggle, id }: PropsType) => {
  const gameProfile = useAppSelector((state) => state.app.gameProfileClient);
  const userInfo = useAppSelector((state) => state.userInfo.userInfo);
  const [userVipLevel, setUserVipLevel] = useState<InfoUser[]>();

  const getUserVipLevel = async (id: string[]) => {
    const resVip = await gameProfile.getUsersInfo(id);
    setUserVipLevel(resVip.data);
  };

  useEffect(() => {
    getUserVipLevel([id]);
  }, [id]);

  return (
    <ModalUI
      isShowing={isShowing}
      hide={toggle}
      title={<div className={cx('title')}>User Information</div>}>
      <div className={cx('wrapper')}>
        <div className={cx('content')}>
          <div className={cx('avatar')}>
            <img src={userInfo?.avatar || '/images/avatar.png'} alt="avatar" />
          </div>
          <div className={cx('infor-item')}>
            <div className={cx('nickname')}>
              <div>
                {userInfo?.nickName || 'Hidden'}{' '}
                {userVipLevel?.map((user, index) => {
                  if (user.userId === userInfo?.id) {
                    return <span key={index}>- Lv: {user.vipLevel}</span>;
                  }
                })}
              </div>
            </div>
            <div className={cx('address')}>
              <text>{shortenAddress(userInfo?.identifier)}</text>
              <div
                className={cx('icon')}
                onClick={() => userInfo?.identifier && copyText(userInfo?.identifier)}>
                <Copy />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalUI>
  );
};

export default ModalChatInformation;
