import BigNumber from 'bignumber.js';
import { CrashGameResult } from 'bybet-game-js/lib/schema/CrashGame';
import classnames from 'classnames/bind';
import styles from '../../../styles/components/games/crash/reward-popup.module.scss';

const cx = classnames.bind(styles);

interface PropsType {
  resultInfo: CrashGameResult;
}

export default function RewardPopup({ resultInfo }: PropsType) {
  if (!resultInfo.isWin) return null;
  return (
    <div className={cx('root')}>
      <p className={cx('title')}>
        <span className={cx('text')}>You Won</span>{' '}
        {new BigNumber(resultInfo.rewardAmount).toFixed(2)}
      </p>
      <div className={cx('payout-container')}>
        <p className={cx('payout')}>
          {new BigNumber(resultInfo.rewardAmount)
            .div(
              resultInfo.betOptions
                ?.map((betOption) => betOption.amount)
                .reduce((prev, current) => new BigNumber(prev).plus(current).toFixed(), '0.00') ||
                '1'
            )
            .toFixed(2)}
          x
        </p>
        <img src="/images/games/reward.png" alt="reward" />
      </div>
    </div>
  );
}
