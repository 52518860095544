export default function Copy() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M13.4286 7H5.57143C4.70479 7 4 7.70479 4 8.57143V16.4286C4 17.2952 4.70479 18 5.57143 18H13.4286C14.2952 18 15 17.2952 15 16.4286V8.57143C15 7.70479 14.2952 7 13.4286 7Z"
        fill="#A2A3A4"
      />
      <path
        d="M18.4286 2H10.5714C10.1547 2 9.75496 2.16556 9.46026 2.46026C9.16556 2.75496 9 3.15466 9 3.57143V5.14286H15.2857C15.7025 5.14286 16.1022 5.30842 16.3969 5.60312C16.6916 5.89782 16.8571 6.29752 16.8571 6.71429V13H18.4286C18.8453 13 19.245 12.8344 19.5397 12.5397C19.8344 12.245 20 11.8453 20 11.4286V3.57143C20 3.15466 19.8344 2.75496 19.5397 2.46026C19.245 2.16556 18.8453 2 18.4286 2Z"
        fill="#A2A3A4"
      />
    </svg>
  );
}
