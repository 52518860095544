const GrayPiece = (props: any) => {
  return (
    <svg
      width={props.width || 22}
      height={props.height || 22}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.2576 15.7368H5.90555C5.69935 15.7368 5.50172 15.6544 5.35669 15.5078L1.69607 11.8087C1.31047 11.419 1.31047 10.7915 1.69607 10.4019L2.37315 9.71766C2.56098 9.52785 2.81692 9.42105 3.08395 9.42105H3.49123C3.95631 9.42105 4.33333 9.04403 4.33333 8.57895V8.46601C4.33333 8.0633 4.65979 7.73684 5.0625 7.73684C5.46521 7.73684 5.79167 7.41038 5.79167 7.00768V6.46378C5.79167 6.20038 5.89559 5.94761 6.08087 5.76039L10.0809 1.71828C10.4722 1.32281 11.1111 1.32281 11.5025 1.71828L13.8358 4.07618C14.0211 4.2634 14.125 4.51617 14.125 4.77957V5.5515C14.125 5.94454 14.4436 6.26316 14.8367 6.26316C15.1551 6.26316 15.4348 6.47474 15.5215 6.78119L15.5409 6.85009C15.6737 7.31953 16.1896 7.5645 16.6373 7.37062L16.7042 7.34166C17.0333 7.19912 17.4161 7.27314 17.6684 7.52807L20.3039 10.1913C20.6895 10.581 20.6895 11.2085 20.3039 11.5981L19.3407 12.5715C19.2027 12.711 19.0146 12.7895 18.8183 12.7895C18.4124 12.7895 18.0833 13.1185 18.0833 13.5244V13.6671C18.0833 13.9128 17.9804 14.1472 17.7994 14.3134L16.0786 15.8943C15.8941 16.0638 15.6526 16.1579 15.4021 16.1579H15.125C14.5727 16.1579 14.125 16.6056 14.125 17.1579V17.412C14.125 17.6866 14.0121 17.9491 13.8127 18.1379L11.502 20.3271C11.1072 20.7011 10.4859 20.691 10.1034 20.3045L6.8698 17.0368C6.72106 16.8865 6.67526 16.6625 6.75309 16.4659C6.89155 16.1161 6.63379 15.7368 6.2576 15.7368Z"
        fill="url(#paint0_radial_1207_25909)"
        stroke="#858686"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1207_25909"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11 11) rotate(96.5819) scale(8.72417 8.78347)">
          <stop stopColor="#D8D8D8" />
          <stop offset={1} stopColor="#A4A4A4" stopOpacity="0.91" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default GrayPiece;
