import React from 'react';

const MagicPenIcon = () => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.25 6.25L15.0083 7.49167L12.5083 4.99167L13.75 3.75C14.1 3.4 14.55 3.23334 15 3.23334C15.45 3.23334 15.9 3.4 16.25 3.75C16.9416 4.44167 16.9416 5.55834 16.25 6.25Z"
        fill="white"
      />
      <path
        d="M14.425 8.08334L5.4167 17.0833C4.72503 17.775 3.60837 17.775 2.9167 17.0833C2.22503 16.3917 2.22503 15.275 2.9167 14.5833L11.925 5.58334L14.425 8.08334Z"
        fill="white"
      />
      <path
        d="M8.29176 2.91668L8.63343 1.75835C8.66676 1.65001 8.63343 1.53335 8.55843 1.45001C8.48343 1.36668 8.3501 1.33335 8.24176 1.36668L7.08343 1.70835L5.9251 1.36668C5.81676 1.33335 5.7001 1.36668 5.61676 1.44168C5.53343 1.52501 5.50843 1.64168 5.54176 1.75001L5.8751 2.91668L5.53343 4.07501C5.5001 4.18335 5.53343 4.30001 5.60843 4.38335C5.69176 4.46668 5.80843 4.49168 5.91676 4.45835L7.08343 4.12501L8.24176 4.46668C8.2751 4.47501 8.3001 4.48335 8.33343 4.48335C8.41676 4.48335 8.49176 4.45001 8.55843 4.39168C8.64176 4.30835 8.66676 4.19168 8.63343 4.08335L8.29176 2.91668Z"
        fill="white"
      />
      <path
        d="M4.95827 7.91668L5.29993 6.75835C5.33327 6.65001 5.29993 6.53335 5.22493 6.45001C5.1416 6.36668 5.02493 6.34168 4.9166 6.37501L3.74993 6.70835L2.5916 6.36668C2.48327 6.33335 2.3666 6.36668 2.28327 6.44168C2.19993 6.52501 2.17493 6.64168 2.20827 6.75001L2.5416 7.91668L2.19993 9.07501C2.1666 9.18335 2.19993 9.30001 2.27493 9.38335C2.35827 9.46668 2.47493 9.49168 2.58327 9.45835L3.7416 9.11668L4.89993 9.45835C4.92493 9.46668 4.95827 9.46668 4.9916 9.46668C5.07493 9.46668 5.14993 9.43335 5.2166 9.37501C5.29993 9.29168 5.32493 9.17501 5.2916 9.06668L4.95827 7.91668Z"
        fill="white"
      />
      <path
        d="M17.4581 12.0833L17.7998 10.925C17.8331 10.8167 17.7998 10.7 17.7248 10.6167C17.6414 10.5333 17.5248 10.5083 17.4164 10.5417L16.2581 10.8833L15.0998 10.5417C14.9914 10.5083 14.8748 10.5417 14.7914 10.6167C14.7081 10.7 14.6831 10.8167 14.7164 10.925L15.0581 12.0833L14.7164 13.2417C14.6831 13.35 14.7164 13.4667 14.7914 13.55C14.8748 13.6333 14.9914 13.6583 15.0998 13.625L16.2581 13.2833L17.4164 13.625C17.4414 13.6333 17.4748 13.6333 17.5081 13.6333C17.5914 13.6333 17.6664 13.6 17.7331 13.5417C17.8164 13.4583 17.8414 13.3417 17.8081 13.2333L17.4581 12.0833Z"
        fill="white"
      />
    </svg>
  );
};

export default MagicPenIcon;
