import classNames from 'classnames/bind';
import { useState } from 'react';
import { useAppSelector } from '../../stores';
import styles from '../../styles/components/layout/menu-mobile.module.scss';
import BadgeIcon from '../Icons/BadgeIcon';
import HomeIcon from '../Icons/HomeIcon';
import InfoIcon from '../Icons/InfoIcon';
import MessageIcon from '../Icons/MessageIcon';
import NotificationIcon from '../Icons/NotificationIcon';
import SideMenu from './SideMenu';

const cx = classNames.bind(styles);

const MenuMobile = () => {
  const [itemActive, setItemActive] = useState<string>('notification');
  const [showMenu, setShowMenu] = useState(false);
  const unSeenCount = useAppSelector((state) => state.notification.unSeenCount);
  const listMenu = [
    {
      key: 'menu',
      name: 'Menu',
      getIcon: () => <HomeIcon />
    },
    {
      key: 'notification',
      name: 'Notification',
      getIcon: () => (
        <div className={cx('notification')}>
          {unSeenCount > 0 && (
            <div className={cx('badge')}>
              <BadgeIcon />
            </div>
          )}
          <NotificationIcon />
        </div>
      )
    },
    {
      key: 'profile',
      name: 'Profile',
      getIcon: () => <InfoIcon />
    },
    {
      key: 'chat',
      name: 'Chat',
      getIcon: () => <MessageIcon />
    }
  ];

  const handleShowSideMenu = (key: string) => {
    setItemActive(key);
    setShowMenu(true);
  };
  const handleCloseSideMenu = () => {
    setShowMenu(false);
  };
  return (
    <>
      <div className={cx('wrapper')}>
        <ul className={cx('list-menu')}>
          {listMenu.map((item) => {
            return (
              <li onClick={() => handleShowSideMenu(item.key)} key={item.key}>
                <div>{item.getIcon()}</div>
                <div>{item.name}</div>
              </li>
            );
          })}
        </ul>
      </div>
      <SideMenu
        isShowing={showMenu}
        itemActive={itemActive}
        handleCloseSideMenu={handleCloseSideMenu}
      />
    </>
  );
};

export default MenuMobile;
