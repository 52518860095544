import { RingGameResult } from 'bybet-game-js/lib/schema/RingGame';
import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { RING_TIME_SECONDS } from '../../../constant';
import useBreakpoints from '../../../hooks/useBreakpoints';
import Anchor from '../../Icons/RingGame/Anchor';

enum RingColor {
  GREEN = '#3DB657',
  BLUE = '#3D82F0',
  RED = '#E03E4F',
  Gray = '#6B7688'
}

function getRoundColor(round_position: number) {
  const colors = new Map(
    Object.entries({
      1: RingColor.GREEN,
      2: RingColor.RED,
      3: RingColor.Gray,
      4: RingColor.BLUE,
      5: RingColor.Gray,
      6: RingColor.BLUE,
      7: RingColor.Gray,
      8: RingColor.BLUE,
      9: RingColor.Gray,
      10: RingColor.RED,
      11: RingColor.Gray,
      12: RingColor.RED,
      13: RingColor.Gray,
      14: RingColor.BLUE,
      15: RingColor.Gray,
      16: RingColor.BLUE,
      17: RingColor.Gray,
      18: RingColor.BLUE,
      19: RingColor.Gray,
      20: RingColor.RED,
      21: RingColor.Gray,
      22: RingColor.RED,
      23: RingColor.Gray,
      24: RingColor.BLUE,
      25: RingColor.Gray,
      26: RingColor.BLUE,
      27: RingColor.Gray,
      28: RingColor.BLUE,
      29: RingColor.Gray,
      30: RingColor.BLUE,
      31: RingColor.Gray,
      32: RingColor.BLUE,
      33: RingColor.Gray,
      34: RingColor.RED,
      35: RingColor.Gray,
      36: RingColor.RED,
      37: RingColor.Gray,
      38: RingColor.BLUE,
      39: RingColor.Gray,
      40: RingColor.BLUE,
      41: RingColor.Gray,
      42: RingColor.BLUE,
      43: RingColor.Gray,
      44: RingColor.RED,
      45: RingColor.Gray,
      46: RingColor.RED,
      47: RingColor.Gray,
      48: RingColor.BLUE,
      49: RingColor.Gray,
      50: RingColor.BLUE,
      51: RingColor.Gray,
      52: RingColor.BLUE,
      53: RingColor.Gray,
      54: RingColor.RED
    })
  );

  return colors.get(String(round_position));
}
interface RingProps {
  spinNumber?: number;
  ringGameResult?: RingGameResult;
}
const Ring = ({ spinNumber, ringGameResult }: RingProps) => {
  const [easeOut, setEaseOut] = useState(0);
  const [rotate, setRotate] = useState(0);
  const [result, setResult] = useState(1);
  const [spinning, setSpinning] = useState(false);
  const { isTablet } = useBreakpoints();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const list = [...Array.from(Array(54), (_, i) => i)];
  const radius = isTablet ? 120 : 200;
  const getResult = (spin: any) => {
    const rotated = Math.round(spin / (360 / list.length));
    const remain = rotated % list.length;
    const currentNumberBottom = list[remain === 0 ? 0 : list.length - remain] + 1;

    setResult(currentNumberBottom);
    setSpinning(false);
  };
  useEffect(() => {
    if (spinNumber && ringGameResult) spin(spinNumber);
  }, [spinNumber]);
  const spin = (number?: number | undefined) => {
    setResult(55);
    const randomNumber = number || Math.floor(Math.random() * list.length) + 1;
    const offset =
      randomNumber - result > 0 ? list.length - (randomNumber - result) : -(randomNumber - result);
    const spinDeg = offset * (360 / list.length);
    setRotate(rotate + spinDeg + 360);
    // setEaseOut(2 + (spinDeg * 4) / 360);
    setEaseOut(RING_TIME_SECONDS);

    setSpinning(true);

    setTimeout(() => {
      getResult(rotate + spinDeg);
    }, RING_TIME_SECONDS * 1000);
  };
  const reset = () => {
    setRotate(0);
    setEaseOut(0);
    // setResult(1);
    setSpinning(false);
  };
  useEffect(() => {
    let numOptions = list.length;
    let arcSize = (2 * Math.PI) / numOptions;
    // setAngle(arcSize);

    // // get index of starting position of selector
    // topPosition(numOptions, arcSize);

    // dynamically generate sectors from state list
    let angle = Math.PI / 2 - arcSize / 2;
    for (let i = 0; i < numOptions; i++) {
      let text = list[i];
      renderSector(i + 1, text + 1, angle, arcSize, getRoundColor(i + 1));
      angle += arcSize;
    }

    // init();
  }, []);

  const renderSector = (index: any, text: any, start: any, arc: any, color: any) => {
    // create canvas arc for each list element
    let canvas = canvasRef.current;
    if (canvas) {
      let ctx = canvas.getContext('2d');
      if (ctx) {
        let x = canvas.width / 2;
        let y = canvas.height / 2;
        let startAngle = start + 0.02;
        let endAngle = start + arc;
        let angle = index * arc + Math.PI / 2 - arc / 2;
        let baseSize = radius * 1.09;
        let textRadius = baseSize - 40;

        ctx.beginPath();
        ctx.arc(x, y, radius, startAngle, endAngle, false);
        //@ts-ignore
        // ctx.roundRect(x, y, 100, 150, 5);
        // ctx.arc(x, y, radius, startAngle - 2, startAngle + 2, false);

        ctx.lineWidth = 21;
        ctx.strokeStyle = color;

        ctx.font = '11px Arial';
        ctx.fillStyle = 'white';
        ctx.stroke();

        ctx.save();
        // ctx.translate(
        //   baseSize + Math.cos(angle - arc / 2) * textRadius,
        //   baseSize + Math.sin(angle - arc / 2) * textRadius
        // );
        ctx.rotate(angle - arc / 2 + Math.PI / 2);
        // ctx.fillText(text, -ctx.measureText(text).width / 2, 0);
        ctx.restore();
      }
    }
  };
  return (
    <>
      <div
        style={{
          position: 'relative',
          width: 'fit-content',
          height: 'fit-content',
          boxShadow: 'inset 0px 0px 0px 42px #262626',
          borderRadius: '100%'
        }}>
        <motion.canvas
          ref={canvasRef}
          id="wheel"
          width={isTablet ? 260 : 442}
          height={isTablet ? 260 : 442}
          animate={{ rotate: rotate }}
          transition={{ ease: 'easeOut', duration: easeOut }}
        />
        <span
          id="selector"
          style={{
            position: 'absolute',
            left: isTablet ? '45%' : '47%',
            top: isTablet ? '76%' : '84%',
            zIndex: 3
            // color: result ? getRoundColor(result) : 'white'
          }}>
          <Anchor color={result && ringGameResult ? getRoundColor(result) : 'white'} />
        </span>
      </div>
      <div style={{ marginTop: 100 }}>
        {/* <button onClick={() => spin(3)}>spinNNNN</button> */}
        {/* <button onClick={reset}>reset</button> */}
      </div>
    </>
  );
};

export default Ring;
