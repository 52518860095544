import { useEffect } from 'react';
import Banner from '../Banner';
import Message from '../Message';
import AffiliateReward from './AffiliateReward';
import TotalCommissionReward from './TotalCommissionReward';

export default function AffiliateMangement() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Banner />
      <AffiliateReward />
      <TotalCommissionReward />
      <Message />
    </div>
  );
}
