import React from 'react';

const InfoIcon = () => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 1C5.02571 1 1 5.02525 1 10C1 14.9742 5.02525 19 10 19C14.9743 19 19 14.9747 19 10C19 5.02578 14.9747 1 10 1ZM10 17.7442C5.72985 17.7442 2.25582 14.2702 2.25582 10C2.25582 5.72982 5.72985 2.25582 10 2.25582C14.2701 2.25582 17.7442 5.72982 17.7442 10C17.7442 14.2702 14.2701 17.7442 10 17.7442Z"
        fill="#969696"
      />
      <path
        d="M10.0001 8.50269C9.46698 8.50269 9.08789 8.72783 9.08789 9.05953V13.5731C9.08789 13.8574 9.46698 14.1417 10.0001 14.1417C10.5095 14.1417 10.9241 13.8574 10.9241 13.5731V9.05945C10.9241 8.72779 10.5095 8.50269 10.0001 8.50269Z"
        fill="#969696"
      />
      <path
        d="M9.99923 5.71851C9.45428 5.71851 9.02783 6.10944 9.02783 6.55962C9.02783 7.00983 9.45431 7.41262 9.99923 7.41262C10.5323 7.41262 10.9589 7.00983 10.9589 6.55962C10.9589 6.10944 10.5323 5.71851 9.99923 5.71851Z"
        fill="#969696"
      />
    </svg>
  );
};

export default InfoIcon;
