export default function CommissionRewardToken() {
  return (
    <svg width={63} height={63} viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1971_39603)">
        <path
          d="M58.3959 44.019V50.0995C58.3959 52.7055 51.9287 54.8184 43.9508 54.8184C38.7636 54.8184 34.2147 53.9251 31.668 52.5834C30.2979 51.8626 29.5068 51.0112 29.5068 50.0995V44.019H58.3959Z"
          fill="#EFAE2E"
        />
        <path
          d="M29.507 44.019H38.4285C37.0006 47.4591 34.6411 50.4186 31.668 52.5834C30.2979 51.8626 29.5068 51.0112 29.5068 50.0995V44.019H29.507Z"
          fill="#E59120"
        />
        <path
          d="M58.3959 44.0191C58.3959 46.6262 51.9287 48.7393 43.9508 48.7393C41.1034 48.7393 38.4483 48.4695 36.2107 48.0038C32.1805 47.1671 29.5068 45.6961 29.5068 44.0192C29.5068 42.8351 30.8412 41.7522 33.0466 40.9229C34.795 40.2662 37.0941 39.7672 39.7062 39.506C41.0479 39.3717 42.4734 39.2991 43.9508 39.2991C51.4173 39.2991 57.5605 41.1497 58.3159 43.5239C58.3688 43.6864 58.3959 43.8516 58.3959 44.0191Z"
          fill="#FFE28F"
        />
        <path
          d="M58.316 43.5239C55.4969 44.3815 51.8759 44.8496 48.0563 44.8496C44.6199 44.8496 41.3734 44.4763 38.6677 43.7691C35.6293 42.9757 33.9414 41.9481 33.0469 40.9229C34.7952 40.2662 37.0943 39.7672 39.7064 39.506C41.0481 39.3717 42.4737 39.2991 43.951 39.2991C51.4174 39.2991 57.5606 41.1496 58.316 43.5239Z"
          fill="#FFD243"
        />
        <path
          d="M39.7062 39.5061C39.2084 42.6369 37.9838 45.5298 36.2107 48.0039C32.1805 47.1672 29.5068 45.6962 29.5068 44.0193C29.5068 41.8952 33.8007 40.0987 39.7062 39.5061Z"
          fill="#FFD243"
        />
        <path
          d="M62.5002 32.5718V38.6521C62.5002 41.2581 56.0329 43.371 48.0563 43.371C44.6471 43.371 41.5126 42.9841 39.0409 42.3386C35.7314 41.475 33.6123 40.1443 33.6123 38.6521V32.5718H62.5002Z"
          fill="#EFAE2E"
        />
        <path
          d="M39.9564 36.3359C39.9564 38.4256 39.636 40.4424 39.0409 42.3386C35.7314 41.475 33.6123 40.1443 33.6123 38.6521V32.5718H39.6028C39.8345 33.7916 39.9564 35.0495 39.9564 36.3359Z"
          fill="#E59120"
        />
        <path
          d="M62.5002 32.5719C62.5002 35.1779 56.0329 37.2908 48.0563 37.2908C45.0548 37.2908 42.2654 36.9914 39.9552 36.4801C36.1271 35.6299 33.6123 34.197 33.6123 32.5719C33.6123 32.4019 33.6406 32.2331 33.6949 32.0667C34.0904 30.8507 35.8992 29.7726 38.574 29.0112C41.1109 28.2892 44.4277 27.8518 48.0563 27.8518C52.4056 27.8518 56.3064 28.4802 58.9542 29.4745C61.1633 30.3024 62.5002 31.3865 62.5002 32.5719Z"
          fill="#FFE28F"
        />
        <path
          d="M58.9546 29.4742C58.1969 30.3392 56.8699 31.214 54.625 31.9471C51.7517 32.8847 47.9619 33.4021 43.9513 33.4021C42.3496 33.4021 40.7689 33.3196 39.2546 33.1557C38.1198 33.0326 35.8786 32.7269 33.6953 32.0665C34.0908 30.8504 35.8996 29.7724 38.5744 29.0109C41.1113 28.289 44.4281 27.8516 48.0567 27.8516C52.406 27.8516 56.3068 28.4799 58.9546 29.4742Z"
          fill="#FFD243"
        />
        <path
          d="M39.9564 36.3358C39.9564 36.3839 39.9564 36.432 39.9552 36.4799C36.1271 35.6297 33.6123 34.1968 33.6123 32.5717C33.6123 31.1511 35.5331 29.876 38.574 29.011C39.4661 31.2817 39.9564 33.752 39.9564 36.3358Z"
          fill="#FFD243"
        />
        <path
          d="M58.3958 21.1243V27.2034C58.3958 29.8106 51.9286 31.9236 43.9507 31.9236C42.3649 31.9236 40.8396 31.8399 39.4129 31.6858C33.659 31.0649 29.5068 29.2918 29.5068 27.2034V21.1243H58.3958Z"
          fill="#EFAE2E"
        />
        <path
          d="M29.5068 27.2034V21.1243H32.974C36.1219 23.8398 38.4199 27.5127 39.4129 31.6858C33.659 31.0649 29.5068 29.2918 29.5068 27.2034Z"
          fill="#E59120"
        />
        <path
          d="M58.3958 21.1243C58.3958 21.9597 57.7305 22.7457 56.5624 23.4259C54.0908 24.8687 49.3695 25.8433 43.9507 25.8433C41.2523 25.8433 38.7266 25.6018 36.5655 25.1792C32.3381 24.3574 29.5068 22.8481 29.5068 21.1244C29.5068 20.4936 29.8851 19.8923 30.5714 19.3441C30.989 19.0089 31.5201 18.6936 32.1497 18.404C34.7655 17.1941 39.0766 16.4043 43.9508 16.4043C51.9286 16.4041 58.3958 18.5171 58.3958 21.1243Z"
          fill="#FFE28F"
        />
        <path
          d="M58.3956 21.1244C58.3956 21.9598 57.7303 22.7459 56.5623 23.426C54.0636 23.9891 51.1237 24.291 48.0558 24.291C44.0453 24.291 40.2554 23.7748 37.3833 22.8359C33.3593 21.5212 32.2837 19.7545 32.1494 18.404C34.7652 17.1941 39.0763 16.4043 43.9505 16.4043C51.9284 16.4042 58.3956 18.5173 58.3956 21.1244Z"
          fill="#FFD243"
        />
        <path
          d="M29.5068 21.1243C29.5068 20.4935 29.8851 19.8922 30.5714 19.344C32.953 20.8483 34.9983 22.8407 36.5655 25.1793C32.3383 24.3573 29.5068 22.848 29.5068 21.1243Z"
          fill="#FFD243"
        />
        <path
          d="M33.6113 12.0129V18.0931C33.6113 20.6997 40.0782 22.8127 48.0555 22.8127C56.0328 22.8127 62.4997 20.6996 62.4997 18.0931V12.0129"
          fill="#EFAE2E"
        />
        <path
          d="M48.0564 16.7325C56.0336 16.7325 62.5004 14.6194 62.5004 12.0127C62.5004 9.40607 56.0336 7.29297 48.0564 7.29297C40.0791 7.29297 33.6123 9.40607 33.6123 12.0127C33.6123 14.6194 40.0791 16.7325 48.0564 16.7325Z"
          fill="#FFE28F"
        />
        <path
          d="M19.8709 54.9428C30.1474 54.9428 38.4782 46.612 38.4782 36.3355C38.4782 26.059 30.1474 17.7283 19.8709 17.7283C9.59443 17.7283 1.26367 26.059 1.26367 36.3355C1.26367 46.612 9.59443 54.9428 19.8709 54.9428Z"
          fill="#EFAE2E"
        />
        <path
          d="M19.8711 51.8221C11.3319 51.8221 4.38477 44.875 4.38477 36.3359C4.38477 27.7968 11.3319 20.8496 19.8711 20.8496C28.4102 20.8496 35.3573 27.7968 35.3573 36.3359C35.3573 44.875 28.4102 51.8221 19.8711 51.8221Z"
          fill="#E59120"
        />
        <path
          d="M19.8714 50.3436C27.6079 50.3436 33.8795 44.072 33.8795 36.3355C33.8795 28.599 27.6079 22.3274 19.8714 22.3274C12.1349 22.3274 5.86328 28.599 5.86328 36.3355C5.86328 44.072 12.1349 50.3436 19.8714 50.3436Z"
          fill="#FFE28F"
        />
        <path
          d="M22.8545 38.4227C22.8545 36.8045 21.5714 35.4804 19.9692 35.4144C19.9462 35.4127 19.9229 35.4118 19.8995 35.4118C19.2584 35.4118 18.7368 34.8901 18.7368 34.249C18.7368 33.6078 19.2584 33.0861 19.8995 33.0861C20.2506 33.0861 20.5794 33.2424 20.8016 33.515C21.1239 33.9106 21.7059 33.9698 22.1018 33.6475C22.4972 33.325 22.5566 32.7429 22.2341 32.3473C21.8607 31.8892 21.3704 31.5591 20.8236 31.3834V30.9116C20.8236 30.4013 20.41 29.9875 19.8995 29.9875C19.3891 29.9875 18.9755 30.4013 18.9755 30.9116V31.3836C17.766 31.7747 16.8887 32.911 16.8887 34.249C16.8887 35.8671 18.1718 37.1913 19.774 37.2574C19.797 37.2591 19.8202 37.26 19.8436 37.26C20.4848 37.26 21.0063 37.7816 21.0063 38.4227C21.0063 39.0639 20.4848 39.5856 19.8436 39.5856C19.4927 39.5856 19.1638 39.4292 18.9415 39.1565C18.619 38.761 18.0369 38.7018 17.6414 39.0244C17.2458 39.3469 17.1867 39.9291 17.5092 40.3246C17.8953 40.7982 18.4063 41.1352 18.9755 41.3061V41.7603C18.9755 42.2706 19.3891 42.6843 19.8995 42.6843C20.41 42.6843 20.8236 42.2706 20.8236 41.7603V41.2692C22.004 40.8614 22.8545 39.7398 22.8545 38.4227Z"
          fill="#E59120"
        />
        <path
          d="M26.5876 37.2599C26.586 37.2599 26.5845 37.2599 26.5829 37.2599C26.0726 37.2573 25.6547 36.8416 25.6572 36.3312C25.6598 35.8224 26.0669 35.4116 26.575 35.4116C26.5766 35.4116 26.5782 35.4116 26.5797 35.4116L26.592 35.4117C27.1022 35.4143 27.514 35.83 27.5114 36.3403C27.509 36.8491 27.0958 37.2599 26.5876 37.2599Z"
          fill="#E59120"
        />
        <path
          d="M13.1687 37.2599C13.1671 37.2599 13.1655 37.2599 13.1639 37.2599C12.6536 37.2573 12.2357 36.8416 12.2383 36.3312C12.2408 35.8224 12.648 35.4116 13.1561 35.4116C13.1576 35.4116 13.1592 35.4116 13.1608 35.4116L13.173 35.4117C13.6833 35.4143 14.095 35.83 14.0925 36.3403C14.0901 36.8491 13.6769 37.2599 13.1687 37.2599Z"
          fill="#E59120"
        />
        <path
          d="M19.8713 22.9101C19.3609 22.9101 18.9473 22.4963 18.9473 21.986V17.8884C18.9473 17.3781 19.3609 16.9644 19.8713 16.9644C20.3817 16.9644 20.7954 17.3781 20.7954 17.8884V21.986C20.7954 22.4963 20.3817 22.9101 19.8713 22.9101Z"
          fill="#FFD243"
        />
        <path
          d="M19.8713 55.7072C19.3609 55.7072 18.9473 55.2934 18.9473 54.7831V50.6855C18.9473 50.1753 19.3609 49.7615 19.8713 49.7615C20.3817 49.7615 20.7954 50.1753 20.7954 50.6855V54.7831C20.7954 55.2935 20.3817 55.7072 19.8713 55.7072Z"
          fill="#FFD243"
        />
        <path
          d="M38.3185 37.2598H34.2209C33.7105 37.2598 33.2969 36.846 33.2969 36.3357C33.2969 35.8254 33.7105 35.4116 34.2209 35.4116H38.3185C38.8289 35.4116 39.2426 35.8254 39.2426 36.3357C39.2426 36.846 38.8289 37.2598 38.3185 37.2598Z"
          fill="#FFD243"
        />
        <path
          d="M5.52164 37.2598H1.42407C0.913656 37.2598 0.5 36.846 0.5 36.3357C0.5 35.8254 0.913656 35.4116 1.42407 35.4116H5.52164C6.03205 35.4116 6.4457 35.8254 6.4457 36.3357C6.4457 36.846 6.03205 37.2598 5.52164 37.2598Z"
          fill="#FFD243"
        />
      </g>
      <defs>
        <clipPath id="clip0_1971_39603">
          <rect width={62} height={62} fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
