export default function MessageIcon() {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0C4.92465 0 0 4.92465 0 11C0 17.0754 4.92465 22 11 22C17.0754 22 22 17.0754 22 11C22 4.92465 17.0754 0 11 0ZM18.1238 8.56496L12.7497 14.4757C12.6036 14.6364 12.3514 14.6382 12.2031 14.4796L9.24344 11.3162C9.12828 11.1934 8.94481 11.1629 8.79656 11.2423L4.41848 13.5876C4.04766 13.7865 3.68371 13.3126 3.9716 13.0054L9.25934 7.36527C9.40285 7.21187 9.64477 7.20801 9.79344 7.35668L12.757 10.3202C12.873 10.4362 13.0509 10.4625 13.1953 10.3851L17.673 7.9866C18.0413 7.79023 18.4044 8.25602 18.1238 8.56496Z"
        fill="#C4C4C4"
      />
    </svg>
  );
}
