import styles from '../../styles/components/shared/amount.module.scss';
import classnames from 'classnames/bind';
import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
const cx = classnames.bind(styles);
interface AmountProps {
  amount: string;
}
const Amount = ({ amount }: AmountProps) => {
  const [formatedAmount, setFormatedAmount] = useState<string>('');
  const [dotIdx, setDotIdx] = useState<number>(-1);
  useEffect(() => {
    const formatedAmount = new BigNumber(amount).gte(10000000)
      ? amount.slice(0, amount.indexOf('.'))
      : amount;
    setFormatedAmount(formatedAmount);
    setDotIdx(formatedAmount.indexOf('.'));
  }, [amount]);

  return (
    <div className={cx('amount')}>
      {dotIdx > 0 ? formatedAmount.slice(0, dotIdx + 1) : formatedAmount}
      {dotIdx > 0 && (
        <span className={cx('suffix')}>
          {formatedAmount.slice(dotIdx + 1, formatedAmount.length)}
        </span>
      )}
    </div>
  );
};

export default Amount;
