import BigNumber from 'bignumber.js';
import { CurrencyConfig } from 'bybet-game-js/lib/schema/BaseGame';
import {
  CrashGameBetType,
  CrashGameResult,
  CrashGameTurnType
} from 'bybet-game-js/lib/schema/CrashGame';
import classnames from 'classnames/bind';
import { find } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AUTH } from '../../../interfaces/Account';
import { RootState, useAppSelector } from '../../../stores';
import { setAuthModalType } from '../../../stores/AccountSlice';
import { toggleModal } from '../../../stores/ModalSlice';
import styles from '../../../styles/components/games/crash/classic-manual.module.scss';
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';
import ArrowDown from '../../Icons/ArrowDown';
import DeleteYellowIcon from '../../Icons/DeleteYellowIcon';
import InfoYellowIcon from '../../Icons/InfoYellowIcon';
import Dropdown from '../../UI/Dropdown';
import Input from '../../UI/Input';
import Tooltip from '../../UI/Tooltip';
import MinMaxSlider from '../MinMaxSlider';

const cx = classnames.bind(styles);

const autoCashInit = 100.0;
const amountInit = 1.0;

const houseEdge = 1;

const minAutoCash = 1.01;
const maxAutoCash = 1000000;

interface PropsType {
  currencyConfig: CurrencyConfig | undefined;
  resultInfo: CrashGameResult | undefined;
}

export default function ClassicManual({ currencyConfig, resultInfo }: PropsType) {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: { amount: `${amountInit}`, autoCash: autoCashInit },
    mode: 'onBlur'
  });

  const [isBettedNextRound, setIsBettedNextRound] = useState(false);
  const [isBetted, setIsBetted] = useState(false);
  const [chance, setChance] = useState(new BigNumber(100 - houseEdge).div(autoCashInit).toFixed(2));
  const currency = useSelector((state: RootState) => state.account.currency);
  const crashGameIns = useAppSelector((state: RootState) => state.crashGame.crashGameIns);
  const currentTurn = useAppSelector((state: RootState) => state.crashGame.currentTurn);
  const accessToken = useAppSelector((state: RootState) => state.account.accountInfo.accessToken);
  const dispatch = useDispatch();

  const onSubmit = (data: any) => console.log(data);

  // const getGameProfile = async () => {
  //   try {
  //     let res = await crashGameIns?.getGameInformation();
  //     if (res) {
  //       let currencyConfig = find(res.currencyConfig, { symbol: currency.symbol?.toUpperCase() });
  //       setCurrencyConfig(currencyConfig);
  //       setValue('amount', currencyConfig?.minBetAmount || '1');
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const onClickButtonAuth = (type: AUTH) => {
    dispatch(setAuthModalType(type));
    dispatch(toggleModal({ modalName: 'authModal', state: true }));
  };

  const sendBet = async () => {
    try {
      const values = getValues();
      let amount = values.amount;
      if (new BigNumber(amount).lt(currencyConfig?.minBetAmount || '0')) {
        toast.error('Amount should be greater than min bet amount');
        setIsBettedNextRound(false);
      } else if (new BigNumber(amount).gt(currencyConfig?.maxBetAmount || '0')) {
        toast.error('Amount should be less than max bet amount');
        setIsBettedNextRound(false);
      } else {
        crashGameIns?.sendBet({
          symbol: currency.symbol,
          options: [
            {
              amount: `${values.amount}`,
              type: CrashGameBetType.MANUAL,
              value: `${values.autoCash}`
            }
          ]
        });
        setIsBetted(true);
      }

      // setIsBettedNextRound(false);
    } catch (error: any) {
      toast.error(capitalizeFirstLetter(error?.message));
    }
  };

  const handleBet = () => {
    if (accessToken.length === 0) {
      onClickButtonAuth(AUTH.SIGN_IN);
      setIsBettedNextRound(false);
    } else if (
      currentTurn === CrashGameTurnType.WAITING_FOR_RESULT ||
      currentTurn === CrashGameTurnType.IDLE
    ) {
      setIsBettedNextRound(true);
    } else {
      sendBet();
      setIsBettedNextRound(false);
    }
  };

  const handleCancelBet = () => {
    setIsBettedNextRound(false);
  };

  const handleSeparate = () => {
    let amount = getValues('amount');
    setValue('amount', new BigNumber(amount).div(2).toFixed(2));
  };

  const handleDoubled = () => {
    let amount = getValues('amount');
    setValue('amount', new BigNumber(amount).multipliedBy(2).toFixed());
  };

  const handleClearAutoCash = () => {
    setValue('autoCash', autoCashInit);
  };

  const handleCashOut = () => {
    crashGameIns?.cashOut();
    setIsBetted(false);
  };

  const handleBlurAutoCashOut = (e: any) => {
    let autoCashOut = getValues('autoCash');
    if (new BigNumber(autoCashOut).lt(minAutoCash)) {
      autoCashOut = minAutoCash;
      setValue('autoCash', minAutoCash);
    }
    if (new BigNumber(autoCashOut).gt(maxAutoCash)) {
      autoCashOut = maxAutoCash;
      setValue('autoCash', maxAutoCash);
    }

    if (new BigNumber(autoCashOut).isNaN() || new BigNumber(autoCashOut).lte(0)) {
      setChance('0');
    } else {
      setChance(new BigNumber(100 - houseEdge).div(autoCashOut).toFixed(2));
    }
  };

  useEffect(() => {
    let amount = getValues('amount');
    if (currencyConfig?.minBetAmount && new BigNumber(amount).lt(currencyConfig?.minBetAmount)) {
      setValue('amount', currencyConfig?.minBetAmount);
    }
    if (currencyConfig?.minBetAmount && new BigNumber(amount).gt(currencyConfig?.maxBetAmount)) {
      setValue('amount', currencyConfig?.maxBetAmount);
    }
  }, [currencyConfig]);

  useEffect(() => {
    if (currentTurn === CrashGameTurnType.WAITING_FOR_BET && isBettedNextRound) {
      sendBet();
      // setIsBettedNextRound(false);
    }
    if (currentTurn === CrashGameTurnType.FINISHED && isBetted) {
      setIsBetted(false);
    }
  }, [currentTurn, isBettedNextRound]);

  useEffect(() => {
    if (crashGameIns) {
      setIsBetted(crashGameIns.isPlaying());

      crashGameIns.onError((code, error) => {
        toast.error(capitalizeFirstLetter(error || 'error'));
        setIsBetted(false);
      });
    }
  }, [crashGameIns, currency.symbol]);

  useEffect(() => {
    if (isBetted) {
      setIsBettedNextRound(false);
    }
  }, [isBetted]);

  useEffect(() => {
    if (resultInfo) {
      setIsBetted(false);
    }
  }, [resultInfo]);

  const showButtonAction = () => {
    if (!isBetted && !isBettedNextRound) {
      if (
        currentTurn === CrashGameTurnType.WAITING_FOR_RESULT ||
        currentTurn === CrashGameTurnType.FINISHED
      ) {
        return (
          <div className={cx('bet-button')} onClick={handleBet}>
            <p>Bet</p>
            <p>(Next round)</p>
          </div>
        );
      }

      if (currentTurn === CrashGameTurnType.WAITING_FOR_BET) {
        return (
          <div className={cx('bet-button')} onClick={handleBet}>
            <p>Bet</p>
          </div>
        );
      }
    } else if (!isBetted) {
      if (
        currentTurn === CrashGameTurnType.WAITING_FOR_RESULT ||
        currentTurn === CrashGameTurnType.FINISHED ||
        currentTurn === CrashGameTurnType.IDLE
      )
        return (
          <div className={cx('bet-button')} onClick={handleCancelBet}>
            <p>Loading...</p>
            <p>(Cancel)</p>
          </div>
        );
    } else {
      if (currentTurn === CrashGameTurnType.WAITING_FOR_RESULT) {
        return (
          <div className={cx('bet-button')} onClick={handleCashOut}>
            <p>Cash Out</p>
          </div>
        );
      }
      if (
        currentTurn === CrashGameTurnType.WAITING_FOR_BET ||
        currentTurn === CrashGameTurnType.COLLECTING_BET
      ) {
        return <div className={cx('bet-button')}>Loading...</div>;
      }
    }

    return (
      <div className={cx('bet-button')} onClick={handleBet}>
        <p>Bet</p>
        <p>(Next round)</p>
      </div>
    );
  };

  return (
    <form className={cx('form-container')} onSubmit={handleSubmit(onSubmit)}>
      {currentTurn && showButtonAction()}

      <div className={cx('form-input')}>
        <div className={cx('amount-input-container')}>
          <div className={cx('amount-label')}>
            <p>Amount</p>
            <Tooltip
              title={`Max Profit: ${new BigNumber(currencyConfig?.maxProfit || '0').toFixed(2)}`}>
              <InfoYellowIcon />
            </Tooltip>
          </div>

          <Input
            prefix={
              currency?.symbol ? (
                <img
                  src={`/images/tokens/${currency?.symbol?.toLowerCase()}.svg`}
                  alt={currency?.symbol || 'token'}
                  className={cx('tokens')}
                />
              ) : null
            }
            suffix={
              <div className={cx('suffix-group')}>
                <div className={cx('suffix-btn')} onClick={handleSeparate}>
                  <p>/2</p>
                </div>
                <div className={cx('suffix-btn')} onClick={handleDoubled}>
                  <p>x2</p>
                </div>
                <div>
                  <Dropdown
                    name="classicAmount"
                    overlay={
                      <MinMaxSlider
                        minAmount={currencyConfig?.minBetAmount}
                        maxAmount={currencyConfig?.maxBetAmount}
                        betAmount={getValues('amount')}
                        setBetAmount={(amount) => setValue('amount', amount)}
                      />
                    }>
                    <div className={cx('suffix-btn-arrows', 'arrows')}>
                      <div className={cx('arrow-btn', 'arrow-up')}>
                        <ArrowDown width={10} height={6} />
                      </div>
                      <div className={cx('arrow-btn')}>
                        <ArrowDown width={10} height={6} />
                      </div>
                    </div>
                  </Dropdown>
                </div>
              </div>
            }
            type="number"
            {...register('amount')}
          />
        </div>

        <div className={cx('auto-cash-input-container')}>
          <div className={cx('label-container')}>
            <p>Auto cash out</p>
            <p className={cx('change-text')}>
              Chance <span>{chance}%</span>
            </p>
          </div>

          <Input
            suffix={
              <div className={cx('suffix-group')}>
                <div className={cx('suffix-auto-cash-btn')} onClick={handleClearAutoCash}>
                  <p>
                    <DeleteYellowIcon />
                  </p>
                </div>
              </div>
            }
            handleBlur={handleBlurAutoCashOut}
            type="number"
            {...register('autoCash')}
          />
        </div>
      </div>
    </form>
  );
}
