import React from 'react';
import { ROUTES } from '../../constant/routes';
import DepositIcon from '../Icons/DepositIcon';
import styles from '../../styles/components/wallet/tab-wallet.module.scss';
import classNames from 'classnames/bind';
import { useLocation, useNavigate } from 'react-router-dom';
import WithdrawIcon from '../Icons/WithdrawIcon';
import TransactionWalletIcon from '../Icons/TransactionWalletIcon';
import InternalTransferIcon from '../Icons/InternalTransferIcon';

const cx = classNames.bind(styles);

const listTab = [
  {
    key: 'deposit',
    title: 'Deposit',
    linkTo: ROUTES.WALLET.DEPOSIT,
    query: '',
    icon: (active: boolean) => <DepositIcon active={active} />
  },
  {
    key: 'withdraw',
    title: 'Withdraw',
    linkTo: ROUTES.WALLET.WITHDRAW,
    query: '',
    icon: (active: boolean) => <WithdrawIcon active={active} />
  },
  {
    key: 'internal-transfers',
    title: 'Internal Transfers',
    linkTo: ROUTES.WALLET.INTERNAL_TRANSFERS,
    query: '',
    icon: (active: boolean) => <InternalTransferIcon active={active} />
  },
  {
    key: 'transactions',
    title: 'Transactions',
    linkTo: ROUTES.WALLET.TRANSACTIONS,
    query: 'category=deposit',
    icon: (active: boolean) => <TransactionWalletIcon active={active} />
  }
];

const TabWallet = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClickPushParam = (params: string) => {
    navigate(params);
  };

  return (
    <div className={cx('list-tab')}>
      {listTab.map((item) => {
        const tabActive = location.pathname === item.linkTo;
        return (
          <button
            onClick={() => onClickPushParam(`${item.linkTo}?${item.query}`)}
            className={cx('item-tab', tabActive && 'item-active')}
            key={item.linkTo}>
            <div className={cx('icon')}>{item.icon(tabActive)}</div>
            <div>{item.title}</div>
          </button>
        );
      })}
    </div>
  );
};

export default TabWallet;
