import NotificationCenterClient, {
  NotificationCenterClientOptions
} from 'cht-notification-center-js';
import { NotificationMessage } from 'cht-notification-center-js/lib/schema';
import classnames from 'classnames/bind';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../stores';
import { setUnseenCount } from '../../stores/NotificationSlice';
import styles from '../../styles/Layout/notification.module.scss';
import ClosedIcon from '../Icons/ClosedIcon';

const cx = classnames.bind(styles);

const DATE_FORMAT = 'YYYY-MM-DD hh:mm a';

interface PropsType {
  handleShowNoti?: () => void;
  isShowNoti: boolean;
}

export default function Notification({ handleShowNoti, isShowNoti = false }: PropsType) {
  const { account, userInfo } = useAppSelector((state) => ({
    userInfo: state.userInfo.userInfo,
    account: state.account.accountInfo
  }));
  const [notifications, setNotifications] = useState<NotificationMessage[]>([]);
  const [client, setClient] = useState<NotificationCenterClient>();
  const dispatch = useDispatch();

  const getMessages = async (client: NotificationCenterClient) => {
    try {
      const messages = await client.getMessages();
      if (messages) {
        setNotifications(messages);
      }
      await getUnSeenCount(client);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getUnSeenCount = async (client: NotificationCenterClient) => {
    try {
      const count = await client.getUnseenMessageCount();
      if (typeof count === 'number') {
        dispatch(setUnseenCount(count));
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleSeenNoti = async (client: NotificationCenterClient) => {
    try {
      await Promise.allSettled(
        notifications.filter((noti) => !noti.seen).map((noti) => client.seenMessage(noti.id))
      );
      getMessages(client);
    } catch (error: any) {
      toast.error(error?.message, error);
    }
  };

  useEffect(() => {
    if (account.accessToken) {
      const options = {
        host: process.env.REACT_APP_NOTIFICATION_HOST,
        secure: true
      } as NotificationCenterClientOptions;
      const client = new NotificationCenterClient(options);
      client.setTokenId(account.accessToken);
      setClient(client);
      getMessages(client);
    }
  }, [account.accessToken]);

  useEffect(() => {
    if (isShowNoti && client) {
      handleSeenNoti(client);
    }
  }, [isShowNoti, client]);

  return (
    <div className={cx('root')}>
      <div className={cx('header')}>
        <p>Notification</p>
        <div className={cx('close')} onClick={handleShowNoti}>
          <ClosedIcon />
        </div>
      </div>
      <div className={cx('body')}>
        {notifications.map((noti) => (
          <div className={cx('item-container', noti.seen ? 'seen' : 'unseen')} key={noti.id}>
            <div className={cx('title-container')}>
              <div className={cx('title-info')}>
                <span>{noti.title}</span>
              </div>
              <div className={cx('logo')}>
                <img src="/images/tokens/bbet.svg?v=2" alt="bbet" />
                <p>Bybet.GAME Official</p>
              </div>
              <div className={cx('time')}>{moment(noti.createdAt).format(DATE_FORMAT)}</div>
            </div>

            <div className={cx('content-container')}>
              <div className={cx('greeting')}>
                {/* <WavingHandIcon /> */}
                <img src="/images/waving-hand-icon.svg" alt="waving-hand" />
                <p>Hi {userInfo?.nickName}</p>
              </div>
              <div className={cx('info')}>
                <span>{noti.body}</span>
              </div>

              {/* <div className={cx('link-to')}>
                <div className={cx('click-here')}>
                  <p>Click here</p>
                  <NaturalUserInterfaceIcon />
                </div>
                <div className={cx('join-us')}>
                  <p>Join us on Telegram for fun & games during the downtime</p>
                </div>
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
