export const faqData = [
  {
    key: 1,
    question: 'What is Bybet?',
    answer:
      'Bybet is the leading online gaming platform that enables players to use their cryptocurrencies for an unrivaled variety of NFT, casino games, slots, and sports betting'
  },
  {
    key: 2,
    question: 'What is Bybet Token (BBET)?',
    answer:
      'Bybet is proud to present our unique governance token BBET. A BBET token, a BEP20-based security token, is a digital asset purpose-built for our platform. BBET tokens are a payment method, a means for investors to receive rewards from liquidity and affiliate programs, and a way to exercise voting rights.'
  },
  {
    key: 3,
    question: 'Is my money safe with you?',
    answer:
      'At Bybet, all customer deposits are held in an insured escrow account, either in BBET or other cryptocurrencies. Your deposit is fully secured and available for withdrawal on a daily basis, with prompt payouts within 24-48 hours. All online transactions are coded and encrypted for your protection.'
  },
  {
    key: 4,
    question: 'What do we bet on?',
    answer:
      'We’re a global, no-limit betting platform, that’s why you can bet on many markets from sports, casino games, world events, and more.'
  },
  {
    key: 5,
    question: 'Where do we bet?',
    answer: 'You can click on the Dapp button above to participate in our casino & sports betting.'
  },
  {
    key: 6,
    question: 'When is the launch?',
    answer: 'We’ll announce the specific date of our Fair Launch on PinkSale as soon as possible.'
  },
  {
    key: 7,
    question: 'Where can I find your documentation?',
    answer:
      'This is our documentation (whitepaper): <a href="https://bybet.gitbook.io/bybet/" target="_blank">https://bybet.gitbook.io/bybet/</a>'
  },
  {
    key: 8,
    question: 'How can I contact you?',
    answer:
      'This is our <a href="https://linktr.ee/bybet" target="_blank">Linktree</a> where you can find all our social platforms. You can then join and ask for support, there’ll always be staff available to help answer your questions.'
  }
];
