import styles from '../../styles/Layout/footer.module.scss';
import classnames from 'classnames/bind';
import HelpIcon from '../Icons/HelpIcon';
import BusinessIcon from '../Icons/BusinessIcon';
import TelegramIcon from '../Icons/TelegramIcon';
import TwitterIcon from '../Icons/TwitterIcon';
import MediumIcon from '../Icons/MediumIcon';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constant/routes';

const cx = classnames.bind(styles);

export default function Footer() {
  const location = useLocation();
  const navigate = useNavigate();
  const networks = [
    { key: 'tele', icon: <TelegramIcon />, link: 'https://t.me/ByBetChat' },
    {
      key: 'twitter',
      icon: <TwitterIcon />,
      link: 'https://twitter.com/ByBetOfficial'
    },
    // { key: "discord", icon: <DiscordIcon />, link: "" },
    // { key: "reddit", icon: <RedditIcon />, link: "" },
    {
      key: 'medium',
      icon: <MediumIcon />,
      link: 'https://medium.com/@bybetofficial'
    }
  ];

  return location.pathname === ROUTES.AFFILIATE ? (
    <div className={cx('footer-root')}>
      <div className={cx('footer-container-affiliate')}>
        <div className={cx('footer-header')}>
          <div className={cx('image-people-jump')}>
            <img src="/images/people-jump.svg" alt="" />
          </div>
          <div className={cx('slogan')}>
            <div className={cx('text')}>
              Invite <span>Friends</span>
            </div>
            <div className={cx('slogan-text')}>
              <div className={cx('text')}>
                To <span>My Referral</span> Now
              </div>
            </div>
          </div>
          <div className={cx('image-friends')}>
            <img src="/images/friends.svg" alt="" />
          </div>
        </div>
        <div className={cx('footer-infor')}>
          <div className={cx('contact-us')}>
            <p>CONTACT US</p>
            <div className={cx('footer-row')}>
              <HelpIcon />{' '}
              <p className={cx('contact-us-text')}>
                Support 24/7:{' '}
                <a className={cx('footer-link')} target={'_blank'} rel="noreferrer">
                  support@bybet.io
                </a>
              </p>
            </div>
            <div className={cx('footer-row')}>
              <BusinessIcon />{' '}
              <p className={cx('contact-us-text')}>
                Info:{' '}
                <a className={cx('footer-link')} target={'_blank'} rel="noreferrer">
                  info@bybet.io
                </a>
              </p>
            </div>
          </div>
          <div className={cx('social-networks-container')}>
            <div className={cx('footer-row')}>
              {networks.map((network) => (
                <a key={network.key} href={network.link} target={'_blank'} rel="noreferrer">
                  {network.icon}
                </a>
              ))}
            </div>
            <p>Copyright © 2022 BYBET All Right Reserved</p>
          </div>
        </div>
      </div>
      <div className={cx('scoin-1')}>
        <img src="/images/scoin.svg" alt="" />
      </div>
      <div className={cx('scoin-2')}>
        <img src="/images/scoin.svg" alt="" />
      </div>
      <div className={cx('scoin-3')}>
        <img src="/images/coin-affiliate.svg" alt="" />
      </div>
      <div className={cx('scoin-4')}>
        <img src="/images/scoin.svg" alt="" />
      </div>
      <div className={cx('scoin-5')}>
        <img src="/images/scoin.svg" alt="" />
      </div>
      <div className={cx('scoin-6')}>
        <img src="/images/coin-affiliate.svg" alt="" />
      </div>
      <div className={cx('scoin-7')}>
        <img src="/images/coin-affiliate.svg" alt="" />
      </div>
    </div>
  ) : (
    <div className={cx('footer-root')}>
      <div className={cx('footer-container')}>
        <div className={cx('contact-us')}>
          <img src="/images/logoNoColor.svg" alt="logoNoColor" />
          <p>CONTACT US</p>
          <div className={cx('footer-row')}>
            <HelpIcon />{' '}
            <p className={cx('contact-us-text')}>
              Support 24/7:{' '}
              <a className={cx('footer-link')} target={'_blank'} rel="noreferrer">
                support@bybet.io
              </a>
            </p>
          </div>
          <div className={cx('footer-row')}>
            <BusinessIcon />{' '}
            <p className={cx('contact-us-text')}>
              Info:{' '}
              <a className={cx('footer-link')} target={'_blank'} rel="noreferrer">
                info@bybet.io
              </a>
            </p>
          </div>
        </div>
        <div className={cx('contact-us')}>
          <p>Originals</p>
          <div className={cx('footer-row')}>
            <a
              className={cx('footer-link-support')}
              target={'_blank'}
              rel="noreferrer"
              href={`${ROUTES.CRASH.INDEX}`}>
              Crash
            </a>
          </div>
          <div className={cx('footer-row')}>
            <a
              className={cx('footer-link-support')}
              target={'_blank'}
              rel="noreferrer"
              href={`${ROUTES.RING_OF_FORTUNE.INDEX}`}>
              Ring of Fortune
            </a>
          </div>
          <div className={cx('footer-row')}>
            <a
              className={cx('footer-link-support')}
              target={'_blank'}
              rel="noreferrer"
              href={`${ROUTES.CLASSIC_DICE.INDEX}`}>
              Classic Dice
            </a>
          </div>
        </div>
        <div className={cx('contact-us')}>
          <a href={`${ROUTES.HELP.GENERAL}`}>Help Center</a>
          <div className={cx('footer-row')}>
            <a
              className={cx('footer-link-support')}
              target={'_blank'}
              rel="noreferrer"
              href={`${ROUTES.HELP.TERMS}`}>
              Term of services
            </a>
          </div>
          <div className={cx('footer-row')}>
            <a
              className={cx('footer-link-support')}
              target={'_blank'}
              rel="noreferrer"
              href={`${ROUTES.HELP.FAQ}`}>
              FAQ
            </a>
          </div>
          <div className={cx('footer-row')}>
            <a
              className={cx('footer-link-support')}
              target={'_blank'}
              rel="noreferrer"
              href={`${ROUTES.HELP.SUPPORT}`}>
              Support
            </a>
          </div>
        </div>
        <div className={cx('social-networks-container')}>
          <div className={cx('footer-row')}>
            {networks.map((network) => (
              <a key={network.key} href={network.link} target={'_blank'} rel="noreferrer">
                {network.icon}
              </a>
            ))}
          </div>
          <p>Copyright © 2022 BYBET All Right Reserved</p>
        </div>
      </div>
    </div>
  );
}
