import classNames from 'classnames/bind';
import styles from '../../styles/components/games/bets-table.module.scss';
import TableGame from '../UI/TableGame';
import ArrowDown from '../Icons/ArrowDown';
import BigNumber from 'bignumber.js';
import { Columns } from '../../interfaces/Table';
import { useAppDispatch, useAppSelector } from '../../stores';
import { useEffect } from 'react';
import { getAssets } from '../../stores/AssetsSlice';
import CurrencyAmount from '../UI/CurrencyAmount';
import { find } from 'lodash';

const cx = classNames.bind(styles);

interface BetData {
  id: string;
  name: string;
  time: string;
  payout: string;
  profit: number;
}
interface BetTableProps {
  dataTable: BetData[];
  isMyBets?: boolean;
  isShowMore?: boolean;
  handleShowMore?: (isShowMore: boolean) => void;
}
export default function BetsTable({
  dataTable,
  isMyBets,
  isShowMore,
  handleShowMore
}: BetTableProps) {
  const { metaverseClient, assets } = useAppSelector((state) => ({
    metaverseClient: state.app.metaverseClient,
    assets: state.assets.assets
  }));
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAssets({ metaverseClient, assetQuery: { chain: 'CHT' } }));
  }, [metaverseClient]);

  const columns = [
    {
      key: 'id',
      title: 'Bet ID',
      align: 'center',
      render: (id: any) => <div className={cx('normal-text')}>{id}</div>
    },
    {
      key: 'name',
      title: 'Player',
      align: 'center',
      render: (name: any) => <div className={cx('player')}>{name}</div>
    },
    {
      key: 'amount',
      title: 'Bet',
      align: 'end',
      render: (amount: any) => <div className={cx('normal-text', 'amount')}>{amount}</div>
    },
    {
      key: 'time',
      title: 'Time',
      align: 'center',
      render: (time: any) => <div className={cx('normal-text')}>{time}</div>
    },
    {
      key: 'payout',
      title: 'Payout',
      align: 'center',
      render: (payout: any) => <div className={cx('normal-text')}>{payout}</div>
    },
    {
      key: 'profit',
      title: 'Profit',
      align: 'end',
      render: ({ profit, symbol }: { profit: string | number; symbol: string }) => (
        <div className={cx('normal-text', 'profit', 'amount')}>
          <span>
            <CurrencyAmount
              color={new BigNumber(profit).gt(0) ? '#1bba64' : '#f82814'}
              amount={new BigNumber(profit).toString()}
              decimalPlaces={find(assets, { symbol })?.decimalPlaces}
            />
          </span>
          <img src={`/images/tokens/${symbol.toLowerCase()}.svg?v=2`} alt={symbol.toLowerCase()} />
        </div>
      )
    }
  ] as Columns[];
  return (
    <TableGame
      columns={
        !isMyBets
          ? columns.filter((col) => col.key !== 'amount')
          : columns.filter((col) => col.key !== 'name')
      }
      dataTable={dataTable}
      footerOutSide={
        dataTable.length >= 10 ? (
          <div className={cx('footer-container')}>
            <div
              className={cx('btn', isShowMore ? 'btn-show-less' : 'btn-show-more')}
              onClick={() => {
                if (handleShowMore) {
                  handleShowMore(!isShowMore);
                }
              }}>
              {isShowMore ? <p>Show less</p> : <p>Show more</p>}
              <ArrowDown />
            </div>
          </div>
        ) : null
      }
    />
  );
}
