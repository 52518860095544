import DiceIcon from '../../Icons/ClassicDiceGame/Dice';
import styles from '../../../styles/components/games/dice.module.scss';
import classnames from 'classnames/bind';
import DiceShadow from '../../Icons/ClassicDiceGame/DiceShadow';
import { motion } from 'framer-motion';
import CountUp from 'react-countup';
import { DiceGameBetType } from 'bybet-game-js/lib/schema/DiceGame';
import { useEffect, useState } from 'react';

const cx = classnames.bind(styles);
interface DiceProps {
  min: number;
  max: number;
  value: number;
  onChange: Function;
  resultNumber: {
    prev: number;
    current: number;
  };
  mode: DiceGameBetType;
  result?: any;
  prevResult?: number;
}
enum SLIDER_COLORS {
  GREEN = '#00BE57',
  YELLOW = '#FFE036'
}
const MILESTONES = [0, 25, 50, 75, 100];
const Dice = ({ min, max, value, onChange, resultNumber, mode, result }: DiceProps) => {
  const colorLeftSide =
    mode === DiceGameBetType.ROLL_UNDER ? SLIDER_COLORS.GREEN : SLIDER_COLORS.YELLOW;
  const colorRightSide =
    mode === DiceGameBetType.ROLL_UNDER ? SLIDER_COLORS.YELLOW : SLIDER_COLORS.GREEN;
  const [displayVictoryAnimation, setDisplayVictoryAnimation] = useState(false);

  useEffect(() => {
    setDisplayVictoryAnimation(false);
    if (result?.isWin) {
      setTimeout(() => {
        setDisplayVictoryAnimation(true);
      }, 1000);
    }
  }, [JSON.stringify(result)]);

  return (
    <div className={cx('dice-animation')}>
      <motion.div
        className={cx('dice-wrapper')}
        animate={{
          x: result ? `${resultNumber.current - resultNumber.prev}%` : 0
        }}
        transition={{ duration: 1 }}>
        <div className={cx('result')}>
          <CountUp
            end={resultNumber.current}
            duration={1}
            preserveValue={true}
            separator=" "
            decimals={2}
            delay={0}
            decimal=".">
            {({ countUpRef }) => (
              <div>
                <span
                  style={{
                    color: result ? (result.isWin ? '#32FF90' : '#F82814') : 'white'
                  }}
                  ref={countUpRef}
                />
              </div>
            )}
          </CountUp>
        </div>
        <div className={cx('dice-icon-wrapper')}>
          <img
            className={cx('victory-bg', displayVictoryAnimation ? 'show' : 'hidden')}
            src="/images/games/victory-bg.png"
          />
          <div className={cx('dice-icon')}>
            <DiceIcon />
            <div className={cx('shadow', displayVictoryAnimation ? 'hidden' : 'show')}>
              <DiceShadow />
            </div>
          </div>
        </div>
      </motion.div>
      <div className={cx('slider-container')}>
        <input
          style={{
            background: `linear-gradient(to right,${colorLeftSide}  0%, ${colorLeftSide} ${value}%,${colorRightSide} ${value}%, ${colorRightSide} 100%)`
          }}
          type="range"
          min={min}
          max={max}
          value={value}
          className={cx('slider')}
          id="classic-dice"
          onChange={(event) => onChange(event.target.value)}
        />
      </div>
      <div className={cx('milestone')}>
        {MILESTONES.map((milestone) => (
          <div key={milestone}>{milestone}</div>
        ))}
      </div>
    </div>
  );
};
export default Dice;
