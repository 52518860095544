import React from 'react';

export default function InfoYellowIcon() {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1706_31074)">
        <path
          d="M8 7.0625V11.2812M8 4.25V5.1875"
          stroke="#FFE036"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 14.5625C11.6244 14.5625 14.5625 11.6244 14.5625 8C14.5625 4.37563 11.6244 1.4375 8 1.4375C4.37563 1.4375 1.4375 4.37563 1.4375 8C1.4375 11.6244 4.37563 14.5625 8 14.5625Z"
          stroke="#FFE036"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1706_31074">
          <rect width={15} height={15} fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
