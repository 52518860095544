import React from 'react';

export default function SearchIcon() {
  return (
    <svg width={16} height={17} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2167 12.1491L14.1273 14.0587C14.2164 14.151 14.2657 14.2745 14.2646 14.4028C14.2635 14.531 14.2121 14.6537 14.1214 14.7444C14.0307 14.8351 13.908 14.8866 13.7797 14.8877C13.6515 14.8888 13.5279 14.8395 13.4357 14.7504L11.5251 12.8398C10.2765 13.9095 8.66217 14.454 7.02079 14.3591C5.3794 14.2643 3.83856 13.5373 2.72159 12.3309C1.60462 11.1244 0.998345 9.53224 1.02999 7.88842C1.06163 6.24459 1.72873 4.67692 2.8913 3.51435C4.05387 2.35178 5.62155 1.68468 7.26537 1.65303C8.90919 1.62139 10.5014 2.22767 11.7078 3.34464C12.9143 4.46161 13.6412 6.00245 13.7361 7.64383C13.831 9.28522 13.2864 10.8996 12.2167 12.1481V12.1491ZM7.38783 13.3915C8.81486 13.3915 10.1835 12.8246 11.1925 11.8156C12.2016 10.8065 12.7685 9.43791 12.7685 8.01088C12.7685 6.58384 12.2016 5.21525 11.1925 4.20619C10.1835 3.19712 8.81486 2.63023 7.38783 2.63023C5.96079 2.63023 4.59221 3.19712 3.58314 4.20619C2.57407 5.21525 2.00719 6.58384 2.00719 8.01088C2.00719 9.43791 2.57407 10.8065 3.58314 11.8156C4.59221 12.8246 5.96079 13.3915 7.38783 13.3915Z"
        fill="#A2A3A4"
      />
    </svg>
  );
}
