export default function CommissionRewardDolar() {
  return (
    <svg width={61} height={51} viewBox="0 0 61 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M60.2605 34.6084L60.2416 40.2625L42.52 50.5611L42.5389 44.9071L60.2605 34.6084Z"
        fill="#5EAF5F"
      />
      <path
        d="M42.5387 44.907L42.5198 50.561L0.740234 26.4397L0.759206 20.7856L42.5387 44.907Z"
        fill="#7BC578"
      />
      <path
        d="M60.2603 34.6084L42.5387 44.9071L0.759277 20.7858L18.4809 10.4871L60.2603 34.6084Z"
        fill="#98D095"
      />
      <path
        d="M45.1122 33.5861L44.6298 33.8665C45.0564 34.2505 45.0592 34.7164 44.5839 34.9927L43.9636 34.6346C44.1791 34.5093 44.0731 34.2246 43.7368 34.0304C43.3931 33.832 42.9013 33.7715 42.6858 33.8968C42.4707 34.0218 42.5763 34.3067 42.92 34.5052C43.6149 34.9064 43.7155 35.4972 43.1512 35.8252C42.6759 36.1015 41.8716 36.1014 41.2146 35.8512L40.7319 36.1316L40.1118 35.7736L40.5943 35.4931C40.1604 35.1133 40.1576 34.6475 40.6329 34.3713L41.2531 34.7294C41.0376 34.8547 41.1508 35.135 41.4945 35.3335C41.8308 35.5277 42.3154 35.5923 42.5309 35.4671C42.7536 35.3376 42.6401 35.0574 42.3038 34.8633C41.6089 34.4621 41.5012 33.8666 42.0656 33.5386C42.5409 33.2625 43.3451 33.2626 44.0095 33.5085L44.492 33.228L45.1122 33.5861Z"
        fill="#46A046"
      />
      <path
        d="M20.9083 19.612L20.4257 19.8924C20.8523 20.2764 20.8626 20.7466 20.3873 21.0228L19.7597 20.6603C19.975 20.5352 19.8766 20.2547 19.5329 20.0563C19.1891 19.8578 18.6972 19.7974 18.4818 19.9226C18.2667 20.0476 18.3722 20.3326 18.7161 20.531C19.411 20.9322 19.519 21.5274 18.9546 21.8553C18.4794 22.1316 17.6675 22.1272 17.0104 21.8771L16.528 22.1575L15.9077 21.7994L16.3903 21.519C15.9563 21.1392 15.9536 20.6733 16.4289 20.3971L17.0491 20.7552C16.8336 20.8804 16.9468 21.1609 17.2906 21.3594C17.6343 21.5578 18.1114 21.6182 18.327 21.493C18.5497 21.3635 18.4435 21.0876 18.0998 20.8891C17.4048 20.4878 17.2973 19.8925 17.8616 19.5645C18.3369 19.2884 19.1411 19.2884 19.8054 19.5343L20.288 19.2539L20.9083 19.612Z"
        fill="#46A046"
      />
      <path
        d="M20.2053 13.8481C21.1544 14.396 21.1593 15.276 20.2164 15.8241C19.2812 16.3676 17.754 16.372 16.8051 15.8241C15.8635 15.2805 15.8587 14.3917 16.7939 13.8481C17.7368 13.3001 19.2637 13.3044 20.2053 13.8481Z"
        fill="#46A046"
      />
      <path
        d="M36.6845 24.9961L35.4895 25.6906C36.5522 26.6313 36.5589 27.7787 35.3783 28.4648L33.8464 27.5803C34.3808 27.2697 34.1162 26.5752 33.2717 26.0876C32.4349 25.6044 31.2274 25.4492 30.6929 25.7597C30.1585 26.0703 30.4305 26.7691 31.2675 27.2524C32.9862 28.2447 33.2476 29.703 31.8519 30.5141C30.679 31.1957 28.6903 31.2 27.0705 30.5831L25.8678 31.282L24.3359 30.3976L25.5386 29.6986C24.4682 28.7624 24.4618 27.6061 25.6347 26.9245L27.1666 27.809C26.632 28.1196 26.9042 28.8185 27.7412 29.3017C28.5855 29.7892 29.7855 29.9403 30.3201 29.6297C30.8545 29.3191 30.5899 28.6244 29.7454 28.137C28.0342 27.149 27.7726 25.6822 29.161 24.8753C30.3416 24.1892 32.3301 24.1937 33.9576 24.8061L35.1526 24.1116L36.6845 24.9961Z"
        fill="#46A046"
      />
      <path
        d="M9.96764 19.7976C10.9166 20.3455 10.9216 21.2255 9.97905 21.7732C9.04347 22.317 7.52398 22.317 6.575 21.7692C5.63347 21.2255 5.62095 20.341 6.55665 19.7973C7.49918 19.2496 9.02611 19.2539 9.96764 19.7976Z"
        fill="#46A046"
      />
      <path
        d="M54.4447 33.6162C55.3938 34.1641 55.3988 35.0443 54.4559 35.5922C53.5205 36.1357 51.9935 36.1402 51.0445 35.5922C50.103 35.0486 50.1056 34.1641 51.0408 33.6205C51.9838 33.0725 53.5032 33.0725 54.4447 33.6162Z"
        fill="#46A046"
      />
      <path
        d="M44.2071 39.5659C45.156 40.1138 45.161 40.994 44.2185 41.5417C43.2829 42.0855 41.7634 42.0854 40.8144 41.5375C39.8729 40.9939 39.8678 40.1137 40.8035 39.57C41.746 39.0223 43.2654 39.0223 44.2071 39.5659Z"
        fill="#46A046"
      />
      <path
        d="M18.4972 12.105L57.4669 34.6042L42.5298 43.2847L26.4786 34.0175L3.56006 20.7855L18.4972 12.105ZM23.086 32.007C27.2108 34.3884 33.8919 34.3841 37.9826 32.0069C42.0732 29.6297 42.059 25.7596 37.9341 23.3782C33.8166 21.001 27.128 21.001 23.0374 23.3782C18.9467 25.7554 18.9684 29.6298 23.086 32.007ZM10.4943 22.0624L18.9457 26.9074C19.2204 25.4492 20.3149 24.034 22.2378 22.9165C24.1607 21.7991 26.5926 21.165 29.1167 20.9966L20.6951 16.1344C21.9421 15.4097 21.9359 14.262 20.7104 13.5544C19.4923 12.8512 17.5034 12.847 16.2857 13.5545C15.0681 14.2622 15.0895 15.4098 16.3075 16.113L10.5098 19.5256C9.25439 18.8007 7.26543 18.7966 6.05543 19.4997C4.83069 20.2116 4.85201 21.3592 6.07007 22.0624C7.29556 22.77 9.26939 22.7743 10.4943 22.0624ZM44.7121 39.2723L50.5172 35.864C51.7726 36.5888 53.7465 36.5931 54.964 35.8856C56.1817 35.1779 56.1755 34.0302 54.9499 33.3226C53.7319 32.6194 51.7504 32.6195 50.5327 33.3272L42.0818 28.482C41.7919 29.9404 40.705 31.351 38.7821 32.4685C36.8593 33.586 34.4198 34.2245 31.9107 34.3927L40.3324 39.255C39.0778 39.9841 39.0916 41.1274 40.3097 41.8306C41.5352 42.5382 43.509 42.5425 44.7339 41.8308C45.9438 41.1276 45.9376 39.9798 44.7121 39.2723ZM23.8972 16.0569L22.7911 16.6997L27.3046 19.3055L28.4105 18.6627L23.8972 16.0569ZM46.1133 28.8834L45.0072 29.5261L49.5132 32.1276L50.6193 31.4849L46.1133 28.8834ZM14.9137 26.5062L16.0125 25.8676L11.4992 23.2619L10.4003 23.9004L14.9137 26.5062ZM37.1224 39.3284L38.2213 38.6898L33.7152 36.0883L32.6165 36.7269L37.1224 39.3284Z"
        fill="#46A046"
      />
      <path
        d="M43.8197 25.1165L26.0981 35.415L25.2822 34.944L43.0038 24.6453L43.8197 25.1165Z"
        fill="#86AEDC"
      />
      <path
        d="M48.5795 27.8644L30.8579 38.1631L26.0981 35.415L43.8197 25.1165L48.5795 27.8644Z"
        fill="#E3E6F0"
      />
      <path
        d="M49.3906 28.3327L31.669 38.6314L30.8579 38.1632L48.5795 27.8645L49.3906 28.3327Z"
        fill="#86AEDC"
      />
      <path
        d="M26.0977 35.4149L26.0607 41.0707L25.2451 40.5999L25.2823 34.9441L26.0977 35.4149Z"
        fill="#678CC8"
      />
      <path
        d="M30.8535 38.1609L30.8163 43.8165L26.0605 41.0708L26.0976 35.415L30.8535 38.1609Z"
        fill="#D4DBE9"
      />
      <path
        d="M31.669 38.6316L31.632 44.2874L30.8164 43.8165L30.8536 38.1609L31.669 38.6316Z"
        fill="#678CC8"
      />
      <path
        d="M60.2605 24.5601L60.2416 30.2141L42.52 40.5128L42.5389 34.8588L60.2605 24.5601Z"
        fill="#5EAF5F"
      />
      <path
        d="M42.5387 34.8588L42.5198 40.5128L0.740234 16.3915L0.759206 10.7373L42.5387 34.8588Z"
        fill="#7BC578"
      />
      <path
        d="M60.2603 24.5601L42.5387 34.8588L0.759277 10.7373L18.4809 0.438721L60.2603 24.5601Z"
        fill="#98D095"
      />
      <path
        d="M45.1122 23.5378L44.6298 23.8182C45.0564 24.2022 45.0592 24.6681 44.5839 24.9442L43.9636 24.5862C44.1791 24.461 44.0731 24.1763 43.7368 23.9821C43.3931 23.7837 42.9013 23.7232 42.6858 23.8484C42.4707 23.9734 42.5763 24.2584 42.92 24.4568C43.6149 24.858 43.7155 25.4489 43.1512 25.7769C42.6759 26.053 41.8716 26.053 41.2146 25.8029L40.7319 26.0833L40.1118 25.7252L40.5943 25.4448C40.1604 25.065 40.1576 24.5991 40.6329 24.323L41.2531 24.681C41.0376 24.8062 41.1508 25.0867 41.4945 25.2852C41.8308 25.4793 42.3154 25.544 42.5309 25.4188C42.7536 25.2893 42.6401 25.0091 42.3038 24.8149C41.6089 24.4137 41.5012 23.8183 42.0656 23.4903C42.5409 23.2142 43.3451 23.2142 44.0095 23.4601L44.492 23.1797L45.1122 23.5378Z"
        fill="#46A046"
      />
      <path
        d="M20.9083 9.56355L20.4257 9.84404C20.8523 10.2279 20.8626 10.6982 20.3873 10.9744L19.7597 10.612C19.975 10.4867 19.8766 10.2064 19.5329 10.008C19.1891 9.80945 18.6972 9.74906 18.4818 9.8743C18.2667 9.99929 18.3722 10.2841 18.7161 10.4826C19.411 10.8839 19.519 11.4791 18.9546 11.807C18.4794 12.0832 17.6675 12.0788 17.0104 11.8287L16.528 12.1091L15.9077 11.751L16.3903 11.4706C15.9563 11.0909 15.9536 10.625 16.4289 10.3487L17.0491 10.7068C16.8336 10.8321 16.9468 11.1126 17.2906 11.311C17.6343 11.5095 18.1114 11.5699 18.327 11.4446C18.5497 11.3152 18.4435 11.0392 18.0998 10.8408C17.4048 10.4395 17.2973 9.84404 17.8616 9.51619C18.3369 9.23991 19.1411 9.24004 19.8054 9.48593L20.288 9.20557L20.9083 9.56355Z"
        fill="#46A046"
      />
      <path
        d="M20.2053 3.79972C21.1544 4.34768 21.1593 5.22771 20.2164 5.77566C19.2812 6.31915 17.754 6.32362 16.8051 5.77579C15.8635 5.23217 15.8587 4.34321 16.7939 3.79972C17.7368 3.25177 19.2637 3.25611 20.2053 3.79972Z"
        fill="#46A046"
      />
      <path
        d="M36.6845 14.9477L35.4895 15.6422C36.5522 16.5828 36.5589 17.7304 35.3783 18.4164L33.8464 17.532C34.3808 17.2214 34.1162 16.5269 33.2717 16.0393C32.4349 15.5561 31.2274 15.4007 30.6929 15.7113C30.1585 16.0219 30.4305 16.7208 31.2675 17.204C32.9862 18.1963 33.2476 19.6546 31.8519 20.4657C30.679 21.1474 28.6903 21.1517 27.0705 20.5348L25.8678 21.2337L24.3359 20.3493L25.5386 19.6503C24.4682 18.7141 24.4618 17.5578 25.6347 16.8762L27.1666 17.7607C26.632 18.0713 26.9042 18.7701 27.7412 19.2534C28.5855 19.7408 29.7855 19.8919 30.3201 19.5812C30.8545 19.2706 30.5899 18.5761 29.7454 18.0886C28.0342 17.1006 27.7726 15.6337 29.161 14.8269C30.3416 14.1409 32.3301 14.1452 33.9576 14.7578L35.1526 14.0632L36.6845 14.9477Z"
        fill="#46A046"
      />
      <path
        d="M9.96764 9.74922C10.9166 10.297 10.9216 11.1772 9.97905 11.7249C9.04347 12.2686 7.52398 12.2686 6.575 11.7207C5.63347 11.1772 5.62095 10.2927 6.55665 9.74897C7.49918 9.20126 9.02611 9.2056 9.96764 9.74922Z"
        fill="#46A046"
      />
      <path
        d="M54.4447 23.5678C55.3938 24.1158 55.3988 24.9959 54.4559 25.5439C53.5205 26.0874 51.9935 26.0918 51.0445 25.5439C50.103 25.0003 50.1056 24.1156 51.0408 23.5722C51.9838 23.0242 53.5032 23.0242 54.4447 23.5678Z"
        fill="#46A046"
      />
      <path
        d="M44.2071 29.5176C45.156 30.0655 45.161 30.9455 44.2185 31.4932C43.2829 32.0371 41.7634 32.037 40.8144 31.4892C39.8729 30.9455 39.8678 30.0654 40.8035 29.5216C41.746 28.9739 43.2654 28.9739 44.2071 29.5176Z"
        fill="#46A046"
      />
      <path
        d="M18.4972 2.05664L57.4669 24.5559L42.5298 33.2364L26.4786 23.9693L3.56006 10.7373L18.4972 2.05664ZM23.086 21.9586C27.2108 24.3402 33.8919 24.3358 37.9826 21.9586C42.0732 19.5814 42.059 15.7114 37.9341 13.3299C33.8166 10.9527 27.128 10.9528 23.0374 13.33C18.9467 15.7072 18.9684 19.5814 23.086 21.9586ZM10.4943 12.0142L18.9457 16.8591C19.2204 15.4009 20.3149 13.9858 22.2378 12.8683C24.1607 11.7508 26.5926 11.1167 29.1167 10.9484L20.6951 6.08614C21.9421 5.36149 21.9359 4.21374 20.7104 3.5062C19.4923 2.80287 17.5034 2.79878 16.2857 3.50633C15.0681 4.21399 15.0895 5.36161 16.3075 6.06482L10.5098 9.4773C9.25439 8.75252 7.26543 8.74842 6.05543 9.4515C4.83069 10.1633 4.85201 11.3109 6.07007 12.0142C7.29556 12.7218 9.26939 12.726 10.4943 12.0142ZM44.7121 29.2241L50.5172 25.8158C51.7726 26.5406 53.7465 26.5449 54.964 25.8374C56.1817 25.1297 56.1755 23.982 54.9499 23.2744C53.7319 22.5712 51.7504 22.5713 50.5327 23.2789L42.0818 18.4337C41.7919 19.8921 40.705 21.3028 38.7821 22.4203C36.8593 23.5377 34.4198 24.1763 31.9107 24.3445L40.3324 29.2067C39.0778 29.9359 39.0916 31.0792 40.3097 31.7824C41.5352 32.49 43.509 32.4943 44.7339 31.7824C45.9438 31.0793 45.9376 29.9316 44.7121 29.2241ZM23.8972 6.00864L22.7911 6.65146L27.3046 9.2572L28.4105 8.61451L23.8972 6.00864ZM46.1133 18.8351L45.0072 19.4779L49.5132 22.0794L50.6193 21.4367L46.1133 18.8351ZM14.9137 16.458L16.0125 15.8194L11.4992 13.2135L10.4003 13.8521L14.9137 16.458ZM37.1224 29.2802L38.2213 28.6416L33.7152 26.0401L32.6165 26.6786L37.1224 29.2802Z"
        fill="#46A046"
      />
      <path
        d="M43.8197 15.0681L26.0981 25.3667L25.2822 24.8956L43.0038 14.5969L43.8197 15.0681Z"
        fill="#86AEDC"
      />
      <path
        d="M48.5795 17.8161L30.8579 28.1148L26.0981 25.3667L43.8197 15.0681L48.5795 17.8161Z"
        fill="#E3E6F0"
      />
      <path
        d="M49.3906 18.2844L31.669 28.5831L30.8579 28.1149L48.5795 17.8162L49.3906 18.2844Z"
        fill="#86AEDC"
      />
      <path
        d="M26.0977 25.3666L26.0607 31.0223L25.2451 30.5515L25.2823 24.8958L26.0977 25.3666Z"
        fill="#678CC8"
      />
      <path
        d="M30.8535 28.1124L30.8163 33.7682L26.0605 31.0225L26.0976 25.3667L30.8535 28.1124Z"
        fill="#D4DBE9"
      />
      <path
        d="M31.669 28.5831L31.632 34.2389L30.8164 33.7681L30.8536 28.1123L31.669 28.5831Z"
        fill="#678CC8"
      />
    </svg>
  );
}
