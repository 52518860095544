import React, { useEffect, useState } from 'react';
import styles from '../../../styles/components/wallet/deposit.module.scss';
import classNames from 'classnames/bind';
import { Asset } from 'metaverse-js/lib/proto/model/asset';

const cx = classNames.bind(styles);

const listCryptoData = ['BTC', 'ETH', 'USDT', 'TRX', 'BNB'];

interface PropsType {
  setTokenSupport: Function;
  tokenSupport?: Asset;
  assets: Asset[];
}

const ListCrypto = ({ setTokenSupport, tokenSupport, assets }: PropsType) => {
  return (
    <ul className={cx('list-crypto')}>
      {assets.map((asset) => {
        const active = tokenSupport && tokenSupport.id === asset.id;
        return (
          <li
            onClick={() => setTokenSupport(asset)}
            key={asset.id}
            className={cx('item-crypto', active && 'item-active')}>
            <div className={cx('icon')}>
              <img src={`/images/tokens/${asset.symbol?.toLowerCase()}.svg?v=2`} alt={''} />
            </div>
            <div className={cx('name-crypto')}>{asset.symbol}</div>
          </li>
        );
      })}
    </ul>
  );
};

export default ListCrypto;
