import React from 'react';

const DateIcon = () => {
  return (
    <svg width={18} height={17} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.369873 14.3333C0.369873 15.75 1.45321 16.8333 2.86987 16.8333H14.5365C15.9532 16.8333 17.0365 15.75 17.0365 14.3333V7.66663H0.369873V14.3333ZM14.5365 1.83329H12.8699V0.999959C12.8699 0.499959 12.5365 0.166626 12.0365 0.166626C11.5365 0.166626 11.2032 0.499959 11.2032 0.999959V1.83329H6.20321V0.999959C6.20321 0.499959 5.86987 0.166626 5.36987 0.166626C4.86987 0.166626 4.53654 0.499959 4.53654 0.999959V1.83329H2.86987C1.45321 1.83329 0.369873 2.91663 0.369873 4.33329V5.99996H17.0365V4.33329C17.0365 2.91663 15.9532 1.83329 14.5365 1.83329Z"
        fill="#A2A3A4"
      />
    </svg>
  );
};

export default DateIcon;
