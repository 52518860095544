import React from 'react';

const LogoutIcon = () => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.87153 16.1332V17.0302C9.87153 17.7496 9.51495 18.3706 8.89533 18.7302C8.59721 18.9033 8.26448 18.9998 7.91951 19C7.57422 19.0002 7.24159 18.9035 6.94327 18.7302L2.97621 16.4274C2.35651 16.0677 2 15.4468 2 14.7273V2.96305C2 1.88056 2.87582 1 3.95241 1H12.6893C13.766 1 14.6419 1.88049 14.6419 2.96305V5.44293C14.6419 5.79868 14.3546 6.08752 14.0008 6.08752C13.6468 6.08752 13.3598 5.79871 13.3598 5.44293V2.96305C13.3598 2.59134 13.059 2.2889 12.6893 2.2889H5.03351L8.89533 4.53105C9.51474 4.8907 9.87153 5.51156 9.87153 6.23075V14.8441H12.6893C13.059 14.8441 13.3598 14.5418 13.3598 14.1701V11.9962C13.3598 11.6402 13.6467 11.3516 14.0008 11.3516C14.3547 11.3516 14.6419 11.6403 14.6419 11.9962V14.1701C14.6419 15.2527 13.766 16.1332 12.6893 16.1332H9.87153ZM15.8116 9.21101L15.1056 9.92085C14.8553 10.1725 14.8554 10.5806 15.1056 10.8323C15.2261 10.9535 15.3885 11.0208 15.5589 11.0208C15.7294 11.0208 15.8916 10.9537 16.0123 10.8323L17.8123 9.02215C18.0625 8.7705 18.0626 8.36269 17.8123 8.11104L16.0123 6.3012C15.7619 6.04948 15.3561 6.04959 15.1056 6.30117C14.8554 6.5526 14.8555 6.9608 15.1056 7.21231L15.8116 7.92197H11.0821C10.728 7.92197 10.4412 8.21053 10.4412 8.56659C10.4412 8.92266 10.728 9.21104 11.0821 9.21104H15.8116V9.21101Z"
        fill="#A2A3A4"
      />
    </svg>
  );
};

export default LogoutIcon;
