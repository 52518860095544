import BigNumber from 'bignumber.js';
import classNames from 'classnames/bind';
import { AssetBalance } from 'metaverse-js/lib/proto/model/asset';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { REGEX } from '../../constant';
import styles from '../../styles/components/wallet/withdraw.module.scss';

const cx = classNames.bind(styles);

interface PropsType {
  register: UseFormRegister<FieldValues>;
  balance?: AssetBalance;
  handleClickButtonWithdrawAmount: (percent: number | string) => void;
  title?: string;
  placeholder?: string;
  amountMsgRequired?: string;
  minAmount?: string;
  maxAmount?: string;
  fee?: string;
}

const WithdrawAmount = ({
  register,
  balance,
  handleClickButtonWithdrawAmount,
  title = 'Withdraw Amount',
  placeholder = 'Enter amount',
  amountMsgRequired = 'Please enter withdraw amount!',
  minAmount = '0.001',
  maxAmount,
  fee
}: PropsType) => {
  const handleValidateAssetAmount = (value: number) => {
    if (new BigNumber(value).eq(0)) {
      return `Withdrawal amount must be greater than 0!`;
    }
    if (new BigNumber(value).lt(minAmount)) {
      return `Withdrawal Amount is less than Minimum Amount`;
    }
    if (new BigNumber(value).gt(balance?.availableAssetAmount || 0)) {
      return 'Insufficient balance';
    }
    if (maxAmount && new BigNumber(value).gt(maxAmount)) {
      return `Withdrawal Amount exceeds Maximum Amount `;
    }
    if (new BigNumber(value).lt(fee || 0)) {
      return 'Invalid Amount';
    }
    return true;
  };
  return (
    <div className={cx('withdraw-amount')}>
      <div className={cx('title-total')}>
        <div>{title}</div>
        <div>
          {new BigNumber(minAmount).gt(0) && `Min: ${new BigNumber(minAmount).toFormat(3)} -`} Max:{' '}
          {maxAmount ? new BigNumber(maxAmount).toFormat(3) : 'Unlimit'}
        </div>
      </div>
      <div className={cx('input-withdraw')}>
        <div className={cx('input')}>
          <input
            {...register('assetAmount', {
              required: amountMsgRequired,
              validate: handleValidateAssetAmount,
              pattern: {
                value: new RegExp(REGEX.ONLY_NUMBER),
                message: 'This field only allows input of numbers'
              }
            })}
            type="text"
            placeholder={placeholder}
            className={cx(placeholder ? 'placeholder' : '')}
          />
        </div>
        <div className={cx('button-group')}>
          <button type="button" onClick={() => handleClickButtonWithdrawAmount('min')}>
            Min
          </button>
          <button type="button" onClick={() => handleClickButtonWithdrawAmount(0.25)}>
            25%
          </button>
          <button type="button" onClick={() => handleClickButtonWithdrawAmount(0.5)}>
            50%
          </button>
          <button type="button" onClick={() => handleClickButtonWithdrawAmount('max')}>
            Max
          </button>
        </div>
      </div>

      <div className={cx('button-group-mobile')}>
        <button>Min</button>
        <button>25%</button>
        <button>50%</button>
        <button>Max</button>
      </div>
    </div>
  );
};

export default WithdrawAmount;
