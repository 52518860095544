import React from 'react';

interface PropsType {
  active?: boolean;
}

const InternalTransferIcon = ({ active }: PropsType) => {
  return (
    <svg width={24} height={25} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2366_40491)">
        <path
          d="M17.9557 7.87951C17.2385 7.59202 17.0313 7.41817 17.0313 7.10423C17.0313 6.853 17.2216 6.55949 17.7581 6.55949C18.2326 6.55949 18.5965 6.7466 18.5998 6.74822C18.6381 6.76865 18.681 6.77954 18.7236 6.77954C18.8274 6.77954 18.9193 6.7167 18.9573 6.61943L19.0877 6.28621C19.1331 6.16479 19.0642 6.05899 18.9764 6.02104C18.6843 5.89387 18.1133 5.79545 18.1075 5.79443C18.0983 5.79281 18.0663 5.78598 18.0663 5.74857L18.0644 5.26518C18.0644 5.11961 17.9429 5.0011 17.7937 5.0011H17.5598C17.4107 5.0011 17.2892 5.11954 17.2892 5.26525L17.2898 5.77346C17.2898 5.81243 17.2476 5.82934 17.2322 5.83292C16.5115 6.0046 16.061 6.53257 16.061 7.19853C16.061 8.02873 16.7473 8.40389 17.4882 8.68549C18.0806 8.91805 18.3222 9.15386 18.3222 9.49973C18.3222 9.8761 17.98 10.139 17.4897 10.139C17.0711 10.139 16.5045 9.8736 16.4989 9.87089C16.4626 9.85371 16.4242 9.84505 16.3847 9.84505C16.2769 9.84505 16.183 9.91026 16.1454 10.0113L16.021 10.3483C15.9767 10.4741 16.0448 10.5762 16.132 10.6217C16.48 10.8029 17.1533 10.9091 17.1833 10.9138C17.1913 10.9149 17.2323 10.9293 17.2323 10.9668V11.4728C17.2323 11.6184 17.3539 11.7369 17.5032 11.7369H17.7448C17.8942 11.7369 18.0157 11.6184 18.0157 11.4728V10.9403C18.0157 10.8905 18.0525 10.8861 18.06 10.8843C18.8279 10.7118 19.3008 10.15 19.3008 9.4446C19.3006 8.72006 18.8983 8.25189 17.9557 7.87951Z"
          fill={active ? 'white' : '#A2A3A4'}
        />
        <path
          d="M17.6538 2.02258C14.1487 2.02258 11.3075 4.86399 11.3075 8.36886C11.3075 11.8738 14.1487 14.7153 17.6538 14.7153C21.1587 14.7153 24 11.8738 24 8.36886C24 4.86399 21.1587 2.02258 17.6538 2.02258ZM17.6538 13.2445C14.961 13.2445 12.7782 11.0615 12.7782 8.36892C12.7782 5.67633 14.961 3.49345 17.6538 3.49345C20.3464 3.49345 22.5291 5.67633 22.5291 8.36892C22.5291 11.0615 20.3464 13.2445 17.6538 13.2445Z"
          fill={active ? 'white' : '#A2A3A4'}
        />
        <path
          d="M22.7105 17.7906C21.361 18.5355 19.5049 19.2242 17.6539 19.5145C16.007 19.7728 12.9088 19.1499 12.9088 18.4142C12.9088 18.1355 15.6261 19.1011 17.2698 18.6626C18.5302 18.3263 18.3513 17.1473 17.6657 16.9918C16.9841 16.837 12.9909 15.3742 11.2852 15.3237C10.4818 15.3 8.49809 15.6031 7.53821 15.7595C7.20939 15.813 7.00321 15.8071 6.98265 16.1218C6.88713 17.5842 6.69888 20.5749 6.59626 22.1371C6.5941 22.1701 6.63996 22.614 7.00625 22.3016C7.40718 21.9598 7.96951 21.5808 8.48963 21.6988C9.30563 21.8839 16.3651 23.1772 17.3388 22.9509C18.7318 22.6271 22.6752 20.2902 23.4312 19.5638C24.6031 18.4377 23.6921 17.2489 22.7105 17.7906Z"
          fill={active ? 'white' : '#A2A3A4'}
        />
        <path
          d="M5.03453 15.7024L1.33627 15.5202C1.05074 15.5061 0.759128 15.7213 0.688169 15.9982L0.0157149 22.1034C-0.0551765 22.3804 0.120564 22.616 0.406227 22.6271L4.50481 22.6266C4.79054 22.6377 5.04204 22.4135 5.06396 22.1284L5.51406 16.2462C5.53584 15.9612 5.32006 15.7165 5.03453 15.7024ZM2.85733 21.8178C2.29256 21.8178 1.83481 21.36 1.83481 20.7953C1.83481 20.2305 2.29263 19.7728 2.85733 19.7728C3.42202 19.7728 3.87984 20.2306 3.87984 20.7953C3.87984 21.3601 3.42209 21.8178 2.85733 21.8178Z"
          fill={active ? 'white' : '#A2A3A4'}
        />
      </g>
      <defs>
        <clipPath id="clip0_2366_40491">
          <rect width={24} height={24} fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InternalTransferIcon;
