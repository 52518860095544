export enum LOGIN_TYPE {
  METAMASK = 'metamask',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  EMAIL = 'email'
}

export interface CurrencyLocal {
  symbol: string;
  balance: string;
}

export enum GameConfigType {
  IGNORE_MAX_CONFIG = 'ignoreMaxConfig',
  SOUND_DISABLE = 'soundDisable'
}

export enum NETWORK {
  BSC = 'BSC',
  ETH = 'ETH',
  TRON = 'TRON'
}

export enum ASSET_TYPE {
  ERC20 = 'ERC20',
  TRC20 = 'TRC20'
}

export enum VOLUME {
  NORMAL = 1,
  MUTE = 0
}
