import { bybetApi } from './api';

export const authService = {
  getNonceMetamask: (address: string): any => {
    return bybetApi
      .post('/identity/auth/wallet/register', { address })
      .then((data) => Promise.resolve(data));
  },
  signWallet: (address: string, signature: string): any => {
    return bybetApi
      .post('/identity/auth/wallet/sign', { address, signature })
      .then((data) => Promise.resolve(data));
  },
  confirmVerificationCode: (code: string, accessToken: string): any => {
    return bybetApi
      .post(
        '/identity/auth/confirmation',
        { code },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then((data) => Promise.resolve(data));
  }
};
