export default function NoteIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8235 9C17.8235 13.8731 13.8731 17.8235 9 17.8235C4.12911 17.8182 0.181799 13.8709 0.176471 9C0.17647 4.1269 4.1269 0.176469 9 0.176469C13.8731 0.176468 17.8235 4.1269 17.8235 9ZM16.563 9.00004C16.563 4.82309 13.1769 1.43701 8.99995 1.43701C4.823 1.43701 1.43692 4.82309 1.43692 9.00004C1.43692 13.177 4.82301 16.5631 8.99995 16.5631C13.1769 16.5631 16.563 13.177 16.563 9.00004ZM8.78999 14.2521C9.13807 14.2521 9.42025 13.9699 9.42025 13.6218L9.42025 7.73947C9.42025 7.39139 9.13807 7.10922 8.78999 7.10922C8.44191 7.10922 8.15974 7.39139 8.15974 7.73947L8.15974 13.6218C8.15974 13.9699 8.44192 14.2521 8.78999 14.2521ZM9.42025 5.42857C9.42025 5.77665 9.13807 6.05882 8.78999 6.05882C8.44191 6.05882 8.15974 5.77665 8.15974 5.42857C8.15974 5.08049 8.44191 4.79832 8.78999 4.79832C9.13807 4.79832 9.42024 5.08049 9.42025 5.42857Z"
        fill="#A2A3A4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9C18 13.9706 13.9706 18 9 18C4.0318 17.9946 0.00543368 13.9684 1.12056e-06 9.00019C6.8602e-07 4.02963 4.02944 -6.86003e-07 9 -1.12054e-06C13.9706 -1.55508e-06 18 4.02944 18 9ZM9.0001 17.6471C13.7757 17.647 17.6471 13.7756 17.6471 9C17.6471 4.22436 13.7756 0.352938 9 0.352938C4.22443 0.352939 0.353045 4.22426 0.352941 8.99981C0.358162 13.7734 4.22656 17.6418 9.0001 17.6471ZM8.99995 16.3866C13.0794 16.3866 16.3865 13.0795 16.3865 9.00004C16.3865 4.92055 13.0794 1.61348 8.99995 1.61348C4.92047 1.61348 1.61339 4.92055 1.61339 9.00004C1.6134 13.0795 4.92047 16.3866 8.99995 16.3866ZM16.7394 9.00004C16.7394 13.2744 13.2744 16.7395 8.99995 16.7395C4.72554 16.7395 1.26045 13.2744 1.26045 9.00004C1.26045 4.72563 4.72554 1.26054 8.99995 1.26054C13.2744 1.26054 16.7394 4.72563 16.7394 9.00004ZM8.78999 14.0756C9.04061 14.0756 9.24378 13.8724 9.24377 13.6218L9.24377 7.73947C9.24377 7.48885 9.04061 7.28569 8.78999 7.28569C8.53938 7.28569 8.33621 7.48885 8.33621 7.73947L8.33621 13.6218C8.33621 13.8724 8.53938 14.0756 8.78999 14.0756ZM9.59672 13.6218C9.59672 14.0674 9.23553 14.4285 8.78999 14.4285C8.34445 14.4285 7.98327 14.0674 7.98327 13.6218L7.98327 7.73947C7.98327 7.29393 8.34445 6.93275 8.78999 6.93275C9.23553 6.93275 9.59672 7.29393 9.59672 7.73947L9.59672 13.6218ZM9.59672 5.42857C9.59672 5.87411 9.23553 6.23529 8.78999 6.23529C8.34445 6.23529 7.98327 5.87411 7.98327 5.42857C7.98327 4.98303 8.34445 4.62185 8.78999 4.62185C9.23553 4.62185 9.59672 4.98303 9.59672 5.42857ZM8.78999 5.88235C9.04061 5.88235 9.24377 5.67919 9.24377 5.42857C9.24377 5.17795 9.04061 4.97479 8.78999 4.97479C8.53938 4.97479 8.33621 5.17795 8.33621 5.42857C8.33621 5.67919 8.53938 5.88235 8.78999 5.88235Z"
        fill="#A2A3A4"
      />
    </svg>
  );
}
