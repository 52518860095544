import React from 'react';

const EmailIcon = () => {
  return (
    <svg width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1667 3.74268H5.83342C3.33341 3.74268 1.66675 4.99268 1.66675 7.90934V13.7427C1.66675 16.6593 3.33341 17.9093 5.83342 17.9093H14.1667C16.6667 17.9093 18.3334 16.6593 18.3334 13.7427V7.90934C18.3334 4.99268 16.6667 3.74268 14.1667 3.74268ZM14.5584 8.81768L11.9501 10.901C11.4001 11.3427 10.7001 11.5593 10.0001 11.5593C9.30008 11.5593 8.59175 11.3427 8.05008 10.901L5.44175 8.81768C5.17508 8.60101 5.13341 8.20101 5.34175 7.93434C5.55841 7.66768 5.95008 7.61768 6.21675 7.83434L8.82508 9.91768C9.45842 10.426 10.5334 10.426 11.1667 9.91768L13.7751 7.83434C14.0417 7.61768 14.4417 7.65934 14.6501 7.93434C14.8667 8.20101 14.8251 8.60101 14.5584 8.81768Z"
        fill="#A2A3A4"
      />
    </svg>
  );
};

export default EmailIcon;
