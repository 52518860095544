import classNames from 'classnames/bind';
import styles from '../../styles/components/modal/modal-auth.module.scss';
import InputPopup from '../UI/InputPopup';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { AUTH } from '../../interfaces/Account';
import { LOGIN_TYPE } from '../../interfaces';
import { REGEX } from '../../constant';
import { PASSWORD } from '../../constant/password';
import { useState } from 'react';
import EyePasswordCloseIcon from '../Icons/EyePasswordCloseIcon';
import EyePasswordIcon from '../Icons/EyePasswordIcon';
const cx = classNames.bind(styles);
const listIcons = [LOGIN_TYPE.GOOGLE, LOGIN_TYPE.METAMASK];

interface SignInProps {
  onClickButtonAuth: (type: AUTH) => void;
  handleLoginWith: (name: LOGIN_TYPE) => void;
  handleTypeModalAuth: Function;
  checkTypeSignIn: boolean;
  register: UseFormRegister<RegisterForm>;
  errors: Partial<FieldErrorsImpl<RegisterForm>>;
  banned?: boolean;
}
type RegisterForm = {
  email: string;
  password: string;
  confirmPassword: string;
  registerEmailAddress: string;
};
const SignIn = ({
  onClickButtonAuth,
  checkTypeSignIn,
  handleTypeModalAuth,
  handleLoginWith,
  register,
  errors,
  banned
}: SignInProps) => {
  const [showPsw, setShowPsw] = useState(false);
  const handleShowPassword = (name: string) => {
    setShowPsw(!showPsw);
  };
  return (
    <>
      <div className={cx('form-item')}>
        <div className={cx('form-label')}>
          <label>Email Address</label>
        </div>
        <div>
          <InputPopup
            placeholder="Your email"
            type="email"
            {...register('email', { required: PASSWORD.REQUIRED_FIELD })}
          />
        </div>
        <div className={cx('error')}>{errors.email && errors.email?.message}</div>
      </div>

      <div className={cx('form-item')}>
        <div className={cx('form-label', 'form-label-forgotPwd')}>
          <div>
            <label>Password</label>
          </div>
          {checkTypeSignIn && (
            <div
              className={cx('forgot-pwd')}
              onClick={() => onClickButtonAuth(AUTH.RESET_PASSWORD)}>
              Forgot Password
            </div>
          )}
        </div>
        <div>
          <InputPopup
            {...register('password', {
              required: PASSWORD.REQUIRED_FIELD,
              pattern: !checkTypeSignIn
                ? {
                    value: new RegExp(REGEX.PASSWORD),
                    message: PASSWORD.REQUIRED_PASSWORD
                  }
                : undefined
            })}
            placeholder="Your password"
            type={showPsw ? 'text' : 'password'}
          />
          <div className={cx('icon')} onClick={() => handleShowPassword('password')}>
            {showPsw ? <EyePasswordCloseIcon /> : <EyePasswordIcon />}
          </div>
          <div className={cx('error')}>{errors.password && errors.password?.message}</div>
        </div>
      </div>

      <div className={cx('form-button')}>
        <button
          onClick={() => {
            handleTypeModalAuth(AUTH.SIGN_IN);
          }}
          type="submit"
          className={cx('button-active')}>
          Sign in
        </button>
        <div
          onClick={() => {
            handleTypeModalAuth(AUTH.SIGN_UP);
          }}>
          Sign up
        </div>
      </div>
      {banned && (
        <div className={cx('banned-warning')}>
          Your account was disabled for violating our Terms of Service. Please contact Customer
          Service to explain so that we will consider reinstating the account.
        </div>
      )}
      <div className={cx('login-diff')}>
        <div className={cx('title')}>Or Sign-in with</div>
        <div className={cx('icons')}>
          {listIcons.map((item) => (
            <div onClick={() => handleLoginWith(item)} key={item} className={cx('icon')}>
              <img src={`/images/login-diff/${item}.png`} alt={item} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SignIn;
