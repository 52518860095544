import { Asset, AssetBalance } from 'metaverse-js/lib/proto/model/asset';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ROUTES } from '../../../constant/routes';
import DepositCurrency from '../Deposit/DepositCurrency';
import LayoutWallet from '../LayoutWallet';
import VerificationCode from '../VerificationCode';
import WithdrawAddress from '../WithdrawAddress';
import WithdrawAmount from '../WithdrawAmount';

import { BigNumber } from 'bignumber.js';
import classNames from 'classnames/bind';
import { errors2FA, lengthVerificationCode } from '../../../constant/2fa';
import { useAppDispatch, useAppSelector } from '../../../stores';
import styles from '../../../styles/components/wallet/withdraw.module.scss';
import Warning from '../Deposit/Warning';
import { getAssets } from '../../../stores/AssetsSlice';

const cx = classNames.bind(styles);

enum STATUS_METADATA {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED'
}

const note =
  ' For security purposes, large or suspicious withdrawal may take 1-6 hours for audit process We appreciate your patience!';

const InternalTransfers = () => {
  const { metaverseClient, userIdentifier, myBalances, account, userInfo, assets } = useAppSelector(
    (state) => ({
      metaverseClient: state.app.metaverseClient,
      userIdentifier: state.app.userIdentityClient,
      myBalances: state.myBalances.balances,
      account: state.account.accountInfo,
      userInfo: state.userInfo.userInfo,
      assets: state.assets.assets
    })
  );
  const [tokenSupport, setTokenSupport] = useState<Asset>();
  const [balance, setBalance] = useState<AssetBalance>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userIdentityClient = useAppSelector((state) => state.app.userIdentityClient);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({ defaultValues: { toAccount: '', assetAmount: '', twoFACode: '' } as FieldValues });

  const handleChangeCode = (code: string) => {
    setValue('twoFACode', code);
  };

  const handleTransfer = async (value: FieldValues) => {
    try {
      const user = await getUserId(value.toAccount);
      if (!user) {
        toast.error('Not found this user in the system. Please try again');
        return;
      }
      if (!tokenSupport) {
        toast.error('Please select token');
        return;
      }
      const myAsset = myBalances.find((asset) => asset.assetSymbol === tokenSupport?.symbol);
      if (!myAsset) {
        toast.error('Token is not supported');
        return;
      }
      if (new BigNumber(myAsset.availableAssetAmount || '0').lt(value.assetAmount)) {
        toast.error('Your balance hasn’t been sufficient to transfer. Please try again');
        return;
      }
      if (userInfo?.isEnableTwoFactor && value.twoFACode?.length < lengthVerificationCode) {
        return toast.error(errors2FA.verificationCode);
      }
      setLoading(true);
      const tokenTwoFactorRes = await userIdentityClient.getTokenTwoFactor({
        tfaCode: value.twoFACode
      });
      if (tokenTwoFactorRes) {
        const idToken = tokenTwoFactorRes.data.idToken;
        await metaverseClient.setTwoFactorToken(idToken);
      }
      await metaverseClient.transfer({
        toUser: user.id,
        assetSymbol: myAsset.assetSymbol,
        assetAmount: value.assetAmount,
        assetType: myAsset.assetType
      });
      toast.success('Request successful. Please wait for confirmation');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Oops! Something went wrong. Please try again');
    }
  };
  const getUserId = async (identifier: string) => {
    const res = await userIdentifier.getUsers({ identifiers: identifier });
    if (res && res.data && res.data.length > 0) {
      return res.data[0];
    }
    return null;
  };

  const handleClickButtonWithdrawAmount = (percent: number | string) => {
    if (percent === 'min') {
      setValue('assetAmount', `${0}`);
    } else {
      setValue('assetAmount', `${Number(balance?.totalAssetAmount) * Number(percent)}`);
    }
  };

  useEffect(() => {
    if (!account.accessToken) {
      setTokenSupport({
        symbol: 'FBET'
      });
    }
  }, [account.accessToken]);

  const updateMyBalance = (assetBalances: AssetBalance[], tokenSupport: Asset) => {
    setBalance(
      assetBalances
        .filter((fil) => fil.assetType === tokenSupport?.type)
        .filter((fil) => fil.assetSymbol === tokenSupport?.symbol)
        .find((find) => find.tokenId === tokenSupport?.tokenId)
    );
  };

  useEffect(() => {
    dispatch(
      getAssets({
        metaverseClient,
        assetQuery: {
          chain: 'CHT',
          internalTransferStatus: STATUS_METADATA.ENABLED
        }
      })
    );
  }, []);

  useEffect(() => {
    setTokenSupport(assets[0]);
  }, [assets]);

  useEffect(() => {
    if (tokenSupport && myBalances) updateMyBalance(myBalances, tokenSupport);
  }, [tokenSupport, myBalances]);

  return (
    <LayoutWallet>
      <form onSubmit={handleSubmit(handleTransfer)}>
        <WithdrawAddress
          network=""
          register={register}
          title="Receiver's Identifier"
          addressMsgRequired="The receiver is required. Please input this field"
          placeholder="Enter the receiver's identifier"
        />
        <div className={cx('error-message')}>{errors.toAccount && errors.toAccount?.message}</div>

        <DepositCurrency
          setTokenSupport={setTokenSupport}
          assets={assets}
          balances={myBalances}
          tokenSupport={tokenSupport}
          onClickLinkHistory={() => navigate(`${ROUTES.WALLET.TRANSACTIONS}?category=withdraw`)}
          title="Transfer Currency"
          assetBalance={balance}
        />
        <WithdrawAmount
          handleClickButtonWithdrawAmount={handleClickButtonWithdrawAmount}
          balance={balance}
          register={register}
          title="Tranfer Amount"
          placeholder="Enter amount"
          amountMsgRequired="Amount is required. Please input this field"
          minAmount="0"
        />
        <div className={cx('error-message')}>
          {errors.assetAmount && errors.assetAmount?.message}
        </div>
        <VerificationCode disable={loading} note={note} handleChangeCode={handleChangeCode} />
      </form>
      {!userInfo?.isEnableTwoFactor && <Warning />}
    </LayoutWallet>
  );
};

export default InternalTransfers;
