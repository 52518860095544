import React from 'react';
import GameDetail from '../../components/Games/Detail';
import { GAME_INTRODUCTION } from '../../constant/game';
import { ROUTES } from '../../constant/routes';

export default function RingOfFortuneDetailPage() {
  return (
    <GameDetail
      name="Ring of fortune"
      imageUrl="/images/games/ring-of-fortune.png"
      releaseDate="--"
      gameInfo={GAME_INTRODUCTION.RING_OF_FORTUNE}
      linkGame={ROUTES.RING_OF_FORTUNE.INDEX}
      listSlide={['ring-of-fortune-slide-1', 'ring-of-fortune-slide-2', 'ring-of-fortune-slide-3']}
    />
  );
}
