import React from 'react';

interface PropsType {
  active?: boolean;
}

const TransactionsIcon = ({ active }: PropsType) => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_809_13832)">
        <path
          d="M0.9375 10.0122H2.595C2.72438 10.0122 2.82938 9.90724 2.82938 9.77787C2.82938 5.94662 5.94656 2.82974 9.77781 2.82974C11.9188 2.82974 13.9556 3.83037 15.2663 5.51568L14.4803 6.3863C14.4259 6.44662 14.4066 6.53068 14.4294 6.6088C14.4519 6.68693 14.5134 6.74755 14.5919 6.76912L19 7.99224C19.0863 8.01662 19.1769 7.98943 19.2366 7.92349C19.2963 7.85755 19.3131 7.7638 19.2809 7.68099L17.6144 3.42068C17.5847 3.34505 17.5181 3.29037 17.4384 3.27568C17.3588 3.26099 17.2769 3.2888 17.2225 3.34912L16.7053 3.92193C14.9753 1.87287 12.4666 0.703491 9.77844 0.703491C4.77406 0.703179 0.703125 4.77412 0.703125 9.77787C0.703125 9.90724 0.808125 10.0122 0.9375 10.0122Z"
          fill={active ? 'white' : '#A2A3A4'}
        />
        <path
          d="M19.0626 9.98786H17.4051C17.2758 9.98786 17.1708 10.0929 17.1708 10.2222C17.1708 14.0535 14.0536 17.1704 10.2223 17.1704C8.08138 17.1704 6.0445 16.1697 4.73388 14.4844L5.51982 13.6138C5.57419 13.5535 5.59357 13.4694 5.57075 13.3913C5.54825 13.3132 5.48669 13.2525 5.40825 13.231L1.00013 12.0079C0.91419 11.9838 0.823253 12.0107 0.763565 12.0766C0.703878 12.1425 0.687003 12.2363 0.71919 12.3191L2.38575 16.5794C2.41544 16.655 2.482 16.7097 2.56169 16.7244C2.64169 16.7391 2.72357 16.7113 2.77763 16.651L3.29482 16.0782C5.02482 18.1272 7.53357 19.2966 10.2217 19.2966C15.2254 19.2966 19.2964 15.2257 19.2964 10.2219C19.2964 10.0925 19.1914 9.98755 19.062 9.98755L19.0626 9.98786Z"
          fill={active ? 'white' : '#A2A3A4'}
        />
        <path
          d="M10 5.39062C7.45844 5.39062 5.39062 7.45844 5.39062 10C5.39062 12.5416 7.45844 14.6094 10 14.6094C12.5416 14.6094 14.6094 12.5416 14.6094 10C14.6094 7.45844 12.5416 5.39062 10 5.39062ZM10 9.76562C10.7325 9.76562 11.3281 10.3616 11.3281 11.0938C11.3281 11.7456 10.855 12.2869 10.2344 12.3981V12.8928C10.2344 13.0222 10.1294 13.1272 10 13.1272C9.87063 13.1272 9.76562 13.0222 9.76562 12.8928V12.3981C9.145 12.2869 8.67188 11.7456 8.67188 11.0938C8.67188 10.9644 8.77688 10.8594 8.90625 10.8594C9.03562 10.8594 9.14062 10.9644 9.14062 11.0938C9.14062 11.5678 9.52594 11.9531 10 11.9531C10.4741 11.9531 10.8594 11.5678 10.8594 11.0938C10.8594 10.6197 10.4741 10.2344 10 10.2344C9.2675 10.2344 8.67188 9.63844 8.67188 8.90625C8.67188 8.25437 9.145 7.71312 9.76562 7.60188V7.10719C9.76562 6.97781 9.87063 6.87281 10 6.87281C10.1294 6.87281 10.2344 6.97781 10.2344 7.10719V7.60188C10.855 7.71312 11.3281 8.25437 11.3281 8.90625C11.3281 9.03562 11.2231 9.14062 11.0938 9.14062C10.9644 9.14062 10.8594 9.03562 10.8594 8.90625C10.8594 8.43219 10.4741 8.04688 10 8.04688C9.52594 8.04688 9.14062 8.43219 9.14062 8.90625C9.14062 9.38031 9.52594 9.76562 10 9.76562Z"
          fill={active ? 'white' : '#A2A3A4'}
        />
      </g>
      <defs>
        <clipPath id="clip0_809_13832">
          <rect width={20} height={20} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TransactionsIcon;
