import classnames from 'classnames/bind';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ChangePasswordBody } from 'user-identity-js/lib/dto';
import { REGEX } from '../../constant';
import { errors2FA, lengthVerificationCode } from '../../constant/2fa';
import { PASSWORD } from '../../constant/password';
import { ROUTES } from '../../constant/routes';
import { AUTH } from '../../interfaces/Account';
import { storageService } from '../../services/storage';
import { useAppDispatch, useAppSelector } from '../../stores';
import { logout, setAuthModalType } from '../../stores/AccountSlice';
import { toggleModal } from '../../stores/ModalSlice';
import { getUserInfo } from '../../stores/UserInformation';
import styles from '../../styles/components/change-psw/change-psw.module.scss';
import EyePasswordCloseIcon from '../Icons/EyePasswordCloseIcon';
import EyePasswordIcon from '../Icons/EyePasswordIcon';
import NoteIcon from '../Icons/NoteIcon';
import LayoutComponentSideMenu from '../LayoutComponentSideMenu';
import ModalNotificationMustHaveEmail from '../Modal/ModalNotificationMustHaveEmail';
import Input from '../UI/Input';
import InputCodeV2 from '../UI/InputCodeV2';
import Warning from '../Wallet/Deposit/Warning';

const cx = classnames.bind(styles);
type RegisterForm = {
  currentPassword: string;
  password: string;
  confirmPassword: string;
  verificationCode: string;
};

const ChangePwd = () => {
  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<RegisterForm>({
    defaultValues: { password: '', currentPassword: '', confirmPassword: '', verificationCode: '' }
  });
  const userIdentitySDK = useAppSelector((state) => state.app.userIdentityClient);
  const { user } = useAppSelector((state) => ({
    user: state.userInfo.userInfo
  }));

  const [showCurrentPsw, setShowCurrentPsw] = useState(false);
  const [showPsw, setShowPsw] = useState(false);
  const [widthScreen, setWidthScreen] = useState<number>(1);
  const [widthModal, setWidthModal] = useState<string>('');
  const [showConfirmPsw, setShowConfirmPsw] = useState(false);
  const [isShowing, setIsShowing] = useState(false);
  const [readonly, setReadonly] = useState(false);
  const { isEnableTwoFactor, isCanUseTwoFactor } = useAppSelector(
    (state) => state.userInfo.userInfo
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const myRef = useRef<any>(null);
  const getWidth = () => {
    const width = myRef?.current?.clientWidth;
    setWidthScreen(width);
  };
  const setSizeModal = () => {
    if (widthScreen > 500) setWidthModal('500px');
    else setWidthModal('350px');
  };
  const handleShowPassword = (name: string) => {
    if (name === 'currentPassword') {
      setShowCurrentPsw(!showCurrentPsw);
    }
    if (name === 'password') {
      setShowPsw(!showPsw);
    }
    if (name === 'confirmPassword') {
      setShowConfirmPsw(!showConfirmPsw);
    }
  };

  const getStatus = async () => {
    if (!user.id) return;
    if (!isCanUseTwoFactor) {
      setIsShowing(true);
      setReadonly(true);
    }
  };

  const handleChangeCode = (code: string) => {
    setValue('verificationCode', code);
  };

  const onSubmit = async (values: RegisterForm) => {
    if (readonly) return;
    try {
      let dataReq = {
        oldPassword: getValues('currentPassword'),
        newPassword: getValues('password')
      } as ChangePasswordBody;
      const tfaCode = values.verificationCode;
      if (isEnableTwoFactor && tfaCode.length < 6) {
        throw Error(errors2FA.verificationCode);
      } else if (isEnableTwoFactor) {
        dataReq = { ...dataReq, tfaCode };
      }
      setIsLoading(true);
      const res = await userIdentitySDK.changePassword(dataReq);

      if (res) {
        toast.success('Update new password successfully');
        onClickLogout();
      }
      setIsLoading(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || error);
      setIsLoading(false);
    }
  };
  const onClickLogout = () => {
    dispatch(logout());
    dispatch(toggleModal({ modalName: 'authModal', state: true }));
    navigate(ROUTES.HOME);
    storageService.removeCurrency();
  };
  useEffect(() => {
    getStatus();
  }, [user.id]);
  useEffect(() => {
    getWidth();
  }, [myRef]);
  useEffect(() => {
    setSizeModal();
  }, [widthScreen]);
  const toggle = () => {
    setIsShowing(!isShowing);
  };
  useEffect(() => {
    const accountData = storageService.getAccount();
    if (!accountData) {
      navigate(ROUTES.HOME);
      dispatch(setAuthModalType(AUTH.SIGN_IN));
      dispatch(toggleModal({ modalName: 'authModal', state: true }));
    } else {
      if (!user) {
        dispatch(getUserInfo({ userIdentityClient: userIdentitySDK, id: accountData.id }));
      }
    }
  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {' '}
      <LayoutComponentSideMenu>
        <ModalNotificationMustHaveEmail
          isShowing={isShowing}
          toggle={toggle}
          widthModal={widthModal}
        />

        <div className={cx('container')} ref={myRef}>
          <div className={cx('form')}>
            <div className={cx('header')}>
              <div className={cx('title')}>Change Password</div>
            </div>
            <div className={cx('input-login')}>
              <p>Current Password</p>
              <div className={cx('input-form')}>
                <Input
                  {...register('currentPassword', {
                    required: PASSWORD.REQUIRED_FIELD,
                    pattern: {
                      value: new RegExp(REGEX.PASSWORD),
                      message: PASSWORD.REQUIRED_PASSWORD
                    }
                  })}
                  readonly={readonly}
                  type={showCurrentPsw ? 'text' : 'password'}
                  placeholder={'Enter your current password...'}
                />
                <div
                  className={cx('eye-icon')}
                  onClick={() => handleShowPassword('currentPassword')}>
                  {showCurrentPsw ? <EyePasswordCloseIcon /> : <EyePasswordIcon />}
                </div>
                <div className={cx('error')}>
                  {errors.currentPassword && errors.currentPassword?.message}
                </div>
              </div>
            </div>
            <div className={cx('input-login')}>
              <p>New Password</p>
              <div className={cx('input-form')}>
                <Input
                  {...register('password', {
                    required: PASSWORD.REQUIRED_FIELD,
                    validate: {
                      matchPassword: (value) =>
                        value !== getValues('currentPassword') || PASSWORD.REQUIRED_DUPLICATE_PWD
                    },
                    pattern: {
                      value: new RegExp(REGEX.PASSWORD),
                      message: PASSWORD.REQUIRED_PASSWORD
                    }
                  })}
                  readonly={readonly}
                  type={showPsw ? 'text' : 'password'}
                  placeholder={'Enter your new password...'}
                />
                <div className={cx('eye-icon')} onClick={() => handleShowPassword('password')}>
                  {showPsw ? <EyePasswordCloseIcon /> : <EyePasswordIcon />}
                </div>
                <div className={cx('error')}>{errors.password && errors.password?.message}</div>
              </div>
            </div>
            <div className={cx('input-login')}>
              <p>Confirm Password</p>
              <div className={cx('input-form')}>
                <Input
                  {...register('confirmPassword', {
                    required: PASSWORD.REQUIRED_FIELD,
                    validate: {
                      matchPassword: (value) =>
                        value === getValues('password') || PASSWORD.REQUIRED_CONFIRM_PWD
                    }
                  })}
                  readonly={readonly}
                  type={showConfirmPsw ? 'text' : 'password'}
                  placeholder={'Enter your confirm password...'}
                />
                <div
                  className={cx('eye-icon')}
                  onClick={() => handleShowPassword('confirmPassword')}>
                  {showConfirmPsw ? <EyePasswordCloseIcon /> : <EyePasswordIcon />}
                </div>
                <div className={cx('error')}>
                  {errors.confirmPassword && errors.confirmPassword?.message}
                </div>
              </div>
              <div className={cx('re-login')}>
                <NoteIcon /> <p>Re-login will be required after changing the password</p>
              </div>
            </div>
            <div className={cx('underline')}></div>

            {isEnableTwoFactor && (
              <div className={cx('code-container')}>
                <p>Verification code</p>
                <div className={cx('input-code')}>
                  <InputCodeV2 length={lengthVerificationCode} onChange={handleChangeCode} />
                </div>
              </div>
            )}

            <div className={cx('button')}>
              <button type="submit" disabled={readonly || isLoading}>
                Confirm
              </button>
            </div>
            {!isEnableTwoFactor && <Warning />}
          </div>
        </div>
      </LayoutComponentSideMenu>
    </form>
  );
};

export default ChangePwd;
