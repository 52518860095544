import LayoutComponentSideMenu from '../LayoutComponentSideMenu';
import styles from '../../styles/components/global-setting/link-email.module.scss';
import classnames from 'classnames/bind';
import EyePasswordIcon from '../Icons/EyePasswordIcon';
import { useEffect, useState } from 'react';
import EyePasswordCloseIcon from '../Icons/EyePasswordCloseIcon';
import { lengthVerificationCode } from '../../constant/2fa';
import InputCodeV2 from '../UI/InputCodeV2';
import { useForm } from 'react-hook-form';
import { PASSWORD } from '../../constant/password';
import { REGEX } from '../../constant';
import { toast } from 'react-toastify';
import { RootState, useAppDispatch, useAppSelector } from '../../stores';
import { UserProvider } from 'user-identity-js/lib/entities/user.entity';
import { LinkEmailDto } from 'user-identity-js/lib/dto/link-email.dto';
import { getUserInfo } from '../../stores/UserInformation';
import { storageService } from '../../services/storage';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constant/routes';
import { AUTH } from '../../interfaces/Account';
import { setAuthModalType } from '../../stores/AccountSlice';
import { toggleModal } from '../../stores/ModalSlice';

const cx = classnames.bind(styles);

const LinkEmail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userInfo = useAppSelector((state: RootState) => state.userInfo.userInfo);
  const userIdentityClient = useAppSelector((state: RootState) => state.app.userIdentityClient);
  const [hidePassword, setHidePassword] = useState(true);
  const [invalidEmail, setInvalidEmail] = useState('');
  const [verified, setVerified] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: { email: '', verificationCode: '', password: '' }
  });
  const sendCode = async () => {
    if (!getValues('email')) {
      setInvalidEmail('Login Email is required. Please input this field');
      return;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i.test(getValues('email'))) {
      setInvalidEmail('Email is invalid');
      return;
    }
    try {
      const res = await userIdentityClient.sendCodeVerifyEmail(getValues('email'));
      if (res) {
        toast.success('A verification code has been sent to your email');
        setInvalidEmail('');
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    if (userInfo && userInfo.email) {
      setValue('email', userInfo.email);
      setVerified(true);
    }
  }, [userInfo]);
  const handleChangeInputCode = (code: string) => {
    setValue('verificationCode', code);
  };

  const onSubmit = async (data: any) => {
    if (!data.verificationCode || data.verificationCode.length < lengthVerificationCode) {
      toast.error('Please input the verification code');
      return;
    }
    try {
      const bodyLinkMail: LinkEmailDto = {
        code: data.verificationCode,
        email: data.email,
        password: data.password
      };
      const linkMailResp = await userIdentityClient.linkEmail(bodyLinkMail);
      if (linkMailResp) {
        toast.success('Verify your login email successfully');
        dispatch(getUserInfo({ userIdentityClient, id: userInfo?.id || '' }));
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    const accountData = storageService.getAccount();
    if (!accountData) {
      navigate(ROUTES.HOME);
      dispatch(setAuthModalType(AUTH.SIGN_IN));
      dispatch(toggleModal({ modalName: 'authModal', state: true }));
    } else {
      if (!userInfo) {
        dispatch(getUserInfo({ userIdentityClient, id: accountData.id }));
      }
    }
  }, []);
  return (
    <LayoutComponentSideMenu>
      <div className={cx('link-email-container')}>
        <div className={cx('title')}>E-mail Verification</div>
        <div className={cx('description')}>
          According to the security policy of BYBET, the registered email cannot be changed
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={cx('label')}>Email</div>
          <div className={cx('input-email')}>
            <div
              className={cx(
                'input-wrapper',
                (userInfo?.provider === UserProvider.PASSWORD || !!userInfo?.linkEmailAt) &&
                  'linked'
              )}>
              <input
                disabled={verified}
                {...register('email', {
                  required: 'Login Email is required. Please input this field'
                })}
                placeholder="Enter your email..."
              />
              {verified && (
                <img className={cx('verified-icon')} src="/images/success-verification.png" />
              )}
            </div>
            {userInfo?.provider !== UserProvider.PASSWORD && !userInfo?.linkEmailAt && (
              <div onClick={() => sendCode()} className={cx('send-code-btn')}>
                Send Code
              </div>
            )}
          </div>
          {(errors.email?.message || invalidEmail) && (
            <div className={cx('error')}>{errors.email?.message || invalidEmail}</div>
          )}

          {userInfo?.provider !== UserProvider.PASSWORD && !userInfo?.linkEmailAt && (
            <>
              <div className={cx('label')}>Login password</div>
              <div className={cx('input-password')}>
                <input
                  {...register('password', {
                    required: 'Login Password is required. Please input this field',
                    pattern: {
                      value: new RegExp(REGEX.PASSWORD),
                      message: PASSWORD.REQUIRED_PASSWORD
                    }
                  })}
                  type={hidePassword ? 'password' : 'text'}
                  placeholder="Enter your login password..."
                />
                {errors.password?.message && (
                  <div className={cx('error')}>{errors.password?.message}</div>
                )}
                <div onClick={() => setHidePassword(!hidePassword)}>
                  {hidePassword ? <EyePasswordCloseIcon /> : <EyePasswordIcon />}
                </div>
              </div>
              <div className={cx('verification-code')}>
                <div className={cx('label')}>Verification code</div>
                <InputCodeV2 length={lengthVerificationCode} onChange={handleChangeInputCode} />
              </div>
              <button type="submit" className={cx('confirm-btn')}>
                <div className={cx('text')}>Confirm</div>
              </button>
            </>
          )}
        </form>
      </div>
    </LayoutComponentSideMenu>
  );
};

export default LinkEmail;
