import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from '../../styles/components/user-setting/user-information.module.scss';
import StatisticsIcon from '../Icons/StatisticsIcon';
import TokenIcon from '../Icons/TokenIcon';
import TotalWageredIcon from '../Icons/TotalWageredIcon';
import TotalWinIcon from '../Icons/TotalWinIcon';

import { useState } from 'react';
import ArrowDown from '../Icons/ArrowDown';
import Dropdown from '../UI/Dropdown';
import { useAppDispatch, useAppSelector } from '../../stores';
import { shortenAddress } from '../../lib/shortenAddress';
import Table from '../UI/Table';
import { Columns } from '../../interfaces/Table';
import { GameStatistic } from 'game-profile-js/lib/client/entities/all.entity';
import CopyIcon from '../Icons/CopyIcon';
import { copyText } from '../../utils/copyText';
import BigNumber from 'bignumber.js';
import { convertStringNoSpace } from '../../utils';
import { toggleDropdown } from '../../stores/DropdownSlice';
import { TOKENS } from '../../constant/token';
import { GameProfileClient } from 'game-profile-js';
import CurrencyAmount from '../UI/CurrencyAmount';

const cx = classNames.bind(styles);

interface User {
  name: string;
  avatar?: string;
  userId: string;
  totalWins: number;
  totalBets: number;
  totalWagered: number;
  favoriteGames: [];
  wagerContest: [];
}

interface PropsType {
  user: User;
}

const selectStatistic = [
  {
    key: 'global',
    value: 'Global'
  },
  {
    key: 'crash',
    value: 'Crash'
  },
  {
    key: 'classic_dice',
    value: 'Classic Dice'
  },
  {
    key: 'ring',
    value: 'Ring of Fortune'
  }
];

const StatisticDetail = ({ user }: PropsType) => {
  const [optionActive, setOptionActive] = useState<{ key: string; value: string }>({
    key: 'global',
    value: 'Global'
  });
  const [dataTable, setDataTable] = useState<any[]>([]);
  const { gameProfileClient, userLevel } = useAppSelector((state) => ({
    gameProfileClient: state.app.gameProfileClient,
    userLevel: state.account.userLevel
  }));
  const dispatch = useAppDispatch();

  const [totalStatistics, setTotalStatistics] = useState({
    totalWagered: 0,
    totalWin: 0,
    totalBet: 0
  });

  const fetchStatistics = async (
    gameProfileClient: GameProfileClient,
    gameType: string | undefined
  ) => {
    try {
      const res = await gameProfileClient.getGameStats({ gameType });
      const listSortedData = [
        res.data.find((item) => item.symbol === TOKENS.FBET),
        res.data.find((item) => item.symbol === TOKENS.BBET),
        ...res.data.filter((item) => item.symbol !== TOKENS.FBET && item.symbol !== TOKENS.BBET)
      ];
      setDataTable(
        listSortedData.map((item?: GameStatistic) => ({
          currency: item?.symbol,
          win: item?.totalWin,
          bet: item?.totalBet,
          wagered: item?.totalWagered
        }))
      );
      const dataTotalBet = res.data.map((item) => item.totalBet);
      const dataTotalWin = res.data.map((item) => item.totalWin);
      const dataTotalWagered = res.data.map((item) => item.totalWagered);
      setTotalStatistics({
        totalWagered: dataTotalWagered.reduce((a, b) => a + b, 0),
        totalWin: dataTotalWin.reduce((a, b) => a + b, 0),
        totalBet: dataTotalBet.reduce((a, b) => a + b, 0)
      });
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      key: 'currency',
      title: 'Currency',
      align: 'start',
      render: (currency) => (
        <div className={cx('currency-columns')}>
          <div className={cx('icon')}>
            <img src={`/images/tokens/${currency.toLowerCase()}.svg?v=2`} alt={currency} />
          </div>
          <div>{currency}</div>
        </div>
      )
    },
    {
      key: 'win',
      title: 'Win'
    },
    {
      key: 'bet',
      title: 'Bet'
    },
    {
      key: 'wagered',
      title: 'Wagered',
      render: (wagered) => (
        <div style={{ color: 'white' }}>
          $<CurrencyAmount amount={wagered} decimalPlaces={6} />
        </div>
      )
    }
  ] as Columns[];

  const handleClickItemSelect = (key: { key: string; value: string }) => {
    setOptionActive(key);
    dispatch(toggleDropdown({ name: 'statictis', state: false }));
  };

  useEffect(() => {
    const gameType =
      optionActive?.key !== 'global'
        ? convertStringNoSpace(optionActive.key).toLocaleUpperCase()
        : undefined;
    fetchStatistics(gameProfileClient, gameType);
  }, [gameProfileClient, optionActive]);

  return (
    <div className={cx('content')}>
      <div className={cx('profile-statictis')}>
        <div className={cx('avatar')}>
          <img src={user.avatar || '/images/avatar.png'} />
        </div>
        <div>
          <div className={cx('name')}>
            {user.name}
            <span className={cx('level')}> - Lv: {userLevel.vipLevel}</span>
          </div>
          <div className={cx('user-id')}>
            <div>User ID: {shortenAddress(user.userId)}</div>
            <div className={cx('icon')} onClick={() => copyText(user.userId)}>
              <CopyIcon />
            </div>
          </div>
        </div>
      </div>
      <div className={cx('statistics-detail')}>
        <div className={cx('title')}>Statistics</div>

        <div className={cx('container-select')}>
          <Dropdown
            name="statictis"
            styleOverlay={{ width: '100%' }}
            placement="left"
            overlay={
              <div className={cx('option-wrapper')}>
                {selectStatistic.map((item) => {
                  const active = optionActive.key === item.key;
                  return (
                    <div
                      onClick={() => handleClickItemSelect(item)}
                      key={item.key}
                      className={cx('option', active && 'option-active')}>
                      <div>{item.value}</div>
                      {active && (
                        <div className={cx('radio')}>
                          <div className={cx('content')} />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            }>
            <div className={cx('select-wrapper')}>
              <button className={cx('button-select')}>
                <div>{optionActive.value}</div>
                <div>
                  <ArrowDown />
                </div>
              </button>
            </div>
          </Dropdown>
        </div>
      </div>

      <div className={cx('panel')}>
        <div className={cx('header')}>
          <div className={cx('statistic-wrapper')}>
            <StatisticsIcon />
            Statistics
          </div>
        </div>
        <div className={cx('statistic-content')}>
          <div className={cx('statistic-block')}>
            <div className={cx('title')}>
              <TotalWinIcon /> Total wins
            </div>
            <div className={cx('data')}>{totalStatistics.totalWin}</div>
          </div>
          <div className={cx('statistic-block')}>
            <div className={cx('title')}>
              <TokenIcon /> Total Bets
            </div>
            <div className={cx('data')}>{totalStatistics.totalBet}</div>
          </div>
          <div className={cx('statistic-block')}>
            <div className={cx('title')}>
              <TotalWageredIcon /> Total Wagered
            </div>
            <div className={cx('data')}>
              $<CurrencyAmount amount={totalStatistics.totalWagered} decimalPlaces={6} />
            </div>
          </div>
        </div>
      </div>
      <div className={cx('panel')}>
        <Table
          columns={columns}
          dataTable={dataTable}
          isBorder={false}
          showNodata={
            totalStatistics.totalBet === 0 &&
            totalStatistics.totalBet === 0 &&
            totalStatistics.totalWagered === 0
          }
        />
        {/* <div className={cx('header-currency')}>
          <div>Currency</div>
          <div>Wagered</div>
        </div> */}
        {/* <div className={cx('favorites-game')}>
          <MushroomNodata />
          <div className={cx('nodata-text')}>Oops! There is no data yet!</div>
        </div> */}
      </div>
    </div>
  );
};

export default StatisticDetail;
