import classNames from 'classnames/bind';
import { ConfigReward } from 'game-profile-js/lib/client/entities/all.entity';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../stores';
import styles from '../../styles/components/affiliate/affiliate-reward.module.scss';
import BestSeller from '../Icons/BestSeller';
import TableAffiliate, { Table } from './Table';

const cx = classNames.bind(styles);

const AffiliateReward = () => {
  const gameProfile = useAppSelector((state) => state.app.gameProfileClient);
  const [tableData, setTableData] = useState<Table[]>();

  useEffect(() => {
    getConfig();
  }, []);
  const getConfig = async () => {
    const res = await gameProfile.getConfig();
    const colorMileStones = [1, 5, 7, 9];
    if (res) {
      const tableData: Table[] = res.data.map((levelRecord: ConfigReward, index) => ({
        id: index + '',
        name: colorMileStones.includes(index) ? 'color' : '',
        vipLevel: levelRecord.level.toString(),
        totalXp: levelRecord.exp,
        rewards: levelRecord.reward.toFixed(2)
      }));
      setTableData(tableData);
    }
  };
  return (
    <div className={cx('container')}>
      <div className={cx('title')}>
        <div className={cx('rewards-block')}>
          <BestSeller />
          <div>Affiliate Rewards</div>
        </div>
      </div>
      <img src="/images/arrow.svg" alt="" className={cx('arrow')} />
      <div className={cx('item')}>
        <div className={cx('item-content')}>
          <div className={cx('header')}>
            <div className={cx('item-header')}>
              <div className={cx('header-left')}>
                <div className={cx('header-title')}>USA Rewards</div>
                <div className={cx('header-text')}>
                  <div>Each friend you invite will give you $1000.00</div>
                  <div className={cx('text')}>
                    <span className={cx('header-title')}>$1,000.00</span> USD Rewards Rules
                  </div>
                </div>
              </div>
              <div className={cx('header-right')}>
                <img src="/images/coin.svg" alt="" />
              </div>
            </div>
          </div>

          <div className={cx('item-body')}>{tableData && <TableAffiliate table={tableData} />}</div>
        </div>

        <div className={cx('item-content')}>
          <div className={cx('header')}>
            <div className={cx('item-header')}>
              <div className={cx('header-left')}>
                <div className={cx('header-title')}>Commisson Rewards</div>
                <div className={cx('header-text')}>
                  <div>
                    Each friend you refer will rebate you a<br /> percentage of their wagers as a
                    <br />
                    commission and be credited directly to
                    <br /> your balance.
                  </div>
                </div>
              </div>

              <div className={cx('header-right')}>
                <img src="/images/coin.svg" alt="" />
              </div>
            </div>
          </div>

          <div className={cx('item-body-commision')}>
            <div className={cx('chart-text-item')}>
              <div className={cx('chart-text')}>Your commission</div>
              <div>
                Wager x 1% x <span className={cx('chart-text')}>15%</span>
              </div>
            </div>
            <div className={cx('body-text')}>
              Your refferd members will rebate you a percentage of their wagers as your commission.
            </div>
            <div className={cx('chart')}>
              <img src="/images/jump.svg" alt="" />
            </div>
            <div className={cx('scoin-1')}>
              <img src="/images/scoin.svg" alt="" />
            </div>
            <div className={cx('scoin-2')}>
              <img src="/images/scoin.svg" alt="" />
            </div>
            <div className={cx('scoin-3')}>
              <img src="/images/coin-affiliate.svg" alt="" />
            </div>
            <div className={cx('scoin-4')}>
              <img src="/images/scoin.svg" alt="" />
            </div>
            <div className={cx('scoin-5')}>
              <img src="/images/scoin.svg" alt="" />
            </div>
            <div className={cx('scoin-6')}>
              <img src="/images/scoin.svg" alt="" />
            </div>
            <div className={cx('scoin-7')}>
              <img src="/images/scoin.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffiliateReward;
