interface PropsType {
  width?: number;
  height?: number;
}

const ArrowDown = ({ width = 12, height = 7 }: PropsType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1L6.05263 6.05263L11.1053 1"
        stroke="#969696"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowDown;
