import classnames from 'classnames/bind';
import { useAppDispatch, useAppSelector } from '../../stores';
import { logout } from '../../stores/AccountSlice';
import styles from '../../styles/Layout/header.module.scss';
import LogoutIcon from '../Icons/LogoutIcon';
import StatisticsIcon from '../Icons/StatisticsIcon';
import TransactionsIcon from '../Icons/TransactionsIcon';
import { toggleModal } from '../../stores/ModalSlice';
import { SLIDE_USER } from '../../constant';
import { shortenAddress } from '../../lib/shortenAddress';
import { UserEntity } from 'user-identity-js/lib/entities/user.entity';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constant/routes';
import { storageService } from '../../services/storage';
import SettingIcon from '../Icons/SettingIcon';
import AffiliateMenuIcon from '../Icons/AffiliateMenuIcon';
import { toggleDropdown } from '../../stores/DropdownSlice';
import CopyIcon from '../Icons/CopyIcon';
import { copyText } from '../../utils/copyText';

const cx = classnames.bind(styles);

interface PropsType {
  setSlideStart: (value: number) => void;
  user?: UserEntity;
}

interface MenuUser {
  key: string;
  title: string;
  icon: () => void;
  onClick: () => void;
}

const ProfileAccount = ({ setSlideStart, user }: PropsType) => {
  const { account, userLevel } = useAppSelector((state) => ({
    account: state.account.accountInfo,
    userLevel: state.account.userLevel
  }));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClickItemUserInfo = (slide: number) => {
    setSlideStart(slide);
    dispatch(toggleModal({ modalName: 'userProfileModal', state: true }));
  };

  const onClickLogout = () => {
    dispatch(logout());
    dispatch(toggleModal({ modalName: 'authModal', state: false }));
    navigate(ROUTES.HOME);
    storageService.removeCurrency();
  };

  const handleClickItemUser = (onClick: () => void) => {
    dispatch(
      toggleDropdown({
        name: 'globalSetting',
        state: false
      })
    );
    onClick();
  };

  const menus = [
    {
      key: 'global-setting',
      onClick: () => navigate(ROUTES.GLOBAL_SETTING.INDEX),
      title: 'Global settings',
      icon: () => <SettingIcon />
    },
    {
      key: 'statistics',
      onClick: () => handleClickItemUserInfo(SLIDE_USER.DETAIL),
      title: 'Statistics',
      icon: () => <StatisticsIcon />
    },
    {
      key: 'transactions',
      onClick: () => navigate(`${ROUTES.WALLET.TRANSACTIONS}?category=deposit`),
      title: 'Transactions',
      icon: () => <TransactionsIcon />
    },
    {
      key: 'affiliate',
      onClick: () => navigate(ROUTES.AFFILIATE),
      title: 'Affiliate',
      icon: () => <AffiliateMenuIcon />
    }
  ] as MenuUser[];

  return (
    <>
      <div className={cx('account')}>
        <div className={cx('info')}>
          <div className={cx('detail')}>
            <div>
              <img
                src={user?.avatar || account.photoURL || '/images/avatar.png'}
                alt="avatar"
                className={cx('header-avatar')}
              />
            </div>
            <div className={cx('desc')}>
              <div className={cx('name')}>{user?.nickName || account.displayName}</div>
              <div className={cx('id')}>
                <div>ID: {user?.identifier && shortenAddress(user.identifier)}</div>
                <div className={cx('icon')} onClick={() => copyText(user?.identifier || '')}>
                  <CopyIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cx('user-level')}>
          <div className={cx('detail')}>
            <div>V {userLevel.vipLevel}</div>
            <div className={cx('exp-to-next-level')}>
              Need {userLevel.expToNextLevel} xp
              <span className={cx('next-level')}>V {userLevel.nextLevel}</span>
            </div>
          </div>
          <div className={cx('progress-level')}>
            <div className={cx('progress-bar')}>
              <div
                className={cx('percent')}
                style={{
                  width: `${userLevel.progressPercent}%`
                }}
              />
            </div>
            <div>{userLevel.progressPercent}%</div>
          </div>
        </div>
        <ul className={cx('list')}>
          {menus.map((item) => {
            return (
              <li key={item.key} onClick={() => handleClickItemUser(item.onClick)}>
                <div className={cx('icon')}>{item.icon()}</div>
                <div className={cx('title')}>{item.title}</div>
              </li>
            );
          })}
        </ul>
        <button type="button" className={cx('button-logout')} onClick={onClickLogout}>
          <div className={cx('icon')}>
            <LogoutIcon />
          </div>
          <div>Logout</div>
        </button>
      </div>
    </>
  );
};

export default ProfileAccount;
