import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../constant/routes';
import styles from '../styles/components/help/layout.module.scss';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

const menuHelp = [
  {
    key: 'general',
    title: 'General',
    url: ROUTES.HELP.GENERAL
  },
  {
    key: 'term',
    title: 'Term of services',
    url: ROUTES.HELP.TERMS
  },
  {
    key: 'fqa',
    title: 'FAQ',
    url: ROUTES.HELP.FAQ
  },
  {
    key: 'support',
    title: 'Support',
    url: ROUTES.HELP.SUPPORT
  },
  {
    key: 'fee',
    title: 'Fee',
    url: ROUTES.HELP.FEE
  }
];
const menuGlobalSetting = [
  {
    key: 'user-information',
    title: 'User information',
    url: ROUTES.GLOBAL_SETTING.USER_INFO
  },
  {
    key: 'change-password',
    title: 'Change Password',
    url: ROUTES.GLOBAL_SETTING.CHANGE_PWD
  },
  {
    key: 'link-email',
    title: 'Link email',
    url: ROUTES.GLOBAL_SETTING.LINK_EMAIL
  },
  {
    key: '2fa',
    title: '2FA',
    url: ROUTES.GLOBAL_SETTING.E2FA
  }
];
interface LayoutProps {
  children: React.ReactNode;
}

const LayoutComponentSideMenu = ({ children }: LayoutProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isGlobalSettingPage = location.pathname.includes(ROUTES.GLOBAL_SETTING.INDEX);
  const menus = isGlobalSettingPage ? menuGlobalSetting : menuHelp;
  return (
    <div className={cx('layout')}>
      <div className={cx('menu')}>
        {menus.map((menuItem) => (
          <div
            className={cx('menu-item', location.pathname === menuItem.url && 'active')}
            key={menuItem.key}
            onClick={() => navigate(menuItem.url)}>
            <div>{menuItem.title}</div>
            {menuItem.key === 'fee' && <div className={cx('soon')} />}
          </div>
        ))}
      </div>
      <div className={cx('content')}>{children}</div>
    </div>
  );
};

export default LayoutComponentSideMenu;
