export default function HomeIcon() {
  return (
    <svg width={27} height={26} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.32963 10.9848L13.2046 4.20543C13.4156 4.02387 13.7017 3.92188 14 3.92188C14.2983 3.92188 14.5844 4.02387 14.7954 4.20543L22.6704 10.9848C22.8814 11.1664 22.9999 11.4127 23 11.6695V20.9534C23 21.2103 22.8815 21.4566 22.6705 21.6382C22.4595 21.8198 22.1734 21.9219 21.875 21.9219H16.25V15.1425H11.75V21.9219H6.125C5.82663 21.9219 5.54048 21.8198 5.32951 21.6382C5.11853 21.4566 5 21.2103 5 20.9534V11.6695C5.00006 11.4127 5.11863 11.1664 5.32963 10.9848Z"
        fill="#A2A3A4"
      />
    </svg>
  );
}
