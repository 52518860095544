import React from 'react';

export default function CameraIcon() {
  return (
    <svg width={50} height={50} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M44.0217 11.9565H38.8043L35.9239 7.6087C34.837 5.97826 32.9348 5 30.9783 5H19.0217C17.0652 5 15.163 5.97826 14.0761 7.6087L11.1957 11.9565H5.97826C2.66304 11.9565 0 14.6196 0 17.9348V39.0217C0 42.337 2.66304 45 5.97826 45H44.0217C47.337 45 50 42.337 50 39.0217V17.9348C50 14.6196 47.337 11.9565 44.0217 11.9565ZM25 40.1087C17.5543 40.1087 11.5217 34.0761 11.5217 26.6304C11.5217 19.1848 17.5543 13.2065 25 13.2065C32.4457 13.2065 38.4783 19.2391 38.4783 26.6848C38.4783 34.0761 32.4457 40.1087 25 40.1087ZM43.2609 20.3804C43.2065 20.3804 43.1522 20.3804 43.0435 20.3804H40.8696C39.8913 20.3261 39.1304 19.5109 39.1848 18.5326C39.2391 17.6087 39.9456 16.9022 40.8696 16.8478H43.0435C44.0217 16.7935 44.837 17.5543 44.8913 18.5326C44.9456 19.5109 44.2391 20.3261 43.2609 20.3804Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 19.1846C20.8696 19.1846 17.5 22.5541 17.5 26.6846C17.5 30.815 20.8696 34.1302 25 34.1302C29.1304 34.1302 32.5 30.7607 32.5 26.6302C32.5 22.4998 29.1304 19.1846 25 19.1846ZM25 21.5C25.5523 21.5 26 21.9477 26 22.5V26.0831H29.5917C30.144 26.0831 30.5917 26.5308 30.5917 27.0831C30.5917 27.6354 30.144 28.0831 29.5917 28.0831H26V31.25C26 31.8023 25.5523 32.25 25 32.25C24.4477 32.25 24 31.8023 24 31.25V28.0831H20.408C19.8557 28.0831 19.408 27.6354 19.408 27.0831C19.408 26.5308 19.8557 26.0831 20.408 26.0831H24V22.5C24 21.9477 24.4477 21.5 25 21.5Z"
        fill="white"
      />
    </svg>
  );
}
