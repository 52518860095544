import classNames from 'classnames/bind';
import useModal from '../../../hooks/useModal';
import { useAppSelector } from '../../../stores';
import styles from '../../../styles/components/wallet/deposit.module.scss';
import Enable2FA from '../../GlobalSetting/2FA/Enable2FA';
import ModalWarning from '../../Modal/ModalWarning';
import ModalUI from '../../UI/Modal';

const cx = classNames.bind(styles);

const Warning = () => {
  const { isShowing, toggle } = useModal();
  const { isCanUseTwoFactor } = useAppSelector((state) => state.userInfo.userInfo);

  return (
    <>
      <div className={cx('warning')}>
        To secure your assets, please{' '}
        <span className={cx('text-yellow', 'enable-2fa')} onClick={toggle}>
          Enable 2FA
        </span>
      </div>
      {isCanUseTwoFactor ? (
        <ModalUI
          title={
            <div>
              <p className={cx('title')}>Two - Factor Authentication</p>
            </div>
          }
          isShowing={isShowing}
          hide={toggle}>
          <div className={cx('enable-twoFA-container')}>
            <Enable2FA hide={toggle} />
          </div>
        </ModalUI>
      ) : (
        <ModalWarning isShowing={isShowing} toggle={toggle} />
      )}
    </>
  );
};

export default Warning;
