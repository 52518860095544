import React from 'react';

interface PropsType {
  active?: boolean;
}

const WithdrawIcon = ({ active }: PropsType) => {
  return (
    <svg width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 8.25H6.5V19.5C6.5 20.097 6.737 20.6692 7.15925 21.0907C7.58075 21.513 8.153 21.75 8.75 21.75H16.25C16.847 21.75 17.4192 21.513 17.8407 21.0907C18.263 20.6692 18.5 20.097 18.5 19.5V8.25ZM11.75 11.2402C11.2805 11.3572 10.8823 11.6002 10.6138 11.9618C10.3925 12.2595 10.25 12.642 10.25 13.1302C10.25 14.097 10.9183 14.5875 11.846 14.8583C12.182 14.9558 12.5525 15.0278 12.875 15.1357C12.9747 15.1695 13.0685 15.2055 13.1503 15.2528C13.2005 15.282 13.25 15.3127 13.25 15.3802C13.25 15.6788 13.0235 15.7935 12.7745 15.8273C12.323 15.888 11.7808 15.7522 11.4193 15.5085C11.0765 15.2767 10.61 15.3675 10.3783 15.7103C10.1465 16.0538 10.2373 16.5202 10.5807 16.752C10.9078 16.9725 11.3188 17.1405 11.75 17.238V17.25C11.75 17.664 12.086 18 12.5 18C12.9102 18 13.244 17.67 13.25 17.2612C13.3295 17.2425 13.4075 17.22 13.4818 17.1937C14.2183 16.9335 14.75 16.3658 14.75 15.3802C14.75 14.4135 14.0817 13.923 13.154 13.6522C12.818 13.554 12.4475 13.482 12.125 13.374C12.0253 13.341 11.9315 13.3042 11.8497 13.2577C11.7995 13.2285 11.75 13.197 11.75 13.1302C11.75 12.828 11.9773 12.7088 12.2285 12.6735C12.6763 12.6113 13.2125 12.7448 13.5702 12.9952C13.91 13.2323 14.378 13.149 14.615 12.8092C14.852 12.4703 14.7688 12.0023 14.4298 11.7653C14.0997 11.5343 13.6842 11.3588 13.25 11.2575V11.25C13.25 10.836 12.914 10.5 12.5 10.5C12.0898 10.5 11.7552 10.8307 11.75 11.2402ZM5 8.25H2V10.5C2 11.7428 3.00725 12.75 4.25 12.75H5V8.25ZM23 8.25H20V12.75H20.75C21.9928 12.75 23 11.7428 23 10.5V8.25ZM23 6.75V4.5C23 3.25725 21.9928 2.25 20.75 2.25H4.25C3.00725 2.25 2 3.25725 2 4.5V6.75H23ZM17.75 5.25H19.25C19.664 5.25 20 4.914 20 4.5C20 4.086 19.664 3.75 19.25 3.75H17.75C17.336 3.75 17 4.086 17 4.5C17 4.914 17.336 5.25 17.75 5.25Z"
        fill={active ? 'white' : '#A2A3A4'}
      />
    </svg>
  );
};

export default WithdrawIcon;
