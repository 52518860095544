import Layout from '../LayoutComponentSideMenu';
import styles from '../../styles/components/help/support.module.scss';
import classnames from 'classnames/bind';
import ClockIcon from '../Icons/ClockIcon';
import EmailIcon from '../Icons/EmailIcon';
import { Link } from 'react-router-dom';
const cx = classnames.bind(styles);
const Support = () => {
  return (
    <Layout>
      <div className={cx('support-container')}>
        <div className={cx('background')} />
        <div className={cx('content')}>
          <div className={cx('logo')}>
            <img src="/images/logo.svg" alt="" />
          </div>
          <div className={cx('thanks')}>
            Thank you for your patience as Bybet team attempt to get all your requests as soon as
            possible.
          </div>

          <div className={cx('contact')}>
            <div className={cx('icon')}>
              <ClockIcon />
            </div>
            <div className={cx('document')}>
              <div className={cx('title')}>Support hours for the service desk are</div>
              <div className={cx('desc')}>Monday - Sunday: 8:00 am - 7:00 pm UTC</div>
            </div>
          </div>

          <div className={cx('contact')}>
            <div className={cx('icon')}>
              <EmailIcon />
            </div>
            <div className={cx('document')}>
              <div className={cx('title')}>Employee Portal Onboarding Issues:</div>
              <div className={cx('desc')}>
                <a href="mailto:support@bybet.io">support@bybet.io</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Support;
