import classNames from 'classnames/bind';
import styles from '../../styles/components/layout/confirm-layout.module.scss';
import ModalUI from '../UI/Modal';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constant/routes';
const cx = classNames.bind(styles);

interface PropsType {
  widthModal: string;
  isShowing: boolean;
  toggle: () => void;
}

const ModalNotificationMustHaveEmail = ({ isShowing, toggle, widthModal }: PropsType) => {
  return (
    <>
      <ModalUI
        hide={toggle}
        isShowing={isShowing}
        backgroundColor={'#1F1F1F'}
        width={widthModal}
        height={'252px'}>
        <div>
          <div className={cx('content-text')}>
            You need to input the login email and login password first. Please complete{' '}
            <Link to={ROUTES.GLOBAL_SETTING.LINK_EMAIL}> Email Verification</Link> to change
            password
          </div>
        </div>
      </ModalUI>
    </>
  );
};

export default ModalNotificationMustHaveEmail;
