import BigNumber from 'bignumber.js';
import { BetTurn } from 'bybet-game-js/lib/schema/BaseGame';
import classnames from 'classnames/bind';
import { find } from 'lodash';
import { useEffect, useState } from 'react';
import { Columns } from '../../../interfaces/Table';
import { RootState, useAppSelector } from '../../../stores';
import styles from '../../../styles/components/games/crash/room.module.scss';
import ArrowDown from '../../Icons/ArrowDown';
import HiddenIcon from '../../Icons/HiddenIcon';
import TableGame from '../../UI/TableGame';

const cx = classnames.bind(styles);

const columns = [
  {
    key: 'name',
    title: 'Player',
    align: 'start',
    render: (text: string) => (
      <div className={cx('col-header-flex')}>
        <HiddenIcon />
        <p>{text}</p>
      </div>
    )
  },
  {
    key: 'cashOut',
    title: 'Cash Out'
  },
  {
    key: 'amount',
    title: 'Amount',
    render: ({ amount, token }: { amount: string | number; token: string }) => (
      <div className={cx('col-header-flex')}>
        <img
          src={`/images/tokens/${token.toLowerCase()}.svg`}
          alt={token}
          className={cx('token')}
        />
        <p className={cx('text-amount')}>{amount}</p>
      </div>
    )
  },
  {
    key: 'profit',
    title: 'Profit',
    align: 'end',
    render: ({ profit, token }: { profit: string | number | null; token: string }) => (
      <div className={cx('col-header-flex', 'profit-data')}>
        {profit ? (
          <>
            <img
              src={`/images/tokens/${token.toLowerCase()}.svg`}
              alt={token}
              className={cx('token')}
            />
            <p className={cx(new BigNumber(profit).gt(0) ? 'win' : 'lose')}>
              {new BigNumber(profit).abs().toString()}
            </p>
          </>
        ) : (
          <p>betting</p>
        )}
      </div>
    )
  }
] as Columns[];

interface DataTable {
  name: string;
  cashOut: string;
  amount: any;
  profit: any;
}

// const dataTable = [
//   { name: 'Hidden', cashOut: 'betting', amount: '123412312', profit: null },
//   { name: 'Hidden', cashOut: 'betting', amount: '124123123', profit: '41234123123' }
// ];

export default function Room() {
  const [checked, setChecked] = useState(false);
  const players = useAppSelector((state: RootState) => state.crashGame.players);
  const [dataTable, setDataTable] = useState<DataTable[]>([]);
  const [isShowMore, setIsShowMore] = useState(false);
  const userIdentityClient = useAppSelector((state) => state.app.userIdentityClient);

  const handleChecked = (newChecked: boolean) => {
    setChecked(newChecked);
  };

  const handleShowMore = () => {
    setIsShowMore(!isShowMore);
  };

  const updateData = async () => {
    try {
      const users = await userIdentityClient.getUsers({
        ids: players.map((player) => player.uid).toString()
      });

      let dataTable = players
        .filter((player) => {
          return (
            player.currentTurn !== BetTurn.IDLE &&
            player.currentTurn !== BetTurn.WAITING_FOR_TRANSFER &&
            player.currentTurn !== BetTurn.ERROR
          );
        })
        .map((player) => {
          let cashOutText = 'betting';
          let profit: string | number | null = null;
          if (player.currentTurn === BetTurn.FINISHED && player.isWin) {
            const amount =
              player.betOptions
                ?.map((betOption) => betOption.amount)
                ?.reduce((prev, current) => {
                  return new BigNumber(prev).plus(current).toFormat(2);
                }) || 1;
            cashOutText = `${new BigNumber(player.rewardAmount).div(amount).toFormat(2)}x`;
            profit = new BigNumber(player.rewardAmount).minus(amount).dp(8, 1).toString();
          } else if (player.currentTurn === BetTurn.FINISHED) {
            cashOutText = 'bang';
            profit = `-${player.betOptions
              ?.map((betOption) => betOption.amount)
              ?.reduce((prev, current) => {
                return new BigNumber(prev).plus(current).dp(8, 1).toString();
              })}`;
          }
          return {
            name: find(users?.data, { id: player.uid })?.nickName || 'Hidden',
            cashOut: cashOutText,
            amount: {
              amount: new BigNumber(player.betAmount || '0').dp(8, 1).toString(),
              token: player.betSymbol
            },
            profit: {
              profit: profit,
              token: player.betSymbol
            }
          };
        });
      setDataTable(dataTable);
    } catch (error: any) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (players.length > 0) {
      updateData();
    }
  }, [players]);

  return (
    <div className={cx('root')}>
      <div className={cx('header')}>
        <p>All Bets</p>
        {/* <div className={cx('trenball-container')}>
          <p>Trenball</p>
          <ToggleSwitch checked={checked} handleChange={handleChecked} />
        </div> */}
      </div>
      <div className={cx('table-container')}>
        <TableGame
          columns={columns}
          dataTable={isShowMore ? dataTable : dataTable.slice(0, 20)}
          footer={
            <div className={cx('footer-table')}>
              <div className={cx('footer-item-flex')}>
                <div className={cx('point-yellow')} />
                <p>
                  {dataTable.length}/{players.length} Players
                </p>
              </div>

              {dataTable.length > 20 && (
                <div
                  className={cx('btn-show-more', isShowMore && 'btn-show-less')}
                  onClick={handleShowMore}>
                  {isShowMore ? <p>Show less</p> : <p>Show more</p>}
                  <ArrowDown />
                </div>
              )}
            </div>
          }
        />
      </div>
    </div>
  );
}
