import React from 'react';

const WalletIcon = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_809_8755)">
        <path
          d="M9.7969 3.6443C9.7969 2.35195 10.8483 1.30055 12.1407 1.30055H12.9518L12.7071 0.620391C12.5695 0.237891 12.209 0 11.8249 0C11.7184 0 11.6101 0.0182812 11.5043 0.0568125L3.30804 3.03998C2.82078 3.21736 2.57 3.75656 2.7484 4.24345L3.18148 5.42555H9.7969V3.6443Z"
          fill="#131313"
        />
        <path
          d="M12.1406 2.70679C11.6228 2.70679 11.2031 3.12651 11.2031 3.64429V5.42554H19.4062V3.64429C19.4062 3.12651 18.9865 2.70679 18.4688 2.70679H12.1406Z"
          fill="#131313"
        />
        <path
          d="M21.8906 18.949H18.6094C16.6709 18.949 15.0938 17.3719 15.0938 15.4333C15.0938 13.4948 16.6709 11.9177 18.6094 11.9177H21.8906V8.70679C21.8906 7.67127 21.0511 6.83179 20.0156 6.83179H1.875C0.839484 6.83179 0 7.67127 0 8.70679V22.125C0 23.1605 0.839484 24 1.875 24H20.0156C21.0511 24 21.8906 23.1605 21.8906 22.125V18.949Z"
          fill="#131313"
        />
        <path
          d="M23.2969 13.324H18.6094C17.4463 13.324 16.5 14.2702 16.5 15.4333C16.5 16.5965 17.4463 17.5427 18.6094 17.5427H23.2969C23.6846 17.5427 24 17.2273 24 16.8396V14.0271C24 13.6394 23.6846 13.324 23.2969 13.324ZM18.6094 16.1365C18.2211 16.1365 17.9062 15.8217 17.9062 15.4333C17.9062 15.045 18.2211 14.7302 18.6094 14.7302C18.9977 14.7302 19.3125 15.045 19.3125 15.4333C19.3125 15.8217 18.9977 16.1365 18.6094 16.1365Z"
          fill="#131313"
        />
      </g>
      <defs>
        <clipPath id="clip0_809_8755">
          <rect width={24} height={24} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WalletIcon;
