import React from 'react';

export default function EyePasswordCloseIcon() {
  return (
    <svg width={21} height={20} viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3347_80510)">
        <path
          d="M39.5 13.1663C22.6109 13.1663 7.90096 23.774 0 39.4999C7.90096 55.2287 22.6109 65.8336 39.5 65.8336C56.3891 65.8336 71.099 55.2287 79 39.4999C71.099 23.774 56.3891 13.1663 39.5 13.1663ZM39.5 55.9586C30.409 55.9586 23.0413 48.5909 23.0413 39.4999C23.0413 30.4089 30.409 23.0413 39.5 23.0413C48.591 23.0413 55.9587 30.4089 55.9587 39.4999C55.9587 48.5909 48.591 55.9586 39.5 55.9586Z"
          fill="#A2A3A4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.8626 7.8592C74.4359 9.52343 74.3622 12.148 72.698 13.7212L11.4985 71.5761C9.8343 73.1494 7.20977 73.0757 5.63648 71.4115C4.0632 69.7472 4.13693 67.1227 5.80116 65.5494L67.0006 7.69452C68.6648 6.12123 71.2894 6.19496 72.8626 7.8592Z"
          fill="#A2A3A4"
        />
        <path
          d="M39.5 49.375C44.9538 49.375 49.375 44.9538 49.375 39.5C49.375 34.0462 44.9538 29.625 39.5 29.625C34.0462 29.625 29.625 34.0462 29.625 39.5C29.625 44.9538 34.0462 49.375 39.5 49.375Z"
          fill="#A2A3A4"
        />
      </g>
      <defs>
        <clipPath id="clip0_3347_80510">
          <rect width={79} height={79} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
