import React from 'react';
import styles from '../../styles/UI/tooltip.module.scss';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

interface PropsType {
  children: React.ReactNode;
  title: React.ReactChild;
}

export default function Tooltip({ children, title }: PropsType) {
  return (
    <div className={cx('root')}>
      {children}
      <div className={cx('title-container')}>
        <p>{title}</p>
      </div>
    </div>
  );
}
