export default function CopyColorIcon() {
  return (
    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.75 5.5H2.5C1.81062 5.5 1.25 6.06062 1.25 6.75V13C1.25 13.6894 1.81062 14.25 2.5 14.25H8.75C9.43937 14.25 10 13.6894 10 13V6.75C10 6.06062 9.43937 5.5 8.75 5.5Z"
        fill="url(#paint0_linear_2604_11319)"
      />
      <path
        d="M12.5 1.75H6.25C5.91848 1.75 5.60054 1.8817 5.36612 2.11612C5.1317 2.35054 5 2.66848 5 3V4.25H10C10.3315 4.25 10.6495 4.3817 10.8839 4.61612C11.1183 4.85054 11.25 5.16848 11.25 5.5V10.5H12.5C12.8315 10.5 13.1495 10.3683 13.3839 10.1339C13.6183 9.89946 13.75 9.58152 13.75 9.25V3C13.75 2.66848 13.6183 2.35054 13.3839 2.11612C13.1495 1.8817 12.8315 1.75 12.5 1.75Z"
        fill="url(#paint1_linear_2604_11319)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2604_11319"
          x1="1.25"
          y1="9.875"
          x2={10}
          y2="9.875"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2604_11319"
          x1={5}
          y1="6.125"
          x2="13.75"
          y2="6.125"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
      </defs>
    </svg>
  );
}
