import { CrashGameTurnType } from 'bybet-game-js/lib/schema/CrashGame';
import { useCallback, useEffect, useRef } from 'react';
import { RootState, useAppSelector } from '../../../stores';
import { subName } from '../../../utils';

interface PropsType {
  xCoordinate: number;
  yCoordinate: number;
}

interface Drop {
  x: number;
  y: number;
  opacity: number;
  user: string;
}

interface Coordinates {
  x: number;
  y: number;
}

export default function Falldown({ xCoordinate, yCoordinate }: PropsType) {
  const fallDownRef = useRef<HTMLCanvasElement>(null);
  const crashGameIns = useAppSelector((state: RootState) => state.crashGame.crashGameIns);
  const currentTurn = useAppSelector((state: RootState) => state.crashGame.currentTurn);
  const dropsRef = useRef<Drop[]>([]);
  const coordinatesRef = useRef<Coordinates>({ x: 0, y: 0 });
  const userIdentityClient = useAppSelector((state) => state.app.userIdentityClient);

  const draw = () => {
    const canvas = fallDownRef.current;
    const ctx = canvas?.getContext('2d');
    if (ctx && canvas) {
      ctx.clearRect(0, 0, canvas?.width, canvas?.height);

      const widthHalf = 40;
      const heightHalf = 15;

      const speedFall = 0.5;
      const deviationAngle = 0.05;

      dropsRef.current.forEach((dropItem, index) => {
        ctx.save();
        ctx.beginPath();
        ctx.globalAlpha = dropItem.opacity / 100;
        ctx.fillStyle = '#FFFFFF';
        ctx.textAlign = 'center';
        ctx.fillText(dropItem.user, dropItem.x, dropItem?.y + 5);

        ctx.fillStyle = '#2C2C2C50';
        ctx.strokeStyle = '#2C2C2C50';
        ctx.moveTo(dropItem.x + widthHalf, dropItem?.y);
        ctx.arc(dropItem.x + widthHalf, dropItem?.y, heightHalf, (3 * Math.PI) / 2, Math.PI / 2);
        ctx.moveTo(dropItem.x - widthHalf, dropItem?.y - heightHalf);
        ctx.fillRect(
          dropItem.x - widthHalf,
          dropItem?.y - heightHalf,
          widthHalf * 2,
          heightHalf * 2
        );
        ctx.moveTo(dropItem.x - widthHalf, dropItem?.y);
        ctx.arc(dropItem.x - widthHalf, dropItem?.y, heightHalf, Math.PI / 2, (3 * Math.PI) / 2);

        ctx.fill();
        dropsRef.current[index].x = dropItem.x - deviationAngle;
        dropsRef.current[index].y = dropItem.y + speedFall;
        dropsRef.current[index].opacity =
          dropItem.opacity - speedFall * 1.25 >= 0 ? dropItem.opacity - speedFall * 1.25 : 0;

        ctx.restore();
      });

      requestAnimationFrame(draw);
    }
  };

  const init = useCallback(async (uid: string | undefined) => {
    const user = await userIdentityClient.getUser(uid || '');
    dropsRef.current = dropsRef.current.concat([
      {
        x: coordinatesRef.current?.x,
        y: coordinatesRef.current?.y,
        opacity: 100,
        user: subName(user.nickName || 'Hidden', 15)
      }
    ]);
    requestAnimationFrame(draw);
  }, []);

  useEffect(() => {
    coordinatesRef.current = { x: xCoordinate, y: yCoordinate };
  }, [xCoordinate, yCoordinate]);

  useEffect(() => {
    if (crashGameIns) {
      crashGameIns.onPlayerStateChange((player) => {
        if (player.isWin) {
          init(player.uid);
        }
      });
    }
  }, [crashGameIns]);

  useEffect(() => {
    const canvas = fallDownRef.current;
    const ctx = canvas?.getContext('2d');
    if (currentTurn === CrashGameTurnType.WAITING_FOR_BET && ctx && canvas) {
      ctx.clearRect(0, 0, canvas?.width, canvas?.height);
    }
  }, [currentTurn]);

  return <canvas id={'fall-down-canvas'} ref={fallDownRef} width="2000" height="1000" />;
}
