import classNames from 'classnames/bind';
import styles from '../../styles/components/affiliate/start-earning.module.scss';

const cx = classNames.bind(styles);

const StartEarning = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('title')}>START EARNING TODAY</div>
      <div className={cx('item')}>
        <div className={cx('item-content')}>
          <div className={cx('item-header')}>
            <div className={cx('item-label')}>Build Your Own Casino Club Quickly</div>
            <div className={cx('header-img')}>
              <img src="/images/card.svg" alt="" />
            </div>
          </div>

          <div className={cx('item-text')}>
            <div className={cx('text')}>
              It's hard to build a casino, but you can start from managing your own table on BYBET
              GAME.
            </div>
            <div className={cx('text')}>
              <span>BYBET GAME</span> is not just games, it’s a platform to realize the potential of
              your hidden dreams. Join BYBET GAME and set sail on a new chapter in your wealth
              journey immediately!
            </div>
          </div>
        </div>
        <div className={cx('item-content')}>
          <div className={cx('item-header')}>
            <div className={cx('item-label')}>Build A Casino Website With Your Own Domain</div>
            <div className={cx('header-img')}>
              <img src="/images/computer.png" alt="" />
            </div>
          </div>

          <div className={cx('item-text')}>
            <div className={cx('text')}>
              If you got your own plan. you can set up a casino website with your own domain, design
              style and theme. If you are capable to invite players and their wager amount reaches a
              billion dollars and above, you will be able to get your own customized casino!
            </div>
            <div className={cx('text')}>
              Contact <span>Affiliate@bybet.game</span> for more details
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartEarning;
