import classnames from 'classnames/bind';
import { useForm } from 'react-hook-form';
import styles from '../../../styles/components/games/crash/trenball-manual.module.scss';
import ArrowDown from '../../Icons/ArrowDown';
import InfoYellowIcon from '../../Icons/InfoYellowIcon';
import Input from '../../UI/Input';

const cx = classnames.bind(styles);

export default function TrenballManual() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();
  const onSubmit = (data: any) => console.log(data);

  const bets = [
    {
      key: 'red',
      gradient: 'radial-gradient(50% 50% at 50% 50%, #E64A5B 0%, #EA1930 100%)',
      name: 'Bet Red'
    },
    {
      key: 'green',
      gradient: 'radial-gradient(50% 50% at 50% 50%, #32D170 0%, #23A454 100%)',
      name: 'Bet Green'
    },
    {
      key: 'moon',
      gradient: 'linear-gradient(90deg, #FFE036 0%, #FBC006 100%)',
      name: 'Bet Moon'
    }
  ];

  return (
    <form className={cx('form-container')} onSubmit={handleSubmit(onSubmit)}>
      <div className={cx('input-container')}>
        <div className={cx('amount-label')}>
          <p>Amount</p>
          <InfoYellowIcon />
        </div>

        <Input
          prefix={<img src="/images/tokens/bnb.svg" alt="bnb" className={cx('tokens')} />}
          suffix={
            <div className={cx('suffix-group')}>
              <div className={cx('suffix-btn')}>
                <p>/2</p>
              </div>
              <div className={cx('suffix-btn')}>
                <p>x2</p>
              </div>
              <div className={cx('suffix-btn', 'arrows')}>
                <div className={cx('arrow-up')}>
                  <ArrowDown width={10} height={6} />
                </div>
                <div>
                  <ArrowDown width={10} height={6} />
                </div>
              </div>
            </div>
          }
          {...register('amount')}
        />
      </div>

      <div className={cx('bet-container')}>
        {bets.map((bet) => (
          <BetItem key={bet.key} gradient={bet.gradient} name={bet.name} />
        ))}
      </div>
    </form>
  );
}

interface PropsTypeBetItem {
  name: string;
  gradient: string;
}

const BetItem = ({ gradient, name }: PropsTypeBetItem) => {
  return (
    <div className={cx('bet-item-container')}>
      <p className={cx('label')}>
        Payout <span>1.96x</span>
      </p>

      <div className={cx('bet-item-card-container')}>
        <div className={cx('bet-item-ball')} style={{ background: gradient }} />
        <div>
          <p>{name}</p>
          <p>(Next round)</p>
        </div>
      </div>
    </div>
  );
};
