import classNames from 'classnames/bind';
import styles from '../../../styles/components/wallet/transactions.module.scss';
import { ColumnType } from '../../../types/tablet';

const cx = classNames.bind(styles);

interface PropsType {
  dataSource: any;
  columns: ColumnType[];
}

const TabletTransaction = ({ dataSource, columns }: PropsType) => {
  return (
    <div className={cx('table-transaction')}>
      {dataSource.length > 0 ? (
        <div className={cx('table-ui')}>
          <table>
            <thead>
              <tr>
                {columns.map((item) => (
                  <th key={item.key}>{item.title}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {dataSource.map((data: any) => {
                return (
                  <tr key={data.key}>
                    {columns.map((column) => (
                      <td key={column.key}>
                        {column.render
                          ? column.render(data[column.dataIndex], data)
                          : data[column.dataIndex]}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className={cx('no-data')}>
          <div>
            <img src="/images/ufo.png" alt="ufo" />
          </div>
          <div>Oops! There is no data yet!</div>
        </div>
      )}
    </div>
  );
};

export default TabletTransaction;
