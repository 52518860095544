import styles from '../../styles/components/games/auto-bet.module.scss';
import classnames from 'classnames/bind';
import Switch from './Switch';
import { useSelector } from 'react-redux';
import { RootState } from '../../stores';
import { BigNumber } from 'bignumber.js';
import { BET_DECIMAL_CONFIG } from '../../constant/game';

const cx = classnames.bind(styles);
interface AutoBetProps {
  numberOfBets: number;
  setNumberOfBets: (value: number) => void;
  increaseOnWin: number;
  setIncreaseOnWin: (value: number) => void;
  stopOnWin: string;
  setStopOnWin: (number: string) => void;
  increaseOnLose: number;
  setIncreaseOnLose: (value: number) => void;
  stopOnLose: string;
  setStopOnLose: (number: string) => void;
}
const AutoBet = ({
  numberOfBets,
  increaseOnWin,
  stopOnWin,
  increaseOnLose,
  stopOnLose,
  setNumberOfBets,
  setIncreaseOnWin,
  setStopOnWin,
  setIncreaseOnLose,
  setStopOnLose
}: AutoBetProps) => {
  const currency = useSelector((state: RootState) => state.account.currency);

  return (
    <div className={cx('auto-bet')}>
      <div>
        <div>Number of Bets </div>
        <div className={cx('row', 'input-amount')}>
          <input
            type="number"
            min={0}
            value={numberOfBets}
            onChange={(event) => setNumberOfBets(Number(event.target.value))}
          />
          <div className={cx('quick-action', 'option')} onClick={() => setNumberOfBets(0)}>
            ∞
          </div>
          <div className={cx('quick-action', 'option')} onClick={() => setNumberOfBets(10)}>
            10
          </div>
          <div className={cx('quick-action', 'option')} onClick={() => setNumberOfBets(100)}>
            100
          </div>
        </div>
      </div>
      <div>
        <div>On win </div>
        <div className={cx('input-amount')}>
          <div className={cx('switch-wrapper')}>
            <Switch className={cx('switch')} />
            <div className={cx('reset-label')}>
              <div className={cx('reset')}>Reset</div>
              <div className={cx('increase')}>Increase by</div>
            </div>
          </div>
          <input
            value={increaseOnWin}
            onChange={(event) => setIncreaseOnWin(new BigNumber(event.target.value).toNumber())}
          />
          <div className={cx('percent')}>%</div>
        </div>
      </div>
      <div>
        <div className={cx('row')}>
          <div>Stop on win</div> <div>0 USD</div>
        </div>
        <div className={cx('row', 'input-amount', 'stop-milestone')}>
          <img
            src={`/images/tokens/${currency.symbol.toLowerCase()}.svg?v=2`}
            alt={currency.symbol}
          />

          <input
            value={stopOnWin}
            onChange={(event) =>
              setStopOnWin(new BigNumber(event.target.value).toFixed(BET_DECIMAL_CONFIG))
            }
          />
        </div>
      </div>
      <div>
        <div>On lose </div>
        <div className={cx('input-amount')}>
          <div className={cx('switch-wrapper')}>
            <Switch className={cx('switch')} />
            <div className={cx('reset-label')}>
              <div className={cx('reset')}>Reset</div>
              <div className={cx('increase')}>Increase by</div>
            </div>
          </div>
          <input
            value={increaseOnLose}
            onChange={(event) => setIncreaseOnLose(new BigNumber(event.target.value).toNumber())}
          />
          <div className={cx('percent')}>%</div>
        </div>
      </div>
      <div>
        <div className={cx('row')}>
          <div>Stop on lose </div> <div>0 USD</div>
        </div>
        <div className={cx('input-amount', 'stop-milestone')}>
          <img
            src={`/images/tokens/${currency.symbol.toLowerCase()}.svg?v=2`}
            alt={currency.symbol}
          />

          <input
            value={stopOnLose}
            onChange={(event) =>
              setStopOnLose(new BigNumber(event.target.value).toFixed(BET_DECIMAL_CONFIG))
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AutoBet;
