export default function CommisionRewardsIcon() {
  return (
    <svg
      width={150}
      height={150}
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1931_44622)">
        <path
          d="M99.491 56.2451C99.2657 56.6892 98.7167 56.8679 98.2725 56.6423L69.9073 42.2552C69.4629 42.0296 69.2842 41.4818 69.5098 41.0379L89.3493 2.00689C89.5749 1.56275 90.1233 1.38404 90.5678 1.60962L118.933 15.9967C119.377 16.2223 119.556 16.7702 119.331 17.214L99.491 56.2451Z"
          fill="#60A041"
        />
        <path
          d="M80.6707 38.7452C80.2254 38.5196 80.0466 37.9721 80.2728 37.5279L92.8629 12.7586C93.0887 12.3145 93.6372 12.1357 94.0816 12.3613L108.17 19.5071C108.615 19.7324 108.794 20.2803 108.567 20.7244L95.9774 45.4938C95.7515 45.9379 95.2028 46.1166 94.7589 45.891L80.6707 38.7452Z"
          fill="#4B7D32"
        />
        <path
          d="M96.7245 44.6795C96.7245 45.1775 96.3164 45.5851 95.8181 45.5851H64.0061C63.5074 45.5851 63.0996 45.1775 63.0996 44.6795V0.905566C63.0996 0.40752 63.5074 0 64.0061 0H95.8184C96.3167 0 96.7248 0.40752 96.7248 0.905566V44.6795H96.7245Z"
          fill="#89C947"
        />
        <path
          d="M72.0119 37.5877C71.5133 37.5877 71.1055 37.1802 71.1055 36.6821V8.90283C71.1055 8.40479 71.5133 7.99756 72.0119 7.99756H87.8123C88.3109 7.99756 88.7187 8.40508 88.7187 8.90283V36.6821C88.7187 37.1802 88.3109 37.5877 87.8123 37.5877H72.0119Z"
          fill="#4B7D32"
        />
        <path
          d="M79.9133 41.1874C84.2243 41.1874 87.7191 37.6962 87.7191 33.3897C87.7191 29.0832 84.2243 25.592 79.9133 25.592C75.6022 25.592 72.1074 29.0832 72.1074 33.3897C72.1074 37.6962 75.6022 41.1874 79.9133 41.1874Z"
          fill="#89C947"
        />
        <path
          d="M101.416 53.9823C103.078 53.9823 105.117 52.8061 105.948 51.3685L114.876 35.9217C115.707 34.4841 115.027 33.3081 113.365 33.3081H58.5315C56.8695 33.3081 56.1898 34.4841 57.0206 35.9217L65.9486 51.3685C66.7794 52.8061 68.8188 53.9823 70.4808 53.9823H101.416Z"
          fill="#A67C52"
        />
        <path
          d="M53.7772 145.153C52.8764 145.153 52.0095 144.811 51.3515 144.197C41.1166 134.648 35.2461 121.155 35.2461 107.177C35.2461 79.2499 57.9913 56.5295 85.9481 56.5295C113.906 56.5295 136.65 79.2499 136.65 107.177C136.65 121.155 130.781 134.649 120.546 144.197C119.887 144.811 119.02 145.153 118.12 145.153H53.7772Z"
          fill="#A67C52"
        />
        <path
          opacity="0.1"
          d="M41.8944 107.177C41.8944 80.3658 62.8587 58.3577 89.2713 56.6409C88.1727 56.5683 87.0652 56.5293 85.9481 56.5293C57.991 56.5293 35.2461 79.2496 35.2461 107.177C35.2461 121.155 41.1166 134.649 51.3515 144.197C52.0095 144.811 52.8764 145.152 53.7772 145.152H60.4256C59.5247 145.152 58.6578 144.811 57.9998 144.197C47.7649 134.649 41.8944 121.155 41.8944 107.177Z"
          fill="black"
        />
        <path
          d="M85.9482 125.839C99.1464 125.839 109.846 115.151 109.846 101.968C109.846 88.7841 99.1464 78.0967 85.9482 78.0967C72.75 78.0967 62.0508 88.7841 62.0508 101.968C62.0508 115.151 72.75 125.839 85.9482 125.839Z"
          fill="#4B7D32"
        />
        <path
          opacity="0.1"
          d="M68.0506 101.967C68.0506 89.7987 77.1666 79.764 88.9475 78.2892C87.9646 78.1658 86.9646 78.0952 85.948 78.0952C72.7504 78.0952 62.0518 88.783 62.0518 101.967C62.0518 115.15 72.7507 125.838 85.948 125.838C86.9649 125.838 87.9646 125.767 88.9475 125.644C77.1669 124.169 68.0506 114.135 68.0506 101.967Z"
          fill="black"
        />
        <path
          d="M85.948 128.035C71.5601 128.035 59.8545 116.341 59.8545 101.967C59.8545 87.5924 71.5601 75.8979 85.948 75.8979C100.336 75.8979 112.042 87.5924 112.042 101.967C112.042 116.341 100.336 128.035 85.948 128.035ZM85.948 80.2925C73.9832 80.2925 64.249 90.0155 64.249 101.967C64.249 113.918 73.9835 123.64 85.948 123.64C97.9135 123.64 107.648 113.917 107.648 101.967C107.648 90.0155 97.9135 80.2925 85.948 80.2925Z"
          fill="#60A041"
        />
        <path
          d="M80.3438 58.008C79.13 58.008 78.1465 57.0242 78.1465 55.8107V45.585C78.1465 44.3715 79.13 43.3877 80.3438 43.3877C81.5575 43.3877 82.541 44.3715 82.541 45.585V55.8107C82.541 57.0242 81.5575 58.008 80.3438 58.008Z"
          fill="#8C6239"
        />
        <path
          d="M91.5527 58.0081C90.339 58.0081 89.3555 57.0243 89.3555 55.8108V47.1843C89.3555 45.9708 90.339 44.9871 91.5527 44.9871C92.7665 44.9871 93.75 45.9708 93.75 47.1843V55.8108C93.75 57.0243 92.7665 58.0081 91.5527 58.0081Z"
          fill="#8C6239"
        />
        <path
          d="M89.9069 99.0533H86.4133H84.5509H81.9892C80.761 99.0533 79.7617 98.0563 79.7617 96.8309V95.9001C79.7617 94.6749 80.7607 93.6779 81.9892 93.6779H91.0709C92.2847 93.6779 93.2682 92.6941 93.2682 91.4807C93.2682 90.2672 92.2847 89.2834 91.0709 89.2834H88.145V86.3628C88.145 85.1493 87.1615 84.1655 85.9478 84.1655C84.734 84.1655 83.7505 85.1493 83.7505 86.3628V89.2834H81.9892C78.3379 89.2834 75.3672 92.2515 75.3672 95.9001V96.8309C75.3672 100.479 78.3379 103.448 81.9892 103.448H84.5509H86.4133H89.9069C91.1351 103.448 92.1344 104.445 92.1344 105.67V106.601C92.1344 107.826 91.1354 108.823 89.9069 108.823H78.9616C77.7478 108.823 76.7644 109.807 76.7644 111.021C76.7644 112.234 77.7478 113.218 78.9616 113.218H83.7508V116.371C83.7508 117.584 84.7343 118.568 85.948 118.568C87.1618 118.568 88.1453 117.584 88.1453 116.371V113.218H89.9072C93.5585 113.218 96.5292 110.249 96.5292 106.601V105.67C96.5286 102.022 93.5582 99.0533 89.9069 99.0533Z"
          fill="#89C947"
        />
        <path
          d="M73.9418 138.005V130.536C73.9418 129.706 73.2621 129.027 72.4313 129.027H47.2122C48.0431 129.027 48.7228 128.348 48.7228 127.518V120.05C48.7228 119.22 48.0431 118.541 47.2122 118.541C48.0431 118.541 48.7228 117.862 48.7228 117.032V109.563C48.7228 108.733 48.0431 108.054 47.2122 108.054C48.0431 108.054 48.7228 107.375 48.7228 106.545V99.077C48.7228 98.247 48.0431 97.5679 47.2122 97.5679H18.1746C17.3437 97.5679 16.6641 98.247 16.6641 99.077V106.545C16.6641 107.375 17.3437 108.054 18.1746 108.054C17.3437 108.054 16.6641 108.733 16.6641 109.563V117.032C16.6641 117.862 17.3437 118.541 18.1746 118.541C17.3437 118.541 16.6641 119.22 16.6641 120.05V127.518C16.6641 128.348 17.3437 129.027 18.1746 129.027C17.3437 129.027 16.6641 129.706 16.6641 130.536V138.005C16.6641 138.835 17.3437 139.514 18.1746 139.514C17.3437 139.514 16.6641 140.193 16.6641 141.023V148.491C16.6641 149.321 17.3437 150 18.1746 150H43.3934H47.2119H72.431C73.2618 150 73.9415 149.321 73.9415 148.491V141.023C73.9415 140.193 73.2618 139.514 72.431 139.514C73.2618 139.514 73.9418 138.835 73.9418 138.005Z"
          fill="#FECF05"
        />
        <path
          d="M48.7228 148.491C48.7228 149.321 48.0431 150 47.2122 150H18.1746C17.3437 150 16.6641 149.321 16.6641 148.491V141.023C16.6641 140.193 17.3437 139.514 18.1746 139.514H47.2119C48.0428 139.514 48.7225 140.193 48.7225 141.023L48.7228 148.491Z"
          fill="#FECF05"
        />
        <path
          d="M48.7228 138.005C48.7228 138.835 48.0431 139.514 47.2122 139.514H18.1746C17.3437 139.514 16.6641 138.835 16.6641 138.005V130.536C16.6641 129.706 17.3437 129.027 18.1746 129.027H47.2119C48.0428 129.027 48.7225 129.706 48.7225 130.536L48.7228 138.005Z"
          fill="#F8B816"
        />
        <path
          d="M48.7228 127.518C48.7228 128.348 48.0431 129.027 47.2122 129.027H18.1746C17.3437 129.027 16.6641 128.348 16.6641 127.518V120.05C16.6641 119.22 17.3437 118.541 18.1746 118.541H47.2119C48.0428 118.541 48.7225 119.22 48.7225 120.05L48.7228 127.518Z"
          fill="#FECF05"
        />
        <path
          d="M48.7228 117.032C48.7228 117.862 48.0431 118.541 47.2122 118.541H18.1746C17.3437 118.541 16.6641 117.862 16.6641 117.032V109.564C16.6641 108.734 17.3437 108.054 18.1746 108.054H47.2119C48.0428 108.054 48.7225 108.734 48.7225 109.564L48.7228 117.032Z"
          fill="#F8B816"
        />
        <path
          d="M48.7228 106.546C48.7228 107.375 48.0431 108.055 47.2122 108.055H18.1746C17.3437 108.055 16.6641 107.375 16.6641 106.546V99.0774C16.6641 98.2475 17.3437 97.5684 18.1746 97.5684H47.2119C48.0428 97.5684 48.7225 98.2475 48.7225 99.0774L48.7228 106.546Z"
          fill="#FECF05"
        />
        <path
          d="M73.9425 148.491C73.9425 149.321 73.2628 150 72.4319 150H43.3943C42.5635 150 41.8838 149.321 41.8838 148.491V141.023C41.8838 140.193 42.5635 139.514 43.3943 139.514H72.4319C73.2628 139.514 73.9425 140.193 73.9425 141.023V148.491Z"
          fill="#F8B816"
        />
        <path
          d="M73.9425 138.005C73.9425 138.835 73.2628 139.514 72.4319 139.514H43.3943C42.5635 139.514 41.8838 138.835 41.8838 138.005V130.536C41.8838 129.706 42.5635 129.027 43.3943 129.027H72.4319C73.2628 129.027 73.9425 129.706 73.9425 130.536V138.005Z"
          fill="#FECF05"
        />
        <path
          d="M110.734 61.0128C110.734 61.8428 110.054 62.5219 109.223 62.5219H62.6736C61.8428 62.5219 61.1631 61.8428 61.1631 61.0128V53.492C61.1631 52.662 61.8428 51.9829 62.6736 51.9829H109.223C110.054 51.9829 110.734 52.662 110.734 53.492V61.0128Z"
          fill="#EBB272"
        />
        <path
          d="M22.8128 86.0947C22.4381 86.0947 22.1003 85.8688 21.957 85.5229C20.0211 80.8526 18.5946 79.4268 13.9221 77.4914C13.5755 77.3484 13.3496 77.0103 13.3496 76.6362C13.3496 76.2615 13.5755 75.9237 13.9221 75.7808C18.5943 73.846 20.0208 72.4201 21.957 67.7496C22.1003 67.4033 22.4381 67.1777 22.8128 67.1777C23.1872 67.1777 23.5256 67.4033 23.6688 67.7496C25.6045 72.4201 27.0315 73.8457 31.7038 75.7808C32.0504 75.924 32.2763 76.2618 32.2763 76.6362C32.2763 77.0106 32.0504 77.3487 31.7038 77.4914C27.0315 79.4268 25.6048 80.8526 23.6688 85.5229C23.5256 85.8691 23.1875 86.0947 22.8128 86.0947Z"
          fill="#F8B816"
        />
        <path
          d="M38.0235 58.9021C37.7437 58.9021 37.4918 58.7337 37.3843 58.475C35.9396 54.9901 34.8753 53.9258 31.3881 52.4817C31.1297 52.3748 30.9609 52.1228 30.9609 51.8434C30.9609 51.5639 31.1297 51.3116 31.3881 51.2047C34.875 49.7609 35.9396 48.6966 37.3843 45.2117C37.4915 44.9533 37.7437 44.7852 38.0235 44.7852C38.3024 44.7852 38.555 44.9533 38.6619 45.2117C40.1068 48.6969 41.1709 49.7609 44.6581 51.2047C44.9168 51.3116 45.0855 51.5639 45.0855 51.8434C45.0855 52.1228 44.9168 52.3745 44.6581 52.4817C41.1712 53.9258 40.1068 54.9901 38.6619 58.475C38.555 58.7337 38.3024 58.9021 38.0235 58.9021Z"
          fill="#FECF05"
        />
        <path
          opacity="0.1"
          d="M20.9446 148.491V141.023C20.9446 140.193 21.6243 139.514 22.4552 139.514C21.6243 139.514 20.9446 138.835 20.9446 138.005V130.536C20.9446 129.706 21.6243 129.027 22.4552 129.027C21.6243 129.027 20.9446 128.348 20.9446 127.518V120.05C20.9446 119.22 21.6243 118.541 22.4552 118.541C21.6243 118.541 20.9446 117.862 20.9446 117.032V109.563C20.9446 108.733 21.6243 108.054 22.4552 108.054C21.6243 108.054 20.9446 107.375 20.9446 106.545V99.077C20.9446 98.247 21.6243 97.5679 22.4552 97.5679H18.1746C17.3437 97.5679 16.6641 98.247 16.6641 99.077V106.545C16.6641 107.375 17.3437 108.054 18.1746 108.054C17.3437 108.054 16.6641 108.733 16.6641 109.563V117.032C16.6641 117.862 17.3437 118.541 18.1746 118.541C17.3437 118.541 16.6641 119.22 16.6641 120.05V127.518C16.6641 128.348 17.3437 129.027 18.1746 129.027C17.3437 129.027 16.6641 129.706 16.6641 130.536V138.005C16.6641 138.835 17.3437 139.514 18.1746 139.514C17.3437 139.514 16.6641 140.193 16.6641 141.023V148.491C16.6641 149.321 17.3437 150 18.1746 150H22.4552C21.6246 150 20.9446 149.321 20.9446 148.491Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M45.3987 148.491V141.023C45.3987 140.193 46.0784 139.514 46.9093 139.514C46.0784 139.514 45.3987 138.835 45.3987 138.005V130.536C45.3987 129.706 46.0784 129.027 46.9093 129.027H43.3934C42.5625 129.027 41.8828 129.706 41.8828 130.536V138.005C41.8828 138.835 42.5625 139.514 43.3934 139.514C42.5625 139.514 41.8828 140.193 41.8828 141.023V148.491C41.8828 149.321 42.5625 150 43.3934 150H46.9093C46.0784 150 45.3987 149.321 45.3987 148.491Z"
          fill="black"
        />
        <path
          d="M128.237 49.1769L130.048 46L131.742 49.2391L134.917 51.0537L131.68 52.7496L129.869 55.9265L128.173 52.6858L125 50.8743L128.237 49.1769Z"
          fill="white"
        />
        <path
          d="M137.285 6.24029L138.563 4L139.759 6.2842L142 7.56377L139.715 8.75971L138.437 11L137.24 8.71476L135 7.43728L137.285 6.24029Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1931_44622">
          <rect width={150} height={150} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
