import React from 'react';
import classNames from 'classnames/bind';
import styles from '../../styles/components/wallet/withdraw.module.scss';
import InputCodeV2 from '../UI/InputCodeV2';
import { lengthVerificationCode } from '../../constant/2fa';
import { useAppSelector } from '../../stores';

const cx = classNames.bind(styles);

interface PropsType {
  actionText?: string;
  disable: boolean;
  note?: string;
  handleChangeCode?: (code: string) => void;
  fee?: string | React.ReactChild;
}

const VerificationCode = ({
  actionText = 'Confirm',
  disable,
  note,
  handleChangeCode,
  fee
}: PropsType) => {
  const { isEnable2FA } = useAppSelector((state) => ({
    isEnable2FA: state.userInfo.userInfo?.isEnableTwoFactor
  }));
  return (
    <div className={cx('verification-code')}>
      {fee && (
        <div className={cx('fee')}>
          Fee <span className={cx('text-yellow')}>{fee}</span>
        </div>
      )}

      {isEnable2FA && (
        <div className={cx('code-container')}>
          <p>Verification code</p>
          <div className={cx('input-code')}>
            <InputCodeV2 length={lengthVerificationCode} onChange={handleChangeCode} />
          </div>
        </div>
      )}
      <div className={cx('button-confirm')}>
        <button disabled={disable} type="submit">
          {actionText}
        </button>
      </div>
      <div className={cx('note')}>{note}</div>
    </div>
  );
};

export default VerificationCode;
