export const FIREBASE_ERRORS: any = {
  'auth/email-already-in-use': 'Email is already in use',
  'auth/id-token-expired': 'Token is expired',
  'auth/invalid-password': 'Invalid password',
  'auth/user-not-found': 'User not found',
  'auth/invalid-email': 'Invalid email',
  'auth/weak-password': 'Password is too weak',
  'auth/wrong-password': 'Wrong password',
  'auth/invalid-verification-id': 'Invalid verification id',
  'auth/admin-restricted-operation': 'Admin Restricted Operation'
};
