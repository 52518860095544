import classNames from 'classnames/bind';
import { ChangeEvent, useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { AVATAR_URL } from '../../constant';
import { useAppSelector } from '../../stores';
import { setAccountInfo } from '../../stores/AccountSlice';
import styles from '../../styles/components/user-setting/edit-avatar.module.scss';
import getCroppedImg from '../../utils/cropImage';
import CameraIcon from '../Icons/CameraIcon';
import UndoIcon from '../Icons/UndoIcon';
import ZoomAndAdjustIcon from '../Icons/ZoomAndAdjustIcon';
import ZoomInIcon from '../Icons/ZoomInIcon';
import ZoomOutIcon from '../Icons/ZoomOutIcon';

const cx = classNames.bind(styles);

enum ZOOM_VALUE {
  MAX = 40,
  MIN = 1
}

const listImage = [7, 8, 9, 10, 11, 12];

interface PropsType {
  defaultAvt?: string;
  userId: string;
  reload: () => void;
}

const EditAvatar = ({ defaultAvt, userId, reload }: PropsType) => {
  const dispatch = useDispatch();
  const { account, userInfo } = useAppSelector((state) => ({
    account: state.account.accountInfo,
    userInfo: state.userInfo.userInfo
  }));
  const userIdentityCLient = useAppSelector((state) => state.app.userIdentityClient);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(ZOOM_VALUE.MIN);
  const [rotation, setRotation] = useState(0);
  const [avatar, setAvatar] = useState<string>(`${userInfo?.avatar}?v=1`);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isUploadLoading, setIsUploadLoading] = useState(false);

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files !== null) {
      const file = files[0];
      const url = URL.createObjectURL(file);
      const isMaxSize2M = file.size / 1024 / 1024 < 2;
      const isFileType = file.type === 'image/png' || file.type === 'image/jpeg';
      if (!isFileType) {
        toast.error('Only accept file .png, .jpg!');
      } else if (!isMaxSize2M) {
        toast.error('Image must smaller than 2MB!');
      } else {
        setAvatar(url);
      }
    }
  };

  const onClickAvatarDefault = (idImage: number) => {
    setAvatar(`${AVATAR_URL}/${idImage}.png?v=1`);
  };

  const showCroppedImage = async (imageSrc: string) => {
    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
    return croppedImage as any;
  };

  const uploadFileUser = async (file: File) => {
    const fileUpload = await userIdentityCLient.uploadFile(file);
    return fileUpload.data.url;
  };

  const updateAvatarRequest = async (url: string) => {
    const body = {
      avatar: url
    };
    await userIdentityCLient.updateUser(userId, body);
  };

  const uploadAvatar = async () => {
    setIsUploadLoading(true);
    try {
      const file = avatar && (await showCroppedImage(avatar));
      const url = file && (await uploadFileUser(file));
      if (url) await updateAvatarRequest(url);
      dispatch(setAccountInfo({ ...account, photoURL: url || '' }));
      setIsUploadLoading(false);
      toast.success('Upload successfully!');
      // reload();
    } catch (error) {
      setIsUploadLoading(false);
      console.log(error);
      toast.error('Upload Error');
    }
  };

  return (
    <div className={cx('content')}>
      <div className={cx('avatar')}>
        <Cropper
          image={avatar || defaultAvt}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          showGrid={false}
          objectFit="auto-cover"
        />
        <div className={cx('camera-icon')}>
          <CameraIcon />
          <input onChange={onChangeFile} type="file" accept="image/png, image/jpeg" />
        </div>
        <div className={cx('zoom-and-adjust')}>
          <ZoomAndAdjustIcon /> Zoom and Adjust
        </div>
      </div>
      <div className={cx('custom-image')}>
        <div className={cx('zoom')}>
          <div
            className={cx('zoom-icon-wrapper', 'zoom-out')}
            onClick={() => setZoom(zoom == ZOOM_VALUE.MIN ? zoom : zoom - 1)}>
            <ZoomOutIcon />
          </div>
          <input
            type="range"
            min={ZOOM_VALUE.MIN}
            max={ZOOM_VALUE.MAX}
            className={cx('slider')}
            id="myRange"
            value={zoom}
            onChange={(event) => setZoom(+event.target.value)}
          />
          <div
            className={cx('zoom-icon-wrapper', 'zoom-in')}
            onClick={() => setZoom(zoom == ZOOM_VALUE.MAX ? zoom : zoom + 1)}>
            <ZoomInIcon />
          </div>
        </div>
        <div className={cx('rotate-image')}>
          <div className={cx('undo')} onClick={() => setRotation(rotation - 45)}>
            <UndoIcon />
          </div>
          <div className={cx('redo')} onClick={() => setRotation(rotation + 45)}>
            <UndoIcon />
          </div>
        </div>
      </div>
      <div className={cx('list-default-image')}>
        <div className={cx('title-image')}>Default Avatar</div>
        <div className={cx('list-image')}>
          {listImage.slice(0, 6).map((item) => (
            <div key={item} onClick={() => onClickAvatarDefault(item)} className={cx('item-image')}>
              <img src={`https://cdn.bybet.io/avatar/${item}.png?v=1`} alt="avatar" />
            </div>
          ))}
        </div>
      </div>
      <div className={cx('submit')}>
        <button
          className={cx('button-submit', isUploadLoading && 'button-submit-disable')}
          type="button"
          onClick={uploadAvatar}>
          {isUploadLoading ? 'Loading...' : 'Submit'}
        </button>
      </div>
    </div>
  );
};

export default EditAvatar;
