import classNames from 'classnames/bind';
import styles from '../../styles/components/modal/modal-auth.module.scss';
import { AUTH } from '../../interfaces/Account';
import { setLoggedIn } from '../../stores/AccountSlice';
import { useDispatch } from 'react-redux';
const cx = classNames.bind(styles);

interface SignInProps {
  successVerification: boolean;
  handleTypeModalAuth: Function;
  resetModal: () => void;
}

const VerificationComplete = ({
  handleTypeModalAuth,
  resetModal,
  successVerification
}: SignInProps) => {
  const dispatch = useDispatch();

  return (
    <>
      {successVerification && (
        <>
          <img
            className={cx('success-verification')}
            src="/images/success-verification.png"
            alt="success-verification"
          />
          <div className={cx('verifycation-complete')}>Verification Complete</div>
          <div className={cx('congratulation')}>
            Congratulations! <br /> You have been successfully verified
          </div>
          <button
            className={cx('confirm', 'button-active')}
            onClick={() => {
              handleTypeModalAuth(AUTH.SIGN_IN);
              dispatch(setLoggedIn(true));
              resetModal();
            }}>
            Done
          </button>
        </>
      )}
    </>
  );
};

export default VerificationComplete;
