import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import styles from '../../styles/components/modal/modal-select-token.module.scss';
import { Asset, AssetBalance } from 'metaverse-js/lib/proto/model/asset';
import ModalUI from '../UI/Modal';
import ArrowDownIcon from '../Icons/ArrowDownIcon';
import { useAppDispatch, useAppSelector } from '../../stores';
import useModal from '../../hooks/useModal';
import { getUserLevel, setCurrency } from '../../stores/AccountSlice';
import SearchIcon from '../Icons/SearchIcon';
import BigNumber from 'bignumber.js';
import { getAllMyBalance, updateAssetBalance } from '../../stores/BalanceSlice';
import { storageService } from '../../services/storage';
import Skeleton from 'react-loading-skeleton';
import Switch from '../UI/Switch';
import NoData from '../NoData';
import { TOKENS } from '../../constant/token';
import { find } from 'lodash';
import MetaverseClient from 'metaverse-js';
import { GameProfileClient } from 'game-profile-js';
import CurrencyAmount from '../UI/CurrencyAmount';

const cx = classNames.bind(styles);

const HeaderModal = ({
  onSearch,
  search
}: {
  search: string;
  onSearch: (value: string) => void;
}) => {
  return (
    <div className={cx('header-title-modal')}>
      <div className={cx('title')}>Assets Portfolio</div>
    </div>
  );
};

const ModalSelectToken = () => {
  const {
    gameProfile,
    metaverseClient,
    currency,
    myBalances,
    isBalanceLoading,
    isBalanceError,
    priceAll
  } = useAppSelector((state) => ({
    userIdentityClient: state.app.userIdentityClient,
    gameProfile: state.app.gameProfileClient,
    metaverseClient: state.app.metaverseClient,
    myBalances: state.myBalances.balances,
    isBalanceLoading: state.myBalances.isBalanceLoading,
    currency: state.account.currency,
    account: state.account.accountInfo,
    isBalanceError: state.myBalances.isBalanceError,
    priceAll: state.price.prices
  }));
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<string>('');
  const [balances, setBalances] = useState<AssetBalance[]>([]);
  const [isHideSmall, setHideSmall] = useState<boolean>(false);
  const [assetsConfig, setAssetsConfig] = useState<Asset[]>([]);

  const { isShowing, toggle } = useModal();
  const localCurrency = storageService.getCurrency();

  const handleClosedModal = () => {
    toggle();
    setSearch('');
  };

  const fetchAssetsConfig = async (metaverseClient: MetaverseClient) => {
    const res = await metaverseClient.getAssets();
    if (res) {
      const assetsConfig = res.data;
      setAssetsConfig(assetsConfig);
    }
  };

  const updateBalances = async (balances: AssetBalance[]) => {
    const currentAssetSymbol = localCurrency.symbol || balances[0]?.assetSymbol || '';
    let currentAssetAmount = localCurrency.balance || balances[0]?.availableAssetAmount || '';
    balances.forEach(async (balance) => {
      if (balance.assetSymbol === currentAssetSymbol) {
        currentAssetAmount = balance.availableAssetAmount;
      }
    });
    dispatch(
      setCurrency({
        symbol: currentAssetSymbol,
        balance: currentAssetAmount
      })
    );
    setBalances(balances);
  };

  const onSearch = (value: string) => {
    setSearch(value);
  };

  const onClickItemAsset = (value: AssetBalance) => {
    if (value?.assetSymbol && value.availableAssetAmount) {
      const currency = { symbol: value?.assetSymbol, balance: value.availableAssetAmount };
      dispatch(setCurrency(currency));
      storageService.setCurrency(currency);
      setSearch('');
    }
    toggle();
  };

  useEffect(() => {
    if (myBalances) updateBalances(myBalances);
  }, [myBalances]);

  useEffect(() => {
    fetchAssetsConfig(metaverseClient);
    if (metaverseClient.tokenId) {
      const updateChain = async () => {
        await metaverseClient.watch();
        metaverseClient.onAssetBalanceChange((assetBalance: AssetBalance) => {
          dispatch(updateAssetBalance(assetBalance));
          dispatch(getUserLevel(gameProfile));
        });
      };
      updateChain();
    }
    dispatch(getAllMyBalance(metaverseClient));
  }, [metaverseClient.tokenId]);

  useEffect(() => {
    if (isBalanceError) {
      setTimeout(() => dispatch(getAllMyBalance(metaverseClient)), 1000);
    }
  }, [isBalanceError, metaverseClient]);

  const listBalanceSorted = [
    balances.find((item) => item.assetSymbol === TOKENS.FBET),
    balances.find((item) => item.assetSymbol === TOKENS.BBET),
    ...balances.filter(
      (item) => item.assetSymbol !== TOKENS.BBET && item.assetSymbol !== TOKENS.FBET
    )
  ];

  const handleCheckPrice = (assetBalance?: AssetBalance) => {
    const priceCurrency =
      priceAll.find((price) => price.symbol === assetBalance?.assetSymbol)?.price || 0;
    const isPriceCompare =
      new BigNumber(assetBalance?.availableAssetAmount || 0).multipliedBy(priceCurrency).lt(1) &&
      assetBalance?.assetSymbol !== TOKENS.FBET &&
      assetBalance?.assetSymbol !== TOKENS.BBET;
    return isHideSmall ? !isPriceCompare : assetBalance;
  };

  const handleSearchBalance = (assetBalance?: AssetBalance) => {
    return assetBalance?.assetSymbol?.toUpperCase().includes(search.toUpperCase());
  };

  const listAssets = listBalanceSorted?.filter(handleCheckPrice).filter(handleSearchBalance);

  return (
    <>
      <div className={cx('wallet-desc')}>
        <div onClick={handleClosedModal} className={cx('coin-name')}>
          {isBalanceLoading ? (
            <CurrencySekeleton />
          ) : (
            currency.symbol && (
              <>
                <div className={cx('icon')}>
                  <img src={`/images/tokens/${currency.symbol.toLowerCase()}.svg?v=2`} alt="" />
                </div>
                <div className={cx('name')}>{currency.symbol}</div>
                <div>{<ArrowDownIcon />}</div>
              </>
            )
          )}
        </div>
        <div className={cx('total-coin')}>
          {isBalanceLoading ? (
            <CurrencySekeleton />
          ) : (
            currency.balance && (
              <CurrencyAmount
                amount={currency?.balance || '0'}
                decimalPlaces={
                  find(assetsConfig, { symbol: currency?.symbol, chain: 'CHT' })?.decimalPlaces
                }
              />
            )
          )}
        </div>
      </div>
      <ModalUI
        backgroundHeader="#1B1B1B"
        title={<HeaderModal search={search} onSearch={onSearch} />}
        isShowing={isShowing}
        footer={<FooterModal setHideSmall={setHideSmall} isHideSmall={isHideSmall} />}
        hide={toggle}>
        <div className={cx('modal-assets')}>
          <div className={cx('header-title-modal')}>
            <div className={cx('input-search')}>
              <div className={cx('icon')}>
                <SearchIcon />
              </div>
              <input
                value={search}
                onChange={(e) => onSearch(e.target.value)}
                type="text"
                placeholder="Search"
              />
            </div>
          </div>
          {listAssets && listAssets.length > 0 ? (
            <ul className={cx('list-assets')}>
              {listAssets.map((item) => (
                <li
                  onClick={() => onClickItemAsset(item || {})}
                  key={`${item?.id}${item?.assetSymbol}${item?.assetAddress}`}
                  className={cx('item', currency.symbol === item?.assetSymbol && 'item-active')}>
                  <div className={cx('crypto-title')}>
                    <div className={cx('icon')}>
                      <img
                        src={`/images/tokens/${item?.assetSymbol?.toLowerCase()}.svg?v=2`}
                        alt=""
                      />
                    </div>
                    <div>{item?.assetSymbol || ''}</div>
                  </div>

                  <div className={cx('price-crypto')}>
                    <CurrencyAmount
                      amount={item?.availableAssetAmount || '0'}
                      decimalPlaces={
                        find(assetsConfig, { symbol: item?.assetSymbol, chain: 'CHT' })
                          ?.decimalPlaces
                      }
                    />
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className={cx('list-assets')}>
              <NoData />
            </div>
          )}
        </div>
      </ModalUI>
    </>
  );
};

const FooterModal = ({
  setHideSmall,
  isHideSmall
}: {
  isHideSmall: boolean;
  setHideSmall: (value: boolean) => void;
}) => {
  return (
    <div className={cx('modal-footer')}>
      {/* <div className={cx('switch')}>
        <Switch value={false} setValue={() => {}} />
        <div className={cx('text')}>View in fiat</div>
      </div> */}
      <div className={cx('switch')}>
        <Switch value={isHideSmall} setValue={() => setHideSmall(!isHideSmall)} />
        <div className={cx('text')}>Hide Small</div>
      </div>
    </div>
  );
};

const CurrencySekeleton = () => {
  return <Skeleton width={100} baseColor={'rgba(255, 255, 255, 0.05)'} />;
};

export default ModalSelectToken;
