import { useState } from 'react';
import classNames from 'classnames/bind';

import styles from '../../../styles/components/wallet/deposit.module.scss';
import ModalUI from '../../UI/Modal';
import Switch from '../../UI/Switch';
import { Asset, AssetBalance } from 'metaverse-js/lib/proto/model/asset';
import BigNumber from 'bignumber.js';
import SearchIcon from '../../Icons/SearchIcon';
import { useAppSelector } from '../../../stores';
import NoData from '../../NoData';
import { TOKENS } from '../../../constant/token';
import CurrencyAmount from '../../UI/CurrencyAmount';
import { find } from 'lodash';

const cx = classNames.bind(styles);

interface PropsType {
  hide: () => void;
  isShowing: boolean;
  assets?: Asset[];
  tokenSupport?: Asset;
  setTokenSupport?: (value: Asset) => void;
  balances: AssetBalance[];
}

interface Balance {
  id: string | undefined;
  symbol: string | undefined;
  balance: string | undefined;
  asset: Asset;
}

const HeaderModal = () => {
  return (
    <div className={cx('header-title-modal')}>
      <div className={cx('title')}>
        <div>Assets Portfolio</div>
      </div>
    </div>
  );
};

const ModalDeposit = (propsModal: PropsType) => {
  const { assets, tokenSupport, setTokenSupport, hide, balances } = propsModal;
  const { priceAll } = useAppSelector((state) => ({
    priceAll: state.price.prices
  }));
  const [isHideSmall, setHideSmall] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const onClickItemAsset = (value: Asset) => {
    if (setTokenSupport) setTokenSupport(value);
    hide();
  };

  const assetBalances = assets?.map((item) => {
    const amountBalance = balances.find(
      (balance) => balance.assetSymbol === item.symbol
    )?.availableAssetAmount;
    return { id: item.id, symbol: item.symbol, balance: amountBalance, asset: item };
  });

  const listBalanceSorted = assetBalances
    ? [
        assetBalances.find((item) => item.symbol === TOKENS.FBET),
        assetBalances.find((item) => item.symbol === TOKENS.BBET),
        ...assetBalances.filter(
          (item) => item.symbol !== TOKENS.FBET && item.symbol !== TOKENS.BBET
        )
      ]
    : [];

  const handleCheckPrice = (assetBalance?: Balance) => {
    const priceCurrency =
      priceAll.find((price) => price.symbol === assetBalance?.symbol)?.price || 0;
    const isPriceCompare =
      new BigNumber(assetBalance?.balance || 0).multipliedBy(priceCurrency).lt(1) &&
      assetBalance?.symbol !== TOKENS.FBET &&
      assetBalance?.symbol !== TOKENS.BBET;
    return isHideSmall ? !isPriceCompare : assetBalance;
  };

  const handleSearchBalance = (assetBalance?: Balance) => {
    return assetBalance?.symbol?.toUpperCase().includes(search.toUpperCase());
  };

  const listAssets = listBalanceSorted?.filter(handleCheckPrice).filter(handleSearchBalance);

  return (
    <ModalUI
      footer={<FooterModal setHideSmall={setHideSmall} isHideSmall={isHideSmall} />}
      backgroundHeader="#1B1B1B"
      title={<HeaderModal />}
      {...propsModal}>
      <div className={cx('modal-assets')}>
        <div className={cx('input-search')}>
          <div className={cx('icon')}>
            <SearchIcon />
          </div>
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            placeholder="Search"
          />
        </div>
        {listAssets && listAssets.length > 0 ? (
          <ul className={cx('list-assets')}>
            {listAssets.map((item) => {
              return (
                <li
                  onClick={() => onClickItemAsset(item?.asset || {})}
                  key={`${item?.symbol}${item?.id}`}
                  className={cx('item', tokenSupport?.symbol === item?.symbol && 'item-active')}>
                  <div className={cx('crypto-title')}>
                    <div className={cx('icon')}>
                      <img src={`/images/tokens/${item?.symbol?.toLowerCase()}.svg?v=2`} alt="" />
                    </div>
                    <div>{item?.symbol}</div>
                  </div>

                  <div className={cx('price-crypto')}>
                    <CurrencyAmount
                      amount={item?.balance || '0'}
                      decimalPlaces={
                        find(assets, { symbol: item?.symbol, chain: 'CHT' })?.decimalPlaces
                      }
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className={cx('list-assets')}>
            <NoData />
          </div>
        )}
      </div>
    </ModalUI>
  );
};

const FooterModal = ({
  setHideSmall,
  isHideSmall
}: {
  isHideSmall: boolean;
  setHideSmall: (value: boolean) => void;
}) => {
  return (
    <div className={cx('modal-footer')}>
      {/* <div className={cx('switch')}>
        <Switch value={false} setValue={() => {}} />
        <div className={cx('text')}>View in fiat</div>
      </div> */}
      <div className={cx('switch')}>
        <Switch value={isHideSmall} setValue={() => setHideSmall(!isHideSmall)} />
        <div className={cx('text')}>Hide Small</div>
      </div>
    </div>
  );
};

export default ModalDeposit;
