import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../stores';
import { getUserInfo } from '../../stores/UserInformation';
import LayoutComponentSideMenu from '../LayoutComponentSideMenu';
import EditNickName from './EditNickname';
import styles from '../../styles/components/user-setting/user-information.module.scss';
import classNames from 'classnames/bind';
import { toggleModal } from '../../stores/ModalSlice';
import ModalEditAvatar from '../Modal/ModalEditAvatar';
import { storageService } from '../../services/storage';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constant/routes';
import { setAuthModalType } from '../../stores/AccountSlice';
import { AUTH } from '../../interfaces/Account';

const cx = classNames.bind(styles);

const UserInformation = () => {
  const { userIdentityClient, userInfo, account } = useAppSelector((state) => ({
    userIdentityClient: state.app.userIdentityClient,
    userInfo: state.userInfo.userInfo,
    account: state.account.accountInfo
  }));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleReload = () => {
    dispatch(
      getUserInfo({
        userIdentityClient,
        id: account.id
      })
    );
  };

  const handleOpenModalEditAvatar = () => {
    dispatch(
      toggleModal({
        modalName: 'editAvatarModal',
        state: true
      })
    );
  };

  useEffect(() => {
    const accountData = storageService.getAccount();
    if (!accountData) {
      navigate(ROUTES.HOME);
      dispatch(setAuthModalType(AUTH.SIGN_IN));
      dispatch(toggleModal({ modalName: 'authModal', state: true }));
    } else {
      if (!userInfo) {
        dispatch(getUserInfo({ userIdentityClient, id: accountData.id }));
      }
    }
  }, []);
  return (
    <LayoutComponentSideMenu>
      <div className={cx('container')}>
        <div className={cx('title')}>User Information</div>
        <EditNickName
          isShowing={true}
          openModalEditAvatar={handleOpenModalEditAvatar}
          name={userInfo?.nickName || ''}
          avatar={userInfo?.avatar}
          id={userInfo?.id || ''}
          reload={handleReload}
        />
      </div>
      <ModalEditAvatar />
    </LayoutComponentSideMenu>
  );
};

export default UserInformation;
