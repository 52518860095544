import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface DropdownState {
  [key: string]: boolean;
}

const initialState: DropdownState = {
  globalSetting: false,
  classicDice: false,
  classicAmount: false,
  ringOfFoture: false,
  statictis: false
};

export const dropdownSlice = createSlice({
  name: 'dropdown',
  initialState,
  reducers: {
    toggleDropdown: (state, action: PayloadAction<{ name: string; state: boolean }>) => {
      state[action.payload.name] = action.payload.state;
    }
  }
});

// Action creators are generated for each case reducer function
export const { toggleDropdown } = dropdownSlice.actions;

export default dropdownSlice.reducer;
