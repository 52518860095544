export default function HelpCenterIcon() {
  return (
    <svg width={26} height={27} viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2091_5325)">
        <path
          d="M24.9848 2.22512C24.7619 1.63935 24.1823 1.1926 23.4564 1.05511C19.5548 0.314965 15.6083 0.314965 11.7072 1.05511C10.9814 1.1926 10.4012 1.63935 10.1789 2.22512C9.19339 4.81817 9.19339 7.43882 10.1789 10.0314C10.3087 10.3733 10.5607 10.6677 10.8924 10.8814C11.2456 11.1083 11.4659 11.4937 11.4659 11.9139V12.8202C11.4659 13.2408 11.9745 13.4514 12.272 13.154L13.4211 12.0049C13.714 11.712 14.1229 11.5668 14.5354 11.6072C17.5087 11.8981 20.4959 11.7626 23.4564 11.2014C24.1823 11.0639 24.7624 10.6171 24.9848 10.0314C25.9703 7.43882 25.9708 4.81817 24.9848 2.22512Z"
          fill="url(#paint0_linear_2091_5325)"
        />
        <path
          d="M19.7874 5.14195H23.1549C23.4018 5.14195 23.6016 4.94209 23.6016 4.69521V3.80172C23.6016 3.55484 23.4018 3.35498 23.1549 3.35498H19.7874C19.5406 3.35498 19.3407 3.55484 19.3407 3.80172V4.69521C19.3407 4.94209 19.5411 5.14195 19.7874 5.14195Z"
          fill="#43765A"
        />
        <path
          d="M12.0083 5.14195H17.887C18.1339 5.14195 18.3337 4.94209 18.3337 4.69521V3.80172C18.3337 3.55484 18.1339 3.35498 17.887 3.35498H12.0083C11.7614 3.35498 11.5615 3.55484 11.5615 3.80172V4.69521C11.5615 4.94209 11.7619 5.14195 12.0083 5.14195Z"
          fill="#43765A"
        />
        <path
          d="M15.3757 8.29429H12.0083C11.7614 8.29429 11.5615 8.09444 11.5615 7.84755V6.95407C11.5615 6.70718 11.7614 6.50732 12.0083 6.50732H15.3757C15.6226 6.50732 15.8224 6.70718 15.8224 6.95407V7.84755C15.8224 8.09392 15.6226 8.29429 15.3757 8.29429Z"
          fill="#43765A"
        />
        <path
          d="M9.46838 6.89697C9.54608 7.9438 9.78274 8.99012 10.1789 10.0318C10.3087 10.3738 10.5607 10.6682 10.8924 10.8819C11.2462 11.1088 11.4659 11.4942 11.4659 11.9144V12.8207C11.4659 13.2413 11.9745 13.4519 12.272 13.1544L13.4211 12.0054C13.714 11.7125 14.1229 11.5673 14.5354 11.6077C17.5087 11.8985 20.4959 11.7631 23.4564 11.2019C24.1823 11.0644 24.7624 10.6176 24.9848 10.0318C25.3809 8.99012 25.6171 7.9438 25.6947 6.89697H9.46838Z"
          fill="url(#paint1_linear_2091_5325)"
        />
        <path
          d="M1.11678 10.9431C1.4296 10.1196 2.24488 9.49193 3.26513 9.2982C8.74872 8.25802 14.2957 8.25802 19.7788 9.2982C20.799 9.49193 21.6143 10.1196 21.9271 10.9431C23.3123 14.5881 23.3123 18.2704 21.9271 21.9148C21.7441 22.3958 21.3904 22.8094 20.9237 23.1094C20.4269 23.4289 20.1176 23.9702 20.1176 24.5605V25.8343C20.1176 26.4257 19.4026 26.7217 18.9844 26.3036L17.3697 24.6888C16.9577 24.2769 16.3832 24.0734 15.8036 24.1302C11.6244 24.5391 7.42638 24.3489 3.26462 23.5592C2.24437 23.3655 1.42909 22.7378 1.11627 21.9148C-0.267916 18.2709 -0.268427 14.5881 1.11678 10.9431Z"
          fill="url(#paint2_linear_2091_5325)"
        />
        <path
          d="M0.11853 17.5093C0.227916 18.9809 0.560161 20.4509 1.1168 21.9154C1.42962 22.7388 2.2449 23.3665 3.26515 23.5597C7.42691 24.3489 11.625 24.5391 15.8041 24.1307C16.3837 24.0739 16.9588 24.2779 17.3702 24.6894L18.985 26.3041C19.4031 26.7222 20.1182 26.4262 20.1182 25.8348V24.5611C20.1182 23.9707 20.4274 23.4289 20.9243 23.1099C21.3904 22.8104 21.7446 22.3964 21.9276 21.9154C22.4843 20.4514 22.8165 18.9809 22.9259 17.5093H0.11853Z"
          fill="url(#paint3_linear_2091_5325)"
        />
        <path
          d="M15.9334 24.123C15.89 24.123 15.847 24.1261 15.8036 24.1302C14.8237 24.2263 13.8428 24.2887 12.8614 24.3183L10.5342 21.9911C10.2311 21.7743 10.1196 21.4155 10.1196 21.027C10.1196 20.385 10.5582 19.9316 11.2002 19.9316C11.5892 19.9316 11.9378 20.0456 12.1535 20.3431L15.9334 24.123Z"
          fill="url(#paint4_linear_2091_5325)"
        />
        <path
          d="M22.4444 20.3188C22.3013 20.8519 22.129 21.384 21.9271 21.9151C21.7447 22.3961 21.3899 22.8101 20.9243 23.1096C20.4274 23.4291 20.1177 23.9704 20.1177 24.5608V25.835C20.1177 26.4264 19.4026 26.7229 18.9845 26.3038L17.3703 24.6896C17.0344 24.3537 16.5908 24.1564 16.1246 24.1263L10.7167 18.7184C10.4463 18.5977 10.4258 18.3186 10.4258 17.7681C10.4258 16.8568 10.7872 16.3574 11.1067 15.9592C11.1 15.9495 10.9293 15.8575 10.9227 15.8472L8.7743 13.6989C8.55349 13.5593 8.4671 13.3227 8.4671 12.9787C8.4671 12.1527 9.3335 11.0103 11.3996 11.0103C12.6023 11.0103 13.5566 11.3384 14.1143 11.9891L22.4444 20.3188Z"
          fill="url(#paint5_linear_2091_5325)"
        />
        <path
          d="M12.9452 16.2533C12.1039 16.9112 12.0885 17.3702 12.0885 18.166C12.0885 18.4569 11.9357 18.7932 11.1858 18.7932C10.5587 18.7932 10.3445 18.5637 10.3445 17.7679C10.3445 16.4522 10.9262 15.8245 11.3699 15.4421C11.8749 15.0138 12.7316 14.5395 12.7316 13.7129C12.7316 13.0091 12.1197 12.6727 11.3545 12.6727C9.79399 12.6727 10.1303 13.8509 9.30431 13.8509C8.89131 13.8509 8.38629 13.5754 8.38629 12.9789C8.38629 12.1529 9.33498 10.9287 11.4005 10.9287C13.3592 10.9287 14.6596 12.0149 14.6596 13.4533C14.6596 14.8916 13.3582 15.9323 12.9452 16.2533ZM12.3789 21.0274C12.3789 21.6551 11.8585 22.2056 11.2007 22.2056C10.5428 22.2056 10.0378 21.6546 10.0378 21.0274C10.0378 20.3849 10.5582 19.8492 11.2007 19.8492C11.8437 19.8492 12.3789 20.3849 12.3789 21.0274Z"
          fill="url(#paint6_linear_2091_5325)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2091_5325"
          x1="12.6937"
          y1="1.98966"
          x2="23.7404"
          y2="13.0364"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4E08E" />
          <stop offset={1} stopColor="#6FA953" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2091_5325"
          x1="17.5814"
          y1="7.67161"
          x2="17.5814"
          y2="13.8983"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#43765A" stopOpacity={0} />
          <stop offset={1} stopColor="#43765A" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2091_5325"
          x1="7.45776"
          y1="12.3652"
          x2="17.6772"
          y2="22.5845"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF755C" />
          <stop offset={1} stopColor="#FF4181" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2091_5325"
          x1="11.522"
          y1="19.3239"
          x2="11.522"
          y2="28.1363"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#843561" stopOpacity={0} />
          <stop offset={1} stopColor="#3A2F4D" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2091_5325"
          x1="13.4833"
          y1="23.3071"
          x2="10.6133"
          y2="20.437"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#843561" stopOpacity={0} />
          <stop offset={1} stopColor="#3A2F4D" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2091_5325"
          x1="17.7782"
          y1="20.7331"
          x2="6.11486"
          y2="9.06975"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#843561" stopOpacity={0} />
          <stop offset={1} stopColor="#3A2F4D" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2091_5325"
          x1="8.38529"
          y1="16.5673"
          x2="14.6586"
          y2="16.5673"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#EDF5FF" />
          <stop offset={1} stopColor="#C7E9FF" />
        </linearGradient>
        <clipPath id="clip0_2091_5325">
          <rect width="25.6463" height={26} fill="white" transform="translate(0.078125 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
