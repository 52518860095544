export interface GameInformation {
  rtp: number;
  provider: string;
  maxWin?: number;
  type: string;
  shortDescription: string;
  description: string;
}

export enum TABS {
  ALL = 'All Bets',
  MY_BETS = 'My Bets'
}
