import classNames from 'classnames/bind';
import { shortenAddress } from '../../lib/shortenAddress';
import styles from '../../styles/components/user-setting/user-information.module.scss';
import ArrowNextIcon from '../Icons/ArrowNextIcon';
import EditIcon from '../Icons/EditIcon';
import MushroomNodata from '../Icons/MushroomNoData';
import StatisticsIcon from '../Icons/StatisticsIcon';
import TokenIcon from '../Icons/TokenIcon';
import TotalWageredIcon from '../Icons/TotalWageredIcon';
import TotalWinIcon from '../Icons/TotalWinIcon';

const cx = classNames.bind(styles);

interface User {
  name: string;
  avatar?: string;
  userId: string;
  totalWins: number;
  totalBets: number;
  totalWagered: number;
  favoriteGames: [];
  wagerContest: [];
}

interface PropsType {
  nextSlideEdit: () => void;
  nextSlideDetail: () => void;
  user: User;
}

const UserInformation = ({ user, nextSlideEdit, nextSlideDetail }: PropsType) => {
  return (
    <div className={cx('content')}>
      <div className={cx('avatar')}>
        <img src={user.avatar} />
      </div>
      <div className={cx('name')}>{user.name}</div>
      <div className={cx('user-id')}>User ID: {shortenAddress(user.userId)}</div>
      <div className={cx('edit')} onClick={nextSlideEdit}>
        <EditIcon />
      </div>
      <div className={cx('panel')}>
        <div className={cx('header')}>
          <div className={cx('statistic-wrapper')}>
            <StatisticsIcon />
            Statistics
          </div>
          <div onClick={nextSlideDetail} className={cx('detail-wrapper')}>
            Details
            <ArrowNextIcon />
          </div>
        </div>
        <div className={cx('statistic-content')}>
          <div className={cx('statistic-block')}>
            <div className={cx('title')}>
              <TotalWinIcon /> Total wins
            </div>
            <div className={cx('data')}>{user.totalWins}</div>
          </div>
          <div className={cx('statistic-block')}>
            <div className={cx('title')}>
              <TokenIcon /> Total Bets
            </div>
            <div className={cx('data')}>{user.totalBets}</div>
          </div>
          <div className={cx('statistic-block')}>
            <div className={cx('title')}>
              <TotalWageredIcon /> Total Wagered
            </div>
            <div className={cx('data')}>{user.totalWins}</div>
          </div>
        </div>
      </div>
      <div className={cx('panel')}>
        <div>Top 3 Favorite Games</div>
        <div className={cx('favorites-game')}>
          <MushroomNodata />
          <div className={cx('nodata-text')}>Oops! There is no data yet!</div>
        </div>
      </div>
      <div className={cx('panel')}>
        <div>Wager Contest</div>
        <div className={cx('favorites-game')}>
          <MushroomNodata />
          <div className={cx('nodata-text')}>Oops! There is no data yet!</div>
        </div>
      </div>
    </div>
  );
};

export default UserInformation;
