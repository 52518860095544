import classNames from 'classnames/bind';
import { useState } from 'react';
import { Swiper as SwiperType } from 'swiper/types';
import { SLIDE_USER } from '../../constant';
import styles from '../../styles/components/layout/menu-mobile.module.scss';
import ClosedIcon from '../Icons/ClosedIcon';
import SlideUserInformation from '../UserInformation/SlideUserInfomation';
import Chat from '../Chat';
import Notification from './Notification';
import Sidebar from './Sidebar';

const cx = classNames.bind(styles);

interface PropsSideMenu {
  isShowing: boolean;
  handleCloseSideMenu: () => void;
  itemActive: string;
}
interface User {
  name: string;
  avatar?: string;
  userId: string;
  totalWins: number;
  totalBets: number;
  totalWagered: number;
  favoriteGames: [];
  wagerContest: [];
}

const SideMenu = ({ isShowing, handleCloseSideMenu, itemActive }: PropsSideMenu) => {
  const user: User = {
    name: '',
    userId: '',
    avatar: '/images/avatar.png',
    totalWins: 0,
    totalBets: 0,
    totalWagered: 0,
    favoriteGames: [],
    wagerContest: []
  };
  const [swiper, setSwiper] = useState<SwiperType>();
  const [slideActive, setSlideActive] = useState<number>(SLIDE_USER.MAIN);

  const onInit = (ev: SwiperType) => {
    setSwiper(ev);
  };

  const handleCategoryShow = (itemActive: string) => {
    switch (itemActive) {
      case 'chat':
        return <Chat handleShowChat={handleCloseSideMenu} isShowChat={isShowing} />;
      case 'profile':
        return (
          <div className={cx('profile-slide')}>
            <div onClick={handleCloseSideMenu} className={cx('icon-closed')}>
              <ClosedIcon />
            </div>
            <SlideUserInformation
              swiper={swiper}
              onInit={onInit}
              user={user}
              setSlideActive={setSlideActive}
              reload={() => {}}
            />
          </div>
        );
      case 'notification':
        return <Notification handleShowNoti={handleCloseSideMenu} isShowNoti={isShowing} />;
      case 'menu':
        return (
          <div className={cx('profile-slide')}>
            <div onClick={handleCloseSideMenu} className={cx('icon-closed')}>
              <ClosedIcon />
            </div>
            <Sidebar handleCloseSideMenu={handleCloseSideMenu} />
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className={cx(!isShowing ? 'wrapper-side-menu' : 'wrapper-side-menu-active')}>
      {handleCategoryShow(itemActive)}
    </div>
  );
};

export default SideMenu;
