import classNames from 'classnames/bind';
import styles from '../../styles/components/modal/modal-auth.module.scss';
import InputPopup from '../UI/InputPopup';
import { FieldErrorsImpl, UseFormGetValues, UseFormRegister } from 'react-hook-form';
import CheckIcon from '../Icons/CheckIcon';
import { AUTH } from '../../interfaces/Account';
import { REGEX } from '../../constant';
import { PASSWORD } from '../../constant/password';
import { LOGIN_TYPE } from '../../interfaces';
const cx = classNames.bind(styles);
const listIcons = [LOGIN_TYPE.GOOGLE, LOGIN_TYPE.METAMASK];
interface SignInProps {
  checkTypeSignIn: boolean;
  agreeTermsOfUse: boolean;
  handleAgreeTermsOfUse: () => void;
  handleOpenTermOfUse: () => void;
  handleTypeModalAuth: Function;
  register: UseFormRegister<RegisterForm>;
  errors: Partial<FieldErrorsImpl<RegisterForm>>;
  getValues: UseFormGetValues<RegisterForm>;
  handleLoginWith: (name: LOGIN_TYPE) => void;
}
type RegisterForm = {
  email: string;
  password: string;
  confirmPassword: string;
  registerEmailAddress: string;
};
const SignUp = ({
  checkTypeSignIn,
  handleAgreeTermsOfUse,
  handleOpenTermOfUse,
  agreeTermsOfUse,
  handleTypeModalAuth,
  errors,
  getValues,
  register,
  handleLoginWith
}: SignInProps) => {
  return (
    <>
      <div className={cx('form-item')}>
        <div className={cx('form-label')}>
          <label>Email Address</label>
        </div>
        <div>
          <InputPopup
            placeholder="Your email"
            type="email"
            {...register('email', { required: PASSWORD.REQUIRED_FIELD })}
          />
        </div>
        <div className={cx('error')}>{errors.email && errors.email?.message}</div>
      </div>

      <div className={cx('form-item')}>
        <div className={cx('form-label', 'form-label-forgotPwd')}>
          <div>
            <label>Password</label>
          </div>
        </div>
        <div>
          <InputPopup
            {...register('password', {
              required: PASSWORD.REQUIRED_FIELD,
              pattern: !checkTypeSignIn
                ? {
                    value: new RegExp(REGEX.PASSWORD),
                    message: PASSWORD.REQUIRED_PASSWORD
                  }
                : undefined
            })}
            placeholder="Your password"
            type="password"
          />
          <div className={cx('error')}>{errors.password && errors.password?.message}</div>
        </div>
      </div>
      <div className={cx('form-item')}>
        <div className={cx('form-label', 'form-label-forgotPwd')}>
          <div>
            <label>Confirm Password</label>
          </div>
        </div>
        <div>
          <InputPopup
            {...register('confirmPassword', {
              required: !checkTypeSignIn ? PASSWORD.REQUIRED_FIELD : undefined,
              validate: {
                matchPassword: (value) =>
                  value === getValues('password') || PASSWORD.REQUIRED_CONFIRM_PWD
              }
            })}
            placeholder="Your password"
            type="password"
          />
        </div>
        <div className={cx('error')}>
          {errors.confirmPassword && <p role="alert">{errors.confirmPassword?.message}</p>}
        </div>
      </div>
      <div className={cx('form-item-checkbox')}>
        <div className={cx('checkbox')} onClick={() => handleAgreeTermsOfUse()}>
          <input type="checkbox" id="checkbox" />
          <div className={cx('custom-checkbox')} />
          {agreeTermsOfUse && <CheckIcon />}
        </div>
        <div className={cx('term-of-use-wrapper')}>
          <label htmlFor="checkbox">
            I agree with{' '}
            <span className={cx('term-of-use')} onClick={() => handleOpenTermOfUse()}>
              Term of Service
            </span>
            , and confirm that I am at least 18 years old!
          </label>
        </div>
      </div>
      <div className={cx('form-button')}>
        <div
          onClick={() => {
            handleTypeModalAuth(AUTH.SIGN_IN);
          }}>
          Sign in
        </div>
        <button
          onClick={() => {
            handleTypeModalAuth(AUTH.SIGN_UP);
          }}
          type="submit"
          className={cx('button-active', !agreeTermsOfUse && 'disable')}>
          Sign up
        </button>
      </div>
      <div className={cx('login-diff')}>
        <div className={cx('title')}>Or Sign-up with</div>
        <div className={cx('icons')}>
          {listIcons.map((item) => (
            <div onClick={() => handleLoginWith(item)} key={item} className={cx('icon')}>
              <img src={`/images/login-diff/${item}.png`} alt={item} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SignUp;
