import BigNumber from 'bignumber.js';
import CrashGame from 'bybet-game-js/lib/game/crash';
import DiceGame from 'bybet-game-js/lib/game/dice';
import RingGame from 'bybet-game-js/lib/game/ring';
import { CrashGameResult } from 'bybet-game-js/lib/schema/CrashGame';
import { TIME_DATE_FORMAT } from '../../constant';
import { DiceGameResult } from 'bybet-game-js/lib/schema/DiceGame';
import { RingGameResult } from 'bybet-game-js/lib/schema/RingGame';
import classnames from 'classnames/bind';
import { find } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { TABS } from '../../interfaces/Game';
import { shortenAddress } from '../../lib/shortenAddress';
import { RootState, useAppSelector } from '../../stores';
import styles from '../../styles/components/games/lastest-bet.module.scss';
import { getPayout } from '../../utils/game';
import BetsTable from './BetsTable';

const cx = classnames.bind(styles);

interface PropsType {
  gameIns: CrashGame | RingGame | DiceGame | undefined;
  isResult: boolean;
}

export default function LastestBetAndRace({ gameIns, isResult }: PropsType) {
  const [activeTab, setActiveTab] = useState(TABS.ALL);
  const [isShowMore, setIsShowMore] = useState(false);
  const [dataTable, setDataTable] = useState<any>([]);
  const userIdentityClient = useAppSelector((state) => state.app.userIdentityClient);
  const accessToken = useAppSelector((state: RootState) => state.account.accountInfo.accessToken);

  const fetchBetsHistory = async (tab: TABS, isShowMore?: boolean) => {
    try {
      let dataTable = [];
      const queryParams = {
        limit: isShowMore ? 50 : 10,
        offset: 0
      };
      let res: CrashGameResult[] | RingGameResult[] | DiceGameResult[] | undefined;
      if (tab === TABS.ALL) {
        res = await gameIns?.getBets(queryParams);
      } else if (tab === TABS.MY_BETS) {
        res = await gameIns?.getMyBets(queryParams);
      }
      if (res) {
        const users = await userIdentityClient.getUsers({
          ids: res.map((bet) => bet.userId).toString()
        });

        dataTable = res.map((bet) => {
          let result: any = {
            id: shortenAddress(bet.betId),
            time: moment(new BigNumber(bet.timestamp).multipliedBy(1000).toNumber()).format(
              TIME_DATE_FORMAT
            ),
            amount: (
              <>
                <span>{new BigNumber(bet.betAmount).toFixed(2)}</span>
                <img
                  src={`/images/tokens/${bet.symbol.toLowerCase()}.svg?v=2`}
                  alt={bet.symbol.toLowerCase()}
                />
              </>
            ),
            payout: bet.isWin ? `${getPayout(bet.rewardAmount, bet.betAmount)}x` : '0.00x',
            profit: {
              profit: bet.isWin
                ? new BigNumber(bet.rewardAmount).minus(bet.betAmount).toString()
                : `-${bet.betAmount}`,
              symbol: bet.symbol
            }
          };
          if (tab === TABS.ALL) {
            result.name = find(users?.data, { id: bet.userId })?.nickName || 'Hidden';
          }
          return result;
        });
        setDataTable(dataTable);
      }
    } catch (error) {
      setDataTable([]);
      console.log(error);
    }
  };

  const handleShowMore = (isShowMore: boolean) => {
    setIsShowMore(isShowMore);
  };

  const handleChangeTab = (tab: TABS) => {
    setActiveTab(tab);
    setIsShowMore(false);
  };

  useEffect(() => {
    if (gameIns) {
      fetchBetsHistory(activeTab, isShowMore);
    }
  }, [activeTab, isShowMore, gameIns, accessToken]);

  useEffect(() => {
    if (isResult) {
      fetchBetsHistory(activeTab, isShowMore);
    }
  }, [isResult]);

  useEffect(() => {
    if (!accessToken && activeTab === TABS.MY_BETS) {
      setDataTable([]);
    }
  }, [accessToken]);

  return (
    <div>
      <div className={cx('label')}>Latest Bet & Race</div>
      <div className={cx('panel')}>
        <div className={cx('tabs')}>
          <div
            className={cx('tab', activeTab === TABS.ALL && 'active')}
            onClick={() => handleChangeTab(TABS.ALL)}>
            {TABS.ALL}
          </div>
          <div
            className={cx('tab', activeTab === TABS.MY_BETS && 'active')}
            onClick={() => handleChangeTab(TABS.MY_BETS)}>
            {TABS.MY_BETS}
          </div>
        </div>
        <BetsTable
          dataTable={dataTable}
          isMyBets={activeTab === TABS.MY_BETS}
          isShowMore={isShowMore}
          handleShowMore={handleShowMore}
        />
      </div>
    </div>
  );
}
