import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ByBetGameClient from 'bybet-game-js';
import { GameProfileClient } from 'game-profile-js';
import MetaverseClient from 'metaverse-js';
import { UserIdentityClient } from 'user-identity-js';
import { firebaseConfig } from '../constant/config';
import { getEnv } from '../utils';

export interface AppState {
  bybetGameClient: ByBetGameClient;
  gameProfileClient: GameProfileClient;
  metaverseClient: MetaverseClient;
  userIdentityClient: UserIdentityClient;
}
const initialState: AppState = {
  bybetGameClient: new ByBetGameClient({
    baseUrl: `${getEnv('REACT_APP_BYBET_GAME_BASE_URL')}`,
    wsUrl: `${getEnv('REACT_APP_BYBET_GAME_SOCKET_URL')}`
  }),
  gameProfileClient: new GameProfileClient({
    baseUrl: `${getEnv('REACT_APP_BYBET_BASE_URL')}/profile`
  }),
  metaverseClient: new MetaverseClient({
    baseUrl: `${getEnv('REACT_APP_BYBET_BASE_URL')}/metaverse`,
    baseFunctionUrl: `${getEnv('REACT_APP_BYBET_BASE_FUNCTION_URL')}`
  }),
  userIdentityClient: new UserIdentityClient({
    baseUrl: `${getEnv('REACT_APP_BYBET_BASE_URL')}/identity`,
    firebaseApiKey: firebaseConfig.apiKey
  })
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setTokenId: (state, action: PayloadAction<string>) => {
      state.bybetGameClient.setTokenId(action.payload);
      state.gameProfileClient.setTokenId(action.payload);
      state.metaverseClient.setTokenId(action.payload);
      state.userIdentityClient.setTokenId(action.payload);
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.userIdentityClient.setRefreshToken(action.payload);
    }
  }
});
export const { setTokenId, setRefreshToken } = appSlice.actions;

export const appReducer = appSlice.reducer;
