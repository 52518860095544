export const ROUTES = {
  ERROR: '*',
  HOME: '/',
  HOME_RESETPASSWORD: '/reset-password',
  RING_OF_FORTUNE: {
    INDEX: '/ring-of-fortune',
    DETAIL: '/ring-of-fortune/detail'
  },
  CLASSIC_DICE: {
    INDEX: '/classic-dice',
    DETAIL: '/classic-dice/detail'
  },
  CRASH: {
    INDEX: '/crash',
    DETAIL: '/crash/detail'
  },
  LOTTERY: '/lottery',
  AFFILIATE: '/affiliate',
  STAKING: '/staking',
  BACCARAT: '/baccarat',
  ROULETTE: '/roulette',
  BLACKJACK: '/black-jack',
  CRAZY_TIME: '/crazy-time',
  MEGA_BALL: '/mega-ball',
  LOGIN: '/login',
  WALLET: {
    MAIN: '/wallet',
    DEPOSIT: '/wallet/deposit',
    WITHDRAW: '/wallet/withdraw',
    TRANSACTIONS: '/wallet/transactions',
    INTERNAL_TRANSFERS: '/wallet/internal-transfers'
  },
  HELP: {
    TERMS: '/help/terms-service',
    FAQ: '/help/faq',
    SUPPORT: '/help/support',
    FEE: '/help/fee',
    GENERAL: '/help/general'
  },
  GLOBAL_SETTING: {
    INDEX: '/global-setting',
    USER_INFO: '/global-setting/user-information',
    CHANGE_PWD: '/global-setting/change-pwd',
    LINK_EMAIL: '/global-setting/link-email',
    E2FA: '/global-setting/e2fa'
  }
};
