import styles from '../../../styles/components/games/ring-of-fortune/popup-win.module.scss';
import classnames from 'classnames/bind';
import { RootState, useAppSelector } from '../../../stores';
import Amount from '../../shared/Amount';

const cx = classnames.bind(styles);
interface PopupWinProps {
  reward: string;
  profit: string;
}
const WinPopup = ({ reward, profit }: PopupWinProps) => {
  const currency = useAppSelector((state: RootState) => state.account.currency);
  return (
    <div className={cx('container', 'reward-animation')}>
      <div className={cx('reward')}>
        <Amount amount={reward} />
        <img
          src={`/images/tokens/${currency.symbol.toLowerCase()}.svg?v=2`}
          alt={currency.symbol}
        />
      </div>
      <div className={cx('result-type')}>{+profit}x</div>
    </div>
  );
};

export default WinPopup;
