export default function LotteryIcon() {
  return (
    <svg width={27} height={26} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_512_122)">
        <path
          d="M25.9835 10.1975L22.4719 4.80286C22.3008 4.53996 22.0282 4.35995 21.7192 4.3058L19.863 3.98065C15.8907 3.28477 11.8277 3.28477 7.85545 3.98065L5.99925 4.3058C5.69026 4.35995 5.41767 4.53996 5.24653 4.80286L1.7349 10.1975C1.46811 10.6073 1.49493 11.1422 1.80142 11.5233L12.9779 25.4198C13.4306 25.9828 14.2878 25.9828 14.7406 25.4198L25.917 11.5233C26.2235 11.1423 26.2503 10.6073 25.9835 10.1975Z"
          fill="url(#paint0_linear_512_122)"
        />
        <path
          d="M25.9836 10.1975L22.472 4.80281C22.3008 4.53996 22.0283 4.35995 21.7194 4.3058L19.8631 3.98065C15.8908 3.28477 11.8278 3.28477 7.85556 3.98065L5.99931 4.3058C5.69037 4.35995 5.41777 4.53996 5.24668 4.80281L1.73495 10.1975C1.59904 10.4063 1.53975 10.6477 1.55443 10.8845H26.1641C26.1789 10.6476 26.1196 10.4063 25.9836 10.1975Z"
          fill="url(#paint1_linear_512_122)"
        />
        <path
          d="M22.1957 4.51161C22.0561 4.40838 21.894 4.33639 21.7194 4.3058L19.8631 3.98065C15.8908 3.28477 11.8278 3.28477 7.85556 3.98065L5.99931 4.3058C5.8247 4.33639 5.66258 4.40838 5.52295 4.51161L5.94573 10.8845L13.4861 25.7786C13.7273 25.8625 13.9913 25.8625 14.2324 25.7786L21.7729 10.8845L22.1957 4.51161Z"
          fill="url(#paint2_linear_512_122)"
        />
        <path
          d="M21.7739 10.8844L14.2334 25.7782C14.4256 25.7122 14.6024 25.5927 14.7417 25.4198L25.918 11.5234C26.0919 11.3072 26.1753 11.0413 26.1663 10.7769C26.1675 10.8127 26.167 10.8485 26.1647 10.8844H21.7739Z"
          fill="url(#paint3_linear_512_122)"
        />
        <path
          d="M13.8595 3.4585C12.5951 3.4585 5.94558 10.8841 5.94558 10.8841L5.52295 4.5118C5.66243 4.40806 5.8246 4.33653 5.99926 4.30554L7.85551 3.98064C9.8417 3.63254 11.8506 3.4585 13.8595 3.4585Z"
          fill="url(#paint4_linear_512_122)"
        />
        <path
          d="M5.94531 10.8844L13.4858 25.7782C13.2936 25.7122 13.1168 25.5927 12.9775 25.4198L1.80122 11.5234C1.62727 11.3072 1.54397 11.0413 1.55289 10.7769C1.55172 10.8127 1.55223 10.8485 1.55447 10.8844H5.94531Z"
          fill="url(#paint5_linear_512_122)"
        />
        <path
          d="M5.9458 10.8845L13.4863 25.7787C13.7274 25.8625 13.9914 25.8625 14.2326 25.7787L21.773 10.8845C21.773 10.8845 15.1239 3.45874 13.8594 3.45874C12.5949 3.45874 5.9458 10.8845 5.9458 10.8845Z"
          fill="url(#paint6_linear_512_122)"
        />
        <path
          d="M14.7407 25.4199L25.9172 11.5234C26.2237 11.1423 26.2505 10.6074 25.9837 10.1975L22.4721 4.80289C22.301 4.53998 22.0283 4.35997 21.7194 4.30583L19.8632 3.98068C17.877 3.63274 15.8682 3.45874 13.8594 3.45874V25.8421C14.1869 25.8421 14.5144 25.7014 14.7407 25.4199Z"
          fill="url(#paint7_linear_512_122)"
        />
        <path
          d="M14.1338 16.0116L14.12 15.9943C14.0202 15.8693 13.8611 15.7836 13.6809 15.7579L12.5981 15.6032C10.2809 15.2722 7.91073 15.2722 5.59352 15.6032L5.13525 15.6687L12.9778 25.4199C13.4306 25.9829 14.2878 25.9829 14.7405 25.4199L18.6652 20.54L14.1338 16.0116Z"
          fill="url(#paint8_linear_512_122)"
        />
        <path
          d="M16.1687 18.5598L14.1202 15.9942C14.0204 15.8692 13.8613 15.7836 13.6811 15.7578L12.5983 15.6032C10.2811 15.2722 7.91097 15.2722 5.59376 15.6032L4.51093 15.7578C4.33072 15.7836 4.17166 15.8692 4.07184 15.9942L2.02334 18.5598C1.8677 18.7547 1.88335 19.0091 2.06219 19.1903L8.58192 25.7992C8.84605 26.0669 9.34606 26.0669 9.61019 25.7992L16.1299 19.1903C16.3087 19.0091 16.3244 18.7547 16.1687 18.5598Z"
          fill="url(#paint9_linear_512_122)"
        />
        <path
          d="M16.1688 18.5598L14.1203 15.9942C14.0205 15.8692 13.8615 15.7836 13.6813 15.7579L12.5985 15.6032C10.2813 15.2722 7.91113 15.2722 5.59392 15.6032L4.51109 15.7579C4.33088 15.7836 4.17187 15.8692 4.07205 15.9942L2.02355 18.5598C1.94423 18.6591 1.90966 18.7739 1.91828 18.8865H16.2743C16.2827 18.7739 16.2482 18.6591 16.1688 18.5598Z"
          fill="url(#paint10_linear_512_122)"
        />
        <path
          d="M13.9588 15.8558C13.8774 15.8067 13.7828 15.7725 13.681 15.7579L12.5982 15.6032C10.281 15.2722 7.91084 15.2722 5.59363 15.6032L4.5108 15.7579C4.40895 15.7724 4.31438 15.8067 4.23291 15.8558L4.47955 18.8866L8.87824 25.97C9.0189 26.0098 9.17296 26.0098 9.31362 25.97L13.7123 18.8866L13.9588 15.8558Z"
          fill="url(#paint11_linear_512_122)"
        />
        <path
          d="M13.7127 18.8863L9.31396 25.9695C9.42607 25.9381 9.52926 25.8813 9.61047 25.799L16.1301 19.1902C16.2316 19.0874 16.2802 18.961 16.275 18.8352C16.2757 18.8522 16.2754 18.8693 16.2741 18.8863H13.7127V18.8863Z"
          fill="url(#paint12_linear_512_122)"
        />
        <path
          d="M9.09598 15.355C8.35845 15.355 4.47945 18.8864 4.47945 18.8864L4.23291 15.8559C4.31427 15.8066 4.40884 15.7726 4.51075 15.7578L5.59358 15.6033C6.75226 15.4378 7.92409 15.355 9.09598 15.355Z"
          fill="url(#paint13_linear_512_122)"
        />
        <path
          d="M4.4797 18.8863L8.87839 25.9695C8.76628 25.9381 8.6631 25.8813 8.58188 25.799L2.06226 19.1902C1.9608 19.0874 1.91217 18.961 1.91737 18.8352C1.91666 18.8522 1.91696 18.8693 1.91829 18.8863H4.4797V18.8863Z"
          fill="url(#paint14_linear_512_122)"
        />
        <path
          d="M4.47949 18.8865L8.87818 25.9699C9.01884 26.0098 9.1729 26.0098 9.31356 25.9699L13.7122 18.8865C13.7122 18.8865 9.8335 15.355 9.09587 15.355C8.35823 15.355 4.47949 18.8865 4.47949 18.8865Z"
          fill="url(#paint15_linear_512_122)"
        />
        <path
          d="M9.61038 25.7992L16.1301 19.1903C16.3089 19.0091 16.3245 18.7547 16.1689 18.5598L14.1204 15.9942C14.0206 15.8692 13.8615 15.7836 13.6813 15.7578L12.5985 15.6032C11.4399 15.4377 10.268 15.355 9.09619 15.355V26C9.28727 26 9.47834 25.9331 9.61038 25.7992Z"
          fill="url(#paint16_linear_512_122)"
        />
        <path
          d="M7.59943 6.88098C6.95392 7.30167 6.40318 7.85573 5.98606 8.50394C5.89225 8.6497 5.67579 8.64929 5.58255 8.50323C5.16782 7.85354 4.61912 7.29744 3.97513 6.87446C3.83219 6.78055 3.83259 6.56705 3.97585 6.4737C4.62136 6.05301 5.1721 5.49896 5.58917 4.85079C5.68298 4.70504 5.89944 4.7054 5.99268 4.8515C6.40741 5.5012 6.95616 6.05729 7.60015 6.48028C7.7431 6.57413 7.74274 6.78759 7.59943 6.88098Z"
          fill="url(#paint17_linear_512_122)"
        />
        <path
          d="M25.4642 5.54871C24.8187 5.9694 24.2679 6.52345 23.8508 7.17167C23.757 7.31742 23.5405 7.31701 23.4473 7.17096C23.0326 6.52126 22.4839 5.96517 21.8399 5.54218C21.6969 5.44828 21.6973 5.23477 21.8406 5.14143C22.4861 4.72079 23.0368 4.16668 23.4539 3.51852C23.5477 3.37276 23.7642 3.37312 23.8574 3.51923C24.2722 4.16892 24.8209 4.72502 25.4649 5.148C25.6078 5.24186 25.6074 5.45531 25.4642 5.54871Z"
          fill="url(#paint18_linear_512_122)"
        />
        <path
          d="M24.2508 21.6979C23.6053 22.1185 23.0545 22.6726 22.6374 23.3208C22.5436 23.4666 22.3272 23.4662 22.2339 23.3201C21.8192 22.6704 21.2705 22.1143 20.6265 21.6914C20.4836 21.5974 20.484 21.3839 20.6272 21.2906C21.2727 20.8699 21.8235 20.3159 22.2405 19.6677C22.3343 19.5219 22.5508 19.5223 22.644 19.6684C23.0588 20.3181 23.6075 20.8742 24.2515 21.2972C24.3945 21.391 24.3941 21.6045 24.2508 21.6979Z"
          fill="url(#paint19_linear_512_122)"
        />
        <path
          d="M4.47652 3.75505C4.02004 4.05252 3.63055 4.44436 3.33558 4.90272C3.26925 5.0058 3.11616 5.0055 3.05024 4.90221C2.75695 4.44278 2.36894 4.04951 1.91353 3.75041C1.81244 3.68403 1.81274 3.53303 1.91404 3.46701C2.37052 3.16954 2.76001 2.7777 3.05493 2.31934C3.12126 2.21626 3.27435 2.21651 3.34027 2.31985C3.63356 2.77929 4.02162 3.1725 4.47703 3.47165C4.57813 3.53803 4.57782 3.68903 4.47652 3.75505Z"
          fill="url(#paint20_linear_512_122)"
        />
        <path
          d="M9.31148 1.51284C8.855 1.81031 8.46551 2.20215 8.17054 2.66051C8.10422 2.7636 7.95112 2.76329 7.8852 2.66C7.59191 2.20057 7.2039 1.80731 6.74849 1.5082C6.6474 1.44183 6.64771 1.29082 6.749 1.2248C7.20548 0.927334 7.59497 0.535498 7.88989 0.0771336C7.95622 -0.0259487 8.10931 -0.0256428 8.17523 0.0776434C8.46852 0.537079 8.85658 0.930342 9.31194 1.22944C9.41309 1.29582 9.41278 1.44682 9.31148 1.51284Z"
          fill="url(#paint21_linear_512_122)"
        />
        <path
          d="M20.9413 2.3864C20.4848 2.68387 20.0953 3.0757 19.8004 3.53407C19.7341 3.63715 19.581 3.63684 19.5151 3.53356C19.2218 3.07412 18.8338 2.68086 18.3784 2.38176C18.2773 2.31538 18.2776 2.16438 18.3789 2.09836C18.8354 1.80089 19.2249 1.40905 19.5198 0.950688C19.5861 0.847606 19.7392 0.84786 19.8051 0.951198C20.0984 1.41063 20.4865 1.8039 20.9418 2.103C21.0429 2.16943 21.0426 2.32038 20.9413 2.3864Z"
          fill="url(#paint22_linear_512_122)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_512_122"
          x1="9.3663"
          y1="6.27773"
          x2="20.6321"
          y2="18.1694"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#65E0DB" />
          <stop offset={1} stopColor="#119786" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_512_122"
          x1="12.6901"
          y1="4.97049"
          x2="15.0745"
          y2="16.833"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_512_122"
          x1="11.558"
          y1="12.0777"
          x2="22.5859"
          y2="17.4426"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_512_122"
          x1="17.1773"
          y1="15.1633"
          x2="21.1115"
          y2="19.2168"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_512_122"
          x1="9.69758"
          y1="4.49126"
          x2="9.86893"
          y2="18.1144"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_512_122"
          x1="4.43685"
          y1="12.851"
          x2="14.4258"
          y2="23.395"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_512_122"
          x1="16.3257"
          y1="13.3777"
          x2="26.0422"
          y2="8.2512"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_512_122"
          x1="16.9402"
          y1="11.2307"
          x2="5.89731"
          y2="25.238"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_512_122"
          x1="18.6459"
          y1="23.7579"
          x2="9.63167"
          y2="18.3981"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#119786" stopOpacity={0} />
          <stop offset="0.3708" stopColor="#0E8E7D" stopOpacity="0.371" />
          <stop offset="0.969" stopColor="#077666" stopOpacity="0.969" />
          <stop offset={1} stopColor="#077565" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_512_122"
          x1="6.76299"
          y1="16.1889"
          x2="12.4814"
          y2="22.225"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#65E0DB" />
          <stop offset={1} stopColor="#119786" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_512_122"
          x1="8.52218"
          y1="15.989"
          x2="9.75437"
          y2="22.1191"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_512_122"
          x1="8.06664"
          y1="19.2806"
          x2="14.3093"
          y2="22.3176"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_512_122"
          x1="11.4481"
          y1="21.0151"
          x2="12.9415"
          y2="22.5538"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_512_122"
          x1="6.66753"
          y1="15.8498"
          x2="6.74879"
          y2="22.31"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_512_122"
          x1="3.78167"
          y1="19.5774"
          x2="8.99483"
          y2="25.0802"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_512_122"
          x1="10.425"
          y1="19.9861"
          x2="15.9815"
          y2="17.0545"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_512_122"
          x1="11.1756"
          y1="19.099"
          x2="5.6411"
          y2="26.1193"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_512_122"
          x1="5.16063"
          y1="5.8406"
          x2="7.19161"
          y2="8.55989"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.3043" stopColor="#FFCD3E" />
          <stop offset="0.8558" stopColor="#FFAD2B" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_512_122"
          x1="23.0256"
          y1="4.51188"
          x2="25.0566"
          y2="7.23117"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.3043" stopColor="#FFCD3E" />
          <stop offset="0.8558" stopColor="#FFAD2B" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_512_122"
          x1="21.809"
          y1="20.6609"
          x2="23.84"
          y2="23.3801"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.3043" stopColor="#FFCD3E" />
          <stop offset="0.8558" stopColor="#FFAD2B" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_512_122"
          x1="2.75208"
          y1="3.01909"
          x2="4.18829"
          y2="4.94209"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.3043" stopColor="#FFCD3E" />
          <stop offset="0.8558" stopColor="#FFAD2B" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_512_122"
          x1="7.58747"
          y1="0.777877"
          x2="9.02372"
          y2="2.70083"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.3043" stopColor="#FFCD3E" />
          <stop offset="0.8558" stopColor="#FFAD2B" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_512_122"
          x1="19.2171"
          y1="1.65379"
          x2="20.6534"
          y2="3.57675"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.3043" stopColor="#FFCD3E" />
          <stop offset="0.8558" stopColor="#FFAD2B" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <clipPath id="clip0_512_122">
          <rect width={26} height={26} fill="white" transform="translate(0.859375)" />
        </clipPath>
      </defs>
    </svg>
  );
}
