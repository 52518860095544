import classnames from 'classnames/bind';
import styles from '../../styles/Layout/deposit.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constant/routes';
import { useAppSelector } from '../../stores';
import { AUTH } from '../../interfaces/Account';
import { setAuthModalType } from '../../stores/AccountSlice';
import { toggleModal } from '../../stores/ModalSlice';
import { useDispatch } from 'react-redux';

const cx = classnames.bind(styles);

interface PropsType {
  fullName: string;
  name: string;
  background?: string;
  boxshadow?: string;
}

const Deposit = ({ name, fullName, background, boxshadow }: PropsType) => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => ({
    user: state.userInfo.userInfo
  }));

  const dispatch = useDispatch();
  const onClickLogout = () => {
    if (user.id) {
      navigate(`${ROUTES.WALLET.DEPOSIT}?currency=${name}`);
    } else {
      dispatch(setAuthModalType(AUTH.SIGN_IN));
      dispatch(toggleModal({ modalName: 'authModal', state: true }));
    }
  };

  return (
    <>
      <div className={cx('account')} style={{ boxShadow: boxshadow, background: background }}>
        <div className={cx('info')}>
          <div className={cx('detail')}>
            <img src={`/images/list-accepted-currencies/${name}.svg`} alt={name} />
            <div className={cx('desc')}>
              <div>{fullName}</div>
              <div>{name}</div>
            </div>
          </div>
        </div>
        <button type="button" className={cx('button-logout')} onClick={onClickLogout}>
          <div>DEPOSIT</div>
        </button>
      </div>
    </>
  );
};

export default Deposit;
