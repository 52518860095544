import classNames from 'classnames/bind';
import styles from '../../styles/components/home/banner.module.scss';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import { ROUTES } from '../../constant/routes';
import BannerItem from './BannerItem';

const cx = classNames.bind(styles);
export interface GameBanner {
  name: string;
  description: string;
  houseEdge: string;
  image: string;
  url: string;
}
const BANNERS: GameBanner[] = [
  {
    name: 'Crash',
    description: 'Choose your betting currency and get to the moon',
    houseEdge: '1.00',
    image: '/images/banners/crash.png',
    url: ROUTES.CRASH.INDEX
  },
  {
    name: 'Ring of Fortune',
    description: 'Choose a Color with you and win prizes',
    image: '/images/banners/ring-of-fortune.png',
    houseEdge: '1.00',
    url: ROUTES.RING_OF_FORTUNE.INDEX
  },
  {
    name: 'Classic Dice',
    description: 'Hold tight as the Dice Roll and get ready to win',
    image: '/images/banners/classic-dice.png',
    houseEdge: '1.00',
    url: ROUTES.CLASSIC_DICE.INDEX
  }
];

const Banner = () => {
  const pagination = {
    clickable: true,
    renderBullet: function (_: any, className: any) {
      return `<div class="${className}"></div>`;
    }
  };

  return (
    <div className={cx('slide', 'slide-custom')}>
      <Swiper
        autoplay={{
          delay: 3000,
          disableOnInteraction: false
        }}
        pagination={pagination}
        modules={[Autoplay, Pagination]}
        className={cx('swiper-slide')}
        spaceBetween={50}
        slidesPerView={1}
        loop={true}>
        {BANNERS.map((banner: GameBanner, index) => (
          <SwiperSlide key={index}>
            <BannerItem {...banner} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Banner;
