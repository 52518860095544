interface PropsType {
  width?: number;
  height?: number;
}

export default function GifIcon({ width = 28, height = 28 }: PropsType) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3297_57944)">
        <path
          d="M14.0007 2.22115C17.1466 2.22115 20.1048 3.44683 22.3293 5.67135C24.5538 7.89587 25.7788 10.854 25.7788 14C25.7788 17.146 24.5538 20.1041 22.3287 22.3287C20.1041 24.5538 17.146 25.7788 14 25.7788C10.854 25.7788 7.89587 24.5538 5.67135 22.3287C3.44615 20.1041 2.22115 17.146 2.22115 14C2.22115 10.854 3.44615 7.89587 5.67135 5.67135C7.89587 3.44615 10.854 2.22115 14 2.22115H14.0007ZM14.0007 0H14C6.26769 0 0 6.26769 0 14C0 21.7323 6.26769 28 14 28C21.7323 28 28 21.7323 28 14C28 6.26837 21.7323 0 14.0007 0Z"
          fill="#6E6E6E"
        />
        <path
          d="M11.7735 16.7409C11.7742 17.2242 11.509 17.6684 11.0843 17.8986C8.93044 19.0697 6.23477 18.2735 5.06362 16.1196C3.89246 13.9658 4.68871 11.2701 6.84256 10.099C7.97467 9.48377 9.31746 9.39021 10.5236 9.84319C11.0796 10.0572 11.3562 10.6818 11.1422 11.2378C10.9302 11.787 10.317 12.065 9.76439 11.8624C8.58448 11.4168 7.2666 12.0125 6.82102 13.1924C6.37544 14.3723 6.97112 15.6902 8.15102 16.1358C8.41016 16.2334 8.68477 16.2839 8.96208 16.2832C9.18419 16.2832 9.40496 16.2522 9.619 16.1917V15.3893H8.96208C8.36708 15.3846 7.88919 14.8987 7.89323 14.3043C7.89794 13.7161 8.37381 13.2402 8.96208 13.2355H10.2113C11.0715 13.2335 11.7708 13.9294 11.7728 14.7903C11.7728 14.791 11.7728 14.7917 11.7728 14.7917L11.7735 16.7409Z"
          fill="#6E6E6E"
        />
        <path
          d="M15.8037 17.3599C15.799 17.9549 15.3124 18.4328 14.7181 18.4281C14.1305 18.4234 13.6553 17.9482 13.6499 17.3599V10.6399C13.6546 10.0449 14.1406 9.56703 14.7349 9.57107C15.3232 9.57578 15.799 10.0516 15.8037 10.6399V17.3599Z"
          fill="#6E6E6E"
        />
        <path
          d="M22.3972 11.7168H20.001V13.1007H21.3095C21.9045 13.1061 22.3824 13.5927 22.377 14.187C22.3716 14.7739 21.8971 15.2491 21.3095 15.2545H20.001V17.3599C19.9963 17.9549 19.5097 18.4328 18.9153 18.4281C18.3277 18.4234 17.8526 17.9482 17.8472 17.3599V10.6399C17.8472 10.0456 18.3291 9.56299 18.9234 9.56299H18.9241H22.3972C22.9922 9.56837 23.4701 10.055 23.4647 10.6493C23.4593 11.2369 22.9841 11.7114 22.3972 11.7168Z"
          fill="#6E6E6E"
        />
      </g>
      <defs>
        <clipPath id="clip0_3297_57944">
          <rect width={28} height={28} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
