import classNames from 'classnames/bind';
import styles from '../../styles/components/games/maximum-profit-warning-modal.module.scss';
import ModalUI from '../UI/Modal';
import Switch from '../UI/Switch';

const cx = classNames.bind(styles);

interface PropsType {
  isShowing: boolean;
  toggle: () => void;
  onConfirm: Function;
  ignoreMaxProfit: boolean;
  setIgnoreMaxProfit: (value: boolean) => void;
}

const MaximumProfitWarningModal = ({
  isShowing,
  toggle,
  onConfirm,
  ignoreMaxProfit,
  setIgnoreMaxProfit
}: PropsType) => {
  return (
    <>
      <ModalUI hide={toggle} isShowing={isShowing}>
        <div className={cx('wrapper')}>
          <div className={cx('description')}>
            The bet amount may exceed the potential maximum profit. Please confirm you're aware that
            you may not get the full amount when you win a higher payout
          </div>
          <div className={cx('show-again')}>
            <Switch value={ignoreMaxProfit} setValue={setIgnoreMaxProfit} />{' '}
            <span className={cx('text')}>Don't show this again!</span>
          </div>
          <div className={cx('actions')}>
            <div className={cx('action-btn', 'cancel-btn')} onClick={() => toggle()}>
              Cancel
            </div>
            <div className={cx('action-btn', 'confirm-btn')} onClick={() => onConfirm()}>
              Confirm
            </div>
          </div>
        </div>
      </ModalUI>
    </>
  );
};

export default MaximumProfitWarningModal;
