import classNames from 'classnames/bind';
import styles from '../../styles/components/modal/modal-auth.module.scss';
import InputCode from '../UI/InputCode';
const cx = classNames.bind(styles);

interface SignInProps {
  successVerification: boolean;
  verificationCode: string;
  onCompleteInput: (code: any) => void;
  verify: () => Promise<void>;
}

const VerificationCode = ({
  successVerification,
  verificationCode,
  onCompleteInput,
  verify
}: SignInProps) => {
  return (
    <>
      <img
        className={cx('send-verification')}
        src="/images/send-verification.png"
        alt="send-verification"
      />

      {!successVerification && (
        <>
          <div className={cx('label')}>Verification Code</div>
          <InputCode length={6} onComplete={onCompleteInput} inputSize={45} />
          <button className={cx('confirm', verificationCode && 'button-active')} onClick={verify}>
            Confirm
          </button>
        </>
      )}
    </>
  );
};

export default VerificationCode;
