export const lengthVerificationCode = 6;

export const errors2FA = {
  verificationCode: 'Verification Code is required. Please input this field',
  verificationPsw: 'Please enter Login Password',
  verificationCodeAndPsw: 'Please enter Verification code and Login Password'
};

export const success2FA = {
  enable: 'Awesome! You have enabled 2FA successfully',
  disable: 'Awesome! You have disabled 2FA successfully'
};
