import classNames from 'classnames/bind';
import styles from '../../styles/components/modal/modal-auth.module.scss';
import InputPopup from '../UI/InputPopup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { PASSWORD } from '../../constant/password';
import { useState } from 'react';
import { useAppSelector } from '../../stores';
const cx = classNames.bind(styles);

type RegisterForm = {
  email: string;
};
interface PropsType {
  hide: () => void;
}
const ResetPassword = ({ hide }: PropsType) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<RegisterForm>();
  const identity = useAppSelector((state) => state.app.userIdentityClient);
  const [loading, setLoading] = useState(false);
  const resetPwd = async () => {
    setLoading(true);
    try {
      const res = await identity.forgotPassword(getValues('email'));
      if (res.data) {
        toast.success(
          'Please check the registered email and follow the instructions to change password'
        );
        setLoading(false);
        hide();
      }
    } catch (error: any) {
      toast.error(error?.response.data.message || error);
      setLoading(false);
    }
  };
  const onSubmit = async () => {
    resetPwd();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={cx('verification')}>
        {' '}
        <img
          className={cx('send-verification')}
          src="/images/Protection.png"
          alt="send-verification"
        />
        <div className={cx('verifycation-complete')}>Forgot Password</div>
      </div>

      <div className={cx('form-item')}>
        <InputPopup
          placeholder="Registered email address"
          type="email"
          {...register('email', {
            required: PASSWORD.REQUIRED_FIELD
          })}
        />

        <div className={cx('error')}>{errors.email && errors.email?.message}</div>
      </div>
      <div className={cx('verification')}>
        <button className={cx('confirm', 'button-active')} disabled={loading}>
          Submit
        </button>
      </div>
    </form>
  );
};

export default ResetPassword;
