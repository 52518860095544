import classNames from 'classnames/bind';
import styles from '../../styles/components/home/index.module.scss';
import Banner from './Banner';
import TopWining from './TopWining';
import ListGame from './ListGame';
import NavigatePages from './NavigatePages';
import AcceptedCurrencies from './AcceptedCurrencies';
import LobbyIcon from '../Icons/LobbyIcon';

const cx = classNames.bind(styles);

export default function Home() {
  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('banner')}>
          <Banner />
        </div>
        <div className={cx('top-wining')}>
          <TopWining />
        </div>
      </div>
      <div className={cx('games')}>
        <LobbyIcon />
        Bybet Originals
      </div>
      <div>
        <ListGame />
      </div>
      <div>
        <NavigatePages />
      </div>
      <div>
        <AcceptedCurrencies />
      </div>
    </div>
  );
}
