import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GameProfileClient } from 'game-profile-js';
import { CoinInfo } from 'game-profile-js/lib/client/entities/all.entity';
import { ICoinQuery } from 'game-profile-js/lib/dto/ref.query';

interface PriceState {
  prices: CoinInfo[];
  isPriceLoading: boolean;
}

const initialState: PriceState = {
  prices: [],
  isPriceLoading: false
};

export const getPriceAll = createAsyncThunk(
  'GET_PRICE_ALL',
  async (params: { gameProfileClient: GameProfileClient; query: ICoinQuery }) => {
    const { gameProfileClient, query } = params;
    const res = await gameProfileClient.getPriceAll(query);
    return res;
  }
);

export const prices = createSlice({
  name: 'price',
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build.addCase(getPriceAll.pending, (state) => {
      state.isPriceLoading = true;
      state.prices = [];
    });
    build.addCase(getPriceAll.fulfilled, (state, action) => {
      state.isPriceLoading = false;
      state.prices = action.payload.data;
    });
    build.addCase(getPriceAll.rejected, (state) => {
      state.isPriceLoading = false;
      state.prices = [];
    });
  }
});

export const priceInfoReducer = prices.reducer;
