import React from 'react';

const SettingIcon = () => {
  return (
    <svg width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7834 5.01662L11.4751 2.52495C10.6501 2.04995 9.3584 2.04995 8.5334 2.52495L4.1834 5.03328C2.4584 6.19995 2.3584 6.37495 2.3584 8.23328V12.7583C2.3584 14.6166 2.4584 14.8 4.21673 15.9833L8.52506 18.475C8.94173 18.7166 9.47507 18.8333 10.0001 18.8333C10.5251 18.8333 11.0584 18.7166 11.4667 18.475L15.8167 15.9666C17.5417 14.8 17.6417 14.625 17.6417 12.7666V8.23328C17.6417 6.37495 17.5417 6.19995 15.7834 5.01662ZM10.0001 13.2083C8.5084 13.2083 7.29173 11.9916 7.29173 10.5C7.29173 9.00828 8.5084 7.79162 10.0001 7.79162C11.4917 7.79162 12.7084 9.00828 12.7084 10.5C12.7084 11.9916 11.4917 13.2083 10.0001 13.2083Z"
        fill="#A2A3A4"
      />
    </svg>
  );
};

export default SettingIcon;
