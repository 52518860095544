import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AUTH } from '../../interfaces/Account';
import { RootState, useAppSelector } from '../../stores';
import { setAuthModalType } from '../../stores/AccountSlice';
import { toggleModal } from '../../stores/ModalSlice';
import styles from '../../styles/components/affiliate/banner-affiliate.module.scss';
import { getEnv } from '../../utils';
import { copyText } from '../../utils/copyText';
import CopyColorIcon from '../Icons/CopyColorIcon';
import LetterIcon from '../Icons/LetterIcon';
import Star from '../Icons/Star';
import AddReferralPopup from './AddReferralPopup/AddReferralPopup';

const cx = classNames.bind(styles);

const Banner = () => {
  const isLoggedIn = useAppSelector((state: RootState) => state.account.isLoggedIn);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const dispatch = useDispatch();
  const gameProfile = useAppSelector((state) => state.app.gameProfileClient);
  const [referralCode, setReferralCode] = useState('');
  const [referralLink, setReferralLink] = useState('');

  useEffect(() => {
    setReferralLink(`${getEnv('REACT_APP_BY_BET_URL', '')}/referral?code=${referralCode}`);
  }, [referralCode]);

  useEffect(() => {
    if (isLoggedIn) getRefCode();
  }, [isLoggedIn]);

  const getRefCode = async () => {
    const res = await gameProfile.getRefCode();
    if (res) {
      setReferralCode(res || '');
    }
  };
  return (
    <div className={cx('container')}>
      <div className={cx('affiliate-container')}>
        <div className={cx('affiliate-wrapper')}>
          <div className={cx('title', 'gradient-text')}>AFFILIATE</div>
          {!isLoggedIn ? (
            <>
              <div className={cx('text')}>
                <div className={cx('text-item')}>
                  COMMISSION NEVER <br /> ENDS
                  <span className={cx('title', 'gradient-text')}> $1,000.00</span> FOR <br />
                  EACH REFERRAL <br />
                  FRIEND
                </div>
              </div>
              <button
                className={cx('button')}
                onClick={() => {
                  dispatch(setAuthModalType(AUTH.SIGN_UP));
                  dispatch(toggleModal({ modalName: 'authModal', state: true }));
                }}>
                Get the refferral Code Now
              </button>
              <img
                src="/images/banner-affiliate.svg"
                alt=""
                className={cx('banner-affiliate-img')}
              />
              <div className={cx('star-1')}>
                <Star />
              </div>
              <div className={cx('star-2')}>
                <Star />
              </div>
              <div className={cx('star-3')}>
                <Star />
              </div>
            </>
          ) : (
            <>
              <div className={cx('description')}>
                <div className={cx('line')}>
                  Commission ready everyday <br />
                </div>
                <div className={cx('line')}>
                  <span className={cx('gradient-text')}>$25,000,000.00</span> just a referral away{' '}
                </div>
              </div>
              <div>
                <div className={cx('referral-code')}>
                  <div className={cx('gradient-text', 'label')}>My referral code</div>
                  <div className={cx('code')} onClick={() => copyText(referralCode)}>
                    <div>{referralCode}</div> <CopyColorIcon />
                  </div>
                </div>
                <div className={cx('referral-code')}>
                  <div className={cx('gradient-text', 'label')}>My referral link</div>
                  <div className={cx('code')} onClick={() => copyText(referralLink)}>
                    <div>{referralLink} </div>
                    <CopyColorIcon />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={cx('background-img')}>
        <img src="/images/background-affiliate.svg" alt="" />
      </div>
      {isLoggedIn ? (
        <button className={cx('tag')} onClick={showModal}>
          <LetterIcon />
          <div className={cx('invite-text')}>Your Inviter</div>
        </button>
      ) : null}

      <div className={cx('coin-1')}>
        <img src="/images/coin-affiliate.svg" alt="" />
      </div>
      <div className={cx('coin-2')}>
        <img src="/images/coin-affiliate.svg" alt="" />
      </div>
      <div className={cx('coin-3')}>
        <img src="/images/coin-affiliate.svg" alt="" />
      </div>
      <div className={cx('coin-4')}>
        <img src="/images/coin-affiliate.svg" alt="" />
      </div>
      <div className={cx('coin-5')}>
        <img src="/images/coin-affiliate.svg" alt="" />
      </div>
      <div className={cx('coin-6')}>
        <img src="/images/coin-affiliate.svg" alt="" />
      </div>
      <AddReferralPopup
        isModalOpen={isModalOpen}
        toggle={() => {
          setIsModalOpen(false);
        }}
      />
    </div>
  );
};

export default Banner;
