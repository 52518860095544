import styles from '../../styles/components/games/input-amount.module.scss';
import classnames from 'classnames/bind';
import { DEFAULT_VALUE } from '../../constant/game';
import Dropdown from '../UI/Dropdown';
import useBreakpoints from '../../hooks/useBreakpoints';
import ArrowDownIcon from '../Icons/ArrowDownIcon';

const cx = classnames.bind(styles);

interface InputAmountProps {
  value: string;
  icon: React.ReactNode;
  minMaxSlider: React.ReactNode;
  onDataChange: (value: string) => void;
  onBlur: (value: string) => void;
  handleDivision: () => void;
  handleMultiplication: () => void;
  nameDropdown: string;
}
const InputAmount = ({
  icon,
  value,
  minMaxSlider,
  onDataChange,
  onBlur,
  handleDivision,
  handleMultiplication,
  nameDropdown
}: InputAmountProps) => {
  const { isMobile } = useBreakpoints();

  return (
    <div className={cx('input-amount')}>
      <div className={cx('icon')}>{icon}</div>
      <input
        min={0}
        type="number"
        placeholder={DEFAULT_VALUE}
        value={value}
        onBlur={(event) => onBlur(event.target.value)}
        onChange={(event) => onDataChange(event.target.value)}
      />

      <div className={cx('division', 'quick-action')} onClick={() => handleDivision()}>
        /2
      </div>
      <div className={cx('multiplication', 'quick-action')} onClick={() => handleMultiplication()}>
        x2
      </div>
      <Dropdown name={nameDropdown} placement={isMobile ? 'right' : 'left'} overlay={minMaxSlider}>
        <div className={cx('range', 'quick-action')}>
          <ArrowDownIcon />
          <ArrowDownIcon />
        </div>
      </Dropdown>
    </div>
  );
};

export default InputAmount;
