import classNames from 'classnames/bind';
import styles from '../../styles/components/modal/modal-user-information.module.scss';
import ModalUI from '../UI/Modal';

import { useEffect, useState } from 'react';
import ArrowPreviewIcon from '../Icons/ArrowPreviewIcon';
import { SLIDE_USER } from '../../constant';
import { Swiper as SwiperType } from 'swiper/types';
import SlideUserInformation from '../UserInformation/SlideUserInfomation';
import StatisticDetail from '../UserInformation/StatisticDetail';

const cx = classNames.bind(styles);

interface User {
  name: string;
  avatar?: string;
  userId: string;
  totalWins: number;
  totalBets: number;
  totalWagered: number;
  favoriteGames: [];
  wagerContest: [];
}
interface PropsType {
  isShowing: boolean;
  toggle: () => void;
  user: User;
  slideStart: number;
  reload: () => void;
}

const ModalUserInformation = ({ isShowing, toggle, user, slideStart, reload }: PropsType) => {
  const [swiper, setSwiper] = useState<SwiperType>();
  const [slideActive, setSlideActive] = useState<number>(SLIDE_USER.MAIN);

  const HeaderModal = (modal: number) => {
    switch (modal) {
      case SLIDE_USER.MAIN:
        return <div className={cx('title')}>Statistics Detail</div>;
      case SLIDE_USER.DETAIL:
        return (
          <div className={cx('title-detail')}>
            {/* <div className={cx('icon')} onClick={() => swiper && swiper.slidePrev()}>
              <ArrowPreviewIcon />
            </div> */}
            <div className={cx('title')}>Details</div>
          </div>
        );
      case SLIDE_USER.EDIT_NAME:
        return (
          <div className={cx('title-detail')}>
            <div className={cx('icon')} onClick={() => swiper && swiper.slideTo(SLIDE_USER.MAIN)}>
              <ArrowPreviewIcon />
            </div>
            <div className={cx('title')}>Nickname</div>
          </div>
        );
      case SLIDE_USER.EDIT_AVATAR:
        return (
          <div className={cx('title-detail')}>
            <div className={cx('icon')} onClick={() => swiper && swiper.slidePrev()}>
              <ArrowPreviewIcon />
            </div>
            <div className={cx('title')}>Edit Your Avatar</div>
          </div>
        );
      default:
        break;
    }
  };

  const onInit = (ev: SwiperType) => {
    setSwiper(ev);
  };

  useEffect(() => {
    const slideStartActive = (slide: number) => {
      const speedSlide = 0;
      if (swiper) {
        switch (slide) {
          case SLIDE_USER.MAIN:
            swiper.slideTo(SLIDE_USER.MAIN, speedSlide);
            break;
          case SLIDE_USER.DETAIL:
            swiper.slideTo(SLIDE_USER.DETAIL, speedSlide);
            break;
          case SLIDE_USER.EDIT_AVATAR:
            swiper.slideTo(SLIDE_USER.EDIT_AVATAR, speedSlide);
            break;
          case SLIDE_USER.EDIT_NAME:
            swiper.slideTo(SLIDE_USER.EDIT_NAME, speedSlide);
            break;
          default:
            break;
        }
      }
    };
    slideStartActive(slideStart);
  }, [swiper]);

  return (
    <ModalUI isShowing={isShowing} hide={toggle} title={<>{HeaderModal(slideActive)}</>}>
      {/* <SlideUserInformation
        swiper={swiper}
        user={user}
        reload={reload}
        onInit={onInit}
        setSlideActive={setSlideActive}
      /> */}
      <StatisticDetail user={user} />
    </ModalUI>
  );
};

export default ModalUserInformation;
