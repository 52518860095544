import classNames from 'classnames/bind';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { errors2FA, lengthVerificationCode, success2FA } from '../../../constant/2fa';
import { RootState, useAppSelector } from '../../../stores';
import { setUserInfo } from '../../../stores/UserInformation';
import styles from '../../../styles/components/global-setting/two-factor-body.module.scss';
import EyePasswordCloseIcon from '../../Icons/EyePasswordCloseIcon';
import EyePasswordIcon from '../../Icons/EyePasswordIcon';
import InputCodeV2 from '../../UI/InputCodeV2';

const cx = classNames.bind(styles);

const DisableTwoFactor = () => {
  const userInfo = useAppSelector((state: RootState) => state.userInfo.userInfo);
  const userIdentityClient = useAppSelector((state) => state.app.userIdentityClient);
  const [showPsw, setShowPsw] = useState(false);
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: { verificationCode: '', password: '' }
  });
  const onSubmit = (data: any) => {};

  const handleShowPassword = () => {
    setShowPsw(!showPsw);
  };

  const handleChangeInputCode = (code: string) => {
    setValue('verificationCode', code);
  };

  const handleDisable2FA = async () => {
    const password = getValues('password');
    const twoFactorCode = getValues('verificationCode');
    const dataReq = {
      password,
      twoFactorCode
    };
    try {
      if (password.length === 0 && twoFactorCode.length < lengthVerificationCode) {
        throw new Error(errors2FA.verificationCodeAndPsw);
      }
      if (password.length === 0) {
        throw new Error(errors2FA.verificationPsw);
      }
      if (twoFactorCode.length < lengthVerificationCode) {
        throw new Error(errors2FA.verificationCode);
      }
      await userIdentityClient.turnOff2fa(dataReq);
      toast.success(success2FA.disable);
      dispatch(setUserInfo({ ...userInfo, isEnableTwoFactor: false }));
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || error);
    }
  };

  return (
    <div className={cx('disable-view-container')}>
      <p className={cx('note')}>
        To disable Two-factor authentication. enter your one time password (OTP)
      </p>
      <form className={cx('verification-container')} onSubmit={handleSubmit(onSubmit)}>
        <div className={cx('password-form')}>
          <p>Login Password</p>
          <div className={cx('input-form')}>
            <input
              type={showPsw ? 'text' : 'password'}
              autoComplete="off"
              {...register('password')}
            />
            <div className={cx('eye-icon')} onClick={handleShowPassword}>
              {showPsw ? <EyePasswordCloseIcon /> : <EyePasswordIcon />}
            </div>
          </div>
        </div>

        <div className={cx('divider')} />

        <div className={cx('code-container')}>
          <p>Verification code</p>
          <div className={cx('input-code')}>
            <InputCodeV2 length={lengthVerificationCode} onChange={handleChangeInputCode} />
          </div>
        </div>

        <button className={cx('button-enable')} onClick={handleDisable2FA}>
          Disable
        </button>
      </form>
    </div>
  );
};

export default DisableTwoFactor;
