import React from 'react';

const MuteVolumeIcon = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3276_57094)">
        <path
          d="M20.4836 3.51668C15.7951 -1.17176 8.20605 -1.1727 3.51668 3.51668C-1.17176 8.20512 -1.1727 15.7942 3.51668 20.4836C8.20512 25.172 15.7942 25.1729 20.4836 20.4836C25.172 15.7951 25.1725 8.20605 20.4836 3.51668ZM12.4848 17.0054C12.4848 17.8061 11.5778 18.2725 10.9267 17.8075L7.68012 15.4881H5.99356C5.44934 15.4881 5.00824 15.047 5.00824 14.5028V9.49746C5.00824 8.95324 5.44934 8.51215 5.99356 8.51215H7.68012L10.9267 6.19277C11.5782 5.7273 12.4848 6.19465 12.4848 6.9948V17.0054ZM19.9365 13.427C20.3214 13.8118 20.3214 14.4357 19.9365 14.8206C19.5517 15.2054 18.9278 15.2054 18.5429 14.8206L17.1161 13.3937L15.6892 14.8206C15.3043 15.2054 14.6804 15.2054 14.2956 14.8206C13.9107 14.4357 13.9107 13.8118 14.2956 13.427L15.7225 12.0001L14.2956 10.5732C13.9107 10.1884 13.9107 9.56449 14.2956 9.17965C14.6804 8.7948 15.3043 8.7948 15.6892 9.17965L17.1161 10.6065L18.5429 9.17965C18.9278 8.7948 19.5517 8.7948 19.9365 9.17965C20.3214 9.56449 20.3214 10.1884 19.9365 10.5732L18.5096 12.0001L19.9365 13.427Z"
          fill="#A2A3A4"
        />
      </g>
      <defs>
        <clipPath id="clip0_3276_57094">
          <rect width={24} height={24} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MuteVolumeIcon;
