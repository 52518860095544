/* eslint-disable no-unused-expressions */
import clasnames from 'classnames/bind';
import { debounce } from 'lodash';
import React from 'react';
import styles from '../../styles/UI/input.module.scss';

const cx = clasnames.bind(styles);

interface PropsType {
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  placeholder?: string;
  type?: string;
  isDebounce?: boolean;
  readonly?: boolean;
  onChange: any;
  handleBlur?: (e: any) => void;
}

function Input(
  {
    prefix,
    suffix,
    placeholder = '',
    type = 'text',
    isDebounce,
    onChange,
    handleBlur,
    readonly = false,
    ...props
  }: PropsType,
  ref: any
) {
  return (
    <div className={cx('input-container')}>
      {prefix && <div className={cx('prefix-container')}>{prefix}</div>}
      <input
        readOnly={readonly}
        ref={ref}
        placeholder={placeholder}
        className={cx('input')}
        {...props}
        onBlur={(e) => {
          handleBlur && handleBlur(e);
        }}
        onChange={isDebounce ? debounce(onChange, 1000) : onChange}
        type={type}
      />
      {suffix && <div className={cx('suffix-container')}>{suffix}</div>}
    </div>
  );
}

export default React.forwardRef(Input);
