import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MetaverseClient from 'metaverse-js';
import { Asset } from 'metaverse-js/lib/proto/model/asset';
import { AssetQueryRequest } from 'metaverse-js/lib/proto/service/asset';

interface AssetState {
  assets: Asset[];
  isAssetsLoading: boolean;
}

const initialState: AssetState = {
  assets: [],
  isAssetsLoading: false
};

export const getAssets = createAsyncThunk(
  'GET_ASSETS',
  async (query: { metaverseClient: MetaverseClient; assetQuery: AssetQueryRequest }) => {
    const { metaverseClient, assetQuery } = query;
    const res = await metaverseClient.getAssets(assetQuery);
    return res;
  }
);

export const assets = createSlice({
  name: 'assets',
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build.addCase(getAssets.pending, (state) => {
      state.isAssetsLoading = true;
      state.assets = [];
    });
    build.addCase(getAssets.fulfilled, (state, action) => {
      state.isAssetsLoading = false;
      state.assets = action.payload.data;
    });
    build.addCase(getAssets.rejected, (state) => {
      state.isAssetsLoading = false;
      state.assets = [];
    });
  }
});

export const assetsInfoReducer = assets.reducer;
