import BigNumber from 'bignumber.js';
import { CurrencyConfig } from 'bybet-game-js/lib/schema/BaseGame';
import { MAX_BETTING_TOKEN } from '../constant';
import { BET_DECIMAL_CONFIG, DEFAULT_VALUE } from '../constant/game';

export const getPayout = (reward: string | number, amount: string | number) => {
  return new BigNumber(reward).div(amount).toFixed(2);
};

export const handleBetDataChange = (value: any, currencyConfig?: CurrencyConfig) => {
  let validatedData = value.trim();
  if (
    new BigNumber(value).gt(0) &&
    new BigNumber(value).lt(currencyConfig?.minBetAmount || DEFAULT_VALUE)
  ) {
    validatedData = currencyConfig?.minBetAmount || DEFAULT_VALUE;
  }
  if (new BigNumber(value).gt(currencyConfig?.maxBetAmount || MAX_BETTING_TOKEN)) {
    validatedData = currencyConfig?.maxBetAmount || MAX_BETTING_TOKEN;
  }

  return validatedData
    ? new BigNumber(validatedData).toFixed(BET_DECIMAL_CONFIG)
    : new BigNumber(currencyConfig?.minBetAmount || DEFAULT_VALUE).toFixed(BET_DECIMAL_CONFIG);
};

export const handleDivision = (
  amount: string,
  divisor: number,
  currencyConfig?: CurrencyConfig
) => {
  const newAmount = new BigNumber(amount).dividedBy(divisor);
  if (newAmount.lt(currencyConfig?.minBetAmount || DEFAULT_VALUE)) {
    return amount;
  }
  return newAmount.toFixed(BET_DECIMAL_CONFIG);
};
