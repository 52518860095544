import React from 'react';
import { useAppDispatch, useAppSelector } from '../../stores';
import ModalUI from '../UI/Modal';
import EditAvatar from '../UserInformation/EditAvatar';
import { toggleModal } from '../../stores/ModalSlice';
import { getUserInfo } from '../../stores/UserInformation';
import styles from '../../styles/components/user-setting/edit-avatar.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const ModalEditAvatar = () => {
  const { userIdentityClient, editAvatarModal, userInfo, account } = useAppSelector((state) => ({
    userIdentityClient: state.app.userIdentityClient,
    editAvatarModal: state.modal.editAvatarModal,
    userInfo: state.userInfo.userInfo,
    account: state.account.accountInfo
  }));
  const dispatch = useAppDispatch();
  const toggle = () => {
    dispatch(
      toggleModal({
        modalName: 'editAvatarModal',
        state: false
      })
    );
  };

  const handleReload = () => {
    dispatch(
      getUserInfo({
        userIdentityClient,
        id: account.id
      })
    );
  };
  return (
    <ModalUI
      isShowing={editAvatarModal}
      hide={toggle}
      title={<div className={cx('title')}>Edit Your Avatar</div>}>
      <EditAvatar reload={handleReload} userId={userInfo?.id || ''} defaultAvt={userInfo?.avatar} />
    </ModalUI>
  );
};

export default ModalEditAvatar;
