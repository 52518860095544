import classNames from 'classnames/bind';
import styles from '../../styles/components/games/switch.module.scss';

const cx = classNames.bind(styles);
interface SwitchProps {
  className?: string;
}
const Switch = ({ className }: SwitchProps) => {
  return (
    <div className={cx(className)}>
      <label className={cx('switch')}>
        <input type="checkbox" defaultChecked={false} />
        <span className={cx('slider', 'round')} />
      </label>
    </div>
  );
};

export default Switch;
