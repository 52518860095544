import React from 'react';

const SendMessageIcon = () => {
  return (
    <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={40} height={40} rx={20} fill="url(#paint0_linear_651_19786)" />
      <path
        d="M29.1108 10.889C29.0162 10.7943 28.896 10.7292 28.765 10.7019C28.6339 10.6745 28.4978 10.686 28.3731 10.735L9.12315 18.2975C9.00459 18.344 8.9013 18.4226 8.82481 18.5245C8.74831 18.6263 8.70161 18.7474 8.68991 18.8743C8.67822 19.0011 8.702 19.1287 8.75859 19.2428C8.81517 19.3569 8.90235 19.4531 9.0104 19.5205L14.5104 22.958C14.6102 23.0205 14.7244 23.0563 14.842 23.0619C14.9596 23.0676 15.0767 23.0429 15.1821 22.9903L18.8375 21.1623L17.0095 24.8177C16.9569 24.9231 16.9322 25.0402 16.9379 25.1578C16.9435 25.2754 16.9793 25.3896 17.0418 25.4894L20.4793 30.9894C20.5411 31.0883 20.627 31.1698 20.729 31.2263C20.831 31.2829 20.9457 31.3125 21.0623 31.3125H21.1255C21.2524 31.3008 21.3736 31.254 21.4754 31.1774C21.5773 31.1008 21.6558 30.9974 21.7023 30.8787L29.2648 11.6287C29.3142 11.5038 29.3259 11.3672 29.2986 11.2358C29.2712 11.1043 29.2059 10.9838 29.1108 10.889Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_651_19786"
          x1={40}
          y1={20}
          x2="-0.638297"
          y2={20}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#147FBB" />
          <stop offset={1} stopColor="#36ADD2" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SendMessageIcon;
