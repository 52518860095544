export default function ArrowDownIcon() {
  return (
    <svg width={12} height={13} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.76271 4.44783C2.71906 4.49138 2.68443 4.5431 2.66079 4.60005C2.63716 4.657 2.625 4.71805 2.625 4.77971C2.625 4.84137 2.63716 4.90242 2.66079 4.95937C2.68443 5.01631 2.71906 5.06804 2.76271 5.11158L5.91646 8.26533C5.95116 8.3001 5.99236 8.32768 6.03773 8.3465C6.08309 8.36531 6.13172 8.375 6.18084 8.375C6.22995 8.375 6.27858 8.36531 6.32395 8.3465C6.36931 8.32768 6.41052 8.3001 6.44521 8.26533L9.59896 5.11158C9.78271 4.92783 9.78271 4.63158 9.59896 4.44783C9.41521 4.26408 9.11896 4.26408 8.93521 4.44783L6.18271 7.20033L3.42646 4.44408C3.24271 4.26408 2.94646 4.26408 2.76271 4.44783Z"
        fill="#B3B3B3"
      />
    </svg>
  );
}
