import BigNumber from 'bignumber.js';
import classnames from 'classnames/bind';
import { useEffect, useState } from 'react';
import styles from '../../styles/UI/pagination.module.scss';
import ArrowDown from '../Icons/ArrowDown';

const cx = classnames.bind(styles);

interface PropsType {
  pagination: {
    page: number;
    pageSize: number;
    total?: number;
  };
  changePage: (page: number) => void;
}

export default function Pagination({ pagination, changePage }: PropsType) {
  const [count, setCount] = useState(1);

  useEffect(() => {
    if (pagination.total) {
      setCount(Math.ceil((pagination?.total || 1) / (pagination?.pageSize || 1)));
    }
  }, [pagination?.total, pagination?.pageSize]);

  return (
    <div className={cx('root')}>
      {!!pagination.total && (
        <>
          <p>Page</p>
          <input
            value={pagination.page}
            className={cx('input-current-page')}
            onChange={(e) => {
              let value = e?.target?.value;
              if (new BigNumber(value).isInteger() && new BigNumber(value).gt(0)) {
                changePage(new BigNumber(value).toNumber());
              }
            }}
          />
          <p>of {count}</p>
        </>
      )}
      <button
        className={cx('btn-prev-base', 'btn', pagination.total && 'btn-prev')}
        onClick={() => {
          changePage(pagination.page - 1 > 0 ? pagination.page - 1 : 1);
        }}
        disabled={pagination.page === 1}>
        <ArrowDown />
      </button>
      {!pagination.total && (
        <input
          value={pagination.page}
          className={cx('input-current-page')}
          onChange={(e) => {
            let value = e?.target?.value;
            if (new BigNumber(value).isInteger() && new BigNumber(value).gt(0)) {
              changePage(new BigNumber(value).toNumber());
            }
          }}
        />
      )}
      <button
        className={cx('btn-next', 'btn')}
        onClick={() => {
          changePage(pagination.page + 1 < count ? pagination.page + 1 : count);
        }}
        disabled={pagination.page === count}>
        <ArrowDown />
      </button>
    </div>
  );
}
