import styles from '../../../styles/components/affiliate/rewards-rule/commision-rewards-rule.module.scss';
import classnames from 'classnames/bind';
import ModalUI from '../../UI/Modal';

const cx = classnames.bind(styles);

interface PropsType {
  isModalOpen: boolean;
  toggle: () => void;
}

const CommisionRewardsRule = ({ isModalOpen, toggle }: PropsType) => {
  return (
    <ModalUI
      isShowing={isModalOpen}
      hide={toggle}
      title={<div className={cx('title')}>Commision Rewards Rules</div>}>
      <div className={cx('content')}>
        <div className={cx('info')}>
          <p className={cx('text-primary')}>Your commission</p>
          <p>
            Wager x 1% x <span className={cx('text-primary')}>15%</span>
          </p>
        </div>
        <div className={cx('text-note')}>
          Your refferd members will rebate you a percentage of their wagers as your commission.
        </div>

        <div className={cx('img-container')}>
          <img src="/images/com-rewards-rules-img.png" alt="commission rewards rules" />
        </div>
      </div>
    </ModalUI>
  );
};

export default CommisionRewardsRule;
