/* eslint-disable no-console */
import ChatRoomClient, { ChatRoomClientOptions } from 'cht-chat-js';
import { DefaultChatRoom } from 'cht-chat-js/lib/room/chat';
import { ChatMessage, ChatRoomState } from 'cht-chat-js/lib/schema/Chat';
import classNames from 'classnames/bind';
import EmojiPicker, { EmojiClickData, Theme } from 'emoji-picker-react';
import { find, uniqBy } from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { AUTH } from '../../interfaces/Account';
import { useAppSelector } from '../../stores';
import { setAuthModalType } from '../../stores/AccountSlice';
import { toggleModal } from '../../stores/ModalSlice';
import styles from '../../styles/Layout/chat.module.scss';
import ArrowDown from '../Icons/ArrowDown';
import CloseChat from '../Icons/CloseChat';
import GifIcon from '../Icons/GifIcon';
import SendMessageIcon from '../Icons/SendMessageIcon';
import SmileIcon from '../Icons/SmileIcon';
import ModalChatInformation from '../Modal/ModalChatInformation';
import ModalChatRules from '../Modal/ModalChatRules';
import GiphyPicker from '../UI/GiphyPicker';
import GiphyMessage from './GiphyMessage';

const cx = classNames.bind(styles);

const FORMAT_DATE = 'HH:mm';

const REGIONS = [
  { key: 'philippines', name: 'Philippines' },
  { key: 'us', name: 'US' }
];

interface ItemChat {
  id: string;
  uid: string;
  nickName: string;
  message: string;
  timestamp: number;
  avatar: string;
  type: string;
}

enum MESSAGE_TYPE {
  GIPHY = 'giphy',
  TEXT = 'text'
}

interface PropsType {
  isShowChat: boolean;
  handleShowChat?: () => void;
}

const Chat = ({ handleShowChat, isShowChat }: PropsType) => {
  const { account } = useAppSelector((state) => ({
    account: state.account.accountInfo
  }));
  const userIdentityClient = useAppSelector((state) => state.app.userIdentityClient);
  const [showPickerEmoji, setShowPickerEmoji] = useState<boolean>(false);
  const [showGiphyPicker, setShowGiphyPicker] = useState<boolean>(false);
  const [isShowSend, setIsShowSend] = useState(false);
  const [chatList, setChatList] = useState<ItemChat[]>([]);
  const [chatRoomClient, setChatRoomClient] = useState<ChatRoomClient>();
  const [chatRoom, setChatRoom] = useState<DefaultChatRoom<ChatRoomState>>();
  const [chatIsReady, setChatIsReady] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isShowing, setIsShowing] = useState(false);
  const [getId, setGetId] = useState('');

  const showModal = (uid: string) => {
    setIsShowing(true);
    setGetId(uid);
  };

  const { register, handleSubmit, getValues, setValue, resetField } = useForm({
    defaultValues: { message: '' }
  });
  const formRef = useRef<any>(null);
  const chatContainerRef = useRef<any>();
  const chatItemsRef = useRef<any[]>([]);
  const dispatch = useDispatch();

  const onSubmit = (data: any) => {
    if (data?.message?.length > 0) {
      handleSendText(data?.message);
      resetField('message');
    }
  };

  const onEmojiClick = (emojiObject: EmojiClickData) => {
    const message = getValues('message');
    setValue('message', message.concat(emojiObject.emoji));
    setIsShowSend(true);
  };

  const scrollToBottomChat = async () => {
    const scroll = chatContainerRef.current?.scrollHeight;
    chatContainerRef.current?.scrollTo(0, scroll + 600);
    setUnreadCount(0);
    handleReadMessages();
  };

  const parseChatMesage = async (message: ChatMessage) => {
    try {
      const user = await userIdentityClient.getUser(message.uid);
      const newMessage = {
        id: message.id,
        uid: message.uid,
        nickName: user?.nickName || 'Hidden',
        message: message.message,
        timestamp: message.timestamp * 1000,
        avatar: user.avatar || '/images/avatar.png',
        type: message.type
      } as ItemChat;
      setChatList((prevChat) => {
        let newList = prevChat.concat([newMessage]);
        newList = uniqBy(newList, 'id');
        return newList;
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const getHistory = async (chatRoom: DefaultChatRoom<ChatRoomState>) => {
    try {
      const queryParams = {
        limit: 100,
        offset: 0
      };
      const history = await chatRoom.getChatHistory(queryParams);
      if (history.length > 0) {
        const users = await userIdentityClient.getUsers({
          ids: history.map((historyItem) => historyItem.uid).toString()
        });
        const newChatList = history.map((historyItem) => {
          return {
            id: historyItem.id,
            uid: historyItem.uid,
            nickName: find(users.data, { id: historyItem.uid })?.nickName || 'Hidden',
            message: historyItem.message,
            timestamp: historyItem.timestamp * 1000,
            avatar: find(users.data, { id: historyItem.uid })?.avatar || '/images/avatar.png',
            type: historyItem.type
          };
        });
        setChatList(newChatList);
        scrollToBottomChat();
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getUnreadCount = async (chatRoom: DefaultChatRoom<ChatRoomState>) => {
    try {
      const unreadCount = await chatRoom.getUnreadCount();
      setUnreadCount(unreadCount || 0);
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleSendMessage = async (type: MESSAGE_TYPE, message: string) => {
    if (account.accessToken.length === 0) {
      dispatch(setAuthModalType(AUTH.SIGN_IN));
      dispatch(toggleModal({ modalName: 'authModal', state: true }));
      return;
    }
    try {
      if (!chatIsReady) {
        throw Error('Oops! Something went wrong. Please reload page and try again');
      }
      if (type === MESSAGE_TYPE.GIPHY) {
        await chatRoom?.sendGiphyMessage(message);
      } else {
        await chatRoom?.sendTextMessage(message);
      }
      scrollToBottomChat();
    } catch (error: any) {
      toast.error(error?.message || error);
    }
  };

  const handleSendText = async (message: string) => {
    handleSendMessage(MESSAGE_TYPE.TEXT, message);
  };

  const handleSendGiphy = (gifId: string) => {
    setShowGiphyPicker(false);
    handleSendMessage(MESSAGE_TYPE.GIPHY, gifId);
  };

  const handleReadMessages = async () => {
    try {
      if (chatRoom) {
        await chatRoom.sendRead();
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const handleScrollListChat = () => {
    const clientHeight = chatContainerRef.current?.clientHeight;
    const scrollTop = chatContainerRef.current?.scrollTop;
    const scrollHeight = chatContainerRef.current?.scrollHeight;
    const scroll = scrollHeight - clientHeight * 2;
    if (scrollTop > scroll) {
      setUnreadCount(0);
      handleReadMessages();
    }

    // handle scroll to read new message

    // const unread = 2;
    // let scrollTopUnread = 0;
    // if (chatItemsRef.current.length > 0) {
    //   scrollTopUnread = chatItemsRef.current
    //     .slice(chatItemsRef.current.length - unread, chatItemsRef.current.length)
    //     .map((e: any) => e?.getBoundingClientRect()?.height + 20)
    //     .reduce((prevHeight, currentHeight) => currentHeight + prevHeight, 0);
    //   console.log('scrollTopUnread', scrollTopUnread);
    // }
  };

  const listenEventChat = async (chatRoomClient: ChatRoomClient) => {
    const chatRoom = chatRoomClient.getGlobalChatRoom();
    await chatRoom.connect();
    setChatRoomClient(chatRoomClient);
    setChatIsReady(chatRoom.isReady());
    setChatRoom(chatRoom);

    chatRoom.onError((code, error) => {
      toast.error(error);
    });

    chatRoom.onStateChange((state) => {
      setChatIsReady(state.isReady);
    });

    chatRoom.onChatMessage((message) => {
      parseChatMesage(message);
    });
  };

  useEffect(() => {
    if (account.accessToken && chatRoomClient) {
      chatRoomClient.setTokenId(account.accessToken);
    }
  }, [account.accessToken, chatRoomClient]);

  useEffect(() => {
    if (chatIsReady && chatRoom) {
      getHistory(chatRoom);
    }
  }, [chatIsReady, chatRoom, account.photoURL, account.displayName]);

  useEffect(() => {
    const options = {
      host: process.env.REACT_APP_CHAT_HOST,
      secure: true
    } as ChatRoomClientOptions;
    const chatRoomClient = new ChatRoomClient(options);

    listenEventChat(chatRoomClient);
  }, []);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (formRef.current && !formRef.current?.contains(event.target)) {
        setIsShowSend(false);
        setShowPickerEmoji(false);
        setShowGiphyPicker(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [formRef]);

  useEffect(() => {
    if (chatContainerRef.current && chatRoom) {
      const scrollTop = chatContainerRef.current?.scrollTop;
      const scrollHeight = chatContainerRef.current?.scrollHeight;
      const clientHeight = chatContainerRef.current?.clientHeight;
      if (scrollHeight - scrollTop > clientHeight * 2) {
        getUnreadCount(chatRoom);
      } else {
        scrollToBottomChat();
      }
    }
  }, [chatList]);

  useEffect(() => {
    if (isShowChat) {
      scrollToBottomChat();
    }
  }, [isShowChat]);

  return (
    <>
      <div className={cx('wrapper')}>
        <div className={cx('header')}>
          <div className={cx('dropdown')}>
            <button className={cx('button-dropdown')}>
              <div>Global</div>
              <div className={cx('icon')}>
                <ArrowDown />
              </div>
            </button>

            {/* popover select region */}
            {/* <div className={cx('menu-dropdown')}>
              <ul className={cx('list-location')}>
                {REGIONS.map((region) => (
                  <li className={cx('item')} key={region.key}>
                    {region.name}
                  </li>
                ))}
              </ul>
            </div> */}
          </div>

          <div className={cx('header-right')}>
            {' '}
            <div className={cx('info-icon')}>
              <ModalChatRules />
            </div>
            {/* <div>
            <CrowIcon />
          </div> */}
            <div onClick={handleShowChat} className={cx('close-icon')}>
              <CloseChat />
            </div>
          </div>
        </div>

        <div className={cx('list-chat')} ref={chatContainerRef} onScroll={handleScrollListChat}>
          {chatList.map((item, index) => {
            return (
              <div
                key={item.id}
                id={item.id}
                className={cx('item', item.uid === account.id && 'my-message')}
                ref={(c) => {
                  if (c) {
                    chatItemsRef.current[index] = c;
                  }
                }}>
                <div className={cx('avatar')} onClick={() => showModal(item.uid)}>
                  <img src={item.avatar} alt="avatar" />
                </div>
                <div className={cx('user-chat')}>
                  <div className={cx('info')}>
                    <div className={cx('name')}>{item.nickName}</div>{' '}
                    <div className={cx('time')}>{moment(item.timestamp).format(FORMAT_DATE)}</div>
                  </div>
                  {item.type === MESSAGE_TYPE.GIPHY ? (
                    <div className={cx('giphy-content')}>
                      <GiphyMessage id={item.message} />
                    </div>
                  ) : (
                    <div className={cx('content')}>{item.message}</div>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        <div className={cx('message-box')}>
          {unreadCount > 0 && (
            <div className={cx('unread-count-container')} onClick={scrollToBottomChat}>
              <p>{unreadCount}</p>
            </div>
          )}

          <form className={cx('chat-form')} onSubmit={handleSubmit(onSubmit)} ref={formRef}>
            <div className={cx('box-chat', isShowSend && 'is-show-send')}>
              <input
                placeholder="Your Message..."
                {...register('message')}
                onFocus={() => setIsShowSend(true)}
                autoComplete="off"
              />
              <div className={cx('icon', showPickerEmoji && 'active')}>
                <span
                  onClick={() => {
                    setShowGiphyPicker(false);
                    setShowPickerEmoji(!showPickerEmoji);
                  }}>
                  <SmileIcon />
                </span>
              </div>
              <div className={cx('icon', showGiphyPicker && 'active')}>
                <span
                  onClick={() => {
                    setShowGiphyPicker(!showGiphyPicker);
                    setShowPickerEmoji(false);
                  }}>
                  <GifIcon width={20} height={20} />
                </span>
              </div>
            </div>
            <button
              type="submit"
              className={cx('send-button', isShowSend ? 'send-block' : 'send-hidden')}>
              <SendMessageIcon />
            </button>
            {showPickerEmoji && (
              <div className={cx('emoji-picker')}>
                <EmojiPicker onEmojiClick={onEmojiClick} theme={Theme.DARK} />
              </div>
            )}
            {showGiphyPicker && (
              <div className={cx('giphy-picker-container')}>
                <GiphyPicker
                  onGifClick={(gif: any, e: any) => {
                    e.preventDefault();
                    handleSendGiphy(gif?.id || '');
                  }}
                />
              </div>
            )}
          </form>
          <ModalChatInformation
            isShowing={isShowing}
            toggle={() => {
              setIsShowing(false);
            }}
            id={getId}
          />
        </div>
      </div>
    </>
  );
};

export default Chat;
