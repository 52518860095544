import cookie from 'cookie';
import { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-multi-carousel/lib/styles.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/effect-creative';
import Layout from './components/layouts';
import { EXPIRED_TIME } from './constant';
import { routes } from './routes';
import { storageService } from './services/storage';
import { RootState, useAppDispatch, useAppSelector } from './stores';
import { getUserLevel, logout, setAccountInfo, setLoggedIn } from './stores/AccountSlice';
import { setRefreshToken, setTokenId } from './stores/AppSlice';
import { clearBalance, getAllMyBalance } from './stores/BalanceSlice';
import { getPriceAll } from './stores/PriceSlice';
import { getUserInfo } from './stores/UserInformation';
import './styles/date-picker.scss';
import './styles/global.scss';
import './styles/swiper.scss';

function App() {
  const dispatch = useAppDispatch();
  const account = useAppSelector((state: RootState) => state.account.accountInfo);
  const metaverseClient = useAppSelector((state: RootState) => state.app.metaverseClient);
  const gameProfileClient = useAppSelector((state: RootState) => state.app.gameProfileClient);
  const userIdentityClient = useAppSelector((state: RootState) => state.app.userIdentityClient);
  const userInfo = useAppSelector((state: RootState) => state.userInfo.userInfo);
  const isUserInfoError = useAppSelector((state) => state.userInfo.isUserError);

  useEffect(() => {
    const accountData = storageService.getAccount();
    if (accountData) {
      dispatch(setAccountInfo(accountData));
      dispatch(setRefreshToken(accountData.refreshToken));
    }
  }, []);

  useEffect(() => {
    const cookies = cookie.parse(window.document.cookie);
    dispatch(setLoggedIn(!!cookies.token));
  }, [cookie]);

  useEffect(() => {
    if (account.accessToken) {
      dispatch(getAllMyBalance(metaverseClient));
      dispatch(getUserLevel(gameProfileClient));
    } else {
      dispatch(clearBalance());
    }
    dispatch(setTokenId(account.accessToken));
    dispatch(getUserInfo({ userIdentityClient, id: account.id }));
  }, [account.accessToken, metaverseClient, gameProfileClient, userIdentityClient]);

  useEffect(() => {
    userIdentityClient.onTokenChange((tokenId, refreshToken) => {
      dispatch(setRefreshToken(refreshToken));
      dispatch(setTokenId(tokenId));
      const accountData = storageService.getAccount();
      const newAccountData = { ...accountData, accessToken: tokenId, refreshToken: refreshToken };
      storageService.saveAccount(newAccountData);
      storageService.saveExpiredTimeAccessToken(tokenId, EXPIRED_TIME);
    });
  }, [userIdentityClient]);

  useEffect(() => {
    dispatch(getPriceAll({ gameProfileClient, query: {} }));
  }, [gameProfileClient]);

  useEffect(() => {
    if (userInfo && userInfo.id && userInfo.status !== 'ACTIVED') {
      dispatch(logout());
    }
  }, [userInfo]);

  useEffect(() => {
    if (isUserInfoError) {
      setTimeout(() => {
        dispatch(
          getUserInfo({
            userIdentityClient,
            id: account.id
          })
        );
      }, 1000);
    }
  }, [isUserInfoError]);

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          {routes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
