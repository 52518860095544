import { GiphyFetch } from '@giphy/js-fetch-api';
import { IGif } from '@giphy/js-types';
import { Gif } from '@giphy/react-components';
import { useEffect, useState } from 'react';
import { GIPHY_API_KEY } from '../../constant/chat';

const apiKey = GIPHY_API_KEY;

const giphyFetch = new GiphyFetch(apiKey);

interface PropsType {
  id: string;
}

export default function GiphyMessage({ id }: PropsType) {
  const [gif, setGif] = useState<IGif>();

  const fetchData = async () => {
    try {
      const res = await giphyFetch.gif(id);
      if (res) {
        setGif(res.data);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      {gif && (
        <Gif
          gif={gif}
          width={130}
          height={110}
          onGifClick={(gif: any, e: any) => {
            e.preventDefault();
          }}
        />
      )}
    </>
  );
}
