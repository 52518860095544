import React from 'react';

interface PropsType {
  active?: boolean;
}

const TransactionWalletIcon = ({ active }: PropsType) => {
  return (
    <svg width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.9375 10.5122H2.595C2.72438 10.5122 2.82938 10.4072 2.82938 10.2779C2.82938 6.44662 5.94656 3.32974 9.77781 3.32974C11.9188 3.32974 13.9556 4.33037 15.2663 6.01568L14.4803 6.8863C14.4259 6.94662 14.4066 7.03068 14.4294 7.1088C14.4519 7.18693 14.5134 7.24755 14.5919 7.26912L19 8.49224C19.0863 8.51662 19.1769 8.48943 19.2366 8.42349C19.2963 8.35755 19.3131 8.2638 19.2809 8.18099L17.6144 3.92068C17.5847 3.84505 17.5181 3.79037 17.4384 3.77568C17.3588 3.76099 17.2769 3.7888 17.2225 3.84912L16.7053 4.42193C14.9753 2.37287 12.4666 1.20349 9.77844 1.20349C4.77406 1.20318 0.703125 5.27412 0.703125 10.2779C0.703125 10.4072 0.808125 10.5122 0.9375 10.5122Z"
        fill={active ? 'white' : '#A2A3A4'}
      />
      <path
        d="M19.0624 10.4879H17.4049C17.2755 10.4879 17.1705 10.5929 17.1705 10.7222C17.1705 14.5535 14.0533 17.6704 10.2221 17.6704C8.08113 17.6704 6.04426 16.6697 4.73363 14.9844L5.51957 14.1138C5.57395 14.0535 5.59332 13.9694 5.57051 13.8913C5.54801 13.8132 5.48645 13.7525 5.40801 13.731L0.999884 12.5079C0.913946 12.4838 0.823009 12.5107 0.763321 12.5766C0.703634 12.6425 0.686759 12.7363 0.718946 12.8191L2.38551 17.0794C2.4152 17.155 2.48176 17.2097 2.56145 17.2244C2.64145 17.2391 2.72332 17.2113 2.77738 17.151L3.29457 16.5782C5.02457 18.6272 7.53332 19.7966 10.2214 19.7966C15.2252 19.7966 19.2961 15.7257 19.2961 10.7219C19.2961 10.5925 19.1911 10.4875 19.0618 10.4875L19.0624 10.4879Z"
        fill={active ? 'white' : '#A2A3A4'}
      />
      <path
        d="M10 5.89062C7.45844 5.89062 5.39062 7.95844 5.39062 10.5C5.39062 13.0416 7.45844 15.1094 10 15.1094C12.5416 15.1094 14.6094 13.0416 14.6094 10.5C14.6094 7.95844 12.5416 5.89062 10 5.89062ZM10 10.2656C10.7325 10.2656 11.3281 10.8616 11.3281 11.5938C11.3281 12.2456 10.855 12.7869 10.2344 12.8981V13.3928C10.2344 13.5222 10.1294 13.6272 10 13.6272C9.87063 13.6272 9.76562 13.5222 9.76562 13.3928V12.8981C9.145 12.7869 8.67188 12.2456 8.67188 11.5938C8.67188 11.4644 8.77688 11.3594 8.90625 11.3594C9.03562 11.3594 9.14062 11.4644 9.14062 11.5938C9.14062 12.0678 9.52594 12.4531 10 12.4531C10.4741 12.4531 10.8594 12.0678 10.8594 11.5938C10.8594 11.1197 10.4741 10.7344 10 10.7344C9.2675 10.7344 8.67188 10.1384 8.67188 9.40625C8.67188 8.75437 9.145 8.21312 9.76562 8.10188V7.60719C9.76562 7.47781 9.87063 7.37281 10 7.37281C10.1294 7.37281 10.2344 7.47781 10.2344 7.60719V8.10188C10.855 8.21312 11.3281 8.75437 11.3281 9.40625C11.3281 9.53562 11.2231 9.64062 11.0938 9.64062C10.9644 9.64062 10.8594 9.53562 10.8594 9.40625C10.8594 8.93219 10.4741 8.54688 10 8.54688C9.52594 8.54688 9.14062 8.93219 9.14062 9.40625C9.14062 9.88031 9.52594 10.2656 10 10.2656Z"
        fill={active ? 'white' : '#A2A3A4'}
      />
    </svg>
  );
};

export default TransactionWalletIcon;
