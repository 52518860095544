import styles from '../../styles/components/games/min-max-slider.module.scss';
import classnames from 'classnames/bind';
import { BET_DECIMAL_CONFIG, DEFAULT_VALUE } from '../../constant/game';
import { MAX_BETTING_TOKEN } from '../../constant';
import BigNumber from 'bignumber.js';

interface MinMaxSliderProps {
  betAmount: string;
  minAmount?: string;
  maxAmount?: string;
  setBetAmount: (amount: string) => void;
}
const cx = classnames.bind(styles);
const MinMaxSlider = ({ betAmount, setBetAmount, minAmount, maxAmount }: MinMaxSliderProps) => {
  return (
    <div className={cx('slider-container')}>
      <div
        className={cx('min-max-btn')}
        onClick={() =>
          setBetAmount(Number(minAmount).toFixed(BET_DECIMAL_CONFIG) || DEFAULT_VALUE)
        }>
        Min
      </div>
      <input
        type="range"
        min={minAmount || 0}
        max={maxAmount || MAX_BETTING_TOKEN}
        step="0.0000001"
        className={cx('slider')}
        id="myRange"
        value={betAmount}
        onChange={(event) =>
          setBetAmount(new BigNumber(event.target.value).toFixed(BET_DECIMAL_CONFIG))
        }
      />
      <div
        className={cx('min-max-btn')}
        onClick={() =>
          setBetAmount(
            Number(maxAmount).toFixed(BET_DECIMAL_CONFIG) ||
              MAX_BETTING_TOKEN.toFixed(BET_DECIMAL_CONFIG)
          )
        }>
        Max
      </div>
    </div>
  );
};

export default MinMaxSlider;
