import ComingSoon from '../ComingSoon';
import Layout from '../LayoutComponentSideMenu';

const Fee = () => {
  return (
    <Layout>
      <ComingSoon />
    </Layout>
  );
};

export default Fee;
