const Anchor = (props: any) => {
  return (
    <svg width={23} height={30} viewBox="0 0 23 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.418 7.77436L0.3125 0.000488281L11.418 29.9854V7.77436ZM11.4181 7.77436L22.5236 0.000488281L11.4181 29.9854V7.77436Z"
        fill={props.color || 'white'}
      />
    </svg>
  );
};

export default Anchor;
