import React from 'react';
import ModalUI from '../UI/Modal';
import styles from '../../styles/components/modal/modal-security.module.scss';
import classNames from 'classnames/bind';
import QRCode from 'react-qr-code';
import CopyIcon from '../Icons/CopyIcon';
import InputCode from '../UI/InputCode';
import EyePasswordIcon from '../Icons/EyePasswordIcon';

const cx = classNames.bind(styles);

interface PropsType {
  isShowing: boolean;
  toggle: () => void;
}

const ModalSecurity2FA = ({ isShowing, toggle }: PropsType) => {
  return (
    <ModalUI
      isShowing={isShowing}
      hide={toggle}
      title={<div className={cx('title')}>Security-2FA</div>}>
      <div className={cx('content')}>
        <div className={cx('notice')}>
          Download and install Google Authenticator. Enable Two-factor Authentication to protect
          your account from unauthorized access.
        </div>

        <div className={cx('note-qr')}>
          Scan the QR code with your Google Authenticator App or enter the secret key manually.
        </div>
        <div className={cx('address-qr')}>
          <div className={cx('content')}>
            <div>
              <QRCode size={110} value={'1212412341241'} />
            </div>
          </div>
        </div>

        <div className={cx('select-key')}>
          <div className={cx('title-key')}>Your secret key</div>
          <div className={cx('input-key')}>
            <input type="text" value="5bgzftwttxvv5ec6zxdxg4q5zguqze45" disabled />
            <div>
              <CopyIcon />
            </div>
          </div>
        </div>

        <div className={cx('note-key')}>
          Write down this code, never reveal it to others. You can use it to regain access to your
          account if there is no access to the authenticator.
        </div>

        <div className={cx('identity')}>
          <div className={cx('title')}>Verification code</div>
          <div className={cx('input-code')}>
            <InputCode length={6} />
          </div>
          <div className={cx('input-password')}>
            <input type="text" placeholder="Login Password" />
            <div>
              <EyePasswordIcon />
            </div>
          </div>
        </div>

        <div className={cx('button-enable')}>
          <button>Enable</button>
        </div>
      </div>
    </ModalUI>
  );
};

export default ModalSecurity2FA;
