/* eslint-disable no-unused-expressions */
import clasnames from 'classnames/bind';
import { debounce } from 'lodash';
import React from 'react';
import styles from '../../styles/components/modal/modal-auth.module.scss';

const cx = clasnames.bind(styles);

interface PropsType {
  placeholder?: string;
  type?: string;
  isDebounce?: boolean;
  onChange: any;
  handleBlur?: (e: any) => void;
}

function InputPopup(
  { placeholder = '', type = 'text', isDebounce, onChange, handleBlur, ...props }: PropsType,
  ref: any
) {
  return (
    <input
      ref={ref}
      placeholder={placeholder}
      className={cx('inp-login')}
      {...props}
      onBlur={(e) => {
        handleBlur && handleBlur(e);
      }}
      onChange={isDebounce ? debounce(onChange, 1000) : onChange}
      type={type}
    />
  );
}

export default React.forwardRef(InputPopup);
