export default function NotificationIcon() {
  return (
    <svg width={20} height={22} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 21.8337C10.6709 21.8345 11.3254 21.6263 11.8725 21.238C12.4197 20.8497 12.8322 20.3006 13.0528 19.667H6.94717C7.1678 20.3006 7.58035 20.8497 8.12747 21.238C8.67459 21.6263 9.32909 21.8345 10 21.8337V21.8337ZM17.5833 13.8018V8.83366C17.5833 5.34858 15.2163 2.41274 12.0096 1.52983C11.6922 0.730325 10.9165 0.166992 10 0.166992C9.0835 0.166992 8.30784 0.730325 7.99042 1.52983C4.78375 2.41383 2.41667 5.34858 2.41667 8.83366V13.8018L0.567418 15.6511C0.466622 15.7515 0.386685 15.8709 0.332209 16.0023C0.277733 16.1338 0.249795 16.2747 0.250001 16.417V17.5003C0.250001 17.7876 0.364138 18.0632 0.567302 18.2664C0.770466 18.4695 1.04602 18.5837 1.33333 18.5837H18.6667C18.954 18.5837 19.2295 18.4695 19.4327 18.2664C19.6359 18.0632 19.75 17.7876 19.75 17.5003V16.417C19.7502 16.2747 19.7223 16.1338 19.6678 16.0023C19.6133 15.8709 19.5334 15.7515 19.4326 15.6511L17.5833 13.8018Z"
        fill="white"
        fillOpacity="0.75"
      />
    </svg>
  );
}
