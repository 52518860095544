import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { REGEX } from '../../constant';
import { useAppSelector } from '../../stores';
import { setUserInfo } from '../../stores/UserInformation';
import styles from '../../styles/components/user-setting/edit-nickname.module.scss';
import MagicPenIcon from '../Icons/MaginPenIcon';

const cx = classNames.bind(styles);

interface PropsType {
  isShowing: boolean;
  openModalEditAvatar: () => void;
  name: string;
  avatar?: string;
  id: string;
  reload: () => void;
}

const EditNickName = ({ isShowing, openModalEditAvatar, name, avatar, id, reload }: PropsType) => {
  const dispatch = useDispatch();
  const { account, userInfo } = useAppSelector((state) => ({
    account: state.account.accountInfo,
    userInfo: state.userInfo.userInfo
  }));
  const [displayEditBtn, setDisplayEditButton] = useState(false);
  const [newName, setNewName] = useState(name);
  const [loading, setLoading] = useState(false);
  const [errorMes, setErrorMes] = useState('');

  const userIdentityClient = useAppSelector((state) => state.app.userIdentityClient);

  const updateNickName = async () => {
    const body = {
      nickName: newName || ''
    };
    setLoading(true);
    try {
      const res = await userIdentityClient.updateUser(id, body);
      if (res) {
        setLoading(false);
        toast.success('Updated done!');
        dispatch(setUserInfo({ ...userInfo, nickName: res.nickName || '' }));
      }
    } catch (error) {
      setLoading(false);
      toast.error('Update error!');
      console.log(error);
    }
  };

  const handleUpdateNickName = () => {
    const nickNameRegex = new RegExp(REGEX.NICKNAME);
    if (newName === name) {
      setErrorMes('Please use a different nickname');
    } else if (!nickNameRegex.test(newName)) {
      setErrorMes('Nickname must be from 2 to 18 characters');
    } else {
      setErrorMes('');
      updateNickName();
    }
  };

  useEffect(() => {
    setNewName(name);
  }, [isShowing, name]);

  return (
    <div className={cx('content')}>
      <div
        className={cx('avatar')}
        onMouseEnter={() => setDisplayEditButton(true)}
        onMouseLeave={() => setDisplayEditButton(false)}
        onClick={openModalEditAvatar}>
        <img src={avatar} />
        {displayEditBtn && (
          <div className={cx('edit-wrapper')}>
            <div className={cx('edit-button')}>
              <MagicPenIcon /> Edit Avatar
            </div>
          </div>
        )}
      </div>
      <div className={cx('user-name')}>
        <div>Nickname</div>
        <input value={newName} onChange={(event) => setNewName(event?.target.value)} />
        <div className={cx('error')}>{errorMes}</div>
        <div className={cx('warning')}>
          Do not use special punctuation, otherwise your account may not be supported.
        </div>
      </div>
      <div className={cx('modify')}>
        <div
          onClick={handleUpdateNickName}
          className={cx((newName.trim().length === 0 || loading) && 'disable')}>
          {loading ? 'Loading...' : 'Modify'}
        </div>
      </div>
    </div>
  );
};

export default EditNickName;
