import React from 'react';

const SettingIcon = () => {
  return (
    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.875 5.5H4.375M13.125 5.5H6.875M1.875 9.875H9.375M13.125 9.875H10.7812"
        stroke="url(#paint0_linear_1824_29152)"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5.625 6.125C6.14277 6.125 6.5625 5.70527 6.5625 5.1875C6.5625 4.66973 6.14277 4.25 5.625 4.25C5.10723 4.25 4.6875 4.66973 4.6875 5.1875C4.6875 5.70527 5.10723 6.125 5.625 6.125Z"
        fill="url(#paint1_linear_1824_29152)"
        stroke="url(#paint2_linear_1824_29152)"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M9.375 10.8125C9.89277 10.8125 10.3125 10.3928 10.3125 9.875C10.3125 9.35723 9.89277 8.9375 9.375 8.9375C8.85723 8.9375 8.4375 9.35723 8.4375 9.875C8.4375 10.3928 8.85723 10.8125 9.375 10.8125Z"
        fill="url(#paint3_linear_1824_29152)"
        stroke="url(#paint4_linear_1824_29152)"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1824_29152"
          x1="1.875"
          y1="7.6875"
          x2="13.125"
          y2="7.6875"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1824_29152"
          x1="4.6875"
          y1="5.1875"
          x2="6.5625"
          y2="5.1875"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1824_29152"
          x1="4.6875"
          y1="5.1875"
          x2="6.5625"
          y2="5.1875"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1824_29152"
          x1="8.4375"
          y1="9.875"
          x2="10.3125"
          y2="9.875"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1824_29152"
          x1="8.4375"
          y1="9.875"
          x2="10.3125"
          y2="9.875"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE036" />
          <stop offset={1} stopColor="#FBC006" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SettingIcon;
