import BigNumber from 'bignumber.js';
import React from 'react';

interface PropsType {
  amount: number | string;
  decimalPlaces?: number;
  color?: string;
  fontSize?: string;
}

const CurrencyAmount = ({
  amount,
  decimalPlaces,
  color = 'white',
  fontSize = '16px'
}: PropsType) => {
  const amountBalance = new BigNumber(amount).toFixed(decimalPlaces || 3, BigNumber.ROUND_DOWN);
  const realAmount = new BigNumber(amountBalance).toString().length;
  const amountStr = amountBalance.substring(0, realAmount);
  const suffix = amountBalance.substring(realAmount, amountBalance.length);
  return (
    <>
      <span style={{ color, fontSize }}>{new BigNumber(amountStr).toFormat()}</span>
      <span style={{ color, opacity: 0.5, fontSize }}>{suffix}</span>
    </>
  );
};

export default CurrencyAmount;
