import styles from '../../styles/UI/table.module.scss';
import classnames from 'classnames/bind';
import { Columns } from '../../interfaces/Table';
import Pagination from './Pagination';
import React from 'react';
import MushroomNodata from '../Icons/MushroomNoData';

const cx = classnames.bind(styles);

interface PropsType {
  columns: Columns[];
  dataTable: any[];
  pagination?: {
    page: number;
    pageSize: number;
    total: number;
  };
  isBorder?: boolean;
  changePage?: (page: number) => void;
  footer?: React.ReactNode;
  showNodata?: boolean;
}

export default function Table({
  columns,
  dataTable,
  pagination,
  changePage,
  footer,
  isBorder = true,
  showNodata = false
}: PropsType) {
  return (
    <div>
      <div className={cx('table-root')}>
        <table className={cx('table-container', isBorder && 'border')}>
          <thead>
            <tr>
              {columns.map((col) => (
                <th
                  key={col.key}
                  className={cx('table-header-item', isBorder && 'border-bottom')}
                  style={{ textAlign: col.align || 'center' }}>
                  {col.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataTable.length > 0 && !showNodata ? (
              dataTable.map((data: any, index) => {
                return (
                  <tr key={index}>
                    {columns.map((col) => (
                      <td key={col.key + index} className={cx('data-item')}>
                        {col.render ? col.render(data[col.key]) : data[col.key]}
                      </td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <tr className={cx('nodata')}>
                <td colSpan={columns.length} className={cx('nodata-item')}>
                  <MushroomNodata />
                  <div className={cx('nodata-text')}>Oops! There is no data yet!</div>
                </td>
              </tr>
            )}

            {footer && (
              <tr className={cx('nodata')}>
                <td colSpan={columns.length} className={cx('nodata-item')}>
                  {footer}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {changePage && pagination && dataTable.length > 0 && (
        <div className={cx('pagination-container')}>
          <Pagination pagination={pagination} changePage={changePage} />
        </div>
      )}
    </div>
  );
}
