import React from 'react';

const SmileIcon = () => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_497_1601)">
        <path
          d="M10 20C7.32891 20 4.8177 18.9598 2.92891 17.0711C1.0402 15.1823 0 12.6711 0 10C0 7.32891 1.0402 4.8177 2.92891 2.92891C4.8177 1.0402 7.32891 0 10 0C12.6711 0 15.1823 1.0402 17.0711 2.92891C18.9598 4.8177 20 7.32891 20 10C20 12.6711 18.9598 15.1823 17.0711 17.0711C15.1823 18.9598 12.6711 20 10 20ZM10 1.5625C5.34754 1.5625 1.5625 5.34754 1.5625 10C1.5625 14.6525 5.34754 18.4375 10 18.4375C14.6525 18.4375 18.4375 14.6525 18.4375 10C18.4375 5.34754 14.6525 1.5625 10 1.5625ZM13.6616 11.7261C13.2976 11.4944 12.8147 11.6017 12.5831 11.9658C12.5734 11.9809 11.6012 13.4789 9.96094 13.4789C8.3207 13.4789 7.34844 11.9809 7.33879 11.9658C7.10715 11.6018 6.6243 11.4945 6.26027 11.7261C5.89625 11.9577 5.78895 12.4406 6.02059 12.8046C6.07871 12.8959 7.47262 15.0414 9.96094 15.0414C12.4493 15.0414 13.8432 12.896 13.9013 12.8046C14.1329 12.4406 14.0256 11.9577 13.6616 11.7261ZM6.5625 6.44531C7.10184 6.44531 7.53906 6.88254 7.53906 7.42188C7.53906 7.96121 7.10184 8.39844 6.5625 8.39844C6.02316 8.39844 5.58594 7.96121 5.58594 7.42188C5.58594 6.88254 6.02316 6.44531 6.5625 6.44531ZM12.4219 7.42188C12.4219 7.96121 12.8591 8.39844 13.3984 8.39844C13.9378 8.39844 14.375 7.96121 14.375 7.42188C14.375 6.88254 13.9378 6.44531 13.3984 6.44531C12.8591 6.44531 12.4219 6.88254 12.4219 7.42188Z"
          fill="#6F6F6F"
        />
      </g>
      <defs>
        <clipPath id="clip0_497_1601">
          <rect width={20} height={20} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SmileIcon;
