import classNames from 'classnames/bind';
import { acceptedCurrencies } from '../../constant/data-home-page';
import styles from '../../styles/components/home/accepted-currencies.module.scss';
import Dropdown from '../UI/Dropdown';
import Deposit from '../layouts/Deposit';
import { useEffect, useRef, useState } from 'react';
import ModalUI from '../UI/Modal';

const cx = classNames.bind(styles);

const AcceptedCurrencies = () => {
  const [width, setWidth] = useState<number>(1);
  const [isShowing, setIsShowing] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [fullName, setFullName] = useState<string>('');
  const myRef = useRef<any>(null);
  const getWidth = () => {
    const width = myRef?.current?.clientWidth;
    setWidth(width);
  };
  const toggle = () => {
    setIsShowing(!isShowing);
  };
  useEffect(() => {
    getWidth();
  }, [myRef]);
  useEffect(() => {
    if (isShowing) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'visible';
  }, [isShowing]);
  return (
    <div className={cx('wrapper')} ref={myRef}>
      <div className={cx('header')}>
        <img src={`/images/list-accepted-currencies/accept.svg`} alt="accepted" />
        <p>Accepted Currencies</p>
      </div>

      <ul className={cx('list-navigate-page')}>
        {acceptedCurrencies.map((item) => {
          return (
            <li className={cx('item')} key={item.key}>
              <div className={cx('background')}>
                {width > 500 && (
                  <Dropdown
                    name={item.key}
                    placement="left"
                    overlay={<Deposit name={item.key} fullName={item.fullName} />}>
                    <img src={`/images/list-accepted-currencies/${item.key}.svg`} alt={item.key} />
                  </Dropdown>
                )}
                {width < 500 && (
                  <div>
                    <img
                      src={`/images/list-accepted-currencies/${item.key}.svg`}
                      alt={item.key}
                      onClick={() => {
                        setIsShowing(true);
                        setName(item.key);
                        setFullName(item.fullName);
                      }}
                    />
                    <ModalUI
                      hide={toggle}
                      isShowing={isShowing}
                      width={'230px'}
                      height={'160px'}
                      padding={'16px 10px 0px'}
                      opacity={'0.1'}>
                      <div style={{ marginTop: '-40px' }}>
                        {' '}
                        <Deposit
                          name={name}
                          fullName={fullName}
                          boxshadow={'none'}
                          background={'none'}
                        />
                      </div>
                    </ModalUI>
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default AcceptedCurrencies;
