import classNames from 'classnames/bind';
import BestSeller from '../../Icons/BestSeller';
import styles from '../../../styles/components/affiliate/affiliate-management/affiliate-rewards.module.scss';
import UsdRewardsIcon from '../../Icons/UsdRewardsIcon';
import CommisionRewardsIcon from '../../Icons/CommissionRewardsIcon';
import ArrowRightIcon from '../../Icons/ArrowRightIcon';
import InfoYellowIcon from '../../Icons/InfoYellowIcon';
import Friends, { Friend } from './Friends';
import { useEffect, useState } from 'react';
import { UserEntity } from 'user-identity-js/lib/entities/user.entity';
import USDRewardsRule from '../ModalRewardsRule/USDRewardsRule';
import CommisionRewardsRule from '../ModalRewardsRule/CommissionRewardsRule';
import { NetworkEntity, RefRewardStatistic } from 'game-profile-js/lib/client/entities/all.entity';
import BigNumber from 'bignumber.js';
import { useAppSelector } from '../../../stores';

const cx = classNames.bind(styles);

const AffiliateReward = () => {
  const pageSize = 5;
  const gameProfile = useAppSelector((state) => state.app.gameProfileClient);
  const userIdentitySDK = useAppSelector((state) => state.app.userIdentityClient);

  const [gameData, setGameData] = useState<RefRewardStatistic>();
  const [friendUsdRewards, setFriendUsdRewards] = useState<any>([]);
  const [currentPageUsdReward, setCurrentPageUsdReward] = useState(1);
  const [currentPageCommission, setCurrentPageCommision] = useState(1);
  const [currentPageCountUsdReward, setCurrentPageCountUsdReward] = useState(1);
  const [currentPageCountCommission, setCurrentPageCountCommission] = useState(1);
  const [friendCommissionRewards, setFriendCommissionRewards] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenCommission, setIsModalOpenCommission] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showModalCommision = () => {
    setIsModalOpenCommission(true);
  };

  useEffect(() => {
    getGameData();
  }, []);

  useEffect(() => {
    getNetworkReward(currentPageUsdReward, pageSize);
  }, [currentPageUsdReward, pageSize]);

  useEffect(() => {
    getNetworkBetting(currentPageCommission, pageSize);
  }, [currentPageCommission, pageSize]);

  const getGameData = async () => {
    const gameData = await gameProfile.getRewardStatistic();
    if (gameData) {
      setGameData(gameData);
    }
  };

  const getNetworkReward = async (page: number, take: number) => {
    const res = await gameProfile.getNetworkReward({ page, take: take });
    if (res) {
      const formattedUsers = await formatUserData(res.data);
      setFriendUsdRewards(formattedUsers);
      setCurrentPageCountUsdReward(res.meta.itemCount);
    }
  };

  const getNetworkBetting = async (page: number, take: number) => {
    const res = await gameProfile.getNetworkBetting({ page, take: take });
    if (res) {
      const formattedUsers = await formatUserData(res.data);
      setFriendCommissionRewards(formattedUsers);
      setCurrentPageCountCommission(res.meta.itemCount);
    }
  };
  const getUserProfiles = async (userIds: string[]) => {
    const res = await userIdentitySDK.getUsers({ ids: userIds });
    return res;
  };

  const handlePageCommisionChange = (page: number) => {
    setCurrentPageCommision(page);
  };

  const handlePageUsdRewardsChange = (page: number) => {
    setCurrentPageUsdReward(page);
  };

  const formatUserData = async (users: NetworkEntity[]) => {
    const userIds = users.map((user: NetworkEntity) => user.refInviter.userProfile.userId);
    const userProfiles = await getUserProfiles(userIds);
    const formattedUsers = users.map((user: NetworkEntity) => {
      const userProfile = user.refInviter.userProfile;
      const formattedUser: Friend = {
        id: userProfile.userId,
        nickname: 'Hidden',
        vipLevel: userProfile.vipLevel,
        earned: new BigNumber(user.earnedMoney).toFixed(2)
      };
      const userEntity = userProfiles.data.find(
        (userEntity: UserEntity) => userEntity.id === userProfile.userId
      );
      if (userEntity) {
        formattedUser.nickname = userEntity.nickName || 'Hidden';
      }
      return formattedUser;
    });
    return formattedUsers;
  };

  return (
    <div className={cx('container')}>
      <div className={cx('title')}>
        <div className={cx('rewards-block')}>
          <BestSeller />
          <div>Affiliate Rewards</div>
        </div>
      </div>
      <img src="/images/arrow.svg" alt="" className={cx('arrow')} />
      <div className={cx('reward-info')}>
        <div className={cx('usd-reward')}>
          <div className={cx('info')}>
            <UsdRewardsIcon />
            <div className={cx('title')}>
              Extra <span className={cx('highlight')}>USD Rewards</span>
            </div>
            <div className={cx('rule')} onClick={showModal}>
              USD Rewards Rules <ArrowRightIcon />
            </div>
            <div className={cx('newly-rewards')}>
              <div className={cx('rewards-value')}>
                ${gameData?.availableDistributionReward.toFixed(2) || '0.00'}
              </div>
              <div className={cx('label')}>Newly Available Rewards</div>
            </div>
            <div className={cx('description')}>
              <div className={cx('reward-detail')}>
                <div className={cx('sub-reward')}>
                  Received:{' '}
                  <span className={cx('sub-reward-value')}>
                    ${Number(gameData?.receivedDistributionReward)?.toFixed(2) || '0.00'}
                  </span>
                </div>
                <div className={cx('sub-reward')}>
                  <InfoYellowIcon /> Locked:{' '}
                  <span className={cx('sub-reward-value')}>
                    ${Number(gameData?.lockedDistributionReward)?.toFixed(2) || '0.00'}
                  </span>
                </div>
              </div>
              <div>Your current friends staying active will unlock you more Rewards</div>
            </div>
          </div>
          <Friends
            friends={friendUsdRewards}
            page={currentPageUsdReward}
            pageSize={pageSize}
            total={currentPageCountUsdReward}
            handlePageChange={handlePageUsdRewardsChange}
          />
        </div>
        <div className={cx('commission-reward')}>
          <div className={cx('info')}>
            <CommisionRewardsIcon />
            <div className={cx('title')}>
              My <span className={cx('highlight')}>Commission Rewards</span>
            </div>
            <div className={cx('rule')} onClick={showModalCommision}>
              Commission Rewards Rules <ArrowRightIcon />
            </div>
            <div className={cx('newly-rewards')}>
              <div className={cx('rewards-value')}>
                ${gameData?.availableCommissionReward.toFixed(2) || '0.00'}
              </div>
              <div className={cx('label')}>Newly Available Rewards</div>
            </div>
            <div className={cx('newly-rewards')}>
              <div>
                <span className={cx('highlight')}>Commission</span> You’ve Received in Total:{' '}
                <span className={cx('sub-reward-value')}>
                  ${gameData?.receivedCommissionReward?.toFixed(2) || '0.00'}
                </span>
              </div>
            </div>
          </div>
          <Friends
            friends={friendCommissionRewards}
            page={currentPageCommission}
            pageSize={pageSize}
            total={currentPageCountCommission}
            handlePageChange={handlePageCommisionChange}
          />
        </div>
      </div>
      <USDRewardsRule
        isModalOpen={isModalOpen}
        toggle={() => {
          setIsModalOpen(false);
        }}
      />
      <CommisionRewardsRule
        isModalOpen={isModalOpenCommission}
        toggle={() => {
          setIsModalOpenCommission(false);
        }}
      />
    </div>
  );
};

export default AffiliateReward;
