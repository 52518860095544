import Layout from '../LayoutComponentSideMenu';
import styles from '../../styles/components/help/faq.module.scss';
import classNames from 'classnames/bind';
import { faqData } from '../../constant/faq-data';
import PlusIconGreen from '../Icons/PlusIconGreen';
import { useState } from 'react';

const cx = classNames.bind(styles);

const FAQ = () => {
  const [faqActive, setFaqActive] = useState<number[]>([]);

  const handleClickShowAnswer = (key: number) => {
    if (faqActive.includes(key)) {
      setFaqActive(faqActive.filter((item) => item !== key));
    } else {
      setFaqActive([...faqActive, key]);
    }
  };

  return (
    <Layout>
      <div className={cx('faq-page')}>
        <ul className={cx('list-faq')}>
          {faqData.map((data) => {
            const active = faqActive.includes(data.key);
            return (
              <li
                onClick={() => handleClickShowAnswer(data.key)}
                className={cx('item')}
                key={data.key}>
                <div className={cx('question')}>
                  <div>{data.question}</div>
                  <div>{active ? <div className={cx('subtraction')} /> : <PlusIconGreen />}</div>
                </div>
                <div
                  className={cx('answer', active && 'answer-active')}
                  dangerouslySetInnerHTML={{ __html: data.answer }}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </Layout>
  );
};

export default FAQ;
